import React from "react";
// import collegeLogo from "../../../../assets/images/IIM.png";
import thumbsUp from "../../../../assets/icons/thumbsUp.svg";
import viewGallery from "../../../../assets/icons/viewGallery.svg";
import propTypes from "prop-types";
import { endpoint } from "../../../../API/config";
import { apirequest_schoolsAPI } from "../../../../API/api";
import { useQuery , useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { loginDetails } from "../../../../redux/features/login/LoginSlice";
import { setShowGallery } from "../../../../redux/features/schools/SchoolSlice";
import UnLike from "../../../../assets/icons/unlike.svg";
// import Gallery from "../../global/gallery/Gallery";
// import { setShowGallery } from "../../../../redux/features/schools/SchoolSlice";

function Title({ data, programData }) {
  const LoginDetails = useSelector(loginDetails);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { refetch } = useQuery(
    [endpoint.AddStudentSchoolLike.key],
    () =>
      apirequest_schoolsAPI({
        method: "post",
        url: endpoint.AddStudentSchoolLike.addr,
        headers: {
          "access-token": LoginDetails.token,
        },
        data: {
          isLiked:programData?.isSchoolLiked=== "1" ? "0" : "1",
          schoolId: data.schoolId,
          schoolProgramCourseMappingId: programData.schoolProgramCourseMapping,
        },
      }),
    {
      enabled: false,
      onSuccess : ()=>queryClient.invalidateQueries(endpoint.getSchoolDetails.key)
    }
  );

  const handleClick = () => {
    refetch();
  };

  return (
    <div className="flex items-center justify-between mb-16 mt-8 ">
      <div className="flex items-center ">
        <img src={data?.image} alt="logo" className="mr-6 w-16 h-16" loading="lazy" />
        <div>
          <div className="text-xl mb-2  items-center justify-center">
            <span className=" font-medium text-3xl">{data.schoolName}&nbsp;-&nbsp;<span className="text-xl font-normal">{programData?.programs}</span></span>
            

          </div>
          <p className="text-xl font-normal">{data.location}</p>
        </div>
      </div>

      <div className="flex items-center">
        <img
          onClick={() =>
            handleClick(data.schoolId, programData.schoolProgramCourseMapping)
          }
          src={programData?.isSchoolLiked ==="1" ? thumbsUp : UnLike}
          alt=""
          className="mr-7 cursor-pointer w-8 h-8 "
          loading="lazy"
        />
        <a
          href={data.websiteUrl}  
          rel="noreferrer"
          target="_blank"
          className="mr-8 text-primary-200 underline cursor-pointer w-48 truncate text-lg font-medium"
        >
          {data.websiteUrl}
        </a>
        <div
          className="flex cursor-pointer w-36"
          onClick={() => dispatch(setShowGallery(true))}
        >
          <img src={viewGallery} alt="" className="mr-2 " loading="lazy" />
          <p className="text-lg font-normal w-full">View Gallery</p>
        </div>
      </div>
    </div>
  );
}

Title.propTypes = {
  data: propTypes.any,
  programData: propTypes.any,
};

export default Title;
