// import React, {useSelector} from "react";
import React from "react";
import propTypes from "prop-types";
import {loginDetails } from "../../../redux/features/login/LoginSlice";
import {  useSelector } from "react-redux";
function ProfileDropdownMenuItem({ src, id, title, path, handleClick }) {
  const LoginDetails = useSelector(loginDetails);
  return (
    <div className="flex flex-row ml-2">
      {src && (
        <div className="mt-3 items-center">
          <img className="w-5 h-5" src={src} alt="logout" loading="lazy"/>
        </div>
      )}
      <div
        onClick={() => {
          if (typeof handleClick === "function") handleClick(id);
        }}
        className={` ml-5 mt-2 mb-2 mr-5 text-ibm  items-center ${id !==1 &&"hover:text-black hover:underline"} `}
      >
        { path ? (
          title === "CMS Login" ? (
            <a href={path} target="_blank" rel="noreferrer">
              {title}
            </a>
          ) : (
            <p className=" uppercase">{id===1?LoginDetails.student_imspin:title}</p>
          )
        ) : (
          <p className=" cursor-pointer ">{title}</p>
        )}
      </div>
    </div>
  );
}

ProfileDropdownMenuItem.propTypes = {
  src: propTypes.string,
  id: propTypes.number,
  title: propTypes.string,
  path: propTypes.string,
  handleClick: propTypes.func,
};

export default ProfileDropdownMenuItem;
