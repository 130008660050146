import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Tag from "./tag";
import propTypes from "prop-types";


function Block({ data, title }) {

  const convertToMinutes = hms => {
    const a = hms.split(":");
    const minutes = +a[0] * 60 + +a[1];
    return minutes;
  };

  return (
    <div className="font-ibm w-[423px] h-[405px] shadow-outer rounded-2xl ">
      {/* header */}
      <div className="px-7 py-5 flex items-center justify-between shadow-outer w-full">
        <p className="text-lg font-medium ">{title}</p>
        <Link to={`/school/${title.toLowerCase()}`}>
          <p
            className="text-lg font-medium text-primary-200 ">
            View All
          </p>
        </Link>
      </div>

      {/* Body */}
      <div className="px-7 py-6 ">
        {data.slice(0, 3).map(item => (
          <div key={item.articleId || item.eventId} className="mb-6">
            <a href={item?.htmlContent || item?.registrationUrl} target="_blank" rel="noreferrer noopener">
              <p className="mb-[10px] text-base font-normal">{item.articleName || item.eventTitle}</p>
            </a> 
            <div className="flex items-center space-x-2 ">
              {item.activeDate && (
                <Tag>{moment(item.activeDate).format("DD MMM YYYY")}</Tag>
              )}
              {item.date && (
                <Tag>{moment(item.date).format("DD MMM YYYY")}</Tag>
              )}
              {item.articleId && item.duration && (
                <Tag>{convertToMinutes(item.duration)} min read</Tag>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

Block.propTypes = {
  data: propTypes.array,
  title: propTypes.string,
};

export default Block;
