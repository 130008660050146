import React from "react";
import testIcon from "../../../assets/icons/calendar_tests.svg";
import mentorIcon from "../../../assets/icons/calendar_mentor.svg";
import classIcon from "../../../assets/icons/calendar_classes.svg";
import eventIcon from "../../../assets/icons/calendar_events.svg";
import missedClassCalendarIcon from "../../../assets/icons/missedClassCalendar.svg";
import attendedClassCalendarIcon from "../../../assets/icons/attendedClassCalendar.svg";
import moment from "moment";

let baseWidth = 14.2857;

export const getWidth = (startDate, endDate) => {
  let eventLength = endDate.diff(startDate, "days") + 1;
  let returnWidth = eventLength * baseWidth;
  return returnWidth + "%";
};

export const getMaxWidth = day => {
  let daysRemaining = 7 - day;
  let returnWidth = baseWidth * daysRemaining;
  return returnWidth + "%";
};

export const getMaxWidthForWeek = (event, week) => {
  let day = event.start.day();
  let weekStart = week[0];
  let weekEnd = week[6];
  let daysRemaining = 7 - day;
  if (event.start.isBefore(weekStart)) {
    if (event.end.isAfter(weekEnd)) {
      daysRemaining = 7;
    } else {
      daysRemaining = event.end.day() + 1;
    }
  }
  let returnWidth = baseWidth * daysRemaining;
  return returnWidth + "%";
};

export const getDaysInWeek = selectedDay => {
  let dayOfWeek = moment(selectedDay).day();
  let totalDays = [];
  let j = 0;
  for (let i = dayOfWeek; i < 7; i++) {
    totalDays.push(moment(selectedDay).add(j++, "days"));
  }
  if (moment(selectedDay).day() !== 0) {
    for (let i = 1; i <= moment(selectedDay).day(); i++) {
      totalDays.unshift(moment(selectedDay).subtract(i, "days"));
    }
  }
  return totalDays;
};

export const getDaysInMonth = (selectedMonth) => {
  const totalDays = new Array();
  const totalLimit = 42;
  // Add days of current month
  for (let i = 0; i < selectedMonth.daysInMonth(); i++) {
    totalDays.push(moment(selectedMonth).add(i, "days"));
  }
  // Add days of previous month if first day of current month isn't Sunday
  if (moment(selectedMonth).day() !== 0) {
    const previousMonth = moment(selectedMonth)
      .subtract(1, "months")
      .endOf("month")
      .startOf("day");
    for (let i = 0; i < moment(selectedMonth).day(); i++) {
      // moment(selectedMonth).day() returns any number between 0-6 , where 0 = sunday
      totalDays.unshift(moment(previousMonth).subtract(i, "days"));
    }
  }
  // Add days of next month if total days is not enough
  if (totalDays.length < totalLimit) {
    const nextMonth = moment(selectedMonth).add(1, "months");
    const daysToFill = totalLimit - totalDays.length;
    for (let i = 0; i < daysToFill; i++) {
      totalDays.push(moment(nextMonth).add(i, "days"));
    }
  }
  return totalDays;
};

export const scheduleViewDaysInMonth = (selectedMonth) => {
  const totalDays = new Array();
  for (let i = 0; i < selectedMonth.daysInMonth(); i++) {
    totalDays.push(moment(selectedMonth).add(i, "days"));
  }
  return totalDays;
};

export const calculateTop = (start_date, date) => {
  // 1440 minutes (24hrs) = 100%, therefore 1 min = 100/1440 which is equal to 0.06944 %
  let one_minute = 0.06944;
  let diff = start_date.diff(date.startOf("day"), "minutes");
  let top = one_minute * diff;
  return top + "%";
};

export const calculateWeeklyTop = index => {
  const baseHeight = 37;
  return index * baseHeight + "px";
};

export const calculateWidth = len => {
  return 99 / len + "%";
};

export const calculateLeftPosition = (arrLen, ind) => {
  let split = 100 / arrLen;
  return split * ind + "%";
};

export const calculateWeeklyLeftPosition = (event, week) => {
  const day = event.start.day();
  const weekStart = week[0];
  if (event.start.isBefore(weekStart)) return 0 + "%";
  return day * baseWidth + "%";
};

export const calculateHeight = (start_date, end_date) => {
  let one_minute = 0.06944;
  let diff = end_date.diff(start_date, "minutes");
  let height = one_minute * diff + "%";
  return height;
};

export const returnIcon = type => {
  const lowerCaseFilterNameWithoutSpace =
    type && type.replace(/\s/g, "").toLowerCase();
  let src = "";
  if (lowerCaseFilterNameWithoutSpace === "all") return;
  if (
    lowerCaseFilterNameWithoutSpace === "test" ||
    lowerCaseFilterNameWithoutSpace === "tests"
  )
    src = testIcon;
  if (
    lowerCaseFilterNameWithoutSpace === "mentor" ||
    lowerCaseFilterNameWithoutSpace === "mentorvisits"
  )
    src = mentorIcon;
  if (
    lowerCaseFilterNameWithoutSpace === "class" ||
    lowerCaseFilterNameWithoutSpace === "classes"
  )
    src = classIcon;
  if (
    lowerCaseFilterNameWithoutSpace === "event" ||
    lowerCaseFilterNameWithoutSpace === "events"
  )
    src = eventIcon;
  if (lowerCaseFilterNameWithoutSpace === "missedclasses")
    src = missedClassCalendarIcon;
  if (lowerCaseFilterNameWithoutSpace === "attendedclasses")
    src = attendedClassCalendarIcon;
  return <img className="m-0 p-0 w-[22px] h-[22px] " src={src} alt={type} loading="lazy"/>;
};

export const returnGradient = (type, scheduleView) => {
  if (type === "Tests")
    return scheduleView
      ? " from-gradient-yellow3 to-gradient-yellow1 "
      : " from-gradient-yellow1 to-gradient-yellow2 ";
  if (type === "Classes")
    return scheduleView
      ? " from-gradient-purple2 to-gradient-purple1 "
      : " from-gradient-purple1 to-gradient-purple2 ";
  if (type === "MentorVisits")
    return scheduleView
      ? " from-gradient-green2 to-gradient-green1 "
      : " from-gradient-green1 to-gradient-green2 ";
  if (type === "Events") return " from-gradient-blue1 to-gradient-blue2 ";
  if (type ==="missedClasses")
    return " from-gradient-red1 to-gradient-red2 ";
  if(type === "attendedClasses")
    return " from-gradient-green3 to-gradient-green4 ";
};

export const returnBgColor = type => {
  if (type === "Tests") return " bg-calendar-test ";
  if (type === "Classes") return " bg-calendar-class ";
  if (type === "MentorVisits") return " bg-calendar-mentor ";
  if (type === "Events") return " bg-calendar-event ";
};

export const returnEventType = calendarObject => {
  if (Object.hasOwn(calendarObject, "calendarEventId")) return "event";
};

export const getLongEventClasses = (
  eventStartDate,
  eventEndDate,
  isStartOfTheWeek,
  day,
) => {
  let eventLength = eventEndDate.diff(eventStartDate, "days");
  let maxLengthAllowed = 7 - eventStartDate.day() - 1;
  let classes = "";
  if (day.isSame(eventStartDate, "date") && !isStartOfTheWeek) {
    classes += " border-l-[1px] rounded-l-lg  ";
  }
  if (eventLength <= maxLengthAllowed) {
    classes += " border-r-[1px] rounded-r-lg ";
  }
  return classes;
};

export const sortByType = (a, b) => {
  if (a.type < b.type) return -1;
  if (a.type > b.type) return 1;
  return 0;
};

export const filterEventsByDate = (date, events) => {
  let filteredEventsArr = events
    .filter(event => {
      return (
        date.isSameOrAfter(moment(event.start).startOf("day")) &&
        date.isSameOrBefore(moment(event.end).endOf("day"))
      );
    })
    .sort(sortByType);
  return filteredEventsArr;
};

const getDayOfTheWeekInNumber = day => {
  day = day.toLowerCase();
  switch (day) {
  case "sun":
    return 0;
  case "mon":
    return 1;
  case "tue":
    return 2;
  case "wed":
    return 3;
  case "thu":
    return 4;
  case "fri":
    return 5;
  case "sat":
    return 6;
  default:
    break;
  }
};

const convertRepeatonToNumbersArray = repeatOnString => {
  return repeatOnString.split(",").map(day => getDayOfTheWeekInNumber(day));
};

export const _filterEventsByDate = (dateParam, events) => {
  let date = moment(dateParam).startOf("day");
  let hr = moment().hour();
  let min = moment().minute();
  let sec = moment().second();
  dateParam.set("hour", hr).set("minute",min).set("second",sec);
  let filteredEventsArr = [];

  events.forEach(event => {
    let startDate = event?.startDate ?? event?.bookingDate ?? event?.classDate;
    const eventStartDate = moment(startDate).startOf("day");
    const eventEndDate = ("endDate" in event && event.endDate !== "None") ? moment(event.endDate).endOf("day") : (moment(event.bookingDate) || moment(event.classDate).endOf("day") || "None");
    const publishDate = (event.countDownDate && event.countDownDate !== "None") ? moment(event.countDownDate + " " + event.publishTime) : null;
    const now = moment();
    let canPublish = publishDate ? now.isSameOrAfter(publishDate) : true;
    if(event.bookingDate || event.classDate) {
      canPublish = true;
    }
    if (canPublish) {
      if (eventStartDate.isSame(date)) {
        filteredEventsArr.push(event);
      } else if (date.isAfter(eventStartDate)) {
        const diffInDays = date.diff(event.startDate, "days");
        const endsNever = event.endsNever === "1";

        if (
          (event.meetingRecurrance && event.meetingRecurrance === "0") &&
          moment(date).endOf("day").isSameOrBefore(eventEndDate.endOf("day"))
        )
          filteredEventsArr.push(event);
        else if ( event.meetingRecurrance && event.meetingRecurrance == "1") {
          if (
            event.repeatType.toLowerCase() === "day" &&
            diffInDays % event.repeatCount === 0
          ) {
            if (endsNever) filteredEventsArr.push(event);
            else if (
              event.endsOn !== "None" &&
              date.isSameOrBefore(moment(event.endsOn))
            )
              filteredEventsArr.push(event);
            else if (
              event.endsAfter > 0 &&
              diffInDays <= event.repeatCount * event.endsAfter
            )
              filteredEventsArr.push(event);
          }

          if (event.repeatType.toLowerCase() === "week") {
            let deadLine;
            const repeatOn = convertRepeatonToNumbersArray(event.repeatOn);
            if (event.endsNever === "1" && repeatOn.includes(date.day())) {
              filteredEventsArr.push(event);
            } else {
              if (event.endsOn !== "None") {
                deadLine = moment(event.endsOn).endOf("day");
              } else if (event.endsAfter !== null) {
                deadLine = moment(event.startDate)
                  .add(event.endsAfter * event.repeatCount, "weeks")
                  .subtract(1, "days")
                  .endOf("day");
              }
              if (
                date.isSameOrBefore(deadLine) &&
                repeatOn.includes(date.day()) &&
                date.diff(eventStartDate, "weeks") % event.repeatCount === 0
              )
                filteredEventsArr.push(event);
            }
          }

          if (event.repeatType.toLowerCase() === "month") {
            let deadLine;
            let repeatOnCondition;
            if (event.repeatOn.includes("day")) {
              repeatOnCondition = date.date() === eventStartDate.date();
            } else {
              repeatOnCondition = date.date() === eventStartDate.date();
            }

            if (
              endsNever &&
              date.isSameOrAfter(eventStartDate) &&
              repeatOnCondition
            )
              filteredEventsArr.push(event);
            else {
              if (event.endsOn !== "None") {
                // if there is an endsOnDate
                deadLine = moment(event.endsOn).endOf("day");
              } else if (event.endsAfter !== null) {
                let start = moment(event.startDate);
                let noOfMonthsToAdd = event.endsAfter * event.repeatCount;
                let finalEndDate = start.add(noOfMonthsToAdd, "months");
                deadLine = finalEndDate.subtract(1, "days").endOf("day");
              }
              let isDateBeforeDeadLine = date.isSameOrBefore(deadLine);
              let isDateAfterEventStart = date.isSameOrAfter(eventStartDate);
              if (
                isDateBeforeDeadLine &&
                isDateAfterEventStart &&
                repeatOnCondition &&
                date.diff(eventStartDate, "months") % event.repeatCount === 0
              )
                filteredEventsArr.push(event);
            }
          }

          if(event.repeatType.toLowerCase() === "year"){
            let deadLine;
            let repeatOnCondition = (date.date() === eventStartDate.date()) && (date.month() === eventStartDate.month());
            if(endsNever && date.isSameOrAfter(eventStartDate) && repeatOnCondition) {
              filteredEventsArr.push(event);
            }else{
              if(event.endsOn !== "None"){
                deadLine = moment(event.endsOn).endOf("day");
              }else if(event.endsAfter !== null){
                let start = moment(event.startDate);
                let noOfYearsToAdd = event.endsAfter * event.repeatCount;
                let finalEndDate = start.add(noOfYearsToAdd, "years");
                deadLine = finalEndDate.subtract(1, "days").endOf("day");
              }
              let isDateBeforeDeadLine = date.isSameOrBefore(deadLine);
              let isDateAfterEventStart = date.isSameOrAfter(eventStartDate);
              if (
                isDateBeforeDeadLine &&
                isDateAfterEventStart &&
                repeatOnCondition &&
                date.diff(eventStartDate, "years") % event.repeatCount === 0
              )
                filteredEventsArr.push(event);
            }
          }
        }
      }
    }
  });
  return filteredEventsArr;
};

// after the startDate, should be the nth day (mon, tue) , month

export const isLongerThanWeek = event => {
  let end = moment(event.end);
  let start = moment(event.start);
  let difference = end.diff(start, "days");
  return difference > 7 - event.start.day();
};

export const checkIfEventRepeatIsContinuous = event => {
  const repeatOn = convertRepeatonToNumbersArray(event.repeatOn);
  for (let i = 1; i < repeatOn.length; i++) {
    if (repeatOn[i] - repeatOn[i - 1] !== 1) return false;
  }
  return true;
};

function convertTo24HrsFormat(time12Hrs) {
  if(!time12Hrs) return null;
  let [time, meridiem] = time12Hrs.split(" ");
  let [hours, minutes] = time.split(":").map(Number);

  if (meridiem.toLowerCase() === "am" && hours === 12) {
    hours = 0;
  } else if (meridiem.toLowerCase() === "pm" && hours !== 12) {
    hours += 12;
  }

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:00`;
}

export const getAndChangeEventsFormat = (date, events) => {
  const eventsNewFormattedArray = [];
  const startMonth = date.month() + 1;
  const startYear = date.year();
  const startDate = date.date();
  const start = `${startYear}-${startMonth}-${startDate}`;
  if (!Array.isArray(events)) {
    return {
      id: events.id ?? events.bookingId ?? events.mappingId,
      name: events.name ?? events.sessionName ?? events.classTitle,
      start: moment(start + " " + (events.startTime ?? convertTo24HrsFormat(events.bookingTime) ?? events.classTime)),
      end: moment(start + " " + events.endTime ?? convertTo24HrsFormat(events.bookingTime) ?? events.classTime ),
      moduleType: events.moduleType || events.type,
      ctaName: events.ctaName ?? "Open",
      link: events.link ?? events.hangoutLink ?? events.handout ?? "",
      venue: events.venue ?? events.branchName ?? events.venue_name ?? "",
      mentorName: events?.mentor_name ?? "",
      event_details:events
    };
  }

  const eventsForDate = _filterEventsByDate(date, events);

  let _longEvents = [];
  let _singleEvents = {};

  const isLong = event => {
    if (event.endDate && event.endDate!== "None") {
      return moment(event.endDate)
        .startOf("day")
        .isAfter(moment(event.startDate).endOf("day"));
    }else return false;
  };

  eventsForDate.length > 0 &&
    eventsForDate.forEach(e => {
      let obj = {
        id: e.id ?? e.bookingId ?? e.mappingId,
        name: e.name ?? e.sessionName ?? e.classTitle,
        start: moment(start + " " + (e.startTime ?? convertTo24HrsFormat(e.bookingTime) ?? e.classTime)),
        end: moment(start + " " + (e.endTime ?? convertTo24HrsFormat(e.bookingTime) ?? e.classTime)),
        moduleType: e.moduleType,
        ctaName: e.ctaName ?? "Open",
        link: e.link ?? e.hangoutLink ?? e.handout ?? "",
        venue: e.venue ?? e.branchName ?? e.venue_name,
        event_details: e
      };
      if (isLong(e)) {
        _longEvents = [
          ..._longEvents,
          { ...obj, end: moment(e.endDate + " " + e.endTime) },
        ];
      } else {
        let startTime = moment(start + " " + (e.startTime ?? convertTo24HrsFormat(e.bookingTime) ?? e.classTime));
        let key = startTime.hour() + ":" + startTime.minute();
        if (_singleEvents[key]) {
          _singleEvents[key] = [..._singleEvents[key], obj];
        } else _singleEvents[key] = [obj];
      }
      eventsNewFormattedArray.push(obj);
    });
  _singleEvents = Object.entries(_singleEvents).map(([, value]) => value);
  return { _longEvents, _singleEvents, eventsNewFormattedArray };
};

export const filterEventsByMonth = (month, events) => {
  let newEvents = [];
  const daysInMonth = scheduleViewDaysInMonth(month);
  daysInMonth.forEach(day => {
    const { eventsNewFormattedArray } = getAndChangeEventsFormat(day, events);
    newEvents = [...newEvents, ...eventsNewFormattedArray];
  });
  return newEvents;
};

export const segregateEvents = events => {
  let eventsWithoutTime = [];
  let eventsWithTimeObj = {};
  let eventsWithTime = [];
  events.forEach(event => {
    if (event.start.hour() === "" && event.start.minute() === "") {
      eventsWithoutTime = [...eventsWithoutTime, event];
    } else {
      let key = event.start.hour() + ":" + event.start.minute();
      if (eventsWithTimeObj[key]) {
        eventsWithTimeObj[key] = [...eventsWithTimeObj[key], event];
      } else eventsWithTimeObj[key] = [event];
    }
  });
  eventsWithTime = Object.entries(eventsWithTimeObj).map(([, value]) => value);
  return { eventsWithoutTime, eventsWithTime };
};

export const hours = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];
