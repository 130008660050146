import React, { useState } from "react";

import arrowDown from "../../../assets/icons/arrow-down.svg";
import arrowUp from "../../../assets/icons/arrow-up.svg";
import PropTypes from "prop-types";
import OutsideClickHandler from "react-outside-click-handler";
import { useSelector } from "react-redux";

const DropDown = ({
  options,
  text,
  setDropdownValue,
  width,
  std,
  disabled,
  forProfile
}) => {
  const standard = std
    ? useSelector(
      state => state.prelogin.profileDetails.educationalDetails[std],
    )
    : null;
  const gradeSystem = standard?.Grade_Type || text;
  const [topic, setTopic] = useState(gradeSystem);
  const [open, setOpen] = useState(false);
  const handleClick = topic => {
    if (!disabled) {
      setOpen(!open);
      setTopic(topic);
      setDropdownValue(topic, std);
    }
  };
  function handleOpenClose() {
    if (!disabled) {
      setOpen(!open);
    }
  }
  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      {/* Selector */}

      <div className={`relative border border-secondary-300 focus:outline-none w-96 rounded-2xl overflow-hidden ${forProfile ? width : "w-96"} `}>
        <div
          onClick={handleOpenClose}
          className={`flex items-center justify-between font-ibm font-normal text-sm text-ibm p-4 ${disabled ? "bg-[#fafafa]" : "bg-white"} `}>
          <p
            className={`text-left font-medium text-lg h-5 capitalize ${
              topic === text ? "text-secondary-800" : ""
            } `}>
            {topic}
          </p>
          <img
            className="h-5 w-5"
            src={open ? arrowUp : arrowDown}
            alt="arrow"
            loading="lazy"
          />
        </div>
      </div>

      {/* Drop Down */}
      {open && (
        <div
          className={`absolute w-[310px] max-h-52 -ml-1 rounded-2xl shadow-md bg-secondary-100  scrollbar-thumb-secondary-700 scrollbar-thin scrollbar-thumb-rounded mt-2 z-10 ${
            width ? width : "w-310px"
          }`}>
          {options.map(item => (
            <div
              role="list"
              key={item.id || item.examId}
              className="mx-8 my-4 w-48">
              <div
                role="listitem"
                onClick={() => handleClick(item.topic || item.examName)}
                className="text-ibm text-base font-normal font-ibm mb-2 cursor-pointer hover:font-semibold focus:font-semibold">
                <div className="flex items-center space-x-2">
                  <a>{item.topic || item.examName}</a>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </OutsideClickHandler>
  );
};

DropDown.propTypes = {
  options: PropTypes.array,
  text: PropTypes.string,
  width: PropTypes.string,
  setDropdownValue: PropTypes.func,
  std: PropTypes.string,
  disabled: PropTypes.bool,
  forProfile : PropTypes.bool
};

export default DropDown;
