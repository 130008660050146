import React from "react";
import propTypes from "prop-types";
import ProgressIcon from "../../../../../assets/icons/in-process.svg";
import { ReactComponent as VideoIcon } from "../../../../../assets/icons/video.svg";
import { ReactComponent as HtmlIcon } from "../../../../../assets/icons/pdf.svg";
import { ReactComponent as PlaylistIcon } from "../../../../../assets/icons/video-playlist.svg";
import { ReactComponent as PdfIcon } from "../../../../../assets/icons/quiz-new.svg";
import { ReactComponent as AssesmentIcon } from "../../../../../assets/icons/testnew.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  clearPlaylistAndPathData,
} from "../../../../../redux/features/assetDetail/assetDetailSlice";
import { selectCurrentHeaderTab } from "../../../../../redux/features/header/headerSlice";
import { useHistory } from "react-router-dom";

const ResumeProgressCard = ({ asset }) => {
  const dispatch = useDispatch();
  const headerTab = useSelector(selectCurrentHeaderTab);
  const history = useHistory();
  function returnIcon(assetId, type) {
    if (!assetId) return <PlaylistIcon />;
    if (type?.toLowerCase() === "video") return <VideoIcon />;
    if (type?.toLowerCase() === "html") return <HtmlIcon />;
    if (type?.toLowerCase() === "pdf") return <PdfIcon />;
    if (type?.toLowerCase() === "assessment") return <AssesmentIcon />;
  }

  function handleClick() {
    localStorage.setItem("breadcrumb1", "Home");
    localStorage.setItem("breadcrumb2",asset.moduleName);
    localStorage.setItem("breadcrumb3",asset.assetName);
    localStorage.setItem("breadcrumb4", "");
    
    dispatch(clearPlaylistAndPathData());
    if (asset.moduleType.toLowerCase() == "channel") {
      history.push(
        `/home/homedetail/${asset.assetName?.replaceAll("/", "-")}/?moduleId=${
          asset.moduleId
        }&parentId=${asset.learningPathId}&assetId=${asset.assetId}`,
      );
    } else {
      history.push(
        `/prepare-${headerTab}/detail/${
          encodeURIComponent(asset.assetName?.replace("/", "-") || asset.name.replace("/", "-"))
        }/?moduleId=${asset.moduleId}&parentId=${asset.learningPathId}&assetId=${
          asset.assetId
        }`,
      );
    }
  }

  return (
    <div key={asset.assetId} className=" w-[280px] bg-secondary-100 pl-1">
      <div
        style={{
          boxShadow: "0px 8px 24px rgba(112, 144, 176, 0.15)",
        }}
        className="h-[108px] w-full resize bg-secondary-100 shadow-outer rounded-2xl relative">
        <div className="flex flex-col">
          <div className="flex flex-row  h-auto relative">
            <div className=" relative mt-5 ml-2">
              <div className="mt-4">
                {returnIcon(asset.assetId, asset.assetType || asset.type)}
              </div>
              <img src={ProgressIcon} className="w-[45px] h-[45px] relative top-[-40px] right-[5px]" loading="lazy"/>
            </div>
            <div className="mt-9 font-ibm font-normal w-56 text-base">
              <p
                className="line-clamp-2 ml-2  w-11/12 cursor-pointer "
                onClick={() => handleClick()}>
                {" "}
                {asset.assetName || asset.name}
              </p>
            </div>
            {/* Remved so that tag shouldn't come in course change modal */}
            <div className="right-0 absolute   h-6  bg-black rounded-bl-xl rounded-tr-lg ">
              <p className="px-4  font-ibm text-secondary-100">
                {" "}
                {asset.moduleName}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ResumeProgressCard.propTypes = {
  asset: propTypes.object,
  title: propTypes.string,
};

export default ResumeProgressCard;