import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import propTypes from "prop-types";

const WithModal = Component => {
  class ComponentWithModal extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isModalOpen: false,
      };
    }
    openModal = () => {
      this.setState({ isModalOpen: true });
    };

    closeModal = () => {
      const functions = this.props.onCloseFunctions;
      functions && functions.forEach(func => func());
      this.setState({ isModalOpen: false });
    };

    render() {
      const { Trigger } = this.props;
      const { disable } = this.props;
      if (!Trigger)
        throw new Error(
          "Trigger element is required. Create a Trigger component and pass it as a prop to the component that is to be converted as a modal.",
        );
      return (
        <>
          {/* The UI for the modal trigger is plugged here */}
          <button disabled={disable} onClick={this.openModal}>
            {Trigger && <Trigger />}
          </button>

          {/* Modal Code */}
          <Transition appear show={this.state.isModalOpen} as={React.Fragment}>
            <Dialog
              as="div"
              className="fixed inset-0 z-50 overflow-y-auto"
              onClose={this.closeModal}>
              <div className="min-h-screen px-4 text-center">
                <Transition.Child
                  as={React.Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <Dialog.Overlay className={`fixed inset-0 bg-black ${this.state.isModalOpen ? "opacity-70" : "opacity-0"}`} />   
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                  className="inline-block h-screen align-middle"
                  aria-hidden="true">
                  &#8203;
                </span>
                <Transition.Child
                  as={React.Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95">
                  <div className="inline-block overflow-visible rounded-2xl text-left align-middle transition-all transform bg-white ">
                    {/* The component wrapped with WithModal HOC */}
                    <Component {...this.props} closeModal={this.closeModal} />
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition>
        </>
      );
    }
  }

  ComponentWithModal.propTypes = {
    Trigger: propTypes.func.isRequired,
    disable: propTypes.bool,
    onCloseFunctions: propTypes.array,
  };

  return ComponentWithModal;
};

WithModal.propTypes = {
  Component: propTypes.elementType,
};

export default WithModal;
