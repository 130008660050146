import React, { useState, useRef } from "react";
import arrowRightActive from "../../../../assets/icons/arrow-right-homepage-active.svg";
import arrowRightInactive from "../../../../assets/icons/arrow-right-homepage-inactive.svg";
import arrowLeftActive from "../../../../assets/icons/arrow-left-homepage-active.svg";
import arrowLeftInactive from "../../../../assets/icons/arrow-left-homepage-inactive.svg";
import ArticleCard from "../schools_articles/ArticleCard";
import propTypes from "prop-types";

import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import SwiperCore, { Navigation } from "swiper";

import "swiper/swiper.min.css";
import "swiper/swiper-bundle.css";
import "swiper/swiper-bundle.min.css";

SwiperCore.use([Navigation]);

// const data = [
//   {
//     articleId: "mc1",
//     articleName:
//       "IIM-Ahmedabad launches centre for transportation and logistics research",
//     description:
//       "The IIMA Centre for Transportation and Logistics plans to organise a series of webinars, workshops... ",
//     readTime: "00:20:00",
//     date: "2017-10-13 10:53:53",
//     thumbnailLink: "http://IIM-Ahemdabad1.com",
//   },
//   {
//     articleId: "mc2",
//     articleName:
//       "IIM-Ahmedabad launches centre for transportation and logistics research",
//     description:
//       "The IIMA Centre for Transportation and Logistics plans to organise a series of webinars, workshops...",
//     readTime: "00:20:00",
//     date: "2017-10-13 10:53:53",
//     thumbnailLink: "http://IIM-Ahemdabad1.com",
//   },
//   {
//     articleId: "mc3",
//     articleName:
//       "IIM-Ahmedabad launches centre for transportation and logistics research",
//     description:
//       "The IIMA Centre for Transportation and Logistics plans to organise a series of webinars, workshops...",
//     readTime: "00:20:00",
//     date: "2017-10-13 10:53:53",
//     thumbnailLink: "http://IIM-Ahemdabad1.com",
//   },
//   {
//     articleId: "mc4",
//     articleName:
//       "IIM-Ahmedabad launches centre for transportation and logistics research",
//     description:
//       "The IIMA Centre for Transportation and Logistics plans to organise a series of webinars, workshops...",
//     readTime: "00:20:00",
//     date: "2017-10-13 10:53:53",
//     thumbnailLink: "http://IIM-Ahemdabad1.com",
//   },
// ];

function ArticlesSlider({ data }) {
  const [disableRightIcon, setDisableRightIcon] = useState(false);
  const [disableLeftIcon, setDisableLeftIcon] = useState(true);

  const handleRightIcon = () => {
    setDisableRightIcon(true);
    setDisableLeftIcon(false);
  };

  const handleLeftIcon = () => {
    setDisableLeftIcon(true);
    setDisableRightIcon(false);
  };

  const prevRef = useRef();
  const nextRef = useRef();

  return (
    <div className="mb-16">
      {/* Header */}
      <div className="flex items-center justify-between mb-7">
        <h1 className="font-medium">Articles</h1>
        <div className="flex items-center ">
          <div
            className="bg-white cursor-pointer h-12 w-12 rounded-full flex items-center justify-center shadow-outer"
            ref={prevRef}>
            <img
              src={disableLeftIcon ? arrowLeftInactive : arrowLeftActive}
              alt="left"
            />
          </div>
          <div
            className="bg-white cursor-pointer h-12 w-12 rounded-full flex items-center justify-center shadow-outer ml-6"
            ref={nextRef}>
            <img
              src={disableRightIcon ? arrowRightInactive : arrowRightActive}
              alt="right"
            />
          </div>
        </div>
      </div>

      {/*Body */}

      <div className="w-full ">
        <Swiper
          navigation={false}
          onReachEnd={handleRightIcon}
          onReachBeginning={handleLeftIcon}
          spaceBetween={16}
          onInit={swiper => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          slidesPerView={3.75}>
          {data?.map(article => (
            <SwiperSlide key={article.articleId} style={{minWidth : "373px"}} className="py-7"  >
              <ArticleCard
                articleId={article.articleId}
                articleName={article.articleName}
                description={article.htmlContent}
                readTime={article.duration}
                date={article.date}
                thumbnailLink={article.thumbnailLink}
                isBookmark={article.isBookmark}
                htmlContent={article?.htmlContent}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

ArticlesSlider.propTypes = {
  data: propTypes.array,
};

export default ArticlesSlider;
