import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import SwiperCore, { Navigation } from "swiper";

import "swiper/swiper.min.css";
import "swiper/swiper-bundle.css";
import "swiper/swiper-bundle.min.css";
// import Thumbnail from "../../../../../assets/images/thumbnail.svg";

import propTypes from "prop-types";

SwiperCore.use([Navigation]);
const Advertisement = ({  slidesPerView, data}) => {
  const prevRef = useRef();
  const nextRef = useRef();

  return (
    <div className="font-ibm mb-10 ">
      <section className="max-w-full z-40 mt-5 overflow-x-visible">
        <Swiper
          navigation={false}
          spaceBetween={0}
          onInit={swiper => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          slidesPerView={slidesPerView}>
          {data?.map(item => (
            <SwiperSlide key={item.id}>
              <iframe src={`Advertisement.html?id=${item}`}></iframe>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </div>
  );
};

Advertisement.propTypes = {
  width: propTypes.string,
  height: propTypes.string,
  slidesPerView: propTypes.number,
  data: propTypes.array
};

export default Advertisement;
