import React from "react";
import Header from "../../components/web/header/Header";
import Footer from "../../components/web/global/footer/Footer";
import Prepare from "./Prepare";
import { useSelector } from "react-redux";
import { loginDetails } from "../../redux/features/login/LoginSlice";
import { useLocation } from "react-router-dom";

function PrepareContainer() {
  const _loginDetails = useSelector(loginDetails);
  const studentImsPin = _loginDetails.student_imspin;
  const courseId = useSelector(state => state.login.courseId);
  const variantId = useSelector(state => state.login.variantId);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  return (
    <div className="flex flex-col h-screen  font-ibm">
      <Header />
      <div className="">
        {/* <NotFound> */}
        <Prepare
          studentImsPin={studentImsPin}
          parentId={query.get("parentId")}
          id={query.get("id")}
          courseId={courseId.toString()}
          variantId={variantId.toString()}
        />
        {/* </NotFound> */}
      </div>
      <Footer />
    </div>
  );
}

export default PrepareContainer;
