import React, { useState } from "react";
// import DropDown from "../../components/web/global/dropDown/DropDown";
import Search from "../../components/web/global/search/Search";
import Accordian from "../../components/web/global/accordian/Accordian";
import { useQuery } from "react-query";
import { prepare_apirequest } from "../../API/api";
import { endpoint } from "../../API/config";
import Loader from "../../components/web/global/loader/Loader";
import propTypes from "prop-types";
import { loginDetails } from "../../redux/features/login/LoginSlice";
import { useSelector } from "react-redux";
import ProctoredTest from "../../components/web/Test/ProctoredTest/ProctoredTest";

function Test({ studentImsPin, parentId, id, courseId, variantId }) {
  const login = useSelector(loginDetails);
  const moduleId = useSelector((state) => state.header.headerId);
  // Prepare api calls
  const fetchPrepareList = () => {
    return prepare_apirequest({
      url: `${endpoint.navigationPathList.addr}`,
      method: "post",

      headers: {
        "access-token": login.token,
      },
      data: {
        studentImsPin,
        courseId,
        variantId,
        parentId,
        id,
      },
    });
  };

  const [filteredPrepareList, setFilteredPrepareList] = useState([]);
  const [onlyAssets, setOnlyAssets] = useState(false);

  const isRequiredPayloadAvailable =
    !!studentImsPin && !!courseId && !!variantId && !!parentId && !!id;

  const {
    data: prepareList,
    isLoading,
    isError,
    error,
    refetch: prepareListRefetch,
  } = useQuery(
    [
      endpoint.navigationPathList.key,
      studentImsPin,
      courseId,
      variantId,
      id,
      parentId,
    ],
    fetchPrepareList,
    {
      enabled: isRequiredPayloadAvailable,
      select: (data) => data.data.data[0],
      onSuccess: (res) => {
        setFilteredPrepareList(res?.children);
        const isAsset = (asset) => "assetId" in asset;
        setOnlyAssets(res.children.every(isAsset));
      },
      retry: false,
    }
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    if (error.response?.status === 404)
      return (
        <h1 className="h-full flex items-center justify-center">
          No records found
        </h1>
      );
  } else
    return (
      <div className=" px-14 py-8 font-ibm">
        <div className=" w-full flex justify-between ">
          {/* <DropDown text="Show" /> */}
          {prepareList && !onlyAssets && (
            <div className="ml-auto">
              <Search
                data={prepareList.children}
                setFunction={setFilteredPrepareList}
              />
            </div>
          )}
        </div>
        {prepareList &&
        filteredPrepareList &&
        filteredPrepareList.length > 0 &&
        !onlyAssets ? (
          filteredPrepareList.map(
            (child) =>
              !("assetId" in child) && (
                <Accordian
                  key={child.learningPathId}
                  data={child}
                  moduleId={moduleId}
                  courseId={courseId}
                  prepareListRefetch={prepareListRefetch}
                  isTestModule={true}
                />
              )
          )
        ) : (
          <ProctoredTest
            testModule={true}
            data={prepareList.children}
            parentId={prepareList.learningPathId}
          />
        )}
      </div>
    );
}

Test.propTypes = {
  children: propTypes.string,
  studentImsPin: propTypes.string,
  parentId: propTypes.string,
  id: propTypes.string,
  courseId: propTypes.string,
  variantId: propTypes.string,
};

export default Test;
