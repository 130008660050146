const config = {
  state: process.env.REACT_APP_STATE,
  staging: process.env.REACT_APP_STAGING,
  staging_LIVEAPI: process.env.REACT_APP_LIVE,
  staging_HOMEAPI: process.env.REACT_APP_HOME,
  staging_SCHOOLSAPI: process.env.REACT_APP_SCHOOLS,
  staging_MYPLAN: process.env.REACT_APP_MYPLAN,
  staging_LOGINAPI: process.env.REACT_APP_LOGIN,
  staging_PREPARE: process.env.REACT_APP_PREPARE,
  staging_FIREBASEAPIKEY: process.env.REACT_APP_FIREBASE_APIKEY,
  staging_FIREBASEAUTHDOMAIN: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  staging_FIREBASEPROJECTID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  staging_FIREBASESTORAGEBUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  staging_FIREBASEMESSAGESENDERID:
    process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  staging_FIREBASEAPPID: process.env.REACT_APP_FIREBASE_APP_ID,
  staging_FIREBASEMEASUREMENT_ID: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  staging_FIREBASEVAPIDKEY: process.env.REACT_APP_FIREBASE_VAPID_KEY,

  module: {
    myPlan: "/myPlan",
  },
  user: {
    student: "/student",
    mentor: "/mentor",
  },
  endpoint: {
    // Myplan Mentors APIs
    addMentorSlots: {
      addr: "/myPlan/mentor/addMentorSlots",
      key: "addMentorSlots",
    },
    getMentorSlotsData: {
      addr: "/myPlan/mentor/getMentorSlotsData",
      key: "getMentorSlotsData",
    },

    datesAndSlotAvailable: {
      addr: "/myPlan/mentor/datesAndSlotAvailable",
      key: "datesAndSlotAvailable",
    },
    InstantSlotBooking: {
      addr: "/myPlan/mentor/InstantSlotBooking",
      key: "InstantSlotBooking",
    },
    // Myplan Mentor APIs end here

    // Myplan Mentor Login and Dashboard starts here
    mentorAction: {
      addr: "/myPlan/login/dashBoardActions",
      key: "mentorAction",
    },
    mentorDashboard: {
      addr: "/myPlan/login/DashBoardDataAfterUpdate",
      key: "mentorDashboard",
    },
    // Myplan Mentor Login and Dashboard ends here

    // Myplan Reports starts here
    getBranchList: {
      addr: "/myPlan/reports/mentorWiseDetails",
      key: "getBranchList",
    },
    getProductGroupUsingBranchId: {
      addr: "/myPlan/reports/branchWiseProductDetails",
      key: "getProductGroupUsingBranchid",
    },
    getVariantListUsingProductGroupId: {
      addr: "/myPlan/reports/productGroupWiseVariantDetails",
      key: "getVariantListUsingProductGroupId",
    },

    getStudentsUsingMentorId: {
      addr: "myPlan/reports/searchDetailsByNameOrImsPin",
      key: "getStudentsUsingMentorId",
    },
    getMentors: {
      addr: "/myPlan/reports/mentorsList",
      key: "mentorsList",
    },
    getStudentDetailsUsingBatchId: {
      addr: "/myPlan/reports/getStudentData",
      key: "getStudentDetailsUsingBatchId",
    },
    //Myplan Reports End here

    //Meet a mentor Related starts here
    venue_Details: {
      addr: "/myPlan/student/mentorsData/branches",
      key: "venueDetails",
    },
    mentor_details: {
      addr: "/myPlan/student/mentorsData",
      key: "mentorDetails",
    },
    dates_Available: {
      addr: "/myPlan/student/datesAvailable",
      key: "availableDates",
    },
    slots_Available: {
      addr: "/myPlan/student/slotsAvailable",
      key: "availableSlots",
    },
    bookings_Available: {
      addr: "/myPlan/student/bookingsAvailable",
      key: "available_Student_booking_Data",
    },
    course_Reason_type: {
      addr: "/myPlan/student/courseReasonTypes",
      key: "courseReasonType",
    },
    book_Slot: {
      addr: "/myPlan/student/bookSlot",
      key: "bookSlot",
    },
    bookings_Data: {
      addr: "/myPlan/student/bookingsData",
      key: "all_Booking_Data",
    },
    bookings_Data_usingId: {
      addr: "/myPlan/student/bookingsData/",
      key: "",
    },
    cancel_Booking: {
      addr: "/myPlan/student/cancelBooking",
      key: "cancelBooking",
    },
    //Meet a mentor Related ends here

    // dashboardCancelBooking starts here
    mentorCancelBooking: {
      addr: "/myPlan/admin/dashboardCancelBooking",
      key: "mentorCancelBooking",
    },
    // dashboardCancelBooking ends here

    // Student - Slide Show API starts here
    getCourseSlideShow: {
      addr: "/cms/home/slideShow/getCourseSlideShow",
      key: "getCourseSlideShow",
    },
    // Student - Slide Show API ends here

    //Blog APIs starts here
    getBlogOfTheWeek: {
      addr: "/cms/home/blogs/getBlogOfTheWeek",
      key: "getBlogOfTheWeek",
    },
    getFeaturedBlogs: {
      addr: "/cms/home/blogs/getFeaturedBlogs",
      key: "getFeaturedBlogs",
    },
    //Blog APIs ends here

    //Post Bookmark APIs starts here
    postBookmark: {
      addr: "/cms/home/blogs/posts/postBookmark",
      key: "postBookmark",
    },
    //Post Bookmark APIs ends here

    //Communication APIs starts here
    noticeCenter: {
      addr: "/cms/home/communication/getCommunicationPushNotifications",
      key: "noticeCenter",
    },
    addPushNotificationAsRead: {
      addr: "/cms/home/communication/addPushNotificationAsRead",
      key: "addPushNotificationAsRead",
    },
    unReadPushNotifications: {
      addr: "/cms/home/communication/unReadPushNotifications",
      key: "unReadPushNotifications",
    },
    getMaintenanceData: {
      addr: "/myims/home/getMaintenanceData",
      key: "getMaintenanceData",
    },
    //Communication APIs ends here

    //Channel APIs starts here
    //for channels
    channelsList: {
      addr: "/cms/channel/channelsList",
      key: "channelsList",
    },

    getChannelData: {
      addr: "/cms/channel/getChannelData",
      key: "getChannelData",
    },
    getChannelAssetData: {
      addr: "/cms/channel/getChannelAssetData",
      key: "getChannelAssetData",
    },
    //Channel APIs ends here

    //Student side all learning path bookmarks APIs starts here
    addBookmark: {
      addr: "/cms/bookmark/addBookmark",
      key: "addBookmark",
    },

    //Student side all learning path bookmarks APIs ends here
    OTP: {
      addr: "/myPlan/auth/otpGenerationFormyPlan",
      key: "generateOTP",
    },

    // classes
    live_Class: {
      addr: "/imsLive/admin/getLiveClassData",
      key: "liveClass",
    },
    getCompletedClass: {
      addr: "/imsLive/admin/getRecentClassData",
      key: "getCompletedClasses",
    },
    getCompletedClassUsingBatchId: {
      addr: "/imsLive/admin/getRecentClassUsingVariant",
      key: "getCompletedClassUsingBatchId",
    },
    getCompletedViewAllClassData: {
      addr: "/imsLive/admin/getRecentViewALLClassData",
      key: "getCompletedViewAllClassData",
    },
    getUpcomingClassData: {
      addr: "/imsLive/admin/getUpcomingClassData",
      key: "getUpcomingClass",
    },
    // classes end

    // Store attendance
    storeAttendance: {
      addr: "/imsLive/admin/storeAttendance",
      key: "storeAttendance",
    },
    // store attendance end

    // batches
    getBatchesUsingVariantId: {
      addr: "/myPlan/reports/variantWiseBatchDetails",
      key: "getBatchesUsingVariantId",
    },
    //batches end

    // Admin
    updateRecordings: {
      addr: "/imsLive/admin/updateRecordings",
      key: "updateRecordings",
    },
    addregistrant: {
      addr: "/imsLive/admin/addRegistrant",
      key: "addregistrant",
    },
    // Admin End

    getEligibilityCriteria: {
      addr: "/myims/cms/eligibilitycriteria/GetCourseEligibilityCriteria",
      key: "getEligibilityCriteria",
    },
    getSelectionCriteria: {
      addr: "/myims/cms/selectioncriteria/GetCourseSelectionCriteria",
      key: "getSelectionCriteria",
    },
    getAllNoticeboard: {
      addr: "/cms/home/noticeboard/NoticeBoardFilter",
      key: "getAllNoticeboard",
    },
    getAllNoticeboardListing: {
      addr: "/cms/home/noticeboard/noticeBoardListing",
      key: "getAllNoticeboardListing",
    },

    getSchoolArticles: {
      addr: "/myims/cms/article/getSchoolArticleEvent",
      key: "getSchoolArticles",
    },
    getCourseArticleEventData: {
      addr: "/myims/cms/school/getCourseArticleEventData",
      key: "getCourseArticleEventData",
    },
    getCourseSchoolProgramData: {
      addr: "/myims/cms/courses/GetCourseSchoolProgramData",
      key: "getCourseSchoolProgramData",
    },
    GetCourseDeadline: {
      addr: "/myims/cms/deadlines/GetCourseDeadline",
      key: "GetCourseDeadline",
    },
    GetSchoolDeadlines: {
      addr: "/myims/cms/deadlines/GetSchoolDeadlines",
      key: "GetSchoolDeadlines",
    },
    GetCourseCutoff: {
      addr: "/myims/cms/cutoff/GetCourseCutoff",
      key: "GetCourseCutoff",
    },
    GetCourseSchoolProgramData: {
      addr: "/myims/cms/courses/GetCourseSchoolProgramData",
      key: "GetCourseSchoolProgramData",
    },
    // ^---------replace url ^^^^^^
    getSchoolDetails: {
      addr: "myims/cms/school/getSchoolDetails",
      key: "getSchoolDetails",
    },
    getCourseArticleEventDeadlineData: {
      addr: "/myims/cms/courses/GetCourseArticleEventDeadlineData",
      key: "getCourseArticleEventDeadlineData",
    },
    getshortlistedSchools: {
      addr: "/myims/cms/school/ShortlistedSchoolByStudent",
      key: "getshortlistedSchools",
    },
    // getCourseArticleEventData: {
    //   addr: "/myims/cms/school/getCourseArticleEventData",
    //   key: "getCourseArticleEventData",
    // },
    // AddStudentSchoolLike: {
    //   addr: "/myims/cms/school/AddStudentSchoolLike",
    //   key: "AddStudentSchoolLike",
    // },
    applyNow: {
      addr: "myims/cms/school/ApplyNow",
      key: "applyNow",
    },
    getSchoolGallery: {
      addr: "/myims/cms/school/getSchoolGallery",
      key: "getSchoolGallery",
    },
    AddArticleBookMark: {
      addr: "/myims/cms/article/AddArticleBookMark",
      key: "AddArticleBookMark",
    },
    AddEventBookMark: {
      addr: "myims/cms/event/AddEventBookMark",
      key: "AddEventBookMark",
    },

    calendar_events: {
      addr: "cms/home/calendar/getCalendarEventsDataUsingCourse",
      key: "getCalendarEventsDataUsingCourse",
    },

    calendar_filters: {
      addr: "/cms/home/calendar/getCalendarFiltersData",
      key: "getCalendarFiltersData",
    },

    //Slots
    getCentre: {
      addr: "/myPlan/reports/mentorWiseDetails",
      key: "mentorWiseDetails",
    },

    add_Registrant: {
      addr: "/imsLive/admin/addRegistrant",
      key: "add_Regitrant",
    },

    //Student Login API
    studentLoginCheck: {
      addr: "/myims/login/studentLogin",
      key: "studentLoginCheck",
    },

    // For headerData
    moduleData: {
      addr: "/cms/header/moduleData",
      key: "moduleData",
    },

    // Prepare
    navigationPathList: {
      addr: "/cms/navigationPath/navigationPathList",
      key: "navigationPathList",
    },
    getPrepareAssetData: {
      addr: "/cms/prepare/getPrepareAssetData",
      key: "prepareAssetData",
    },
    getBookMark: {
      addr: "/cms/mypage/bookmark/getBookMark",
      key: "getBookMark",
    },
    addMyTasks: {
      addr: "/cms/mypage/mytask/addMyTask",
      key: "addMyTask",
    },
    getMyTask: {
      addr: "/cms/mypage/mytask/getMyTask",
      key: "getMyTask",
    },
    getResumeAssetsProgress: {
      addr: "/myims/home/getResumeAssetsProgress",
      key: "getResumeAssetsProgress",
    },
    assetProgress: {
      addr: "/cms/prepare/assetProgress",
      key: "assetProgress",
    },
    markAsComplete: {
      addr: "/cms/prepare/addLearningPathTierMarkAsComplete",
      key: "markAsComplete",
    },
    getTestData: {
      addr: "/myims/getTestData",
      key: "getTestData",
    },
    getStickyNotes: {
      addr: "/cms/prepare/getStickyNotes",
      key: "getStickyNotes",
    },
    getNextLearningUnit: {
      addr: "/myims/prepare/getNextLearningUnit",
      key: "getNextLearningUnit",
    },
    getTokenForTest: {
      addr: "/myims/generateAuthTokenForTest",
      key: "getTokenForTest",
    },
    // Prepare end

    // Forgot Password
    otpGeneration: {
      addr: "/cms/studentlogin/forgotPassword/otpGeneration",
      key: "otpGeneration",
    },
    // For createNewPassword
    createNewPassword: {
      addr: "/cms/studentlogin/forgotPassword/createNewPassword",
      key: "createNewPassword",
    },
    // For otpVerification
    otpVerification: {
      addr: "/cms/studentlogin/forgotPassword/otpVerification",
      key: "otpVerification",
    },

    // for newlogin
    newlogincreateNewPassword: {
      addr: "/myims/api/v2/profile/addStudentEnrollPasswordMyimsv2",
      key: "newlogincreateNewPassword",
    },
    newloginOtpVerification: {
      addr: "/myims/api/login/verifyOTPNewStudentLogin",
      key: "newloginOtpVerification",
    },
    studentVerification: {
      addr: "/myims/login/studentVerification",
      key: "studentVerification",
    },
    //for /myims/login/adminLogin
    adminLogin: {
      addr: "/myims/login/adminLogin",
      key: "adminLogin",
    },
    // For getUpgradeVariantsData
    getUpgradeVariantsData: {
      addr: "/myims/upgrades/getUpgradeVariantsData",
      key: "getUpgradeVariantsData",
    },

    //Student profile
    getStudentData: {
      addr: "/myims/profile/getStudentData",
      key: "getStudentData",
    },

    updateStudentProfile: {
      addr: "/cms/profile/updateStudentProfile",
      key: "updateStudentProfile",
    },

    studentPrelogin: {
      addr: "/cms/profile/studentPreLogin",
      key: "studentPrelogin",
    },

    getExamName: {
      addr: "/myims/profile/getExams",
      key: "getExamName",
    },
    getJobSector: {
      addr: "/myims/profile/getJobSectors",
      key: "getJobSector",
    },
    getUniversities: {
      addr: "/myims/profile/getUniversities",
      key: "getUniversities",
    },
    getCities: {
      addr: "/myims/profile/getCities",
      key: "getCities",
    },
    getStates: {
      addr: "/myims/profile/getStates",
      key: "getStates",
    },
    getBranch: {
      addr: "/myims/profile/getBranches",
      key: "getBranch",
    },
    getDegree: {
      addr: "/myims/profile/getQualifications",
      key: "getDegree",
    },

    //OTp Verification for profile
    otpGenerationProfile: {
      addr: "/cms/otp/otpGenerationForProfile",
      key: "otpGenerationProfile",
    },
    otpVerificationProfile: {
      addr: "/cms/otp/otpVerificationForProfile",
      key: "otpVerificationProfile",
    },

    // firebase push notification ==========>
    fireBase_PushNotification: {
      addr: "/myims/student/addFirebaseToken",
      key: "firebase_Key",
    },
    // sticky notes
    adding_notes: {
      addr: "/cms/prepare/addStickyNotes",
      key: "sticky_Key",
    },
    //For image upload in mentor feedback
    s3FileUplaod: {
      addr: "/cms/mentor/feedback/s3FileUplaod",
      key: "s3FileUplaod",
    },
    //For live ,recent and upcoming classes
    getMentorLiveClassData: {
      addr: "/imsLive/admin/getMentorLiveClassData",
      key: "getMentorLiveClassData",
    },
    getMentorUpcomingClassData: {
      addr: "/imsLive/admin/getMentorUpcomingClassData",
      key: "getMentorUpcomingClassData",
    },
    getMentorRecentClassData: {
      addr: "/imsLive/admin/getMentorRecentClassData",
      key: "getMentorRecentClassData",
    },

    // Home page calendar api
    getMonthEvents: {
      addr: "myims/home/calendar/getCalendarEventFilterData",
      key: "getMonthEvents",
    },
    // For Mentor Pending Tasks
    mentorAwaitingFeedBack: {
      addr: "/myPlan/mentor/mentorAwaitingFeedBack",
      key: "mentorAwaitingFeedBack",
    },
    //For getting All students attendance
    getAllStudentAttendanceData: {
      addr: "/imsLive/admin/getStudentAttendanceData",
      key: "getAllStudentAttendanceData",
    },
    //For getting particular student attendance
    getStudentAttendanceData: {
      addr: "/imsLive/admin/getStudentAttendanceDataByMentor",
      key: "getStudentAttendanceData",
    },
    //For  getCourseAndVariants in instant slot booking
    getCourseAndVariants: {
      addr: "/myPlan/reports/getCourseAndVariants",
      key: "getCourseAndVariants",
    },
    //API for Detailed Analytics
    getDetailedAnalyticsData: {
      addr: "/myims/getDetailedAnalyticsData",
      key: "getDetailedAnalyticsData",
    },
    getAllCities: {
      addr: "/myims/profile/getAllCities",
      key: "getAllcities",
    },
    // for performance module
    getPerformanceTabs: {
      addr: "/performance/getPerformanceData",
      key: "getPerformanceTabs",
    },
    getScoreSummaryData: {
      addr: "/myims/performance/getScoreSummaryData",
      key: "getScoreSummaryData",
    },
    getProgressTrackerData: {
      addr: "/myims/performance/getProgressTrackerData",
      key: "getProgressTrackerData",
    },
    getQuestionSelectionData: {
      addr: "/myims/performance/getQuestionSelectionData",
      key: "getQuestionSelectionData",
    },
    getPerformanceFilter: {
      addr: "/myims/performance/getPerformanceFilter",
      key: "getPerformanceFilter",
    },
    progressTrackerFilter: {
      addr: "/myims/performance/progressTrackerFilter",
      key: "progressTrackerFilter",
    },
    getQuestionSelectionFilterData: {
      addr: "/myims/performance/getQuestionSelectionFilterData",
      key: "getQuestionSelectionFilterData",
    },
    percentilePredictorData: {
      addr: "/myims/performance/percentilePredictorData",
      key: "percentilePredictorData",
    },
    //for student Feedback
    mentorFeedBackGiven: {
      addr: "/myPlan/mentor/mentorFeedBackGiven",
      key: "mentorFeedBackGiven",
    },
    ///student/getCourseAndVariantDataBasedOnPin
    getCourseAndVariantDataBasedOnPin: {
      addr: "/myims/student/getCourseAndVariantsBasedOnPin",
      key: "getCourseAndVariantDataBasedOnPin",
    },
    ///myims/student/GetMentorStudentHeaderModule
    GetMentorStudentHeaderModule: {
      addr: "/myims/student/GetMentorStudentHeaderModule",
      key: "GetMentorStudentHeaderModule",
    },
    ///myims/adverticement/getAdverticementsData
    getAdverticementsData: {
      addr: "/myims/adverticement/getAdverticementsData",
      key: "getAdverticementsData",
    },
    //for loginVerification
    loginVerification: {
      addr: "/myims/login/loginVerification",
      key: "loginVerification",
    },
    //for getReservationCategories
    getReservationCategories: {
      addr: "/myims/profile/getReservationCategories",
      key: "getReservationCategories",
    },
    //Selection criteria
    GetSelectionCriteria: {
      addr: "/myims/cms/selectioncriteria/GetSelectionCriteria",
      key: "GetSelectionCriteria",
    },
    AddStudentSchoolLike: {
      addr: "/myims/cms/school/AddStudentSchoolLike",
      key: "AddStudentSchoolLike",
    },
    ///myims/cms/school/SchoolCompare
    SchoolCompare: {
      addr: "/myims/cms/school/SchoolCompare",
      key: "SchoolCompare",
    },
    getReservationCategoryData: {
      addr: "/myims/cms/cutoff/getReservationCategoryData",
      key: "getReservationCategoryData",
    },
    updateAssetOrder: {
      addr: "/cms/mypage/mytask/updateAssetOrder",
      key: "updateAssetOrder",
    },
    ///myims/cms/article/getBookMarkArticle
    getBookMarkArticle: {
      addr: "/myims/cms/article/getBookMarkArticle",
      key: "getBookMarkArticle",
    },
    // full text search API for Articles and Events===>
    fullTextSearchForArticlesandEvents: {
      addr: "/myims/cms/school/articleandeventSearch",
      key: "fullTextSearchForArticlesandEvents",
    },
    videoCypherURL: {
      addr: "/myims/video/vdcypherDetails",
      key: "videoCypherURL",
    },
    // for Avatars in prelogin and profile
    getImsProfileAvatars: {
      addr: "/myims/profile/getImsProfileAvatars",
      key: "getImsProfileAvatars",
    },
    getSchoolFiltersData: {
      addr: "/myims/cms/school/getSchoolFiltersData",
      key: "getSchoolFiltersData",
    },
    getIMSEligibilityCriteria: {
      addr: "/myims/cms/eligibilitycriteria/GetEligibilityCriteria",
      key: "getEligibilityCriteria",
    },

    // storeAttendanceByMentor
    storeAttendanceByMentor: {
      addr: "/imsLive/admin/storeAttendanceByMentor",
      key: "storeAttendanceByMentor",
    },

    // filter School data
    filterSchoolData: {
      addr: "/myims/cms/school/ApplySchoolFilters",
      key: "filterSchoolData",
    },
    //get pdf url
    getPdfUrl: {
      addr: "/imsLive/admin/azureURL",
      key: "getPdfUrl",
    },
    //get variant details
    getVariantDetails: {
      addr: "/cms/variants/getVariantDetail",
      key: "variant details",
    },
    //get Venues
    getVenuesUrl: {
      addr: "/cms/courseware/assets/slots/getVenues",
      key: "venues",
    },
    //get Slots
    getSlotsUrl: {
      addr: "/cms/courseware/assets/venue/getSlots",
      key: "slots",
    },
    //get booked slots
    getBookedSlotsUrl: {
      addr: "/myims/test/slots/getStudentBookedSlots",
      key: "booked slots",
    },
    cancelBookedSlot: {
      addr: "/myims/test/slots/cancelSlotBooking",
      key: "cancel slot",
    },
  },
};

module.exports = config;
