import React, { useState, Fragment, useEffect } from "react";
import propTypes from "prop-types";
import { loginAPI_apirequest} from "../../../../API/api";
import { useQuery } from "react-query";
import { endpoint } from "../../../../API/config.jsx";
import {loginDetails } from "../../../../redux/features/login/LoginSlice";
import {  useSelector } from "react-redux";
import {  Transition ,Dialog  } from "@headlessui/react";
import OtpModal from "./OtpModal";
import {ReactComponent as Spinner} from "../../../../assets/icons/spinner.svg";
function OtpModalTrigger({setIsgetOTPClicked,mobileNumberOrEmail,setDetails , modalOpen, setShowOtpModal}) {
  const LoginDetails = useSelector(loginDetails);

  const [verificationDetails,setVerificationDetails] = React.useState();
  const[showOtpModal, setShowModal] = useState(false);  // state to maintain the open status of modal
  
  

  
  const getOtpGeneration = () => {
    return loginAPI_apirequest({
      url: `${endpoint.otpGeneration.addr}`,
      method: "post",
 
      headers: {
        "access-token": LoginDetails.token
      },
      data: {
        studentEmail : mobileNumberOrEmail.includes("@")?mobileNumberOrEmail:"",
        studentImsPin :mobileNumberOrEmail.includes("@")?"":mobileNumberOrEmail
      },
    });
  };
  const {data:otpGeneration,refetch, isFetching , isError} = useQuery([endpoint.otpGeneration.key,mobileNumberOrEmail], getOtpGeneration, {
    enabled:false,
    retry:false,
    onSuccess: (res) => {
      setVerificationDetails(res.data.data);
      setDetails(res.data.data);
      setShowModal(true); // setting model to open after authentication =============>
    },
  });

  useEffect(()=>{
    modalOpen  && !showOtpModal && setShowOtpModal(showOtpModal);
  },[showOtpModal]);

  useEffect(()=>{
    modalOpen && refetch();
    return ()=>{};
  },[modalOpen]);
  
  return (
    <div>
      {/* To show the error message when user isn't registered */}
      {isError && <p className="text-red-500 text-base font-medium mt-2">User Not Found</p>}  
      <button
        disabled={mobileNumberOrEmail ? false : true}
        className={`w-full px-24 py-3 rounded-xl mt-16 font-medium ${
          mobileNumberOrEmail!= ""
            ? "bg-primary-200"
            : "bg-[#8E9EAB]"}
        `}
        onClick={refetch}
      >
        <span className="text-white text-lg">Get OTP</span>
        {isFetching && <Spinner />}
      </button>  
      {/* OTP modal component starts here =========> */}
      <Transition appear show={showOtpModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={()=>setShowModal(false)}
        >
          <OtpModal 
            setShowModal={setShowModal} 
            setIsgetOTPClicked={setIsgetOTPClicked} 
            mobileNumber={otpGeneration?.data?.data?.mobile} 
            verificationDetails={verificationDetails} 
            showModal = {showOtpModal}
            generateNewOtp = {refetch}
          />
        </Dialog>
      </Transition>
      {/* } */}
    </div>
  );
  
  // return 
}

OtpModalTrigger.propTypes = {
  isgetOTPClicked:propTypes.bool,
  setIsgetOTPClicked:propTypes.func,
  mobileNumberOrEmail:propTypes.string,
  setDetails:propTypes.func,
  modalOpen : propTypes.bool,
  setShowOtpModal : propTypes.func
};
export default OtpModalTrigger;