import React,{useEffect, useState} from "react";
import CompareItem from "./compareItem";
import AddToCompare from "./addToCompare";
import Header from "../../header/Header";
import Breadcrumb from "../../global/breadcrumb/Breadcrumb";
import Footer from "../../global/footer/Footer";
import { useSelector,useDispatch } from "react-redux";

import { useQuery } from "react-query";
import { endpoint } from "../../../../API/config";
import {apirequest_schoolsAPI} from "../../../../API/api";
import {loginDetails } from "../../../../redux/features/login/LoginSlice";
import {setCompareSchoolList } from "../../../../redux/features/schools/SchoolSlice";

function Compare() {
 
  const menu_items = [
    "School Name",
    "IMS Classification",
    "Ranking",
    "Batch Size",
    "Fee",
    "Avg. Salary",
    "Duration",
    "Exams Accepted",
  ];
  const [schoolId,setSchoolId]= useState(null);
  const [programId,setProgramId]= useState(null);
  const CollegeList = useSelector(state=> state.school.compareSchoolList);
  const [collegeDetails,setCollegeDetails] = useState(CollegeList);
  const dispatch = useDispatch();
  const LoginDetails = useSelector(loginDetails);

  console.log({collegeDetails});
  const addToComp = () => {

    return apirequest_schoolsAPI({
      url: `${endpoint.SchoolCompare.addr}`,
      method: "post",
 
      headers: {
        "access-token": LoginDetails.token
      },
      data: {
        schoolDetails:[
          {schoolId:schoolId,programId:programId}
        ]
      }
    });
  };
  useQuery(
    [endpoint.SchoolCompare.key,schoolId,programId],
    addToComp,
    {
      enabled: !!schoolId && !!programId,
      retry:false,
      onSuccess: res => {
        dispatch(setCompareSchoolList(res.data.data));
      }
    }
  ); 
  useEffect(() => {
    setCollegeDetails(CollegeList);
  }, [CollegeList]);
  
  return (
    <>
      <Header />
      <Breadcrumb />
      <div className="max-w-full px-11 mt-14  font-ibm flex ">
        {/* Compare menu */}
        <div className=" mr-4 w-1/5">
          <p className="text-2xl font-medium mb-[184px]  ">
            Compare(<span>{collegeDetails?.length}</span>)
          </p>
          {menu_items?.map(item => (
            <p key={item} className="mb-12 font-medium text-secondary-600 text-xl">
              {item}
            </p>
          ))}
        </div>
        <div className=" pl-2  flex overflow-x-scroll scrollbar-thumb-secondary-100 scrollbar-thin scrollbar-thumb-rounded ">
          {collegeDetails && collegeDetails?.map((school,ind) => (
            <CompareItem key={ind} data={school} />
          ))}
          {Array.from(Array(5 - collegeDetails?.length), (item) => (
            <AddToCompare key={item}  setSchoolId={setSchoolId} setProgramId={setProgramId} />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Compare;
