import React from "react";
import moment from "moment";

import Facebook from "../../../../assets/icons/facebook.svg";
import Twitter from "../../../../assets/icons/Twitter-01.png";
import LinkedIn from "../../../../assets/icons/linkedin.svg";
import Instagram from "../../../../assets/icons/instagram.svg";
import PlayStore from "../../../../assets/icons/play-store.svg";
import AppleStore from "../../../../assets/icons/apple-store.svg";
import Youtube from "../../../../assets/icons/Youtube.svg";
import Tooltip from "../../global/tooltip/Tooltip";

const socialmedias = [
  {
    id: 1,
    name: "Facebook",
    thumbnail: Facebook,
    link: "https://www.facebook.com/imsindia",
  },
  {
    id: 2,
    name: "Twitter",
    thumbnail: Twitter,
    link: "https://twitter.com/ims_india", //Change for Twitter redirection URL
  },
  {
    id: 3,
    name: "LinkedIn",
    thumbnail: LinkedIn,
    link: "https://in.linkedin.com/school/ims-learning-resources/",
  },
  {
    id: 4,
    name: "Instagram",
    thumbnail: Instagram,
    link: "https://instagram.com/ims.india",
  },
  {
    id: 5,
    name: "Youtube",
    thumbnail: Youtube,
    link: "https://www.youtube.com/channel/UCjX-3wCGEPW3DzlLR35pB7g",
  },
];
const Footer = () => {
  return (
    <footer className="flex flex-col h-auto w-full bg-gradient-to-r from-[#F5F7FA] to-[#C3CFE2] px-14 mt-24">
      {/* Top */}
      <div className="flex flex-row justify-between items-start mt-14">
        <div className="flex flex-col">
          <h3 className="font-ibm text-xl font-semibold text-secondary-600 mb-6">
            Contact Us
          </h3>
          <a
            className="font-ibm text-xl font-normal text-ibm underline"
            href="https://support.imsindia.com"
            rel="noreferrer"
            target="_blank"
          >
            Helpdesk |{" "}
            <a
              className="text-primary-200 underline"
              href="https://www.imsindia.com/locate-center/"
              rel="noreferrer"
              target="_blank"
            >
              Locate Center
            </a>
          </a>
        </div>

        <div className="flex flex-col">
          <h3 className="font-ibm text-xl font-semibold text-secondary-600 mb-6">
            Get in Touch
          </h3>
          <div className="flex items-center space-x-3">
            {socialmedias.map((item) => (
              <a
                href={item.link}
                key={item.id}
                target="_blank"
                rel="noreferrer noopener"
              >
                <img
                  className="h-5 w-5 cursor-pointer"
                  src={item.thumbnail}
                  alt="Facebook"
                />
              </a>
            ))}
          </div>
        </div>

        <div className="flex flex-col">
          <h3 className="font-ibm text-xl font-semibold text-secondary-600 ml-6">
            Download App
          </h3>
          <div data-tip data-for="mobile-tip" className="flex flex-row">
            <img
              className="cursor-pointer"
              src={PlayStore}
              alt="PlayStore"
              loading="lazy"
            />
            <img
              className="cursor-pointer"
              src={AppleStore}
              alt="AppleStore"
              loading="lazy"
            />
          </div>
        </div>
        <Tooltip
          multiline={false}
          id="mobile-tip"
          place="bottom"
          text={"Stay tuned, mobile app launching soon."}
          offset={{ bottom: 20, right: 10 }}
        />
      </div>

      <p className="font-ibm font-normal text-sm text-secondary-600 mb-6">
        © {moment().year()} IMS Learning Resources Pvt. Limited , All rights
        reserved.
        <a
          className="underline"
          href="https://www.imsindia.com/privacy-policy.html"
          rel="noreferrer"
          target="_blank"
        >
          Privacy policy
        </a>{" "}
        and{" "}
        <a
          className="underline"
          href="http://www.imsindia.com/disclaimer.html"
          rel="noreferrer"
          target="_blank"
        >
          Disclaimer
        </a>
        .{" "}
      </p>

      {/* Bottom */}
      <p className="font-ibm font-normal text-sm text-secondary-600 mb-14">
        All copyrights to this material vests with IMS Learning Resources Pvt.
        Ltd. No part of this material either in part or as a whole shall be
        copied, reprinted, reproduced, sold, distributed or transmitted in any
        form or by any means, electronic, mechanical, photocopying, recording or
        otherwise, or stored in any retrieval system of any nature without the
        permission of IMS Learning Resources Pvt. Ltd., and any such violation
        would entail initiation of suitable legal proceedings.
      </p>
    </footer>
  );
};

export default Footer;
