import propTypes from "prop-types";
import React from "react";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/cancel.svg";
const ModalTitle = ({
  title,
  description1,
  closeModal,
  TitleIcon,
  closeIcon,
  modalForSuccessfullyBooked,
  date,
  time,
  description,
  isSlotCancelled,
  courseReasonType,
  mode,
  mentorName,
  branchName,
}) => {
  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <div className="flex">
          {TitleIcon && <TitleIcon className="mr-2" />}
          <p className="text-secondary-600 font-medium text-lg leading-5 font-ibm text-justify">
            {title}
          </p>
        </div>

        {closeIcon && (
          <CloseIcon className="cursor-pointer" onClick={closeModal} />
        )}
      </div>
      {modalForSuccessfullyBooked && (
        <div className="mt-7 font-ibm">
          <p>Your slot has been Successfully Booked on</p>
          <span className="font-semibold text-sm">{date}</span> at
          <span className="pl-1 font-semibold text-sm">{time}</span>
        </div>
      )}
      {isSlotCancelled && (
        <p className="font-ibm text-sm text-justify px-2">
          Your Slot booking for study plan on{" "}
          <span className="font-semibold">{date}</span> has been cancelled
        </p>
      )}
      {courseReasonType && mode && mentorName && branchName && (
        <div className="mt-7">
          <h2 className="font-semibold text-base">Details :</h2>
          <span>
            {courseReasonType} | {mode} | {mentorName} | {branchName}{" "}
          </span>
        </div>
      )}
      {description && (
        <p className="font-ibm">
          {description && (
            <span className="text-red-400">&nbsp;&#42;&nbsp;</span>
          )}
          {description}
        </p>
      )}
      <div className="mb-7">
        {description1 && (
          <p className="text-sm font-normal text-secondary-600 font-ibm">
            {description1}
          </p>
        )}
      </div>
    </>
  );
};

ModalTitle.propTypes = {
  title: propTypes.string,
  description1: propTypes.string,
  description2: propTypes.string,
  closeModal: propTypes.oneOfType([propTypes.func, propTypes.bool]),
  successScreen: propTypes.bool,
  TitleIcon: propTypes.elementType,
  closeIcon: propTypes.oneOfType([propTypes.elementType, propTypes.bool]),
  modalForSuccessfullyBooked: propTypes.bool,
  date: propTypes.string,
  time: propTypes.string,
  details: propTypes.object,
  description: propTypes.string,
  isSlotCancelled: propTypes.bool,
  courseReasonType: propTypes.string,
  mode: propTypes.string,
  branchName: propTypes.string,
  mentorName: propTypes.string,
};

export default ModalTitle;
