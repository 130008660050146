import React from "react";
import PropTypes from "prop-types";
import arrowUp from "../../../../assets/icons/arrow-up.svg";
import arrowDown from "../../../../assets/icons/arrow-down.svg";

function AccordianBar({
  isAccordianOpen,
  handleOpenCloseAccordian,
  name,
  noOfUnits,
}) {
  return (
    <div className="mt-4 shadow-outer rounded-2xl">
      <div
        className={`h-[72px] flex flex-row justify-between bg-secondary-100 text-ibm px-8 py-6 text-xl font-ibm font-medium cursor-pointer ${
          isAccordianOpen ? "rounded-t-2xl" : "rounded-2xl"
        }`}
        onClick={handleOpenCloseAccordian}>
        {name}
        <div className="flex flex-row items-center justify-between">
          {noOfUnits} Units{" "}
          {isAccordianOpen ? (
            <img className="ml-3" src={arrowUp} alt="arrow up" loading="lazy"/>
          ) : (
            <img className="ml-3" src={arrowDown} alt="arrow down" loading="lazy"/>
          )}
        </div>
      </div>
    </div>
  );
}

export default AccordianBar;

AccordianBar.propTypes = {
  isAccordianOpen: PropTypes.bool,
  handleOpenCloseAccordian: PropTypes.func,
  name: PropTypes.string,
  noOfUnits: PropTypes.number,
};
