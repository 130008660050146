import React from "react";
import ImsLiveModal from "./ImsLiveModal";
import propTypes from "prop-types";
import watchLiveIcon from "../../../../assets/icons/watch-live.svg";
import playVideoIcon from "../../../../assets/icons/playIcon.svg";
import { apirequest_liveAPI} from "../../../../API/api";
import { useQuery } from "react-query";
import { endpoint } from "../../../../API/config.jsx";
import {loginDetails } from "../../../../redux/features/login/LoginSlice";
import {  useSelector } from "react-redux";

function ImsLiveModalTrigger({status,classData}) {
  const Trigger = () => {
    const LoginDetails = useSelector(loginDetails);
    const markAttendance = () => {
      return apirequest_liveAPI({
        url: `${endpoint.storeAttendance.addr}`,
        method: "post",
   
        headers: {
          "access-token": LoginDetails.token
        },
        data: {
          imspin: LoginDetails.student_imspin,
          mappingId:classData.mappingId.toString(),
          status:status == "Live"  ? "Live" :"Play"

        },
      });
    };
    const {refetch}=useQuery([endpoint.storeAttendance.key], markAttendance, {
      enabled: false,
      retry:false,
     
    });
    return (
      <div className="flex items-center space-x-2 cursor-pointer">
        <img  
          src={status === "Live" ? watchLiveIcon : playVideoIcon}
          alt="watch live"
          loading="lazy"
        />
        <p className={`${status === "Live"  ?"text-primary-500":"text-primary-200"} font-ibm font-medium text-base`} onClick={refetch}>
          {status === "Live"  ? "Watch LIVE" :"Play video"}
        </p>
      </div>
    );
  };
  return <ImsLiveModal Trigger={Trigger}  status={status} classData={classData}/>;
}

ImsLiveModalTrigger.propTypes = {
  status: propTypes.string,
  classData:propTypes.object
};

export default ImsLiveModalTrigger;
