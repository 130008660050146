import React, { useReducer, createContext, useContext } from "react";
import PropTypes from "prop-types";
import ArrowUp from "../../../assets/icons/arrow-up.svg";
import ArrowDown from "../../../assets/icons/arrow-down.svg";
import OutsideClickHandler from "react-outside-click-handler";

const CustomContext = createContext();

function useCustomContext() {
  return useContext(CustomContext);
}

const initialState = {
  isOpen: false,
  value: "Select",
};
function reducer(state, action) {
  switch (action.type) {
  case "toggle":
    return { ...state, isOpen: !state.isOpen };
  case "close":
    return { ...state, isOpen: false };
  case "setValue":
    return { isOpen: false, value: action.value };
  default:
    break;
  }
}

const Options = ({ children }) => {
  const { state } = useCustomContext();
  if (!state.isOpen) {
    return null;
  }

  return (
    <div className=" h-auto pt-2 w-full border-0 border-secondary-300 rounded-2xl pl-4 pr-5 py-3 border-solid  px-2 shadow-lg absolute z-[1000] top-14 bg-white overflow-y-scroll scrollbar-thumb-secondary-700 scrollbar-thin scrollbar-thumb-rounded  max-h-[10rem]">
      {children}
    </div>
  );
};

const Option = ({ option, setValue }) => {
  const { dispatch } = useCustomContext();

  function handleClick() {
    setValue(option);
    dispatch({
      type: "setValue",
      value: option,
    });
  }

  return (
    <div
      onClick={handleClick}
      className="font-ibm text-sm text-black font-medium opacity-60 py-1 cursor-pointer hover:bg-gray-200 px-4 capitalize flex "
    >
      {option}
    </div>
  );
};

const SimpleDropDown = ({ children, value, error, disabled = false }) => {

  const [state, dispatch] = useReducer(reducer, initialState);

  const provideState = {
    state,
    dispatch,
  };

  const handleToggleDropDown = () => {
    if (!disabled) {
      dispatch({ type: "toggle" });
    }
  };

  const closeDropDown = () => {
    dispatch({ type: "close" });
  };

  React.useEffect(() => {
    dispatch({
      type: "setValue",
      value,
    });
  }, [value]);

  return (
    <CustomContext.Provider value={provideState}>
      <OutsideClickHandler onOutsideClick={closeDropDown}>
        <div className="relative w-full">
          <div
            onClick={handleToggleDropDown}
            className={`${
              disabled ? "bg-[#fafafa]" : "bg-white"
            }  align-middle rounded-xl pl-4 pr-5 py-4 border-solid  px-2 focus:outline-none w-full items-center cursor-pointer focus:placeholder-transparent border ${
              error ? "border-red-300" : "border-secondary-300 text-sm "
            } flex justify-between `}
          >
            <p className="flex text-center font-ibm h-5 text-lg font-medium ">
              {state.value}
            </p>
            <img
              src={state.isOpen ? ArrowUp : ArrowDown}
              alt="arrow"
              loading="lazy"
              className="h-5"
            />
          </div>
          {error && (
            <p className=" text-xs text-red-400 mt-1 ml-2 ">* Required</p>
          )}
          {children}
        </div>
      </OutsideClickHandler>
    </CustomContext.Provider>
  );
};

SimpleDropDown.Options = Options;

SimpleDropDown.Option = Option;

SimpleDropDown.propTypes = {
  children: PropTypes.object,
  value: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
};

Options.propTypes = {
  children: PropTypes.array,
};

Option.propTypes = {
  option: PropTypes.string,
  children: PropTypes.object,
  setValue: PropTypes.func,
};

export default SimpleDropDown;
