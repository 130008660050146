import React from "react";
import propTypes from "prop-types";

function EligibilityCriteria({ data }) {
  return (
    <div className="mb-16 font-ibm">
      <h1 className="mb-6" >Eligibility Criteria</h1>
      <p>{data}</p>
    </div>
  );
}

EligibilityCriteria.propTypes = {
  data: propTypes.string,
};

export default EligibilityCriteria;
