import React from "react";
import bookmarkDisable from "../../../../assets/icons/bookmark-disable.svg";
import bookmarkActive from "../../../../assets/icons/bookmark-active-without-bg.svg";
import propTypes from "prop-types";
import { useQuery , useQueryClient} from "react-query";
import { endpoint } from "../../../../API/config";
import { apirequest_schoolsAPI } from "../../../../API/api";

import { loginDetails } from "../../../../redux/features/login/LoginSlice";
import { useSelector } from "react-redux";

const EventBookmark = ({ eventId, className, isBookMark }) => {
  const LoginDetails = useSelector(loginDetails);
  const courseID = useSelector(state => state.login.courseId);

  //  onClick={()=>{setIsBookMarkClicked(!bookMarkClicked);}}
  // const [bookmarked, setBookmarked] = React.useState(isBookMark);
  // const [bookMarkClicked,setIsBookMarkClicked]=React.useState(false);

  const AddArticleBookMark = eventId => {
    const response = apirequest_schoolsAPI({
      url: `${endpoint.AddEventBookMark.addr}`,
      method: "post",

      headers: {
        "access-token": LoginDetails.token,
      },
      data: {
        studentImsPin: LoginDetails.student_imspin,
        eventId: eventId ? eventId : "",
        isBookMark: "1",
        schoolId: "62a59647282d091387807831",
        courseId: courseID.toString(),
      },
    });
    // articleRefetch();
    return response;
  };
  let queryClient = useQueryClient()
;  const AddArticleUnBookMark = eventId => {
    const response = apirequest_schoolsAPI({
      url: `${endpoint.AddEventBookMark.addr}`,
      method: "post",

      headers: {
        "access-token": LoginDetails.token,
      },
      data: {
        studentImsPin: LoginDetails.student_imspin,
        eventId: eventId,
        isBookMark: "0",
        schoolId: "62a59647282d091387807831",
        courseId: courseID.toString(),
      },
    });
    // articleRefetch();
    return response;
  };

  const { refetch } = useQuery(
    [endpoint.AddEventBookMark.key],
    () =>
      isBookMark == "1"
        ? AddArticleUnBookMark(eventId)
        : AddArticleBookMark(eventId),
    {
      enabled: false,
      onSuccess: () => {
        queryClient.invalidateQueries(endpoint.getCourseArticleEventData.key);
        queryClient.invalidateQueries(endpoint.getSchoolDetails.key);
      },
    },
  );
  React.useEffect(() => {}, [isBookMark]);
  return (
    <div>
      <img
        src={isBookMark == "1" ? bookmarkActive : bookmarkDisable}
        className={`cursor-pointer ${className}`}
        onClick={refetch}
        loading="lazy"
      />
    </div>
  );
};
EventBookmark.propTypes = {
  className: propTypes.string,
  isBookMark: propTypes.string,
  eventId: propTypes.string,
  eventRefetch: propTypes.func,
};
export default EventBookmark;
