import React from "react";
import propTypes from "prop-types";
import CalendarModalTrigger from "./CalendarModalTrigger";
import ProfileHeader from "../PreLogin/ProfileHeader";
import {
  setFirstName,
  setProfileImage,
  setDateOfBirth,
  setGender,
  setAvatars
} from "../../../redux/features/prelogin/preloginSlice";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import Loader from "../global/loader/Loader";
import { useQuery } from "react-query";
import { endpoint } from "../../../API/config";
import { loginAPI_apirequest} from "../../../API/api";
import {loginDetails } from "../../../redux/features/login/LoginSlice";

const Profile = ({ nextStep, prevStep, isgetStudentDataSuccess,isLoading }) => {
  const dispatch = useDispatch();
  const profileDetails = useSelector((state) => state.prelogin.profileDetails);
  const LoginDetails = useSelector(loginDetails);
  const avatars = useSelector(state => state.prelogin.avatars);

  const handleFirstName = (e) => {

    dispatch(setFirstName(e.target.value));
  };

  const setDOB = (date) => {
    dispatch(setDateOfBirth(date));
  };

  const initialValues = {
    firstName: profileDetails.firstName || "",
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("*Name is required").max(50),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: () => {
      nextStep();
    },
  });
  const getImsProfileAvatars = () => {
    return loginAPI_apirequest({
      url: endpoint.getImsProfileAvatars.addr,
      method: "get",
 
      headers: {
        "access-token": LoginDetails.token
      }
    });
  };
  useQuery(
    [endpoint.getImsProfileAvatars.key],
    getImsProfileAvatars,
    {
      retry:false,
      onSuccess: (res) => {
        dispatch(setAvatars(res.data.data));
      },
    }
  );


  useEffect(() => {
    if (profileDetails) {
      formik.setValues({ firstName: profileDetails.firstName });
    }
  }, [profileDetails]);

  const submitFormData = (e) => {
    e.preventDefault();
  };

  return (
    <div>
      <ProfileHeader
        title="Let’s create your profile"
        backIcon={false}
        prevStep={prevStep}
        isLoading = {isLoading}
        isgetStudentDataSuccess={isgetStudentDataSuccess}
      />
      <section className="flex justify-center font-ibm">
        <div className=" w-[800px] ">
          {/*  select your avatar section */}
          <div className="flex h-[27rem] shadow-outer rounded-2xl mt-4 pt-12">
            <div>
              <p className="ml-24 font-medium text-lg mb-3">Select your Avatar</p>
              <div className="grid grid-cols-2 gap-3 h-48 mb-4 mx-20">
                {avatars?.map((item) => (
                  <div className="w-[90px] h-[90px] rounded-xl " key={item.avatarId}>
                    <img
                      src={item.avatarUrl}
                      alt="avatar"
                      className="h-[90px] bg-[#ECF3F5] cursor-pointer rounded-xl"
                      onClick={() => {
                        dispatch(setProfileImage(item.avatarUrl));
                      }}
                      loading="lazy"
                    />
                  </div>
                ))}
              </div>
            </div>
            <form onSubmit={submitFormData}>
              <div className="space-y-2">
                <p className="font-medium text-lg">Your Avatar</p>
                {isLoading?<Loader/>:<div className={`border-2 border-gray-50 rounded-xl ${profileDetails.profileImage? "bg-[#ECF3F5]":"bg-white"} w-[90px] h-[90px]`}>
                  <div className="w-[90px] h-[90px] rounded-xl">
                    {profileDetails.profileImage && (
                      <img
                        src={profileDetails.profileImage}
                        className="h-[90px] w-[90px] object-fill -mt-1 rounded-xl cursor-pointer"
                        loading="lazy"
                      />
                    )}
                  </div>
                </div>}
                <div>
                  <input
                    name="firstName"
                    value={profileDetails.firstName}
                    type="text"
                    placeholder="Enter name*"
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleFirstName(e);
                    }}
                    className="border-2 text-sm border-gray-200 rounded-xl p-2 w-[340px] focus:outline-none"
                  />
                  {Boolean(formik.errors.firstName) && (
                    <div className="text-red-400">
                      {formik.errors.firstName}
                    </div>
                  )}
                </div>
                <div className="w-full flex border-2 border-gray-200 rounded-xl pr-4 pl-2 py-2">
                  <input
                    name="date"
                    className="w-full outline-none boder text-sm border-gray-200  text-secondary-600  font-normal"
                    value={profileDetails?.dob === "0000-00-00" ? null : profileDetails?.dob }
                    data-provide="datepicker"
                    placeholder="Date of Birth"
                  />
                  <CalendarModalTrigger setDate={setDOB} isProfile={true} />
                </div>

                {/* Gender options */}
                <div>
                  <span className="font-ibm text-sm">Gender</span>
                  <div className="flex space-x-4 font-ibm mt-2">
                    <p
                      onClick={() => dispatch(setGender("Male"))}
                      className={`py-3 px-5 rounded-2xl text-sm cursor-pointer shadow-outer ${
                        profileDetails?.gender?.toLowerCase() === "male"
                          ? "bg-primary-800 shadow-outer font-ibm text-white font-medium"
                          : "font-ibm"
                      } `}
                      name="gender"
                      value={profileDetails.gender}
                    >
                      Male
                    </p>
                    <p
                      onClick={() => dispatch(setGender("Female"))}
                      className={`py-3 px-5 rounded-2xl text-sm cursor-pointer shadow-outer ${
                        profileDetails?.gender?.toLowerCase() === "female"
                          ? "bg-primary-800 shadow-outer font-ibm text-white font-medium"
                          : "font-ibm"
                      } `}
                      name="gender"
                      value={profileDetails.gender}
                    >
                      Female
                    </p>
                    <p
                      onClick={() => dispatch(setGender("Others"))}
                      className={`py-3 px-5 rounded-2xl text-sm cursor-pointer shadow-outer ${
                        profileDetails?.gender?.toLowerCase() === "others"
                          ? "bg-primary-800 shadow-outer font-ibm text-white font-medium"
                          : "font-ibm"
                      } `}
                      name="gender"
                      value={profileDetails.gender}
                    >
                      Others
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="flex justify-end">
            <button
              disabled={isLoading}
              onClick={formik.handleSubmit}
              type="submit"
              className=" px-16 py-3 mt-4 shadow-outer text-lg rounded-2xl bg-primary-200  text-white cursor-pointer"
            >
              Next
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

Profile.propTypes = {
  prevStep: propTypes.func,
  nextStep: propTypes.func,
  isgetStudentDataSuccess: propTypes.bool,
  isLoading: propTypes.bool,

};

export default Profile;