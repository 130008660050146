import React from "react";
import propTypes from "prop-types";
import moment from "moment";
import altImage from "../../../../assets/icons/altImage.svg";

// import Thumbnail from "../../../../assets/images/schoolArticleImage.png";
import MoreArticleBookmark from "./MoreArticleBookmark";

// redirect to htmlContent
function MoreArticleCard({ articleName, createdTs, articleId,isBookmark,articleRefetch, thumbnailLink, htmlContent , tag }) {
  return (
    <div className=" w-full relative overflow-hidden flex justify-between">
      <div className="flex">
        <a href={htmlContent} target="_blank" rel="noreferrer noopener">
          <img className="w-[214px] h-36 mt-8 rounded-xl" src={thumbnailLink ==="" ? altImage : thumbnailLink} loading="lazy"/>
        </a>
        <div className="mt-12  pl-6 pr-7">
          <div className="w-full flex-col justify-between">
            <a href={htmlContent} target="_blank" rel="noreferrer noopener">
              <p className="mb-6 font-ibm text-secondary-600 font-normal text-lg hover:underline hover:font-medium">
                {articleName}
              </p>
            </a>
            <div className="flex space-x-4">
              <p className="px-[12px] py-[6px] bg-secondary-200 rounded-full font-ibm mt-2 w-32">
                {moment(createdTs).format("DD MMM YYYY")}
              </p>
              <p className="px-[12px] py-[6px] bg-secondary-200 rounded-full font-ibm mt-2 ">
                {tag.length>0 ? tag[0] : ""}
              </p>
            </div>
          </div>
        </div>
      </div>
      <MoreArticleBookmark 
        className="shadow-md mt-14 rounded-full flex p-3 bg-secondary-100" 
        isBookMark={isBookmark}
        articleId={articleId}
        articleRefetch={articleRefetch}
      />
    </div>
  );
}

MoreArticleCard.propTypes = {
  createdTs: propTypes.string,
  headerTag: propTypes.string,
  articleName: propTypes.string,
  status: propTypes.string,
  tag: propTypes.string,
  video: propTypes.string,
  activeTab: propTypes.string,
  subContent: propTypes.string,
  time: propTypes.string,
  articleId: propTypes.string,
  isBookmark: propTypes.string,
  articleRefetch:propTypes.func,
  thumbnailLink: propTypes.string,
  htmlContent: propTypes.string,
};

export default MoreArticleCard;
