import React from "react";
import { Popover } from "@headlessui/react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  clickOnDropdownOption,
  clearTimeout,
  selectTimeoutID,
} from "../../../redux/features/header/headerSlice";
import { setAccordianId, setPreparePageUrl } from "../../../redux/features/assetDetail/assetDetailSlice";

function SubHeaderTab({ subHeaderTabName, dropDownOptions, isFromStudentProfile}) {
  const headerTabId = useSelector(state => state.header.headerId);
  const headerTab = useSelector(state => state.header.currentHeaderTab);
  const learningPathName = useSelector(state => state.header.currentDropdownOption);
  const learningPathId = useSelector(state => state.header.id);
  const timeoutID = useSelector(selectTimeoutID);
  const history = useHistory();
  const dispatch = useDispatch();
  // const studentCourseName = useSelector(state=>state.login.studentCourseName);
  const studentCourseName = localStorage.getItem("courseName");

  const handleClick = dropDownOption => {
    localStorage.setItem("breadcrumb1", subHeaderTabName);
    localStorage.setItem("breadcrumb2", dropDownOption.name);
    if (dropDownOption.isHeader === "1") {
      const dropDownOptionName = dropDownOption.name;
      const id = dropDownOption.learningPathId;
      const obj = { dropDownOptionName, subHeaderTabName, dropDownOptions, id };
      dispatch(clickOnDropdownOption(obj));
      const module = dropDownOption.type.toLowerCase();
      if(module==="hyperlink"){
        window.open(dropDownOption.url);
      }
      if (isFromStudentProfile) {
        let studentImsPin = localStorage.getItem("studentImsPin");
        let courseName = localStorage.getItem("courseName");
        let variantId = localStorage.getItem("variantId");
        let courseId = localStorage.getItem("courseId");
        let url = `/studentPage/${studentCourseName}/${dropDownOption.name}/?parentId=${headerTabId}&id=${dropDownOption.learningPathId}&studentImsPin=${studentImsPin}&variantId=${variantId}&courseId=${courseId}&courseName=${courseName}&learningPathId=${learningPathId}&learningPathName=${learningPathName}`;
        dispatch(setAccordianId(null));
        dispatch(setPreparePageUrl(url));
        history.push(url);
      }
      else if (module === "prepare" || module === "test" || module==="performance" ) {
        let url = `/${module}-${headerTab.toLowerCase()}/${subHeaderTabName}/${dropDownOption.name}/?parentId=${headerTabId}&id=${dropDownOption.learningPathId}&learningPathId=${learningPathId}&learningPathName=${learningPathName}`;
        dispatch(setAccordianId(null));
        dispatch(setPreparePageUrl(url));
        history.push(url);
      }
      
    }
  };

  const handleHover = () => {
    dispatch(clearTimeout(timeoutID));
  };


  return (
    <Popover className="relative">
      <Popover.Button className=" px-6 py-5 font-ibm">
        {subHeaderTabName}
      </Popover.Button>
      <Popover.Panel
        onMouseEnter={handleHover}
        className={`absolute flex flex-col items-start pl-6 pt-5 top-[84px] bg-white w-60 rounded-2xl shadow-outer z-50 ${
          subHeaderTabName == "SAT2" ? "-ml-24" : ""
        }`}>
        {dropDownOptions &&
          dropDownOptions.map((option, ind) => (
            <Popover.Button key={ind} option={option}>
              <div
                onClick={() => {
                  handleClick(option);
                }}
                className="mb-5 hover:font-semibold"
                key={option.id}>
                <p className="text-left pl-1">{option.name}</p>{" "}
              </div>
            </Popover.Button>
          ))}
      </Popover.Panel>
    </Popover>
  );
}

SubHeaderTab.propTypes = {
  subHeaderTabName: PropTypes.string,
  dropDownOptions: PropTypes.array,
  currentHeaderTab: PropTypes.string,
  isFromStudentProfile:PropTypes.bool,
};
export default SubHeaderTab;
