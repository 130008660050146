import React from "react";
import propTypes from "prop-types";
import moment from "moment";
import {
  returnIcon,
  getWidth,
  getMaxWidth,
  getLongEventClasses,
  getAndChangeEventsFormat,
} from "./utils";
import { useDispatch } from "react-redux";
import { setCurrentActiveSechuleViewPopup } from "../../../redux/features/calendar/calendarSlice";

function DateCell({
  classes,
  changeDay,
  day,
  events,
  ind,
  setSelectedView,
  setMonth,
  setYear,
  setHoveredEvent,
  setHoveredElement,
  setXPos,
  setYPos,
}) {

  const dispatch = useDispatch();

  const isLong = event => {
    if (event.endDate !== "None")
      return moment(event.endDate)
        .startOf("day")
        .isAfter(moment(event.startDate).endOf("day"));
  };

  let slot1,
    slot2,
    slot3,
    slot4,
    slot5,
    slot6,
    slot7,
    slot8,
    slot9,
    slot10,
    slot11,
    slot12,
    slot13,
    slot14,
    slot15;

  if (!events.some(isLong)) {
    slot1 = events[0] && {
      element: returnIcon(events[0].moduleType || events[0].type),
      moduleType: "single",
      event: events[0],
    };
    slot2 = {
      event: events[1],
      icon: events[1] && returnIcon(events[1].moduleType || events[1].type),
    };
    slot3 = {
      event: events[2],
      icon: events[2] && returnIcon(events[2].moduleType || events[2].type),
    };
    slot4 = {
      event: events[3],
      icon: events[3] && returnIcon(events[3].moduleType || events[3].type),
    };
    slot5 = {
      event: events[4],
      icon: events[4] && returnIcon(events[4].moduleType || events[4].type),
    };
    slot6 = {
      event: events[5],
      element: events[5] && returnIcon(events[5].moduleType || events[5].type),
      moduleType: "single",
    };
    slot7 = {
      event: events[6],
      icon: events[6] && returnIcon(events[6].moduleType || events[6].type),
    };
    slot8 = {
      event: events[7],
      icon: events[7] && returnIcon(events[7].moduleType || events[7].type),
    };
    slot9 = {
      event: events[8],
      icon: events[8] && returnIcon(events[8].moduleType || events[8].type),
    };
    slot10 = {
      event: events[9],
      icon: events[9] && returnIcon(events[9].moduleType || events[9].type),
    };
    slot11 = {
      event: events[10],
      icon: events[10] && returnIcon(events[10].moduleType || events[10].type),
    };
    slot12 = {
      event: events[11],
      icon: events[11] && returnIcon(events[11].moduleType || events[11].type),
    };
    slot13 = {
      event: events[12],
      icon: events[12] && returnIcon(events[12].moduleType || events[12].type),
    };
    slot14 = {
      event: events[13],
      icon: events[13] && returnIcon(events[13].moduleType || events[13].type),
    };
    slot15 = {
      event: events[14],
      icon: events[14] && returnIcon(events[14].moduleType || events[14].type),
    };
    if (events.length > 15)
      slot15 = {
        event: null,
        icon: "+" + (events.length - 14),
      };
  } else {
    let singleEvents;
    let longEvents = [];
    events.forEach(event => {
      const isLongEvent = moment(event.endDate)
        .startOf("day")
        .isAfter(moment(event.startDate).endOf("day"));
      if (isLongEvent) {
        if (moment(event.startDate).startOf("day").isSame(day.startOf("day"))) {
          longEvents.unshift(event);
        } else {
          longEvents.push(event);
        }
      }
    });

    if (moment(longEvents[0].startDate).isSame(day, "day"))
      slot1 = {
        element: (
          <div
            style={{
              maxWidth: getMaxWidth(moment(longEvents[0].startDate).day()),
              width: getWidth(
                moment(longEvents[0].startDate),
                moment(longEvents[0].endDate),
              ),
            }}
            className={`absolute font-ibm z-0 overflow-hidden bg-primary-600 border-t-[1px] border-b-[1px] border-solid border-primary-700 px-3 py-1 w-full ${getLongEventClasses(
              moment(longEvents[0].startDate),
              moment(longEvents[0].endDate),
              false,
              day,
            )}`}>
            <nobr>{longEvents[0].name} </nobr>
          </div>
        ),
        moduleType: "long",
        event: longEvents[0],
      };
    else if (day.day() === 0) {
      if (
        moment(longEvents[0].startDate).isBefore(day) &&
        moment(longEvents[0].endDate).isSameOrAfter(day)
      ) {
        slot1 = {
          element: (
            <div
              style={{
                maxWidth: getMaxWidth(0),
                width: getWidth(day, moment(longEvents[0].endDate)),
              }}
              className={`absolute font-ibm whitespace-nowrap overflow-hidden bg-primary-600 border-t-[1px] border-b-[1px] border-solid border-primary-700 z-0 px-3 py-1 w-full ${getLongEventClasses(
                day,
                moment(longEvents[0].endDate),
                true,
                day,
              )}`}>
              {longEvents[0].name}{" "}
            </div>
          ),
          moduleType: "long",
          event: longEvents[0],
        };
      }
    }
    if (longEvents.length > 1) {
      if (
        moment(longEvents[1].startDate)
          .startOf("day")
          .isSame(day.startOf("day"), "day")
      )
        slot6 = {
          element: (
            <div
              style={{
                maxWidth: getMaxWidth(moment(longEvents[1].startDate).day()),
                width: getWidth(
                  moment(longEvents[1].startDate),
                  moment(longEvents[1].endDate),
                  true,
                ),
              }}
              className={`absolute font-ibm whitespace-nowrap overflow-hidden bg-primary-600 border-t-[1px] border-b-[1px] border-solid border-primary-700 z-0 px-3 py-1 w-full ${getLongEventClasses(
                moment(longEvents[1].startDate),
                moment(longEvents[1].endDate),
                false,
                day,
              )}`}>
              {longEvents[1].name}{" "}
            </div>
          ),
          moduleType: "long",
          event: longEvents[1],
        };
      else if (day.day() === 0) {
        if (
          moment(longEvents[1].startDate).isBefore(day) &&
          moment(longEvents[1].endDate).isSameOrAfter(day)
        ) {
          slot6 = {
            element: (
              <div
                style={{
                  maxWidth: getMaxWidth(0),
                  width: getWidth(day, moment(longEvents[1].endDate)),
                }}
                className={`absolute font-ibm whitespace-nowrap overflow-hidden bg-primary-600 border-t-[1px] border-b-[1px] border-solid border-primary-700 z-0 px-3 py-1 w-full ${getLongEventClasses(
                  day,
                  moment(longEvents[1].endDate),
                  true,
                  day,
                )}`}>
                {longEvents[1].name}{" "}
              </div>
            ),
            moduleType: "long",
            event: longEvents[1],
          };
        }
      }
      const isSameEvent = (e1, e2) => e1.id === e2.id;
      singleEvents = events.filter(
        event =>
          !longEvents.some(longEvent => isSameEvent(event, longEvent)) &&
          !day.endOf("day").isAfter(moment(event.endDate).endOf("day")),
      );
      slot11 = {
        event: singleEvents[0],
        icon: singleEvents[0] && returnIcon(singleEvents[0].moduleType),
      };
      slot12 = {
        event: singleEvents[1],
        icon: singleEvents[1] && returnIcon(singleEvents[1].moduleType),
      };
      slot13 = {
        event: singleEvents[2],
        icon: singleEvents[2] && returnIcon(singleEvents[2].moduleType),
      };
      slot14 = {
        event: singleEvents[3],
        icon: singleEvents[3] && returnIcon(singleEvents[3].moduleType),
      };
      slot15 = {
        event: singleEvents[4],
        icon: singleEvents[4] && returnIcon(singleEvents[4].moduleType),
      };
      if (events.length > 7)
        slot15 = {
          event: null,
          icon: "+" + (events.length - 6),
        };
    } else if (longEvents.length === 1) {
      singleEvents = events.filter(
        event => !longEvents.some(longEvent => event.id === longEvent.id),
      );
      slot6 = singleEvents[0] && {
        event: singleEvents[0],
        element: returnIcon(singleEvents[0].moduleType),
        moduleType: "single",
      };
      slot7 = {
        event: singleEvents[1],
        icon: singleEvents[1] && returnIcon(singleEvents[1].moduleType),
      };
      slot8 = {
        event: singleEvents[2],
        icon: singleEvents[2] && returnIcon(singleEvents[2].moduleType),
      };
      slot9 = {
        event: singleEvents[3],
        icon: singleEvents[3] && returnIcon(singleEvents[3].moduleType),
      };
      slot10 = {
        event: singleEvents[4],
        icon: singleEvents[4] && returnIcon(singleEvents[4].moduleType),
      };
      slot11 = {
        event: singleEvents[5],
        icon: singleEvents[5] && returnIcon(singleEvents[5].moduleType),
      };
      slot12 = {
        event: singleEvents[6],
        icon: singleEvents[6] && returnIcon(singleEvents[6].moduleType),
      };
      slot13 = {
        event: singleEvents[7],
        icon: singleEvents[7] && returnIcon(singleEvents[7].moduleType),
      };
      slot14 = {
        event: singleEvents[8],
        icon: singleEvents[8] && returnIcon(singleEvents[8].moduleType),
      };
      slot15 = {
        event: singleEvents[9],
        icon: singleEvents[9] && returnIcon(singleEvents[9].moduleType),
      };
      if (events.length > 11)
        slot15 = {
          event: null,
          icon: "+" + (events.length - 10),
        };
    }
  }

  const returnClasses = ind => {
    let classes = "border-solid border-primary-400 ";
    if (ind < 36) {
      if (ind % 7 === 0) classes += "border-b-[1px]";
      else classes += "border-r-[1px] border-b-[1px]";
    } else {
      if (ind !== 42) classes += "border-r-[1px]";
    }
    return classes;
  };

  const handleChangeDay = () => {
    changeDay(day);
    setSelectedView(2);
    setMonth(moment(day).format("MMMM"));
    setYear(moment(day).format("YYYY"));
  };

  function handleMouseOver(event, e) {
    if (!event) return;
    dispatch(
      setCurrentActiveSechuleViewPopup(
        event?.id ?? event?.bookingId ?? event?.mappingId,
      ),
    );
    const pos = e.target.getBoundingClientRect(); 
    setXPos(pos.x);
    setYPos(pos.y);
    setHoveredEvent(getAndChangeEventsFormat(day, event));
    setHoveredElement(e.target);
  }

  return (
    <div
      className={`grid grid-cols-5 grid-rows-4 font-ibm h-[122px] overflow-hidden text-black ${returnClasses(
        ind,
      )} `}>
      <div
        className={`cursor-pointer font-ibm font-semibold flex items-center justify-center w-8 text-xs rounded-full ${classes}`}
        onClick={handleChangeDay}>
        {day.date() < 10 ? `0${day.date()}` : day.date()}
      </div>
      <div className=""></div>
      <div className=""></div>
      <div className=""></div>
      <div className=""></div>
      <div
        onMouseEnter={e => handleMouseOver(slot1?.event, e)}
        className={
          slot1 && slot1.moduleType === "single"
            ? "overflow-hidden justify-self-center self-center cursor-pointer"
            : "text-xs font-medium cursor-pointer"
        }>
        {slot1 && slot1.element}
      </div>

      <div
        className="overflow-hidden cursor-pointer justify-self-center self-center  "
        onMouseEnter={e => handleMouseOver(slot2?.event, e)}>
        {slot2 && slot2.icon}
      </div>
      <div
        className="overflow-hidden justify-self-center self-center cursor-pointer"
        onMouseEnter={e => handleMouseOver(slot3.event, e)}>
        {slot3 && slot3?.icon}
      </div>
      <div
        className="overflow-hidden justify-self-center self-center cursor-pointer"
        onMouseEnter={e => handleMouseOver(slot4?.event, e)}>
        {slot4 && slot4.icon}
      </div>
      <div
        className="overflow-hidden justify-self-center self-center cursor-pointer"
        onMouseEnter={e => handleMouseOver(slot5?.event, e)}>
        {slot5 && slot5.icon}
      </div>
      <div
        className={
          slot6 && slot6.moduleType === "single"
            ? "overflow-hidden justify-self-center self-center cursor-pointer"
            : "text-xs font-medium cursor-pointer"
        }
        onMouseEnter={e => handleMouseOver(slot6?.event, e)}>
        {slot6 && slot6.element}
      </div>
      <div
        className="overflow-hidden justify-self-center self-center cursor-pointer"
        onMouseEnter={e => handleMouseOver(slot7?.event, e)}>
        {slot7 && slot7.icon}
      </div>
      <div
        className="overflow-hidden justify-self-center self-center cursor-pointer"
        onMouseEnter={e => handleMouseOver(slot8?.event, e)}>
        {slot8 && slot8.icon}
      </div>
      <div
        className="overflow-hidden justify-self-center self-center cursor-pointer"
        onMouseEnter={e => handleMouseOver(slot9?.event, e)}>
        {slot9 && slot9.icon}
      </div>
      <div
        className="overflow-hidden justify-self-center self-center cursor-pointer"
        onMouseEnter={e => handleMouseOver(slot10?.event, e)}>
        {slot10 && slot10.icon}
      </div>
      <div
        className="overflow-hidden justify-self-center self-center cursor-pointer"
        onMouseEnter={e => handleMouseOver(slot11?.event, e)}>
        {slot11 && slot11.icon}
      </div>
      <div
        className="overflow-hidden justify-self-center self-center cursor-pointer"
        onMouseEnter={e => handleMouseOver(slot12?.event, e)}>
        {slot12 && slot12.icon}
      </div>
      <div
        className="overflow-hidden justify-self-center self-center cursor-pointer"
        onMouseEnter={e => handleMouseOver(slot13?.event, e)}>
        {slot13 && slot13.icon}
      </div>
      <div
        className="overflow-hidden justify-self-center self-center cursor-pointer"
        onMouseEnter={e => handleMouseOver(slot14?.event, e)}>
        {slot14 && slot14.icon}
      </div>
      <div
        className="overflow-hidden justify-self-center self-center cursor-pointer"
        onMouseEnter={e => handleMouseOver(slot15?.event, e)}>
        {slot15 && slot15.icon}
      </div>
    </div>
  );
}
DateCell.propTypes = {
  date: propTypes.number,
  classes: propTypes.string,
  day: propTypes.object,
  changeDay: propTypes.func,
  setSelectedView: propTypes.func,
  events: propTypes.array,
  ind: propTypes.number,
  setMonth: propTypes.func,
  setYear: propTypes.func,
  setHoveredEvent: propTypes.func,
  setHoveredElement: propTypes.func,
  setXPos: propTypes.func,
  setYPos: propTypes.func,
};
export default DateCell;
