import React from "react";
import ModalTitle from "./ModalTitle";
import Checkbox from "./Checkbox";
import DropDown from "./DropDown";
import LinkButton from "./LinkButton";
import ModalButton from "./ModalButton";
import propTypes from "prop-types";
import {Link} from "react-router-dom";

function ModalTemplate({
  closeModal,
  TitleIcon,
  closeIcon,
  title,
  description1,
  description2,
  modalButtonValue,
  linkButtonValue,
  dropDownOptions,
  checkBoxOptions,
  modalButtonFunctions,
  dropDownPlaceholder,
  date ,
  time,
  details,
  courseReasonType,mode,mentorName,branchName,
  modalForSuccessfullyBooked ,
  description,
  isSlotCancelled
}) {
  return (
    <div className="py-6 px-8 w-[396px] ">
      <ModalTitle
        TitleIcon={TitleIcon}
        closeIcon={closeIcon}
        title={title}
        description1={description1}
        description2={description2}
        description={description}
        closeModal={closeModal}
        modalForSuccessfullyBooked={modalForSuccessfullyBooked}
        date={date}
        time={time}
        details={details}
        isSlotCancelled={isSlotCancelled}
        courseReasonType={courseReasonType}
        mode={mode}
        mentorName={mentorName}
        branchName={branchName}
      />
      {checkBoxOptions && <Checkbox checkBoxOptions={checkBoxOptions} />}
      {dropDownOptions && <DropDown dropDownOptions={dropDownOptions} placeHolder={dropDownPlaceholder} />}
      <div
        className={`h-auto ${linkButtonValue ? "flex items-center justify-between  gap-6" : ""}`}>
        {linkButtonValue && <LinkButton linkButtonValue={linkButtonValue} linkButtonFunctions={[closeModal]} />}
        {modalButtonValue && modalButtonValue=="Submit Test"?(
          <Link to="/preparepractice/result">
            <ModalButton
              modalButtonValue={modalButtonValue}
              modalButtonFunctions={modalButtonFunctions}
            />
          </Link>):(
          <ModalButton
            modalButtonValue={modalButtonValue}
            modalButtonFunctions={modalButtonFunctions}
          />)}
      </div>
    </div>
  );
}
ModalTemplate.propTypes = {
  closeModal: propTypes.func,
  TitleIcon: propTypes.elementType,
  closeIcon: propTypes.bool,
  title: propTypes.string,
  description1: propTypes.string,
  description2: propTypes.string,
  modalButtonValue: propTypes.string,
  linkButtonValue: propTypes.string,
  dropDownOptions: propTypes.array,
  checkBoxOptions: propTypes.array,
  modalButtonFunctions: propTypes.array,
  dropDownPlaceholder: propTypes.string,
  setIsSolutionShow :propTypes.func,
  time:propTypes.string,
  date:propTypes.string,
  details:propTypes.object,
  modalForSuccessfullyBooked :propTypes.bool,
  description:propTypes.string,
  isSlotCancelled :propTypes.bool,
  courseReasonType:propTypes.string,
  mode: propTypes.string,
  branchName: propTypes.string,
  mentorName: propTypes.string
};
export default ModalTemplate;
