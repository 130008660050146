import React, { useState, useRef } from "react";
import arrowRight from "../../../../assets/icons/arrow-right-homepage-active.svg";
import arrowRightInactive from "../../../../assets/icons/arrow-right-homepage-inactive.svg";
import arrowLeft from "../../../../assets/icons/arrow-left-homepage-active.svg";
import arrowLeftInactive from "../../../../assets/icons/arrow-left-homepage-inactive.svg";

import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import SwiperCore, { Navigation, Pagination } from "swiper";
// import Thumbnail from "../../../../assets/images/thumbnail.svg";
import propTypes from "prop-types";

import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "./slider_styles.css";

// const slides = [
//   {
//     id: 10,
//     url:Thumbnail
//   },
//   {
//     id: 11,
//     url:Thumbnail

//   },
//   {
//     id: 12,
//     url:Thumbnail

//   },
//   {
//     id: 13,
//     url:Thumbnail

//   },
//   {
//     id: 14,
//     url:Thumbnail

//   },
// ];

SwiperCore.use([Navigation, Pagination]);

function BannerSlider({imageData}) {
  const [disableRightIcon, setDisableRightIcon] = useState(false);
  const [disableLeftIcon, setDisableLeftIcon] = useState(true);

  const handleRightIcon = () => {
    setDisableRightIcon(true);
    setDisableLeftIcon(false);
  };
  const handleLeftIcon = () => {
    setDisableLeftIcon(true);
    setDisableRightIcon(false);
  };

  const prevRef = useRef();
  const nextRef = useRef();

  return (
    <div>
      <Swiper
        modules={[Navigation, Pagination]}
        navigation={false}
        pagination={{ clickable: true }}
        onReachEnd={handleRightIcon}
        onReachBeginning={handleLeftIcon}
        onBeforeSlideChangeStart={() => setDisableLeftIcon(true)}
        onSlideNextTransitionStart={() => setDisableLeftIcon(false)}
        onSlidePrevTransitionStart={() => setDisableRightIcon(false)}
        onInit={swiper => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
        slidesPerView={1}
        spaceBetween={30}
        loop={true}>
        <img
          src={disableLeftIcon ? arrowLeftInactive : arrowLeft}
          alt=""
          ref={prevRef}
          className="absolute left-8 top-1/2 z-10 transform -translate-y-1/4 "
          loading="lazy"
        />
        {imageData["schoolImages"].map(slide => (
          <SwiperSlide key={slide.id} className="">
            <img
              className=" object-cover w-full h-96  flex justify-between items-center rounded-2xl mt-10  "
              src={slide.link}
              alt="Thumbnail"
              loading="lazy"
            />
          </SwiperSlide>
        ))}
        <img
          src={disableRightIcon ? arrowRightInactive : arrowRight}
          alt=""
          ref={nextRef}
          className="absolute right-8 top-1/2 z-10 transform -translate-y-1/4"
          loading="lazy"
        />
      </Swiper>
    </div>
  );
}

BannerSlider.propTypes={
  imageData : propTypes.object
};

export default BannerSlider;
