import React from "react";
import RadioActive from "../../../../assets/icons/radio-active.svg";
import RadioInActive from "../../../../assets/icons/radio.svg";
import { useDispatch, useSelector } from "react-redux";
import { handleChangeGraduation, handleEducationalInput, handleGradNPostGradIsApplicable } from "../../../../redux/features/prelogin/preloginSlice";
import { endpoint } from "../../../../API/config";
import { loginAPI_apirequest } from "../../../../API/api";
import propTypes from "prop-types";
import { useQuery } from "react-query";
import SimpleDropDown from "../SimpleDropDown";
import Grade from "./grade";

function GradNPostgrad({ gradLevel,title }) {
  console.log("gradLevel",gradLevel);
  let gradDetails = useSelector(state => state.prelogin.profileDetails.educationalDetails[gradLevel]);
  let dispatch = useDispatch();
  const fetchDegreeDropdown = () => {
    return loginAPI_apirequest({
      url: `${endpoint.getDegree.addr}`,
      method: "get",
    });
  };
  const { data: degree } = useQuery(
    `${endpoint.getDegree.key}`,
    fetchDegreeDropdown,
  );
  const handleGraduationDropdownChange = (dropdownName, attrName) => {
    let payload = {
      value: dropdownName,
      attrName,
      gradLevel
    };
    dispatch(handleChangeGraduation(payload));
  };
  return (
    <div>
      <div className="flex space-x-[27rem] my-5 relative">
        <p className="font-medium text-lg">{title}</p>
        <div className="text-sm absolute left-52">Not applicable </div>
        <img
          src={gradDetails?.isApplicable === "1" ? RadioActive : RadioInActive}
          className="text-sm absolute left-80"
          alt="Radio"
          onClick={() => dispatch(handleGradNPostGradIsApplicable(gradLevel))}
          loading="lazy"
        />
      </div>
      <div>
        <div className="flex flex-col">
          {/* college name */}
          <input
            type="text"
            autoComplete="off"
            placeholder="College Name*"
            className={
              "border text-sm border-secondary-300 rounded-xl w-full p-4 mb-4 focus:outline-none"
            }
            disabled={gradDetails.isApplicable === "1"}
            name="graduationCollege"
            value={gradDetails.School_College}
            onChange={e => {
              dispatch(
                handleEducationalInput({
                  value: e.target.value,
                  type: gradLevel,
                  keyName: "School_College",
                }),
              );
            }}
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            {/*  */}
            <SimpleDropDown
              disabled={gradDetails.isApplicable === "1"}
              value={gradDetails.Degree || "Degree"}>
              <SimpleDropDown.Options>
                {degree?.data?.data
                  .filter(
                    entry =>
                      entry.qualificationType === title ||
                      entry.qualificationType === null,
                  )
                  .map(option => (
                    <SimpleDropDown.Option
                      key={option.qualificationId}
                      option={option.qualificationName}
                      setValue={() => {
                        handleGraduationDropdownChange(
                          option.qualificationName,
                          "Degree",
                        );
                      }}
                    />
                  ))}
              </SimpleDropDown.Options>
            </SimpleDropDown>
          </div>
          <div>
            <input
              type="number"
              autoComplete="off"
              placeholder="Year of Completion*"
              disabled={gradDetails?.isApplicable === "1"}
              className="border text-sm border-secondary-300 rounded-xl w-full p-4 mb-4 focus:outline-none"
              name="graduationYear"
              value={gradDetails.Expected_Actual_Yr_of_completion}
              onChange={e => {
                dispatch(
                  handleEducationalInput({
                    value: e.target.value,
                    type: gradLevel,
                    keyName: "Expected_Actual_Yr_of_completion",
                  }),
                );
              }}
            />
          </div>
        </div>
        {/* grade */}
        <Grade type={gradLevel} disabled={gradDetails.isApplicable === "1"} />
      </div>
    </div>
  );
}

GradNPostgrad.propTypes = {
  gradLevel: propTypes.string,
  title: propTypes.string,

};

export default GradNPostgrad;
