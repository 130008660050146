import React from "react";
import propTypes from "prop-types";
import AddIcon from "../../../../assets/icons/add.svg";
import { useQuery } from "react-query";
import { loginAPI_apirequest } from "../../../../API/api";
import { endpoint } from "../../../../API/config.jsx";

import SimpleDropDown from "../SimpleDropDown";
import ProfileHeader from "../ProfileHeader";
import { useDispatch,useSelector } from "react-redux";
import {
  handleArrayChange,
  addNewObject,
  setProfileDetails
} from "../../../../redux/features/prelogin/preloginSlice";
import DeleteIcon from "../../../../assets/icons/delete.svg";

import { useFormik } from "formik";
import * as Yup from "yup";

const WorkExperience = ({nextStep,prevStep}) => {
  const dispatch = useDispatch();
  const profileDetails =  useSelector(state => state.prelogin.profileDetails);
  const workExperienceDetails = profileDetails.workExperience;

  //job sector dropdown get api
  const fetchJobSectorDropDown = () => {
    return loginAPI_apirequest({
      url: `${endpoint.getJobSector.addr}`,
      method: "get",
    });
  };

  const { data: jobSectorDropdown } = useQuery(
    `${endpoint.getJobSector.key}`,
    fetchJobSectorDropDown,
  );

  const handleDeleteWorkExperience = (id,attrName,attrValue,objInd) => {
    let formObj = JSON.parse(JSON.stringify(profileDetails));

    let {workExperience} = formObj ;

    if(!id){
      let updatedObj = formObj.workExperience.filter((item,ind)=>ind != objInd);

      dispatch(setProfileDetails({ ...formObj,workExperience:updatedObj }));
    }
    else{
      let newObjInd = workExperience.findIndex((obj) => {
        if (obj.work_experience_id == id) {
          return obj;
        }
      });
      formObj.workExperience[newObjInd].isDelete = attrValue;
      dispatch(setProfileDetails({ ...formObj }));
    }
    
  };
  
  const handleInputData = (e, id, arrayName, attrName) => {
    let payload = {
      value: e.target.value,
      id,
      arrayName,
      attrName,
    };
    dispatch(handleArrayChange(payload));
  };

  const handleDropdownchange = (dropdownName, id, arrayName, attrName) => {
    let payload = {
      value: dropdownName,
      id,
      arrayName,
      attrName,
    };
    dispatch(handleArrayChange(payload));
  };


  const addFields = () => {
    let newObject = {
      work_experience_id: "",
      JobSectorName: "",
      Organisation: null,
      Work_experience_in_months: null,
      // Added missing isDelete key with empty string value
      isDelete: ""
    };
    let payload = {
      obj: newObject,
      arrayName: "workExperience",
    };
    dispatch(addNewObject(payload));
  };

  const initialValues = {
    Work_experience_in_months: workExperienceDetails?.Work_experience_in_months || "",
  };

  
  //yup validation
  const validationSchema = Yup.object({
    Work_experience_in_months: Yup.string()
      .matches(/^[a-zA-Z0-9@]+$/, "Work Experience should be valid.")
      // .required("*Year is required"),
  });

  //implement formik
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      nextStep();
    },
  });

  const inputStyles="border-2 border-gray-200 rounded-2xl py-3 px-3 w-full focus:outline-none  text-sm font-normal font-ibm";
  return (
    <div className="font-ibm">
      <ProfileHeader
        title="Tell us about your work experience"
        backIcon={true}
        prevStep={prevStep}
      />

      {/* work Experience */}
      <section className="grid grid-cols-1 place-content-center justify-items-center">
        <div className="w-[60%]">
          <form onSubmit={formik.handleSubmit}>
            <div className=" shadow-outer rounded-2xl grid grid-cols-1 justify-items-center py-12 px-20">
              <div className="grid grid-cols-1 space-y-3 mb-10 w-full relative">
                <h2 className=" font-medium mb-3 text-lg">Enter Details</h2>
      
              
                {workExperienceDetails?.map((item, index) => (
                  item.isDelete!=="1" && <div key={index} >
                    <div className="flex space-x-2">
                      <input
                        className={inputStyles}
                        placeholder="Work experience (in months)*"
                        name="Work_experience_in_months"
                        value={item.Work_experience_in_months}
                        onChange={(event) => {
                          formik.handleChange(event);
                          formik.values.Work_experience_in_months = event.target.value;
                          handleInputData(
                            event,
                            index,
                            "workExperience",
                            "Work_experience_in_months",
                          );
                        }}
                      />
                      {index>0 && <div 
                        onClick={()=>{handleDeleteWorkExperience(item?.work_experience_id,"isDelete","1",index);}}
                        className="w-12 h-12 cursor-pointer rounded-full shadow-outer flex justify-center items-center absolute -right-14 ">
                        <img className="w-5 h-5" src={DeleteIcon} alt="del" 
                          
                        />
                      </div>}
                    </div>
                    { Boolean(formik.errors.Work_experience_in_months) && (
                      <span className="text-red-400">
                        {formik.errors.Work_experience_in_months}
                      </span>
                    )}                    
                    <div className="my-5">

                      {/* Added Organisation as input field */}
                      <input
                        className={inputStyles}
                        placeholder="Organisation"
                        name="Organisation"
                        value={item.Organisation}
                        onChange={(event) => {
                          handleInputData(
                            event,
                            index,
                            "workExperience",
                            "Organisation",
                          );
                        }}
                      />
                    </div>

                    <div className="my-5">
                      {/* Fetching "JobSectorName" value, if user has selected from the dropdown options */}                      
                      <SimpleDropDown value={item?.JobSectorName ||"Job Sector"}>
                        <SimpleDropDown.Options>
                          {jobSectorDropdown?.data?.data.map(option => (
                            <SimpleDropDown.Option
                              key={option.jobSectorId}
                              option={option.jobSectorName}
                              setValue={() =>
                                handleDropdownchange(
                                  option.jobSectorName,
                                  index,
                                  "workExperience",
                                  "JobSectorName",
                                )
                              }
                            />
                          ))}
                        </SimpleDropDown.Options>
                      </SimpleDropDown>
                    </div>
                    <hr className="w-full my-8" />
                  </div>
                ))}
                <div className="flex">
                  <img
                    src={AddIcon}
                    className="cursor-pointer mt-5"
                    alt="add"
                    onClick={addFields}
                    loading="lazy"
                  />
                  <p className="font-medium text-lg mt-8 px-2">Add experience</p>
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="bg-primary-200 font-medium px-10 py-3 rounded-xl text-white text-lg float-right my-10"
            > Next
            </button>
          </form>
        </div>   
      </section>
    </div>
  );
};

WorkExperience.propTypes = {
  nextStep:propTypes.func,
  prevStep:propTypes.func,
};
export default WorkExperience;
