import React from "react";
import propTypes from "prop-types";

function executeAllFunctions(functionsArray) {
  functionsArray && functionsArray.forEach(func => func());
}
function LinkButton({ linkButtonValue, linkButtonFunctions }) {
  return (
    <button
      className="underline font-ibm text-lg text-secondary-600 font-semibold outline-none "
      onClick={() => executeAllFunctions(linkButtonFunctions)}>
      {linkButtonValue}
    </button>
  );
}

LinkButton.propTypes = {
  linkButtonValue: propTypes.string,
  linkButtonFunctions: propTypes.array,
};
export default LinkButton;
