import React from "react";
import {
  changeCourse,
  loginDetails,
  courseIdNvariantId,
  // setChannelData,
  setIsMentor,
  clearChannelId,
  setChannelId
} from "../../../../redux/features/login/LoginSlice";
import RadioActive from "../../../../assets/icons/radio-active.svg";
import RadioInActive from "../../../../assets/icons/radio.svg";
import { loginAPI_apirequest } from "../../../../API/api";
import { useQuery } from "react-query";
import { endpoint } from "../../../../API/config.jsx";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentHeaderData } from "../../../../redux/features/header/headerSlice";
import OutsideClickHandler from "react-outside-click-handler";
import propTypes from "prop-types";
import uuid from "react-uuid";

function SelectProgramModal({
  setShowCourseModal,
  course_id,
  courseId,
  course_name,
  variantId,
}) {
  const dispatch = useDispatch();
  const LoginDetails = useSelector(loginDetails);
  const courseNVariantId = useSelector(courseIdNvariantId);
  const redirectUrl = "/home";  

  const fetchHeaderOnCourseChange = () => {
    return loginAPI_apirequest({
      url: `${endpoint.moduleData.addr}`,
      method: "post",

      headers: {
        "access-token": LoginDetails.token,
      },
      data: {
        studentImsPin: LoginDetails.student_imspin,
        course_id: courseId,
        course_name,
        variant_id: variantId.toString(),
        myImsCourseId: LoginDetails.lastloggedInMyImsCourseId?.toString(),
      },
    });
  };

  useQuery(
    [endpoint.moduleData.key, course_id, course_name,variantId],
    () => fetchHeaderOnCourseChange(),
    {
      enabled: !!courseId && !!course_name,
      onSuccess: res => {
        // condition added so that if there are no channeldata present then clear the previous stored channelId
        Object.keys(res?.data?.data?.channelData).length>0?dispatch(setChannelId(res?.data?.data?.channelData?.moduleId)):dispatch(clearChannelId());
        dispatch(setIsMentor(res?.data?.data?.isMentor));
        dispatch(setCurrentHeaderData(res?.data?.data?.headerModuleData));
      },
    },
  );

  const handleClick = item => {
    dispatch(
      changeCourse({
        courseIdNVariantId: item.courseId + "_" + item.variantId,
        courseId: item.courseId,
        name: item.courseName,
        variantId: item.variantId,
      }),
    );
   
  };

  return (
    <div className=" z-50 h-screen w-screen flex items-center justify-center bg-black/70 fixed left-0 top-0 font-ibm">
      <OutsideClickHandler onOutsideClick={() => {setShowCourseModal(false); window.location.href = redirectUrl;}}>
        <div className=" z-50 flex flex-col justify-between bg-white rounded-xl h-[450px] w-[410px]">
          <div className="pl-8 py-[22px] shadow-outer">Select Course</div>
          <div className="px-8 pt-8 h-[274px] flex-1 overflow-scroll z-50 ">
            {LoginDetails.studentCourses.map(item => (
              <div className="flex mb-3 " key={uuid()}>
                <img
                  className=" w-4 h-4 pt-1 cursor-pointer "
                  style={{ flexBasis: "24px" }}
                  src={
                    item.courseId + "_" + item.variantId !== courseNVariantId
                      ? RadioInActive
                      : RadioActive
                  }
                  alt="RadioActive"
                  onClick={() => handleClick(item)}
                  loading="lazy"
                />
                <p
                  className={` ml-2 flex-1 font-ibm text-sm  ${
                    item.courseId.toString() === course_id
                      ? "font-medium"
                      : "font-normal"
                  }`}>
                  {item.variantName} ({item.courseName})
                </p>
              </div>
            ))}
          </div>
          <div className="flex justify-center items-center rounded-b-xl pt-5 pb-6 ">
            <button
              type="button"
              className="bg-primary-200 w-[318px] py-2 rounded-2xl text-white "
              onClick={() => {
                setShowCourseModal(false);
                window.location.href = redirectUrl;
              }}>
              Done
            </button>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
}

SelectProgramModal.propTypes = {
  course_id: propTypes.string,
  courseId: propTypes.string,
  course_name: propTypes.string,
  closeModal: propTypes.func,
  setShowCourseModal: propTypes.func,
  login: propTypes.object,
  variantId: propTypes.string,
};

export default SelectProgramModal;
