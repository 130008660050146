import React from "react";
import { useQuery } from "react-query";
import { endpoint } from "../../../../API/config";
import { prepare_apirequest } from "../../../../API/api";
// import { loginDetails } from "../../../../redux/features/login/LoginSlice";
// import { useSelector } from "react-redux";

const VideoCypherForIMS2 = () => {
  // const LoginDetails = useSelector(loginDetails);
  const historyUrl = window.location.href;
  console.log(historyUrl.split("/").length);
  var formData = new FormData();
  formData.append("imspin",historyUrl.split("/")[historyUrl.split("/").length-3]);
  formData.append("videoid",historyUrl.split("/")[historyUrl.split("/").length-2]);
  formData.append("videotype",historyUrl.split("/")[historyUrl.split("/").length-1]);

  const {
    data: videoCypherData,
    isError: videoCypherErrorStatus,
    isSuccess: videoCypherSuccessStatus,
  } = useQuery(
    endpoint.videoCypherURL.key,
    () =>
      prepare_apirequest({
        url: "https://stagingmyims.imsindia.com/vdocypher-ajax",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        method: "POST",
        data: formData,
      }),
    {
      enabled: true,
      refetchOnMount: true,
      retry: 0,
    }
  );
  return (
    <div>
      {videoCypherSuccessStatus && (
        <iframe
          className="rounded-2xl"
          src={`${videoCypherData?.data.data.videoURL}`}
          style={{ "max-width": "100%", height: "487px", width: "100%" }}
          allowFullScreen={true}
          allow="encrypted-media"
        ></iframe>
      )}
      {videoCypherErrorStatus && (
        <div className="w-[100%]  h-[487px] rounded-2xl bg-black opacity-80 flex justify-center items-center">
          <p className="text-white">Error Occured ...Please Try Again</p>
        </div>
      )}
    </div>
  );
};

export default VideoCypherForIMS2;