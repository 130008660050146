import React, { useState, useEffect, useRef } from "react";
import DateCell from "./dateCell";
import propTypes from "prop-types";
import { _filterEventsByDate, getDaysInMonth } from "./utils";
import EventDetails from "./event_details";
import moment from "moment";
import { useSelector } from "react-redux";

function MonthView({
  changeDay,
  events,
  selectedDate,
  setSelectedView,
  setMonth,
  setYear,
  attendance
}) {
  const currentActiveSechuleViewPopup = useSelector(state => state.calendar?.currentActiveSechuleViewPopup);
  const [hoveredElement, setHoveredElement] = useState(null);
  const [hoveredEvent, setHoveredEvent] = useState(null);
  const [xPos, setXPos] = useState(null);
  const [yPos, setYPos] = useState(null);
  const [offset, setOffset] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const prevScrollY = useRef(0);  
  const daysInWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const getClassName = day => {
    if (day.date() === moment().date() && day.month() === moment().month()) {
      return "bg-primary-200 text-white";
    } else if (day.month() != selectedDate.month()) {
      return "text-secondary-400";
    } else {
      return "";
    }
  };

  useEffect(() => {
    const onScroll = () => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current !== currentScrollY){
        if(hoveredElement)
          setYPos(hoveredElement.getBoundingClientRect().y);
        setOffset(window.scrollY);
        setIsScrolling(!isScrolling);
      }
      prevScrollY.current = currentScrollY;
    };
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, [isScrolling]);
  

  return (
    <div className="font-ibm">
      {/* s m t w t f s grid */}
      <div className="grid grid-cols-7 justify-items-center mb-6  font-ibm">
        {daysInWeek.map((day, ind) => {
          return (
            <span
              className="font-medium text-lg  flex items-center justify-center"
              key={day + ind}>
              {day}
            </span>
          );
        })}
      </div>

      {/* dates grid */}
      <div className="grid grid-cols-7  relative justify-items-center border-[1px] border-solid border-primary-400 rounded-[4px] shadow-outer">
        {getDaysInMonth(moment(selectedDate).startOf("month")).map(
          (day, ind) => {
            return (
              <DateCell
                ind={ind + 1}
                key={day + ind}
                day={day}
                changeDay={changeDay}
                classes={getClassName(day)}
                events={_filterEventsByDate(day, events, "Month")}
                setSelectedView={setSelectedView}
                setMonth={setMonth}
                setYear={setYear}
                setHoveredElement={setHoveredElement}
                setHoveredEvent={setHoveredEvent}
                setXPos={setXPos}
                setYPos={setYPos}
              />
            );
          },
        )}
      </div>
      {currentActiveSechuleViewPopup ===
        (hoveredEvent?.id ??
          hoveredEvent?.bookingId ??
          hoveredEvent?.mappingId) && (
        <div
          style={{
            position: "absolute",
            top: `${yPos + offset + 23}px`,
            left: `${xPos}px`,
            zIndex: 100,
          }}>
          <EventDetails event={hoveredEvent} attendance={attendance} />
        </div>
      )}
    </div>
  );
}

MonthView.propTypes = {
  changeDay: propTypes.func,
  events: propTypes.array,
  setSelectedView: propTypes.func,
  setMonth: propTypes.func,
  setYear: propTypes.func,
  selectedDate: propTypes.object,
  selectedMonth: propTypes.object,
  attendance: propTypes.bool,
};

export default MonthView;
