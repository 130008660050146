import React, { useState } from "react";
import Header from "../../header/Header";
import Breadcrumb from "../../global/breadcrumb/NewBreadCrumb";
import ChannelCard from "../cards/channels/ChannelCard";
// import { paramsQuery } from "react-router-dom";

import { useQuery } from "react-query";
import { homeAPI_apirequest } from "../../../../API/api";
import { endpoint } from "../../../../API/config";

import {loginDetails } from "../../../../redux/features/login/LoginSlice";
import {  useSelector } from "react-redux";
import moment from "moment";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import Footer from "../../global/footer/Footer";

function MasterClassesSeeAll() {
  function useParamsQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const LoginDetails = useSelector(loginDetails);
  // fetching the channelId state from redux
  const channelId=useSelector(state => state.login.channelId?.toString());
  const courseID = useSelector(state => state.login.courseId);
  const variantID =useSelector(state => state.login.variantId);
  const [data, setData] = useState();
  const paramsQuery = useParamsQuery();
  const learningPathId  = paramsQuery.get("lpid");
  const getDate=(dateTime)=>{
    if(dateTime){
      let dateTimeArray=dateTime?.split(" ");
      return moment(dateTimeArray[0],"YYYY-MM-DD").format("DD-MM-YYYY");}
  };
  const getTime=(dateTime)=>{
    if(dateTime){
      let dateTimeArray=dateTime?.split(" ");
      return dateTimeArray[1];}
  };
  //   For fetching Channel data, 
  const fetchChannelData = () => {
    return homeAPI_apirequest({
      url: `${endpoint.getChannelData.addr}`,
      method: "post",

      headers: {
        "access-token": LoginDetails.token,
      },
      data: {
        "studentimspin": LoginDetails.student_imspin,
        "channelId": channelId,
        "courseId":courseID.toString(),
        "variantId":variantID.toString(),
        "parentId":learningPathId.toString(),
      },
    });
  };

  const { isError: ischannelDataError } = useQuery(
    [endpoint.getChannelData.key],
    () => fetchChannelData(),
    {
      retry: false,
      onSuccess: res => {
        setData(res.data?.channelListData[0]);
      },
    },
  );

  return (
    <div>
      <Header />
      <div className="mt-8 mb-12 ">
        <Breadcrumb />
      </div>

      {!ischannelDataError ? (
        <div className="px-14">
          <div className="grid grid-cols-4">
            {data &&
              data.children.map((item, index) => (
                <ChannelCard
                  key={index}
                  // tag={item.assetId?item.tagNames.toString():""}
                  content={item.assetId?item.assetName:item.name}
                  status={item.assetType}
                  learningPathId={item.assetId?data.learningPathId:item.parentId}
                  assetId={item.assetId?item.assetId:item.learningPathId}
                  assetName={item.assetId?item.assetName:item.name}
                  bookmarked={item.bookmarked}
                  date={item.createdTs?getDate(item.createdTs):""}
                  time={item.createdTs?getTime(item.createdTs):""}
                  moduleId={LoginDetails.channelData?.moduleId?.toString()}
                  duration={item.assetId?item.duration:""}
                  thumbnailLink={item.thumbnailLink?item.thumbnailLink:""}
                  isPlayList={item.assetId?false:true}
                  title={data?.name}
                />
              ))}
          </div>
        </div>
      ) : (
        <p className="ml-14 text-red-500 text-lg">No Record Found</p>
      )}
      <Footer/>
    </div>
  );
}

export default MasterClassesSeeAll;
