import React from "react";
import Header from "../../header/Header";
import Breadcrumb from "../../global/breadcrumb/Breadcrumb";
import ResumeProgress from "../../home/ResumeProgress";

const ResumeProgressSellAll = () => {
  return (
    <div>
      <Header/>
      <div className="mt-8">
        <Breadcrumb />
      </div>
      <div className="mt-10 mx-14">
        <ResumeProgress seeAllNeeded = {false}/>
      </div>
      
    </div>
  );
};

export default ResumeProgressSellAll;
