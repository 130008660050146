import React from "react";
import { ReactComponent as CheckBoxActive } from "../../../assets/icons/checkbox-active.svg";
import { ReactComponent as CheckBoxDisabled } from "../../../assets/icons/checkbox-inactive.svg";
import { returnIcon } from "./utils";
import propTypes from "prop-types";

function FilterItem({ func, condition, filterName }) {
  return (
    <div
      onClick={() => func(filterName)}
      className="flex w-full justify-between shadow-outer py-3 pl-5 font-ibm pr-4 rounded-xl mb-2 cursor-pointer">
      <div className="flex" >
        {filterName!=="All" && returnIcon(filterName)}
        {/* Changing title from Mentor visit to myplan */}
        <p className="ml-5 capitalize text-base font-normal" >{filterName == "Mentor Visits"?"myPlan":filterName}</p>
      </div>
      {condition ? <CheckBoxActive /> : <CheckBoxDisabled />}
    </div>
  );
}

FilterItem.propTypes = {
  func: propTypes.func,
  condition: propTypes.bool,
  filterName: propTypes.string,
};

export default FilterItem;
