import React, { useState } from "react";
import { ReactComponent as CheckboxInactive } from "../../../../assets/icons/checkbox-inactive.svg";
import { ReactComponent as CheckboxActive } from "../../../../assets/icons/checkbox-active.svg";
import { RadioGroup } from "@headlessui/react";
import PropTypes from "prop-types";

function RadioOption({ value }) {
  return (
    <RadioGroup.Option value={value} className="h-5 flex items-center mb-4">
      {({ checked }) => (
        <>
          {checked ? (
            <CheckboxActive className="mr-4" />
          ) : (
            <CheckboxInactive className="mr-4" />
          )}

          <p className="font-normal text-sm"> {value} </p>
        </>
      )}
    </RadioGroup.Option>
  );
}

function Checkbox({ checkBoxOptions }) {
  const [selected, setSelected] = useState();

  return (
    <RadioGroup value={selected} onChange={setSelected}>
      {checkBoxOptions &&
        checkBoxOptions.map(option => (
          <RadioOption value={option.option} key={option.id} />
        ))}
    </RadioGroup>
  );
}

RadioOption.propTypes = {
  value: PropTypes.string,
};

Checkbox.propTypes = {
  checkBoxOptions: PropTypes.array,
};

export default Checkbox;
