import React, { useState } from "react";
import { ReactComponent as TickCircleActive } from "../../../../assets/icons/tick-circle-active.svg";
import { ReactComponent as Cancel } from "../../../../assets/icons/cancelredbackground.svg";
import propTypes from "prop-types";
import WithModal from "../../global/WithModal/WithModal";
import ModalTitle from "../../modal/ModalTemplate/ModalTitle";
import {endpoint } from "../../../../API/config";
import { useQuery } from "react-query";
// import axios from "axios";
// import ModalButton from "../../modal/ModalTemplate/ModalButton";
import {apirequest_myplan} from "../../../../API/api";
import {loginDetails } from "../../../../redux/features/login/LoginSlice";
import {  useSelector } from "react-redux";
import moment from "moment";
const CancelSlotData = {
  id: 1,
  title1: " Cancel Slot?",
  title2:"Slot Cancelled",
  description:
    "Booking cancelled within 24 hrs of session time will be treated as availed",
  goBack: "Go Back ",
  Cancel:" Slot Cancelled",
};


const CancelSlot = ({ closeModal,bookingId,isMentorDashboard, refetchBookingData,date }) => {
  const [isCancelButtonClicked, setIsCancelButtonClicked] = useState(false);
  const LoginDetails = useSelector(loginDetails);

  const cancelSlot = (bookingId) => {
    return apirequest_myplan({
      url: `${endpoint.cancel_Booking.addr}`,
      method: "put",
      data: {
        bookingId: bookingId.queryKey[1],
      },
    });
  };

  
  const mentorCancelSlot = () => {
    return apirequest_myplan({
      url: `${endpoint.mentorCancelBooking.addr}`,
      method: "post",
 
      headers: {
        "access-token": LoginDetails.token
      },
      data: {
        bookingId:[bookingId.toString()]
      },
    });
  };

  const { refetch: refetchCancelSlot } = useQuery([endpoint.cancel_Booking.key,bookingId], cancelSlot, {
    enabled:  false,
    refetchOnMount: false,
    //on success of cancel slot api we should call booking data to get updated value

    onSuccess:()=>{setIsCancelButtonClicked(true);}
  });

  const { refetch: refetchMentorCancelSlot } = useQuery([endpoint.mentorCancelBooking.key,bookingId], mentorCancelSlot, {
    enabled: false,
    refetchOnMount: false,
    //on success of cancel slot api we should call booking data to get updated value
    onSuccess:()=>{refetchBookingData(); setIsCancelButtonClicked(true);}
  });
  

  const handleClick=()=>{
    isMentorDashboard ? refetchMentorCancelSlot():refetchCancelSlot();
  };
  const handleOkayClick=()=>{
    refetchBookingData();
    closeModal();
  };

  return (
    <div>
      <div className="py-6 px-8 w-[396px] ">
        {!isCancelButtonClicked && (
          <div>
            <ModalTitle
              TitleIcon={Cancel}
              closeIcon={true}
              title={CancelSlotData.title1}
              description={CancelSlotData.description}
              closeModal={closeModal}
            />
            <div className="flex  justify-between">
              <button
                className="underline font-ibm text-lg text-secondary-600 font-semibold outline-none "
                onClick={() => closeModal()}>
                  Go back
              </button>
              <button
                onClick={() => handleClick(bookingId)}
                className="shadow-forButton rounded-2xl text-white bg-primary-200 w-[200px] h-[46px] ">
                  Cancel Slot
              </button>
            </div>
          </div>
        )}
        {isCancelButtonClicked && (
          <div>
            <ModalTitle
              TitleIcon={TickCircleActive}
              isSlotCancelled={true}
              closeIcon={true}
              title={CancelSlotData.title2}
              closeModal={closeModal}
              date={moment(date,"YYYY/MM/DD").format("DD/MM/YYYY")}
              modalButtonFunctions={[closeModal]}
            />
            {/* <ModalButton
              modalButtonValue="Okay"
              modalButtonFunctions={[closeModal,refetchBookingData,setIsCancelButtonClicked(true)]}
            /> */}
            <button
              onClick={handleOkayClick}
              type="button"
              className="w-full flex-1 p-3 text-base font-ibm font-medium text-secondary-100  border border-transparent rounded-2xl bg-[#00ABFB] focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
            >
              Okay
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
CancelSlot.propTypes = {
  closeModal: propTypes.func,
  bookingId:propTypes.number,
  refetchBookingData: propTypes.func,
  isMentorDashboard:propTypes.bool,
  date:propTypes.string,
};
export default WithModal(CancelSlot);
