import React from "react";
import propTypes from "prop-types";
import moment from "moment";

function Class({ className, date, index, tag }) {

  const returnGradient = () => {
    switch (index) {
    case 1: return "to-gradient-purple1 from-gradient-purple1";
    case 2: return "to-gradient-yellow1 from-gradient-yellow2";
    case 0: return "to-gradient-blue1 from-gradient-blue2";
    default:
      break;
    }
  };
  return (
    <div className="shadow-compare rounded-2xl flex justify-between px-8 py-6 mb-4 ">
      <p className="text-[20px] font-normal ">{className}</p>
      <div className="flex">
        <div
          className={`mr-4 text-xs rounded-[4px] text-white bg-gradient-to-r ${returnGradient()} w-14 text-center py-[6px]`}>
          {tag}
        </div>
        <div className="text-xs rounded-[4px] text-white bg-black  px-3 py-[6px] max-w-[97px] whitespace-nowrap ">
          {moment(date).format("DD MMM YYYY")}
        </div>
      </div>
    </div>
  );
}

Class.propTypes = {
  className: propTypes.string,
  date: propTypes.string,
  index: propTypes.number,
  tag:propTypes.string,
};

export default Class;
