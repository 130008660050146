import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ProfileHeader from "../ProfileHeader";
import Grade from "./grade";
import GradNPostgrad from "./GradNPostgrad";
import ProfessionalCertificate from "./ProfessionalCertificate";
import {
  addNewObjectEducation,
  handleEducationalInput,
  removeArrayIdProfessionalCert,
  removeObjectId,
} from "../../../../redux/features/prelogin/preloginSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import AddIcon from "../../../../assets/icons/add.svg";
import propTypes from "prop-types";

const EducationalDetails = ({ nextStep, prevStep }) => {
  const dispatch = useDispatch();
  const educationalFields = useSelector(state => state.prelogin.profileDetails);
  let certificateDetails =
    educationalFields.educationalDetails.ProfessionalCertification;

  const validationSchema = Yup.object().shape({
    tenthGradeSystem: Yup.number().required("*Grade System is required."),
    twelthGradeSystem: Yup.number().required("*Grade System is required."),
    twelthStream: Yup.string().required("*Stream is required."),
    disableGraduation: Yup.boolean(),
    graduationCollege: Yup.string().when("disableGraduation", {
      is: false,
      then: Yup.string().required("*College name is required."),
    }),
    // isGraduationDegreeSelected: Yup.boolean().oneOf([!isGraduationDegreeSelected],"*Degree is required"),
    // isPostGraduationDegreeSelected: Yup.boolean().oneOf([!isPostGraduationDegreeSelected],"*Degree is required"),
    // isProfessionalCertificationSelected: Yup.boolean().oneOf([!isProfessionalCertificationSelected],"*Degree is required"),
    graduationYear: Yup.string()
      .required("*Year of Completion is required.")
      .test(
        "len",
        "*Must be exactly 4 digits",
        val => val && val.toString().length === 4,
      ),
    graduationGradeSystem: Yup.string().required("*Grade System is required."),
    pgCollegeName: Yup.string().required("*College name is required."),
    pgYear: Yup.string().required("*Grade System is required."),
    pcCourse: Yup.string(),
    pcCollegeName: Yup.string(),
    pcYear: Yup.string(),
  });

  const initialValues = {
    tenthGradeSystem:
      educationalFields.educationalDetails.SSC.CGPA_x_y ||
      educationalFields.educationalDetails.SSC.Marks ||
      "",
    twelthGradeSystem:
      educationalFields.educationalDetails.HSC.CGPA_x_y ||
      educationalFields.educationalDetails.HSC.Marks ||
      "",
    twelthStream:
      educationalFields.educationalDetails.HSC.Qualification_Stream || "",
    graduationCollege:
      educationalFields.educationalDetails.Graduation.School_College || "",
    // disableGraduation: disableGraduation || false,
    // disablePostGraduation: disablePostGraduation || false,
    // disableProfessionalCertification: disableProfessionalCertification || false,
    // Removed graduation and post graduation, not required initialValues
    graduationYear:
      educationalFields.educationalDetails.Graduation
        .Expected_Actual_Yr_of_completion || "",
    graduationGradeSystem:
      educationalFields.educationalDetails.Graduation.CGPA_x_y ||
      educationalFields.educationalDetails.Graduation.Marks ||
      "",
    pgCollegeName:
      educationalFields.educationalDetails.PostGraduation.School_College || "",
    pgYear:
      educationalFields.educationalDetails.PostGraduation
        .Expected_Actual_Yr_of_completion || "",
    pgGradeSystem:
      educationalFields.educationalDetails.PostGraduation.CGPA_x_y ||
      educationalFields.educationalDetails.PostGraduation.Marks ||
      "",
    // Added Course and updated initial values of all the fields in Professional Certification
    pcCourse:
      educationalFields.educationalDetails.ProfessionalCertification[0]
        .Course || "",
    pcCollegeName:
      educationalFields.educationalDetails.ProfessionalCertification[0]
        .School_College || "",
    pcGradeSystem:
      educationalFields.educationalDetails.ProfessionalCertification[0]
        .CGPA_x_y ||
      educationalFields.educationalDetails.ProfessionalCertification[0].Marks ||
      "",
    pcYear:
      educationalFields.educationalDetails.ProfessionalCertification[0]
        ?.Expected_Actual_Yr_of_completion || "",
    // isGraduationDegreeSelected: isGraduationDegreeSelected[0],
    // isPostGraduationDegreeSelected: isPostGraduationDegreeSelected[0],
    // isProfessionalCertificationSelected: isProfessionalCertificationSelected[0],
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      handleNextStep();
    },
  });

  const handleNextStep = () => {
    let payload = {
      keyName: "Educational_Details_id",
      arrayName: "ProfessionalCertification",
    };
    dispatch(removeArrayIdProfessionalCert(payload));
    payload = {
      objectName: "SSC",
      keyName: "Educational_Details_id",
    };
    dispatch(removeObjectId(payload));
    payload = {
      objectName: "HSC",
      keyName: "Educational_Details_id",
    };
    dispatch(removeObjectId(payload));
    payload = {
      objectName: "Graduation",
      keyName: "Educational_Details_id",
    };
    dispatch(removeObjectId(payload));
    payload = {
      objectName: "PostGraduation",
      keyName: "Educational_Details_id",
    };
    dispatch(removeObjectId(payload));
    nextStep();
  };

  const addFields = () => {
    let newObject = {
      // Added Education Type as Professional Certification
      EducationType: "Professional Certification",
      Educational_Details_id: "",
      // Course: "",
      // School_College: "",
      Degree: "",
      Expected_Actual_Yr_of_completion: "",
      // Grade_Type: "",
      // CGPA_x_y: "",
      // Marks: "",
      isDelete: "",
      isApplicable: "0",
    };
    let payload = {
      obj: newObject,
      arrayName: "ProfessionalCertification",
    };
    dispatch(addNewObjectEducation(payload));
  };

  const inputStyle =
    "border-2 text-sm border-gray-200 rounded-xl w-full p-4 mb-4 focus:outline-none";

  return (
    <>
      <div className="font-ibm">
        <ProfileHeader
          title="Fill in your educational details"
          backIcon={true}
          prevStep={prevStep}
        />
        <section className="grid grid-cols-1 place-content-center justify-items-center">
          <form >
            <div className=" shadow-outer rounded-2xl grid grid-cols-1 justify-items-center py-12 px-20">
              <div className=" space-y-3 mb-10">
                {/******************************** SSC ********************************/}
                <div>
                  <p className="my-4 font-medium text-lg">10th Grade</p>
                  <Grade type="SSC" />
                </div>

                {/******************************** HSC ********************************/}
                <div>
                  <p className="my-4 font-medium text-lg">12th Grade</p>
                  <Grade type="HSC" />
                  {/*********************** HSC Education Stream ***********************/}
                  <div>
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder="Stream*"
                      className={inputStyle}
                      name="twelthStream"
                      value={
                        educationalFields.educationalDetails.HSC
                          .Qualification_Stream
                      }
                      onChange={e => {
                        formik.handleChange(e);
                        formik.values.twelthStream = e.target.value;
                        dispatch(
                          handleEducationalInput({
                            value: e.target.value,
                            type: "HSC",
                            keyName: "Qualification_Stream",
                          }),
                        );
                      }}
                    />
                    {Boolean(formik.errors.twelthStream) && (
                      // For gap b/w input and mandatory msg
                      <div className="text-red-400 -mt-3">
                        {formik.errors.twelthStream}
                      </div>
                    )}
                  </div>
                </div>

                {/***************************** GRADUATION *****************************/}
                <GradNPostgrad gradLevel={"Graduation"} title={"Graduation"}/>

                {/************************** POST GRADUATION ***************************/}
                <GradNPostgrad gradLevel={"PostGraduation"} title={"Post Graduation"}/>

                {certificateDetails?.map((item, index) => {
                  return (
                    <div className="mb-8" key={item.Educational_Details_id}>
                      <ProfessionalCertificate item={item} index={index} />
                    </div>
                  );
                })}

                {/* Add fields button */}
                <div className="flex">
                  <img
                    src={AddIcon}
                    className="cursor-pointer mt-5"
                    alt="add"
                    onClick={addFields}
                    loading="lazy"
                  />
                  <p className="font-medium mt-8 px-2 text-lg">Add education</p>
                </div>
              </div>
            </div>
            <button
              type="button"
              onClick={()=>{nextStep();formik.handleSubmit();}}
              className="bg-primary-200 px-10 py-3 rounded-xl text-white text-lg float-right my-10">
              Confirm
            </button>
          </form>
        </section>
      </div>
    </>
  );
};

EducationalDetails.propTypes = {
  nextStep: propTypes.func,
  prevStep: propTypes.func,
};

export default EducationalDetails;
