import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentHeaderTab: "home",
  headerId: "",
  id: null,
  showSubheader: false,
  subHeaderData: [],
  currentSubheaderTab: null,
  currentDropdownOption: null,
  showSecondSubheader: false,
  secondSubheaderData: null,
  timeoutID: null,
  selectedSubHeaderTab: "home",
  currentHeaderData: [],
  blogId:"",
  selectedBookmarkTab:""

};

function removeSpaces(str) {
  return str.replace(/ /g, "").toLowerCase();
}

const headerSlice = createSlice({
  name: "Home",
  initialState,
  reducers: {
    setCurrentHeaderData: (state, action) => {
      state.currentHeaderData = action.payload;
    },
    setCurrentHeaderTab: (state, action) => {
      state.currentHeaderTab = action.payload;
    },
    setSelectedSubHeaderTab: (state, action) => {
      state.selectedSubHeaderTab = action.payload;
    },
    hoverOnHeader: (state, action) => {
      state.currentHeaderTab = action.payload.name;
      state.headerId = action.payload.learningPathId;
      state.subHeaderData = action.payload.children;
      state.showSecondSubheader = false;
      state.showSubheader = true;
    },
    hoverOutOnHeader: state => {
      if (state.secondSubheaderData) {
        state.showSubheader = false;
        state.showSecondSubheader = true;
      }
    },
    clickOnDropdownOption: (state, action) => {
      const { dropDownOptionName, subHeaderTabName, dropDownOptions, id } =
        action.payload;
      state.id = id;
      state.currentDropdownOption = dropDownOptionName;
      state.currentSubheaderTab = subHeaderTabName;
      state.secondSubheaderData = dropDownOptions;
      state.showSubheader = false;
      state.showSecondSubheader = true;
    },
    clickOnSecondHeaderTab: (state, action) => {
      window.clearTimeout(state.timeoutID);
      state.currentDropdownOption = action.payload;
    },
    setHeaderStateWithUrl: (state, action) => {
      let locationArr = action.payload;
      if (locationArr[0] === "") locationArr.shift();
      if (locationArr[locationArr.length - 1] === "") locationArr.pop();
      state.showSubheader = false;
      state.showSecondSubheader = false;
      let headerName;
      if(locationArr[0].includes("-")){
        headerName = locationArr[0].split("-")[1];
      }else{
        headerName = locationArr[0];
      }
      state.currentHeaderTab = headerName;
      if (locationArr.length === 2) {
        state.currentSubheaderTab = locationArr[1];
        let headerObj = state.currentHeaderData.find(
          header => removeSpaces(header.name) === removeSpaces(headerName),
        );
        state.subHeaderData = headerObj?.children;
        state.showSubheader = true;
      }

      if (locationArr.length === 3) {
        state.currentDropdownOption = locationArr[2];
        state.currentSubheaderTab = locationArr[1];
        let headerObj = state.currentHeaderData.find(
          header => removeSpaces(header.name) === removeSpaces(headerName),
        );
        state.headerId = headerObj?.learningPathId;
        let subHeaderObj = headerObj?.children?.find(
          child => removeSpaces(child.name) === removeSpaces(locationArr[1]),
        );
        state.secondSubheaderData = subHeaderObj?.children || [];
        state.showSubheader = false;
        if (subHeaderObj && subHeaderObj.children && subHeaderObj.children.length > 0)
          state.showSecondSubheader = true;
      }
    },
    setTimeoutID: (state, action) => {
      state.timeoutID = action.payload;
    },
    clearTimeout: state => {
      window.clearTimeout(state.timeoutID);
    },
    clearData: state => {
      state.showSubheader = false;
      state.subHeaderData = null;
      state.currentSubheaderTab = null;
      state.currentDropdownOption = null;
      state.showSecondSubheader = false;
      state.secondSubheaderData = null;
    },
    setBlogId: (state, action) => {
      state.blogId = action.payload;
    },
    setBlogLink: (state, action) => {
      state.blogLink = action.payload;
    },
    setSelectedBookmarkTab: (state, action) => {
      state.selectedBookmarkTab = action.payload;
    },
  },
});

export const {
  hoverOnHeader,
  hoverOutOnHeader,
  clickOnDropdownOption,
  setTimeoutID,
  clearTimeout,
  setCurrentHeaderTab,
  setHeaderStateWithUrl,
  setSelectedSubHeaderTab,
  setCurrentHeaderData,
  clickOnSecondHeaderTab,
  clearData,
  setBlogId,
  setBlogLink,
  setSelectedBookmarkTab
} = headerSlice.actions;

export const selectCurrentHeaderData = state => state.header.currentHeaderData;
export const selectHeaderId = state => state.header.headerId;
export const selectCurrentHeaderTab = state => state.header.currentHeaderTab;
export const selectedSubHeaderTab = state => state.header.selectedSubHeaderTab;
export const selectShowSubheader = state => state.header.showSubheader;
export const selectSubheaderData = state => state.header.subHeaderData;
export const selectSelectedSubheaderTabName = state => state.header.currentSubheaderTab;
export const selectShowSecondSubheader = state => state.header.showSecondSubheader;
export const selectSecondSubheaderData = state => state.header.secondSubheaderData;
export const selectSelectedDropdownOption = state => state.header.currentDropdownOption;
export const selectTimeoutID = state => state.header.timeoutID;

export default headerSlice.reducer;
