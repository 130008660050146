import React from "react";
import propTypes from "prop-types";
import ProfileHeader from "../ProfileHeader";
import CheckBoxActive from "../../../../assets/icons/checkbox-active.svg";
import CheckBoxInActive from "../../../../assets/icons/checkbox-inactive.svg";
import {
  handleCopyAddressToggle,
  handleObjectChange,
} from "../../../../redux/features/prelogin/preloginSlice";
import { useDispatch, useSelector } from "react-redux";
import { loginAPI_apirequest } from "../../../../API/api";
import { endpoint } from "../../../../API/config.jsx";
import SimpleDropDown from "../../PreLogin/SimpleDropDown";
import { useQuery } from "react-query";
import { useFormik } from "formik";
import * as Yup from "yup";

const Address = ({ nextStep, prevStep }) => {
  // const [stateId, setStateId] = useState(null);
  const dispatch = useDispatch();
  const addressDetails = useSelector(state => state.prelogin.profileDetails);
  // const mailingAddress = addressDetails.mailingAddress;
  // const permanentAddress = addressDetails.permanentAddress;

  // Cities dropdown get api for mailing address
  const fetchCitiesDropdown = () => {
    return loginAPI_apirequest({
      url: `${endpoint.getCities.addr}`,
      method: "post",
      data: {
        stateId: [addressDetails.mailingAddress.stateId],
      },
    });
  };
  const { data: citiesDropDown } = useQuery(
    `${(endpoint.getCities.key, addressDetails.mailingAddress.stateId)}`,
    fetchCitiesDropdown,
    {
      enabled: !!addressDetails.mailingAddress.stateId,
      retry : 1
    },
  );
  // Cities dropdown get api for permanent address
  const fetchPermanentAddressCitiesDropdown = () => {
    return loginAPI_apirequest({
      url: `${endpoint.getCities.addr}`,
      method: "post",
      data: {
        stateId: [addressDetails.permanentAddress.stateId],
      },
    });
  };
  const { data: citiesforPermanentAddress } = useQuery(
    `${(endpoint.getCities.key, addressDetails.permanentAddress.stateId)}`,
    fetchPermanentAddressCitiesDropdown,
    {
      enabled: !!addressDetails.permanentAddress.stateId,
      retry :1
    },
  );
  //States dropdown get api
  const fetchStatesDropdown = () => {
    return loginAPI_apirequest({ 
      url: `${endpoint.getStates.addr}`,
      method: "get",
    });
  };
  const { data: statesDropDown } = useQuery(
    `${endpoint.getStates.key}`,
    fetchStatesDropdown,
  );

  const initialValues = {
    pincode: addressDetails.mailingAddress?.pincode || "",
  };

  //yup validation
  const validationSchema = Yup.object({
    pincode: Yup.string()
      .matches(/^[0-9]{6}$/, "Pincode should be of 6 digits.")
      .required("*Pincode is required"),
  });

  //implement formik
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      nextStep();
    },
  });

  const handleDropDown = (state, objectName, attrName, stateId, cityId) => {
    let payload = {
      value: state,
      objectName,
      attrName,
      stateId,
      cityId,
    };
    dispatch(handleObjectChange(payload));
    // setStateId(stateId);
  };

  const handleInputData = (e, objectName, attrName) => {
    let payload = {
      value: e.target.value,
      objectName,
      attrName,
    };
    dispatch(handleObjectChange(payload));
  };

  function handleAddressCopyRadioButton() {
    dispatch(handleCopyAddressToggle());
  }

  const inputStyles =
    "border border-secondary-300 text-sm rounded-xl px-2 py-3 w-full h-full m-0 ";

  return (
    <>
      <div className="font-ibm">
        <ProfileHeader
          title="Where should we deliver your study material?"
          backIcon={true}
          prevStep={prevStep}
        />

        <section className="grid grid-cols-1 place-content-center justify-items-center ">
          <div className="">
            <form onSubmit={formik.handleSubmit} className="w-[876px]">
              <div className=" shadow-outer rounded-2xl justify-items-center px-[114px] py-12 ">
                <div>
                  <div className="grid grid-cols-1 space-y-3">
                    <h2 className=" font-medium mb-5 text-lg">
                      Mailing address
                    </h2>
                    <div
                      className=" space-y-2  text-sm "
                      key={addressDetails.mailingAddress?.mailingAddressId}>
                      <input
                        className="border border-secondary-300 rounded-xl py-3 mb-2 px-3 w-full focus:outline-none"
                        type="text"
                        autoComplete="off"
                        // replaced House no/ Building name with Address
                        placeholder="Address"
                        value={addressDetails.mailingAddress?.address}
                        name="address"
                        onChange={event => {
                          handleInputData(event, "mailingAddress", "address");
                        }}
                      />
                      {/* Removed Street from Mailing Address */}

                      {/* Removed Landmark from Mailing Address*/}

                      <div className="grid grid-cols-3 space-x-5 mt-1">
                        <SimpleDropDown value={addressDetails.mailingAddress.state || "State"}>
                          <SimpleDropDown.Options>
                            {statesDropDown?.data?.data.map(option => (
                              <SimpleDropDown.Option
                                key={option.stateId}
                                option={option.stateName}
                                setValue={() =>
                                  handleDropDown(
                                    option.stateName,
                                    "mailingAddress",
                                    "state",
                                    option.stateId,
                                    null
                                  )
                                }
                              />
                            ))}
                          </SimpleDropDown.Options>
                        </SimpleDropDown>
                        <SimpleDropDown value={addressDetails.mailingAddress.city || "City"}>
                          <SimpleDropDown.Options>
                            {citiesDropDown?.data?.data.map(option => (
                              <SimpleDropDown.Option
                                key={option.cityId}
                                option={option.cityName}
                                setValue={() =>
                                  handleDropDown(
                                    option.cityName,
                                    "mailingAddress",
                                    "city",
                                    null,
                                    option.cityId,
                                  )
                                }
                              />
                            ))}
                          </SimpleDropDown.Options>
                        </SimpleDropDown>
                        <div className="">
                          <input
                            className={`${inputStyles} h-[54px]`}
                            autoComplete="off"
                            placeholder="Pincode"
                            maxLength={6}
                            name="pincode"
                            value={addressDetails.mailingAddress?.pincode}
                            onChange={event => {
                              formik.handleChange(event);
                              formik.values.pincode = event.target.value;
                              handleInputData(
                                event,
                                "mailingAddress",
                                "pincode",
                              );
                            }}
                          />
                          {Boolean(formik.errors.pincode) && (
                            <span className="text-red-400">
                              {formik.errors.pincode}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* ))} */}
                  </div>
                </div>

                <div>
                  <div className="grid grid-cols-1 space-y-3">
                    {/* Paramanent Address */}
                    <div className="">
                      <h2 className=" font-medium text-lg mb-3 mt-5">
                        Permanent address
                      </h2>
                      <div className="flex text-sm space-x-2">
                        <img
                          src={
                            addressDetails.permanentAddress.addressSameAsMailingAddress === "1"
                              ? CheckBoxActive
                              : CheckBoxInActive
                          }
                          alt="CheckInActive"
                          onClick={handleAddressCopyRadioButton}
                          loading="lazy"
                          className="cursor-pointer"
                        />
                        <label>Same as mailing address</label>
                      </div>
                    </div>
                    {addressDetails.permanentAddress.addressSameAsMailingAddress !== "1" && (
                      <div className=" space-y-2 text-sm flex flex-col">
                        <input
                          name="address"
                          autoComplete="off"
                          className={inputStyles}
                          value={addressDetails.permanentAddress?.address}
                          type="text"
                          // replaced placeholder with Address
                          placeholder="Address"
                          onChange={event => {
                            handleInputData(
                              event,
                              "permanentAddress",
                              "address",
                            );
                          }}
                        />
                        {/* Removed Street from PermanentAddress */}

                        {/* Removed Landmark from PermanentAddress*/}

                        <div className="grid grid-cols-3 space-x-5 mb-8 ">
                          <SimpleDropDown
                            value={
                              addressDetails.permanentAddress?.state || "State"
                            }>
                            <SimpleDropDown.Options>
                              {statesDropDown?.data?.data.map(option => (
                                <SimpleDropDown.Option
                                  key={option.stateId}
                                  option={option.stateName}
                                  setValue={() =>
                                    handleDropDown(
                                      option.stateName,
                                      "permanentAddress",
                                      "state",
                                      option.stateId,
                                      null
                                    )
                                  }
                                />
                              ))}
                            </SimpleDropDown.Options>
                          </SimpleDropDown>
                          <SimpleDropDown
                            value={
                              addressDetails.permanentAddress?.city || "City"
                            }>
                            <SimpleDropDown.Options>
                              {citiesforPermanentAddress?.data?.data.map(option => (
                                <SimpleDropDown.Option
                                  key={option.cityId}
                                  option={option.cityName}
                                  setValue={() =>
                                    handleDropDown(
                                      option.cityName,
                                      "permanentAddress",
                                      "city",
                                      null,
                                      option.cityId,
                                    )
                                  }
                                />
                              ))}
                            </SimpleDropDown.Options>
                          </SimpleDropDown>
                          <input
                            // adjusted space inside the input field of pincode
                            className={inputStyles}
                            autoComplete="off"
                            placeholder="Pincode"
                            minLength={6}
                            maxLength={6}
                            name="pincode"
                            value={addressDetails.permanentAddress?.pincode}
                            onChange={event => {
                              handleInputData(
                                event,
                                "permanentAddress",
                                "pincode",
                              );
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="px-10 py-3 shadow-outer rounded-2xl text-lg bg-primary-200 float-right mt-8 mr-4 text-white cursor-pointer">
                Confirm
              </button>
            </form>
          </div>
        </section>
      </div>
    </>
  );
};

Address.propTypes = {
  nextStep: propTypes.func,
  prevStep: propTypes.func,
};
export default Address;
