import React from "react";
import {
  calculateTop,
  calculateWidth,
  calculateLeftPosition,
  calculateHeight,
  returnGradient,
  returnBgColor,
} from "./utils";
import propTypes from "prop-types";
import "./calendar.css";

function Event({ timeless, event, date, ind, length }) {
  const styles = {
    top: calculateTop(event.start, date),
    width: calculateWidth(length),
    minHeight: "1.2%",
    height: calculateHeight(event.start, event.end),
    left: calculateLeftPosition(length, ind),
  };

  return (
    <div
      style={timeless ? {} : styles}
      className={`
      ${timeless ? " mb-1 h-9  " : ""} 
      ${!timeless ? " p-[2px] absolute left-0 " : ""} 
      `}>
      <div className="flex rounded-[4px] overflow-hidden h-full">
        <div
          className={` w-1 bg-gradient-to-b ${returnGradient(
            event.moduleType,
          )} `}></div>
        <p
          className={`w-full pl-1 flex items-center truncate whitespace-pre-wrap
          ${returnBgColor(event.moduleType)} `}> {event.name} <br /> {event.start.format("LT") + " - " + event.end.format("LT") }
        </p>
      </div>
    </div>
  );
}

Event.propTypes = {
  timeless: propTypes.bool,
  event: propTypes.object,
  date: propTypes.object,
  ind: propTypes.number,
  length: propTypes.number,
};

export default Event;
