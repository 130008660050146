import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  selectedView: 0,
  selectedDate:  moment().startOf("day"),
  currentActiveSechuleViewPopup: null,
};

const calendarSlice = createSlice({
  name: "Calendar",
  initialState,
  reducers: {
    setCalendarView: (state, action) => {
      state.selectedView = action.payload;
    },
    changeSelectedDate: (state, action) => {
      state.selectedDate = moment(action.payload);
    },
    setCurrentActiveSechuleViewPopup: (state, action) => {
      state.currentActiveSechuleViewPopup = action.payload;
    },
  },
});

export const { setCalendarView, changeSelectedDate, setCurrentActiveSechuleViewPopup } =
  calendarSlice.actions;

export default calendarSlice.reducer;