import React from "react";
import DropDown from "../DropDown";
import { useDispatch, useSelector } from "react-redux";
import { handleChangeGradeSystemValue, handleChangeGraduationType } from "../../../../redux/features/prelogin/preloginSlice";
import propTypes from "prop-types";

function Grade({ type, disabled }) {

  const dispatch = useDispatch();
  const educationStandard = useSelector(
    state => state.prelogin.profileDetails.educationalDetails[type],
  );
  const options = [
    { id: 1, topic: "CGPA" },
    { id: 2, topic: "Percentage" },
  ];
  const changeGradeSystem = (gradeSystem, type) => {
    let payload = {
      system: gradeSystem,
      std: type,
    };
    dispatch(handleChangeGraduationType(payload));
  };
  return (
    <div className="grid grid-cols-2 gap-4">
      <DropDown
        options={options}
        text="Grade System"
        setDropdownValue={changeGradeSystem}
        std={type}
        disabled={disabled}
      />

      <div>
        <input
          disabled={disabled}
          className="border text-sm border-secondary-300 rounded-xl w-full p-4 mb-4 focus:outline-none"
          type="number"
          min="0"
          max="100"
          step="0.1"
          autoComplete="off"
          placeholder="Grade*"
          //   name="tenthGradeSystem"
          value={
            educationStandard.Grade_Type === "CGPA"
              ? educationStandard.CGPA_x_y
              : educationStandard.Marks
          }
          onChange={e => {
            dispatch(
              handleChangeGradeSystemValue({
                value: e.target.value,
                type,
              }),
            );
          }}
        />
      </div>
    </div>
  );
}

Grade.propTypes = {
  type: propTypes.string,
  disabled: propTypes.bool,
};

export default Grade;
