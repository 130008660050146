import React from "react";
import propTypes from "prop-types";
import ScheduleDay from "./schedule_day";
import { filterEventsByMonth } from "./utils";
import { useSelector } from "react-redux";
function ScheduleView({ events }) {
  const month = useSelector(state => state.calendar.selectedDate.startOf("month"));

  const eventsObjLogic = eventsForTheMonth => {
    let arr = [];
    let eventObj = {};
    eventsForTheMonth.forEach(event => {
      let format = event.start.format("YYYY-MM-DD");
      if (!arr.includes(format)) {
        arr.push(format);
        eventObj[format] = [event];
      } else {
        eventObj[format] = [...eventObj[format], event];
      }
    });
    arr = arr.sort((a, b) => new Date(a) - new Date(b));
    return [ arr, eventObj ];
  };

  let [ arr, eventsObj ] = eventsObjLogic(filterEventsByMonth(month, events));
  return (
    <div className="">
      {arr &&
        arr.map((day, index) => (
          <ScheduleDay key={day + index} day={day} events={eventsObj[day]} />
        ))}
    </div>
  );
}

ScheduleView.propTypes = {
  events: propTypes.array,
};
export default ScheduleView;
