import React, { useState } from "react";

import arrowDown from "../../../../assets/icons/arrow-down.svg";
import arrowUp from "../../../../assets/icons/arrow-up.svg";
import PropTypes from "prop-types";
import OutsideClickHandler from "react-outside-click-handler";
// import { useSelector } from "react-redux";
// import { selectSection } from "../../../../redux/features/section/sectionSlice";

const DropDown = ({ options, text,setDropdownValue }) => {
  // const sectionName = useSelector(selectSection);
  const [topic, setTopic] = useState(options[0]["topic"]);
  const [open, setOpen] = useState(false);
  const handleClick = topic => {
    setOpen(!open);
    setTopic(topic);
    setDropdownValue && setDropdownValue(topic);
  };
  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      {/* Selector */}

      <div className="relative bg-secondary-100 shadow-outer w-[310px] rounded-2xl overflow-hidden">
        <div
          onClick={() => setOpen(!open)}
          className="flex items-center justify-between font-ibm font-normal text-lg text-ibm py-[14px] pl-6 pr-7 ">
          <div className="flex items-center space-x-2">
            <p className=" text-left">
              {text}
              {text && <span>&nbsp; &#58;&nbsp;</span>} {topic}
            </p>
          </div>
          <img
            className="h-5 w-5"
            src={open ? arrowUp : arrowDown}
            alt="arrow"
            loading="lazy"
          />
        </div>
      </div>

      {/* Drop Down */}
      {open && (
        <div className="absolute w-[310px] max-h-64 -ml-1 rounded-2xl shadow-md bg-secondary-100 scrollbar-thumb-secondary-700 scrollbar-thin scrollbar-thumb-rounded mt-2 z-10">
          {options.map(item => (
            <div role="list" key={item.id} className="mx-8 my-4 w-48">
              <div
                role="listitem"
                onClick={() => handleClick(item.topic)}
                className="text-ibm text-base font-normal font-ibm mb-2 cursor-pointer hover:font-semibold focus:font-semibold">
                <div className="flex items-center space-x-2">
                  <p>{item.topic}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </OutsideClickHandler>
  );
};

DropDown.propTypes = {
  text: PropTypes.string,
  options: PropTypes.array,
  setDropdownValue:PropTypes.func
};

export default DropDown;
