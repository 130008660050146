import React, { useState, useEffect } from "react";
import ProgrammesTable from "./programmesTable";
import EligibilityCriteria from "./eligibilityCriteria";
import SelectionCriteria from "./selectionCriteria";
import CutOff from "./cutOffs";
import propTypes from "prop-types";
import Title from "./collegeTitle";

function Programmes({ data , selectionCriteriaData }) {

  const [programId, setProgramId] = useState(data.schoolProgramData[0].programs);

  const [programData, setProgramData] = useState({});

  const [cutOffData, setCutOffData] = useState();

  //   let initial_eligibleData = data.eligibility[0].comments.split(".");
  //   initial_eligibleData.pop();
  // const [eligibilityData, setEligibilityData] = useState("");

  useEffect(() => {

    // To filter the School program data based on selected program =========>
    const filteredProgrammesData = data.schoolProgramData.find(
      item => item.programs === programId,
    );
    setProgramData(filteredProgrammesData);
   
    //To filter cutOff data based upon the selected Program========>

    const filteredCutOffData = data.schoolCutOffData.find(
      item => item.programs === programId,
    );
    setCutOffData(filteredCutOffData);

    // const filteredEligibityCriteriaData  = 
    
    // const selectionCriteria = data.selection.find(
    //   item => item.programId === programId,
    // );
    // setSelectionData(selectionCriteria);

  }, [programId , data]);

  return (
    <div>
      <Title data = {data} programData = {programData} />
      <div className="mb-16">
        <p className="mb-4 text-2xl font-medium">Programmes</p>

        {/* Tabs */}
        <div className="flex mb-6 ">
          {data?.schoolProgramData?.map(prog => (
            <div
              key={prog.schoolProgramCourseMapping}
              onClick={() => setProgramId(prog.programs)}
              className="cursor-pointer">
              <div className={`w-full pb-4 px-6 pt-[19px] text-lg ${prog.programs === programId ? "font-medium" : "font-normal"}`}>
                {prog.programs}
              </div>
              <div
                className={` h-[3px] w-full ${
                  prog.programs === programId ? "bg-primary-200" : ""
                }   `}
              />
            </div>
          ))}
        </div>

        <ProgrammesTable programData={programData} schoolID= {data["schoolId"]} />

        <EligibilityCriteria data={programData?.eligibilityCriteria} />

        <SelectionCriteria data={selectionCriteriaData} />

        {cutOffData && <CutOff data={cutOffData} />}
        
      </div>
    </div>
  );
}

Programmes.propTypes = {
  collegeData: propTypes.object,
  data: propTypes.any,
  selectionCriteriaData : propTypes.any
};

export default Programmes;
