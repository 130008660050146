import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedSlots:[],
  savedSlots:[]
};

const SlotSlice = createSlice({
  name: "slots",
  initialState,
  reducers: {

    updateSlot:(state, action)=>{
      const {payload} = action;
      const isExist = state.selectedSlots.some(val=>{
        if(val.date=== payload.date && val.slotId=== payload.slotId){
          return true ;
        }else{
          return false ;
        }
      });
    
      if(isExist){
        state.selectedSlots = state.selectedSlots.filter(val=>{
          return !( val.slotId === payload.slotId &&  val.date=== payload.date );
        });
      }else{
        state.selectedSlots.push(payload);
      }
      //for deselecting saved slots
      const isExistinSavedSlots = state.savedSlots.some(val=>{
        if(val.date=== payload.date && val.slotId=== payload.slotId){
          return true ;
        }else{
          return false ;
        }
      });
    
      if(isExistinSavedSlots){
        state.savedSlots= state.savedSlots.filter(val=>{
          return !( val.slotId === payload.slotId &&  val.date === payload.date );
        });
      }
    }, 

    updateMode:(state, action)=>{
      let objIndex = state.selectedSlots?.findIndex(obj=>obj.date === action.payload.date && obj.slot === action.payload.slot);
      if(objIndex!==-1){
        state.selectedSlots[objIndex] = {...state.selectedSlots[objIndex], mode: action.payload.mode};
      }
    },

    saveSlots:(state)=>{
      state.savedSlots= state.selectedSlots;
    },

  },
});

export const {
  updateMode,
  updateSlot,
  saveSlots
} = SlotSlice.actions;

export const selectedSlots = state => state.slots.selectedSlots;
export const savedSlots = state => state.slots.savedSlots;



export default SlotSlice.reducer;
