import React from "react";
import ProfileHeader from "../ProfileHeader";
import propTypes from "prop-types";
import { useQuery } from "react-query";
import { loginAPI_apirequest } from "../../../../API/api";
import { endpoint } from "../../../../API/config";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../../global/loader/Loader";

const CongratulationsTab = ({ prevStep }) => {
  const profileDetails = useSelector(state => state.prelogin.profileDetails);
  const history = useHistory();
  const fetchPreloginDetails = () => {
    return loginAPI_apirequest({
      url: `${endpoint.studentPrelogin.addr}`,
      method: "post",
      data: profileDetails,
    });
  };

  const { refetch,isLoading } = useQuery(
    [`${endpoint.studentPrelogin.key}`, "congrats"],
    fetchPreloginDetails,
    {
      enabled: false,
      onSuccess: () => {
        history.push("/home");
      },
      onError: () => {
        history.push("/home");
      }
    },
  );

  

  return (
    <div>
      <ProfileHeader
        backIcon={true}
        isSkipForNeeded= {true} // change for removal of Skip for now button in congratulations tab ==>
        // title="Your Profile has successfully been created !"
        prevStep={prevStep}
      />
      <section className="grid grid-cols-1 place-content-center justify-items-center font-ibm">
        <div className="w-[50%]">
          <div className=" shadow-outer rounded-2xl grid grid-cols-1 items-center p-5  px-20 py-20">
            <div className="grid grid-cols-1 space-y-8 text-center">
              <div className="space-y-6">
                <h2 className=" font-semibold text-[40px] grid grid-cols-1">
                  Congratulations!
                </h2>
                <p className="text-xl font-normal ">
                  Your Profile has successfully been created !
                </p>
              </div>
            </div>
          </div>
          <footer className="flex justify-center mt-5 space-x-4">
            {isLoading ?<Loader/>:
              <button
                className="bg-primary-200 px-10 py-3 rounded-xl text-white text-lg font-medium"
                onClick={() => refetch()}>
                Proceed
              </button>}
          </footer>
        </div>
      </section>
    </div>
  );
};

CongratulationsTab.propTypes = {
  prevStep: propTypes.func,
};
export default CongratulationsTab;
