import React from "react";
import ModalTemplate from "../../ModalTemplate/ModalTemplate";
import { ReactComponent as TickCircleActive } from "../../../../../assets/icons/tick-circle-active.svg";
import propTypes from "prop-types";

const content = {
  id: 1,
  title: "Successfully Booked",
  description1: "The slot has been booked successfully.",
  // description2: "time during the slot you book.",
};

function SuccessScreen({ closeModal }) {
  return (
    <ModalTemplate
      closeModal={closeModal}
      TitleIcon={TickCircleActive}
      closeIcon={true}
      title={content.title}
      description1={content.description1}
      modalButtonValue="Okay"
      modalButtonFunctions={[closeModal]}
    />
  );
}

SuccessScreen.propTypes = {
  closeModal: propTypes.func,
};

export default SuccessScreen;
