import React, { useState } from "react";
import Arrowdown from "../../../../assets/icons/arrow-down.svg";
import arrowUp from "../../../../assets/icons/arrow-up.svg";
import propTypes from "prop-types";
import closeIcon from "../../../../assets/icons/close-black.svg";
import OutsideClickHandler from "react-outside-click-handler";

const DropDownMultiselectForFilter = ({ setSelectedValue,selectedValues , options , keyValue}) => {
//   let [handleInputValue , setInputValue] = useState([]);
  const[open , setOpen] = useState(false);

  const handleDelete  = (e,data)=>{
    const keyName= e.target.id ;
    setSelectedValue((preValue)=>{
      return  {
        ...preValue ,
        [keyName] : preValue[keyValue].filter(item=>item != data)
      };
    });
  };
  const handleSelectedValues =(e , data)=>{
    const keyName= e.target.id ;

    setSelectedValue((prevValue)=>{
      return {
        ...prevValue , 
        [keyName]  : prevValue[keyValue].includes(data) ? prevValue[keyValue] : [...prevValue[keyValue] , data]
      };
    });
  };
 
  return (
    <OutsideClickHandler onOutsideClick={()=>setOpen(false)}>
      <div className="flex flex-wrap w-[15rem] shadow-outer rounded-xl py-2 pb-2 break-words break-normal" >
        <div className="w-[12rem] flex flex-wrap">
          {selectedValues[keyValue].map((dta)=>
            <span 
              key={dta} 
              className="flex flex-wrap shadow-outer  rounded-xl px-3 py-2 mr-2 w-auto">{dta}
              <img 
                id={keyValue}
                className="cursor-pointer" 
                onClick={(e)=>handleDelete(e ,dta)} 
                src={closeIcon} 
              />
            </span> 
          )}
        </div>
        <img  src={open ?arrowUp :  Arrowdown} className="cursor-pointer" loading="lazy"onClick={()=>setOpen(!open)}/>
      </div>
      {open &&<div className="absolute w-[15rem] max-h-32 p-3 -ml-1 rounded-2xl shadow-md bg-secondary-100 scrollbar-thumb-secondary-700 scrollbar-thin scrollbar-thumb-rounded mt-2 z-10">
        {options.map((data)=>(
          <p className="px-2 pb-2 cursor-pointer hover:font-semibold " key={data} id= {keyValue} onClick={(e)=>handleSelectedValues(e ,data)}>{data}</p>
        ))}
      </div>}
    </OutsideClickHandler>
  );
};
DropDownMultiselectForFilter.propTypes={
  placeholder : propTypes.string,
  selectedValues :propTypes.array,
  setSelectedValue :propTypes.func,
  options:propTypes.array,
  keyValue : propTypes.string
};
export default DropDownMultiselectForFilter ;