import React, { useState } from "react";
// import Image from "../../../../assets/images/Image.png";
import { Tab } from "@headlessui/react";
// import CutOffPage from "./CutOffPage";
// import banner from "../../../../assets/images/adv.svg";
import Header from "../../header/Header";
import Footer from "../../global/footer/Footer";
import { useQuery } from "react-query";
import { endpoint } from "../../../../API/config";
import { apirequest_schoolsAPI } from "../../../../API/api";
import { useSelector } from "react-redux";

import { loginDetails } from "../../../../redux/features/login/LoginSlice";
import { useEffect } from "react";
import Loader from "../../global/loader/Loader";
import Advertisement from "../../home/cards/advertisement/Advertisement";
// import { useEffect } from "react";

export const CutOff = () => {
  const courseID = useSelector(state => state.login.courseId);
  const LoginDetails = useSelector(loginDetails);
  const advertisementData = useSelector(state=>state.advertisement.advertisementData);

  const {
    data : reservationCategorydata , 
    isSuccess :reservationCategoryStatus
  } = useQuery(endpoint.getReservationCategoryData.key , ()=>apirequest_schoolsAPI({
    method : "get",
    url :  endpoint.getReservationCategoryData.addr
  }),{
    select : (res)=>res.data.data
  });

  //tab functionality section ==========>
  const [selectedTab , setSelectedTab] = useState("");

  useEffect(()=>{
    reservationCategoryStatus && setSelectedTab(reservationCategorydata[0]["reservationCategoryName"]);
  },[reservationCategoryStatus]);

  // function to call the api for cutOff data==========>
  const getCourseCutoff = () => {
    return apirequest_schoolsAPI({
      url: `${endpoint.GetCourseCutoff.addr}`,
      method: "post",

      headers: {
        "access-token": LoginDetails.token,
      },
      data: {
        courseId: courseID.toString(),
        "categoryName": selectedTab
      },
    });
  };

  const [headerDetails, setHeaderDetails] = useState([]);
  // Query details comes here to get the cutOff details =======>
  const {data , isSuccess , isError, isLoading} = useQuery([endpoint.GetCourseCutoff.key , selectedTab], () => getCourseCutoff(), {
    select : (res)=>res.data.data,
    onSuccess  : (res)=> {
      setHeaderDetails(Object.keys(res[0]["programData"][0]));
    },
    enabled : !!selectedTab
  });

  return (
    <div className="">
      <Header />
      <Advertisement width={"373px"} height={"195px"} slidesPerView={5} data={advertisementData?.cutoffs?.smallbanner}/>

      {<div className="flex flex-col w-full my-12 ">
        <Tab.Group onChange={(e)=>{ return setSelectedTab(reservationCategorydata[e]["reservationCategoryName"]);}}>
          <Tab.List className="flex flex-row space-between items-center space-x-3 text px-16 mb-12">
            {
              reservationCategorydata?.map((item) => (
                <Tab
                  key={item.reservationCategoryName}
                  value={item.reservationCategoryName}
                  // refName={item.reservationCategoryName}
                  
                  className={({ selected }) =>
                    `${
                      selected? "text-white bg-primary-300 font-medium" :"text-secondary-600"
                    } font-ibm font-normal text-md  shadow-outer rounded-2xl px-6 py-4`
                  }>
                  {item.reservationCategoryName}
                </Tab>
              ))}
          </Tab.List>
          
          {isLoading && <Loader/>}
          {isError &&  <p className="text-red-600 flex justify-center font-medium text-lg">No Record Found</p>}
          {/* Table header */}
          {isSuccess &&<div className="m-8 shadow-outer rounded-2xl">

            <div className=" flex items-center text-center px-8 py-6 shadow-outer rounded-t-2xl">
              <p className="flex-1 font-medium text-secondary-600 text-lg">School</p>
              {headerDetails?.map(item=>(
                <p key={item} className="flex-1 font-medium text-secondary-600 text-lg">{item}</p>
              ))} 
            </div>
            {/* Table body */}
            <div className="shadow-outer rounded-b-2xl">
              
              {data?.map((item ,index)=>(
                <div className="flex flex-col" key={index} >
                  <div key={index} className="flex justify-items-start px-4 py-2">
                    <p className="px-4 py-2 text-center flex-1 font-medium text-secondary-600 text-lg"> {item.schoolName}</p>   
                    {item?.programData?.map(program=>(
                      headerDetails.map(val=>(
                        <p className="px-4 py-2 text-center flex-1 font-medium text-secondary-600 text-lg" key={val}>{program[val]}</p>
                      ))
                    ))}
                  </div>
                  {advertisementData?.cutoffs?.bigbanner?.length>0 && 
                    <>
                      {index ==2 && <div className="w-auto m-6 rounded-xl "> <iframe src={`Advertisement.html?id=${advertisementData?.cutoffs?.bigbanner[0]}`}></iframe></div>}
                      {index ==5 && <div className="w-auto m-6 rounded-xl "> <iframe src={`Advertisement.html?id=${advertisementData?.cutoffs?.bigbanner[1]}`}></iframe></div>}
                    </>
                  }
                </div>
              ))}
            </div>
          </div>}
        </Tab.Group>
      </div>}
      <Footer />
    </div>
  );
};

export default CutOff;
