import React from "react";
import { SpinnerCircular } from "spinners-react";
const Loader = () => {
  return (
    <div className="flex items-center justify-center h-full w-full">
      <SpinnerCircular size={50} thickness={100} speed={100} color="#00ABFB" secondaryColor="#f3f4f6" />
    </div>
  );
};

export default Loader;