import React from "react";
import { useHistory } from "react-router-dom";

const NewBreadCrumb = () => {
  let history = useHistory();
  const breadcrumb1 = localStorage.getItem("breadcrumb1");
  const breadcrumb2 = localStorage.getItem("breadcrumb2");
  const breadcrumb3 = localStorage.getItem("breadcrumb3");
  const breadcrumb4 = localStorage.getItem("breadcrumb4");

  return (
    <div className="text-base font-normal font-ibm flex mx-14 cursor-pointer" onClick={()=>history.goBack()}>
      {breadcrumb1 && <p>{breadcrumb1}</p>}
      {breadcrumb2 && <p>&nbsp;&nbsp;&gt;&nbsp;&nbsp; {breadcrumb2}</p>}
      {breadcrumb3 && <p>&nbsp;&nbsp;&gt;&nbsp;&nbsp;	{breadcrumb3}</p>}
      {breadcrumb4 && <p>&nbsp;&nbsp;&gt;&nbsp;&nbsp;	{breadcrumb4}</p>}

    </div>
  );
};

export default NewBreadCrumb;