import React, {useState, useEffect, useRef} from "react";
import propTypes from "prop-types";
const INITIAL_COUNT = 59;
const twoDigits = (num) => String(num);

export default function CountdownApp({refetchOTP,disableVerifyOtpButton , setOtp, setErrorMessage}) {
  const [visible,setVisible] = useState(false);
  const [status, setStatus] = useState(false);
  const [secondsRemaining, setSecondsRemaining] = useState(INITIAL_COUNT);
  const secondsToDisplay = secondsRemaining % 60;
 
  useEffect(() => {
    !visible ? setTimeout(() => {
      setSecondsRemaining(59);
      setVisible(true);
    },60000):setTimeout(() => {
      setVisible(true);
    },59000);
  }, []);

  const handleStart = () => {
    setStatus(true);
    setSecondsRemaining(59);
    refetchOTP();
    disableVerifyOtpButton(false);
    setErrorMessage && setErrorMessage(false);
    setOtp && setOtp({
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp5: "",
      otp6: "",
    });
  };

  useInterval(
    () => {
      if (secondsRemaining > 1) {
        setSecondsRemaining(secondsRemaining - 1);
      } 
      else {
        setStatus(false);
        disableVerifyOtpButton(true);
      }
    },
    // passing null stops the interval
    1000,
  );

  function useInterval(callback, delay) {
    const savedCallback = useRef();
  
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }
  

  return (
    <div>
      {
        visible ?
          (<>
            {status ? <div className="font-ibm text-sm font-normal">
        Resend OTP in {twoDigits(secondsToDisplay)} sec 
            </div>: <button type="button" className="text-primary-200 underline text-sm font-medium cursor-pointer" onClick={handleStart}>Resend OTP</button>}
          </>):(
            <div className="font-ibm text-sm font-normal">
              Resend OTP in {twoDigits(secondsToDisplay)} sec 
            </div>
          )
      }
    </div>
  );
}
CountdownApp.propTypes = {
  refetchOTP: propTypes.func,
  disableVerifyOtpButton: propTypes.func,
  setOtp :  propTypes.func,
  setErrorMessage : propTypes.func
};
