import React from "react";
import propTypes from "prop-types";

function executeAllFunctions(functionsArray) {
  functionsArray && functionsArray.forEach((func) => func());
}

function ModalButton({
  modalButtonValue,
  modalButtonFunctions,
  disabled = false,
}) {
  const handleClick = () => {
    // setIsSolutionShow(true);
    executeAllFunctions(modalButtonFunctions);
  };
  return (
    <button
      disabled={disabled}
      onClick={handleClick}
      type="button"
      className="w-full flex-1 p-3 text-base font-ibm font-medium text-secondary-100  border border-transparent rounded-2xl bg-[#00ABFB] focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
    >
      {modalButtonValue}
    </button>
  );
}

ModalButton.propTypes = {
  disabled: propTypes.bool,
  modalButtonValue: propTypes.string,
  modalButtonFunctions: propTypes.array,
  setIsSolutionShow: propTypes.func,
};

export default ModalButton;
