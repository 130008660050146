import React from "react";
import { ReactComponent as AttendanceRatioIcon } from "../../../../assets/icons/attendanceRatio.svg";
import { ReactComponent as AttendancePercentIcon } from "../../../../assets/icons/attendancePercentage.svg";
import propTypes from "prop-types";
import Loader from "../../global/loader/Loader";

function AttendanceRatio({ attendance, totalDays, percent, isLoading, isPercentage }) {
  return (
    <div className="w-80 h-56 shadow-compare flex items-center justify-center rounded-2xl mr-4 font-ibm ">
      <div className="flex flex-col items-center justify-center">
        <div>{isPercentage ? <AttendancePercentIcon /> : <AttendanceRatioIcon />}</div>
        <h1>
          <span></span>
        </h1>
        <p className="text-center mt-7 mb-3 text-2xl font-light ">
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <span className="text-[42px] font-semibold">
                {percent ? `${percent? percent:"0"}` : `${attendance?attendance:"0"}/${totalDays?totalDays:"0"}`}
              </span>
              {percent && "%"}
            </>
          )}
        </p>
        <p className="text-center text-sm font-normal">Attendance</p>
      </div>
    </div>
  );
}

AttendanceRatio.propTypes = {
  percent: propTypes.bool,
  attendance: propTypes.number,
  totalDays: propTypes.number,
  isLoading: propTypes.bool,
  isPercentage: propTypes.bool,
};

export default AttendanceRatio;
