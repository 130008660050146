import React from "react";
import Compare from "../Compare";
import { useHistory } from "react-router-dom";
import propTypes from "prop-types";
import SchoolWishList from "./schoolWishList/SchoolWishList";
import { useDispatch,useSelector } from "react-redux";
import { setSchoolDetails } from "../../../../redux/features/schools/SchoolSlice";

function SchoolTableItem({ items,schoolListingRefetch }) {
  const advertisementData = useSelector(state=>state.advertisement.advertisementData);

  let history = useHistory();
  let dispatch = useDispatch();

  // const [mappedProgramData , setMappedProgramData] = useState({ ...items.schoolProgramData});

  // console.log(mappedProgramData, "<:mappedProgramData", setMappedProgramData);

  const handleClick = (selectedSchoolDetails)=>{
    dispatch(setSchoolDetails(selectedSchoolDetails));
    history.push("/school/details");
  };
  return (
    <div className="font-ibm space-y-10 pb-7">
      {items?.map((school,index)=>(
        <div className="flex flex-col " key={school.schoolProgramCourseMapping}>
          <div className="grid grid-cols-8 px-6 font-ibm " >
            <div className="col-span-2">
              <div onClick={()=>handleClick(school)} className="cursor-pointer">
                <p className="w-64 text-lg font-medium">
                  {school.schoolName}-
                  <span className="font-normal text-lg">{school.programs}</span>
                </p>
              </div>
              <SchoolWishList
                isLiked = {school.isSchoolLiked} 
                schoolId={school.schoolId} 
                schoolProgramCourseMappingId={school.schoolProgramCourseMapping}
                schoolListingRefetch={schoolListingRefetch}
              />
            </div>

            <div className="flex justify-start ">
              <span className="text-xl font-medium">{school.imsClassification}</span>
            </div>
            <div className="flex justify-start ml-3 ">
              <div>
                <p className=" text-xl font-medium">
                  {school.batchSize}
                </p>
                <p className="font-normal text-sm">Students</p>
              </div>
            </div>
            <div className=" flex justify-center">
              <span className=" text-xl font-medium">
                {school.examAccepted?.join(", ")}
              </span>
            </div>
            <div className="flex justify-self-center">
              <div>
                <p className="text-xl font-medium">
                  {school.fees}
                </p>
                <p className="font-normal text-sm">LPA</p>
              </div>
            </div>
            <div className=" flex justify-center">
              <div>
                <p className=" text-xl font-medium">{school.avgSalary}L</p>
                <p className="font-normal text-sm">Avg. Package</p>
              </div>
            </div>
            <div className="justify-self-center ">
              <Compare schoolId={school.schoolId} programId={school.schoolProgramCourseMapping} />
            </div>
          </div>
          {advertisementData?.home?.bigbanner?.length>0 && 
                    <>
                      {index ==4 && <div className="w-auto m-6 rounded-xl "> <iframe src={`Advertisement.html?id=${advertisementData?.home?.bigbanner[0]}`}></iframe></div>}
                      {index ==9 && <div className="w-auto m-6 rounded-xl "> <iframe src={`Advertisement.html?id=${advertisementData?.home?.bigbanner[1]}`}></iframe></div>}
                    </>}
        </div>
      ))}
    </div>
  );
}

SchoolTableItem.propTypes = {
  items: propTypes.array,
  handleClick: propTypes.func,
  handleCount: propTypes.func,
  selectedId: propTypes.string,
  isLiked: propTypes.bool,
  setIsSchoolSelectedForCompare: propTypes.func,
  schoolListingRefetch: propTypes.func
};

export default SchoolTableItem;
