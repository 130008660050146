import React from "react";
import propTypes from "prop-types";
import { useQuery } from "react-query";
import { endpoint } from "../../../API/config";
import { prepare_apirequest } from "../../../API/api";
import { loginDetails } from "../../../redux/features/login/LoginSlice";
import { useSelector } from "react-redux";
import ResumeProgressCard from "./cards/resumeProgress/ResumeProgressCard";

const ResumeProgress = () => {
  const LoginDetails = useSelector(loginDetails);
  // const [resumeProgressData, setResumeProgressData] = React.useState([]);
  const courseID = useSelector(state => state.login.courseId);
  const variantID = useSelector(state => state.login.variantId);


  const getResumeProgressData = () => {
    return prepare_apirequest({
      url: `${endpoint.getResumeAssetsProgress.addr}`,
      method: "post",

      headers: {
        "access-token": LoginDetails.token,
      },
      data: {
        studentImsPin: LoginDetails.student_imspin,
        courseId: courseID?.toString(),
        variantId: variantID?.toString(),
      },
    });
  };

  const  {data :resumeProgressData , isSuccess , isLoading } = useQuery(
    [endpoint.getResumeAssetsProgress.key, courseID, variantID],
    getResumeProgressData,
    {
      retry: false,
      select :(res)=>res?.data.data
    },
  );
  return (
    <div className="font-ibm w-full">
      <div className="flex justify-between items-center mb-5 ">
        <h1 className="text-2xl font-medium text-secondary-500 ">
          Resume Progress
        </h1>
      </div>
     
      {( isSuccess && resumeProgressData?.length>0) ? <section className="flex z-40  space-x-2 ">
        {resumeProgressData?.map((item, ind) => (
          <ResumeProgressCard
            asset={item}
            key={ind}
          />
        ))}
      </section>:
        <p className={` mt-5  text-xl ${isLoading &&"hidden"}` }>
          {/* You haven&apos;t started learning yet! */}
          <p>This section highlights the learning resources that you have launched but not yet finished.</p>
          <p>There are no resources in the in-progress state now.</p>
        </p>
      }
    </div>
  );
};

ResumeProgress.propTypes = {
  seeAllNeeded: propTypes.bool,
};
export default ResumeProgress;
