import React from "react";
import { Breadcrumbs } from "@material-ui/core";
import { useHistory, withRouter } from "react-router-dom";

import Arrow from "../../../../assets/icons/arrow-right.svg";

const Breadcrumb = () => {
  const pathSnippets = window.location.pathname
    .split("/")
    .filter((index) => index);

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join()}`;
    let history = useHistory();
    const goToPreviousPath = () => {
      history.goBack();
    };
    return (
      <div
        key={url}
        className="text-secondary-600 text-md font-ibm font-normal first-letter:capitalize cursor-pointer"
        onClick={goToPreviousPath}
      >
     
        {pathSnippets[index].replaceAll("%20", " ").replaceAll("%22", " ").replaceAll("_", " ")}
        
      </div>
    );
  });

  const breadcrumbItems = [].concat(extraBreadcrumbItems);

  return (
    <div className="text-secondary-600 text-md font-ibm font-normal py-2 ml-14">
      <Breadcrumbs
        separator={<img src={Arrow} className="w-4 h-4 mr-2 object-contain" loading="lazy"/>}
      >
        {breadcrumbItems}
      </Breadcrumbs>
    </div>
  );
};

export default withRouter(Breadcrumb);
