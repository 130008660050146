import React from "react";
// import Advertisement from "../../../../components/web/home/cards/advertisement/Advertisement";
import Header from "../../header/Header";
import Footer from "../../global/footer/Footer";

import { useQuery } from "react-query";
import { endpoint } from "../../../../API/config";
import {
  fetchCourseSchoolProgramData,
  fetchCourseArticleEventData,
} from "../apiFunctions";

import {loginDetails } from "../../../../redux/features/login/LoginSlice";
import {  useSelector, useDispatch } from "react-redux";
import Block from "./block";
import {setSchoolList} from "../../../../redux/features/schools/SchoolSlice";
import Loader from "../../global/loader/Loader";
import Advertisement from "../../home/cards/advertisement/Advertisement";
import SchoolsListing from "../schoolListings/SchoolsListings";
const Home = () => {
  const dispatch = useDispatch();
  const LoginDetails = useSelector(loginDetails);
  const courseId = useSelector(state=>state.login.courseId);
  // const [schoolData,setSchoolData] = useState([]);
  const {data : schoolDatas ,isError ,isSuccess ,refetch : schoolListingRefetch, isLoading} = useQuery(
    [endpoint.GetCourseSchoolProgramData.key, courseId],
    () => fetchCourseSchoolProgramData(courseId,LoginDetails),
    {
      onSuccess: res=>{
        // setSchoolData(res.data.data);
        dispatch(setSchoolList(res));  //<================== line need to check tomorrow =============>
      },
      select : (res)=>{
        return res.data.data.map(parentArr=>parentArr.schoolProgramData.map(childArr=>{
          return {
            schoolId: parentArr.schoolId,
            schoolProgramCourseMapping :  childArr.schoolProgramCourseMapping,
            batchSize : childArr.batchSize,
            fees: childArr.fees/100000,
            avgSalary : (childArr.avgSalary)/100000, //============>.replace(/,/g, "") value changed from string to number <=======
            cutOffId: childArr.cutOffId,
            isSchoolLiked : childArr.isSchoolLiked,
            programs : childArr.programs,
            imsClassification :  childArr.imsClassification,
            examAccepted : childArr.examAccepted,
            schoolName : parentArr.schoolName,
            courses : childArr.courses
          };
        })).flat();
      },
      enabled : !!courseId
    }
  );

  const advertisementData = useSelector(state=>state.advertisement.advertisementData);
  console.log("Ad",advertisementData);
  const {
    data: courseArticleEventDeadlineData,
    isFetched: isArticleEventDeadlinesFetched,
    
  } = useQuery([endpoint.getCourseArticleEventData.key, courseId, ], () =>
    fetchCourseArticleEventData(courseId, LoginDetails),{retry:false}
  );
  

  return (  
    <div>
      <Header />
      <div className="mx-14 mt-14 ">
        {courseArticleEventDeadlineData && isArticleEventDeadlinesFetched && (
          <div className="flex space-x-6 ">
            <Block
              data={courseArticleEventDeadlineData.data.data[0].article}
              title="Articles"
            />
            <Block
              data={courseArticleEventDeadlineData.data.data[0].event}
              title="Events"
            />
          </div>
        )}
        <Advertisement width={"373px"} height={"195px"} slidesPerView={5} data={advertisementData?.home?.smallbanner}/>
        {isLoading &&<Loader/>}
        {isSuccess && (
          <SchoolsListing schoolListingsData={schoolDatas} schoolListingRefetch={schoolListingRefetch} />
        )}
        {isError && <p className="text-red-600 flex mt-6 justify-center font-medium">No Record Found</p>}
      </div>
      <Footer />
    </div>
  );
};

export default Home;
