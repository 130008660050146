import React from "react";
import { Tab } from "@headlessui/react";
import propTypes from "prop-types";

function TabItem({ tab_name }) {
  return (
    <Tab
      className={({ selected }) => {
        let defaultClasses = " px-6 py-[15px] text-lg ";
        return selected
          ? " bg-white rounded-2xl shadow-outer " + defaultClasses
          : defaultClasses;
      }}>
      {tab_name}
    </Tab>
  );
}

TabItem.propTypes = {
  tab_name: propTypes.string,
};

export default TabItem;
