import React, { useState,useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import ControlPanel from "./ControlPanel";
// import pdf from "./../masterClass/na2.pdf";
import propTypes from "prop-types";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFReader = ({pdfLink}) => {
  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, [ ]);

  
  const [scale, setScale] = useState(1.0);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div>
      <section
        id="pdf-section"
        className=""
      >
        <ControlPanel
          scale={scale}
          setScale={setScale}
          numPages={numPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          file={pdfLink}
        />
        <div className="mx-10 shadow-outer rounded-3xl">
          <Document
            file={pdfLink}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} scale={scale} />
          </Document>
        </div>
      </section>
    </div>
  );
};
PDFReader.propTypes = {
  pdfLink: propTypes.string,
};

export default PDFReader;