import React from "react";
import propTypes from "prop-types";
import CalenderIcon from "../../../../../assets/icons/calendar.svg";

const CalendarModule = ({ venue, selectedDate, setIsCalendarOpen }) => {
  return (
    <div>
      <div className="w-full flex border border-secondary-300 rounded-xl px-4 py-4 mb-4 ">
        <input
          placeholder="Select Date"
          className={`w-full outline-none ${
            selectedDate
              ? "text-base text-secondary-600  font-medium"
              : "text-sm  text-primary-400"
          }`}
          value={selectedDate && selectedDate}
          onChange={setIsCalendarOpen}
        />
        <img
          onClick={() => {
            if (!venue) {
              return alert("Please select venue first.");
            }
            setIsCalendarOpen(true);
          }}
          className="w-5 h-5 font-medium cursor-pointer"
          src={CalenderIcon}
          loading="lazy"
        />
      </div>
    </div>
  );
};

CalendarModule.propTypes = {
  selectedDate: propTypes.string,
  setIsCalendarOpen: propTypes.func,
  venue: propTypes.number,
};
export default CalendarModule;
