import React, { useState } from "react";
import Profile from "../PreLogin/Profile";
import ProfileSlideforFamily from "./loginforfamily/ProfileSlideforFamily";
import Address from "./address/Address";
import EducationalDetails from "./educationDetails/EducationalDetails";
import ExamAttempt from "./examDetails/ExamAttempt";
import WorkExperience from "./workExperience/WorkExperience";
import CongratulationsTab from "./tab_for_congratulations/Congratulations_Tab";
import ProfileHeader from "../PreLogin/ProfileHeader";

import { useQuery } from "react-query";
import { loginAPI_apirequest } from "../../../API/api";
import { endpoint } from "../../../API/config.jsx";
import { useDispatch, useSelector } from "react-redux";
import { setProfileDetails } from "../../../redux/features/prelogin/preloginSlice";
import { loginDetails } from "../../../redux/features/login/LoginSlice";

function Main() {

  const dispatch = useDispatch();
  const login_Details = useSelector(loginDetails);
  // get student data api call
  const fectchStudentData = () => {
    return loginAPI_apirequest({
      url: `${endpoint.getStudentData.addr}`,
      method: "post",
      headers: {
        "access-token": login_Details.token
      },
      data: {
        studentImsPin: login_Details.student_imspin,
      },
    });
  };

  const {isSuccess:isgetStudentDataSuccess,isLoading} = useQuery(`${endpoint.getStudentData.key}`, fectchStudentData, {
    onSuccess: res => {
      dispatch(setProfileDetails(res.data.data));
    },
  });

  const [step, setstep] = useState(1);

  const nextStep = () => {
    setstep(step + 1);
  };

  const prevStep = () => {
    setstep(step - 1);
  };

  switch (step) {
  case 1:
    return <Profile nextStep={nextStep} isgetStudentDataSuccess={isgetStudentDataSuccess} isLoading={isLoading}/>;
  case 2:
    return <ProfileSlideforFamily nextStep={nextStep} prevStep={prevStep} />;

  case 3:
    return <Address nextStep={nextStep} prevStep={prevStep} />;
  case 4:
    return <EducationalDetails nextStep={nextStep} prevStep={prevStep} />;
  case 5:
    return <WorkExperience nextStep={nextStep} prevStep={prevStep} />;
  case 6:
    return <ExamAttempt nextStep={nextStep} prevStep={prevStep} />;
  case 7:
    return <CongratulationsTab nextStep={nextStep} prevStep={prevStep} />;
  default:
    return <ProfileHeader prevStep={prevStep} />;
  }
  
}

export default Main;
