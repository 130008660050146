import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  schoolList:[],
  compareListIds :[],
  compareSchoolList:[],
  schoolDetails : {},
  showGallery : false
};

const SchoolSlice = createSlice({
  name: "schools",
  initialState,
  reducers: {
    addToCompare: (state, action) => {
      const {payload} = action;
      const isExist = state.compareListIds.some(val=>{
        if(val.programId == payload.programId ){
          return true ;
        }else{
          return false ;
        }
      });
    
      if(isExist){
        state.compareListIds = state.compareListIds.filter(val=>{
          return !( val.programId == payload.programId);
        });
      }else{
        state.compareListIds.push(payload);
      }
    },
    setCompareSchoolList:(state,action)=>{
      state.compareSchoolList = [...state.compareSchoolList,...action.payload];
    },
    clearCompareSchoolList:(state)=>{
      state.compareSchoolList = [];
    },
    setSchoolList:(state,action)=>{
      state.schoolList = action.payload;
    },
    setSchoolDetails : (state,action) =>{
      state.schoolDetails = action.payload;
    },
    setShowGallery : (state, action)=>{
      state.showGallery = action.payload;
    }
  },
});

export const { addToCompare, setCompareSchoolList, setSchoolList, clearCompareSchoolList , setSchoolDetails , setShowGallery} = SchoolSlice.actions;

export default SchoolSlice.reducer;
