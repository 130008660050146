import React from "react";
import bookmarkDisable from "../../../../assets/icons/BmarkInActive.svg";
import bookmarkActive from "../../../../assets/icons/Bmark.svg";
import propTypes from "prop-types";
import { useQuery , useQueryClient} from "react-query";
import { endpoint } from "../../../../API/config";
import { apirequest_schoolsAPI } from "../../../../API/api";

import { loginDetails } from "../../../../redux/features/login/LoginSlice";
import { useSelector } from "react-redux";

const BookmarkClick = ({
  articleId,
  className,
  isBookMark,
  // articleRefetch,
}) => {
  const LoginDetails = useSelector(loginDetails);
  const courseID = useSelector(state => state.login.courseId);
  const queryClient = useQueryClient();

  const [isBookmarked, setIsBookmarked] = React.useState(
    isBookMark == "1" ? true : false,
  );

  const AddArticleBookMark = articleId => {
    const response = apirequest_schoolsAPI({
      url: `${endpoint.AddArticleBookMark.addr}`,
      method: "post",

      headers: {
        "access-token": LoginDetails.token,
      },
      data: {
        articleId: articleId,
        isBookMark: "1",
        courseId: courseID.toString(),
      },
    });
    return response;
  };

  const AddArticleUnBookMark = articleId => {
    const response = apirequest_schoolsAPI({
      url: `${endpoint.AddArticleBookMark.addr}`,
      method: "post",

      headers: {
        "access-token": LoginDetails.token,
      },
      data: {
        articleId: articleId,
        isBookMark: "0",
        courseId: courseID.toString(),
      },
    });
    return response;
  };

  const { refetch } = useQuery(
    [endpoint.AddArticleBookMark.key],
    () => {
      isBookmarked && AddArticleUnBookMark(articleId);
      !isBookmarked && AddArticleBookMark(articleId);
    },
    {
      enabled: false,
      onSuccess: () => {
        queryClient.invalidateQueries(endpoint.getCourseArticleEventData.key);
        queryClient.invalidateQueries(endpoint.getSchoolDetails.key);
      },
    },
  );
  React.useEffect(() => {}, [isBookMark]);
  return (
    <div>
      <img
        src={isBookmarked ? bookmarkActive : bookmarkDisable}
        className={`cursor-pointer ${className}`}
        onClick={() => {
          setIsBookmarked(!isBookmarked);
          refetch();
        }}
        loading="lazy"
      />
    </div>
  );
};
BookmarkClick.propTypes = {
  className: propTypes.string,
  isBookMark: propTypes.string,
  articleId: propTypes.string,
  articleRefetch: propTypes.func,
};
export default BookmarkClick;
