import React from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ReactComponent as DownArrow } from "../../../../assets/icons/arrow-down.svg";
import PropTypes from "prop-types";

function DropDown({dropDownOptions, placeHolder,optionName="",setSelectedId,setCourseReasonType,setMentorName,setBranchName }) {
  const [selectedOption, setSelectedOption] = React.useState(placeHolder);
  const handleClick=(id,name)=>{
    
    optionName=="branchName" || optionName=="mentorName"||optionName=="courseReasonType" ?setSelectedId(id):"";
    optionName=="courseReasonType" && setCourseReasonType(name);
    optionName=="branchName" && setBranchName(name);
    optionName=="mentorName" && setMentorName(name);
  };
  return (
    <Listbox
      as="div"
      value={selectedOption}
      onChange={setSelectedOption}
      className="relative mb-4">
      <Listbox.Button className="w-full ">
        <div className=" flex items-center justify-between w-full border-2 border-secondary-300 rounded-2xl pl-4 pr-5 py-3">
          <p
            className={`font-normal text-sm ${
              selectedOption === placeHolder ? "text-secondary-800" : ""
            } `}>
            {selectedOption}
          </p>
          <DownArrow />
        </div>
      </Listbox.Button>
      <Transition
        as={React.Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0">
        <Listbox.Options className="absolute w-full bg-secondary-100 mt-2 rounded-2xl py-4 pl-4 shadow-outer z-30 outline-none">
          {dropDownOptions &&
            dropDownOptions.map(option => (
              <Listbox.Option as="p" value={optionName=="branchName"?option.branchName:optionName=="mentorName"?option.mentorName:optionName=="courseReasonType"?option.courseReasonType:option.option} key={option.id}>
                {({ selected, active }) => (
                  <p
                    className={`${
                      selected || active ? "font-semibold" : "font-normal"
                    } mb-4 text-sm`}
                    onClick={()=>handleClick(optionName=="branchName"? option.branchId
                      :optionName=="mentorName"?option.mentorId:
                        optionName=="courseReasonType"?option.courseReasonTypeId:option.id,

                    optionName=="branchName"? option.branchName:optionName=="mentorName"?option.mentorName:
                      optionName=="courseReasonType"?option.courseReasonType:option.option)}>

                    {optionName=="branchName"?option.branchName:optionName=="mentorName"?option.mentorName:optionName=="courseReasonType"?option.courseReasonType:option.option}
                  </p>
                )}
              </Listbox.Option>
            ))}
        </Listbox.Options>
      </Transition>
    </Listbox>
  );
}

DropDown.propTypes = {
  dropDownOptions: PropTypes.array,
  placeHolder: PropTypes.string,
  optionName: PropTypes.string,
  setSelectedId:PropTypes.func,
  setCourseReasonType:PropTypes.func,
  setMentorName:PropTypes.func,
  setBranchName:PropTypes.func,

};

export default DropDown;
