import React from "react";
import propTypes from "prop-types";

function CutOffs({ data }) {

  const headerData = data?.programData.length>0 ? Object.keys( data?.programData[0]): [];

  return (
    <div className="mb-16 font-ibm">
      <h1 className="mb-6 font-medium text-secondary-600">Cut offs as per Category</h1>

      {/*Table */}
      {data?.programData.length>0 ? <div className="rounded-2xl shadow-outer ">
        {/* Table header */}
        <div className=" flex items-center text-center px-8 py-6 shadow-outer rounded-t-2xl">
          {headerData.map(item=>(
            <p key={item} className="flex-1 font-medium text-secondary-600 text-lg">{item}</p>
          ))} 
        </div>

        {/* Table body */}
        <div className="">
          {data?.programData.map((item , index)=>(
            <div key={index} className="flex justify-items-start px-4 py-2">
              {Object.keys(data?.programData[0]).map(val=>(
                <p className="px-4 py-2 text-center flex-1 font-medium text-secondary-600 text-lg" key={val}>{item[val]}</p>
              ))}
            </div>
            
          ))}
        </div>
      </div> : <p className="text-red-500 text-base font-semibold flex justify-center">No Record Found</p>}
    </div>
  );
}

CutOffs.propTypes = {
  data: propTypes.any,
};

export default CutOffs;
