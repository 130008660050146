import Logout from "../../../assets/icons/logout.svg";
import ScreenMirror from "../../../assets/icons/screenmirroring.svg";
import SelectCourse from "../../../assets/icons/selectcourse.svg";
// import ProfilePic from "../../../assets/icons/my-profile.svg";
export const prePareDropDownOptions = [
  {
    id: "ssh1",
    name: "Guide",
  },
  {
    id: "ssh2",
    name: "Quantitative Ability",
  },
  {
    id: "ssh3",
    name: "Data Interpretation",
  },
  {
    id: "ssh4",
    name: "Logical Reasoning",
  },
  {
    id: "ssh5",
    name: "Verbal Ability",
  },
];

export const testDropDownOptions = [
  {
    id: "ssh1",
    name: "Guide",
  },
  {
    id: "ssh2",
    name: "Proctored Test",
  },
  {
    id: "ssh3",
    name: "Take Home Tests",
  },
  {
    id: "ssh4",
    name: "Past Papers",
  },
  {
    id: "ssh5",
    name: "Sectional Test",
  },
];

export const perfornamceDropDownOptions = [
  {
    id: "ssh1",
    name: "Guide",
  },
  {
    id: "ssh2",
    name: "Predictor",
  },
  {
    id: "ssh3",
    name: "Score Summary",
  },
  {
    id: "ssh4",
    name: "Prep Recommendation",
  },
  {
    id: "ssh5",
    name: "Progress Tracker",
  },
];

export const mentorHeaderData = [
  { id: 1, name: "home", path: "/" },
  {
    id: 2,
    name: "Test",
    path: "/test",
    subHeaderData: [
      {
        id: "test1",
        name: "CAT",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test2",
        name: "XAT",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test3",
        name: "SNAP",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test5",
        name: "IIFT",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test7",
        name: "GKZone",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test8",
        name: "CET",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test9",
        name: "CMAT",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test10",
        name: "MAT",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test11",
        name: "GRE",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test12",
        name: "TOEFL",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test13",
        name: "SAT",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test14",
        name: "GRE2",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test15",
        name: "TOEFL2",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test16",
        name: "SAT2",
        dropDownOptions: testDropDownOptions,
      },
    ],
  },

  {
    id: 3,
    name: "Performance",
    path: "/performance",
    subHeaderData: [
      {
        id: "test1",
        name: "CAT",
        dropDownOptions: perfornamceDropDownOptions,
      },
      {
        id: "test2",
        name: "XAT",
        dropDownOptions: perfornamceDropDownOptions,
      },
      {
        id: "test3",
        name: "SNAP",
        dropDownOptions: perfornamceDropDownOptions,
      },
      {
        id: "test5",
        name: "IIFT",
        dropDownOptions: perfornamceDropDownOptions,
      },
      {
        id: "test7",
        name: "GKZone",
        dropDownOptions: perfornamceDropDownOptions,
      },
      {
        id: "test8",
        name: "CET",
        dropDownOptions: perfornamceDropDownOptions,
      },
      {
        id: "test9",
        name: "CMAT",
        dropDownOptions: perfornamceDropDownOptions,
      },
      {
        id: "test10",
        name: "MAT",
        dropDownOptions: perfornamceDropDownOptions,
      },
      {
        id: "test11",
        name: "GRE",
        dropDownOptions: perfornamceDropDownOptions,
      },
      {
        id: "test12",
        name: "TOEFL",
        dropDownOptions: perfornamceDropDownOptions,
      },
      {
        id: "test13",
        name: "SAT",
        dropDownOptions: perfornamceDropDownOptions,
      },
      {
        id: "test14",
        name: "GRE2",
        dropDownOptions: perfornamceDropDownOptions,
      },
      {
        id: "test15",
        name: "TOEFL2",
        dropDownOptions: perfornamceDropDownOptions,
      },
      {
        id: "test16",
        name: "SAT2",
        dropDownOptions: perfornamceDropDownOptions,
      },
    ],
  },

  { id: 8, name: "Classes", path: "/classes" },
  {
    id: 4,
    name: "myPlan",
    path: "/myplan",
    subHeaderData: [
      {
        id: "mypage1",
        name: "DASHBOARD",
        path: "/myplan/dashboard",
      },
      {
        id: "mypage2",
        name: "SLOTS",
        path: "/myplan/slots",
      },
      {
        id: "mypage3",
        name: "STUDENTS",
        path: "/myplan/students",
      },
    ],
  },
];

export const studentHeaderData = [
  { id: 1, name: "home", path: "/" },
  {
    id: 2,
    name: "Prepare",
    path: "/prepare",
    subHeaderData: [
      {
        id: "prepare1",
        name: "CAT",
        dropDownOptions: prePareDropDownOptions,
      },
      {
        id: "prepare2",
        name: "XAT",
        dropDownOptions: prePareDropDownOptions,
      },
      {
        id: "prepare3",
        name: "SNAP",
        dropDownOptions: prePareDropDownOptions,
      },
      {
        id: "prepare5",
        name: "IIFT",
        dropDownOptions: prePareDropDownOptions,
      },
      {
        id: "prepare7",
        name: "GKZone",
        dropDownOptions: prePareDropDownOptions,
      },
      {
        id: "prepare8",
        name: "CET",
        dropDownOptions: prePareDropDownOptions,
      },
      {
        id: "prepare9",
        name: "CMAT",
        dropDownOptions: prePareDropDownOptions,
      },
      {
        id: "prepare10",
        name: "MAT",
        dropDownOptions: prePareDropDownOptions,
      },
      {
        id: "prepare11",
        name: "GRE",
        dropDownOptions: prePareDropDownOptions,
      },
      {
        id: "prepare12",
        name: "TOEFL",
        dropDownOptions: prePareDropDownOptions,
      },
      {
        id: "prepare13",
        name: "SAT",
        dropDownOptions: prePareDropDownOptions,
      },
      {
        id: "prepare14",
        name: "GRE2",
        dropDownOptions: prePareDropDownOptions,
      },
      {
        id: "prepare15",
        name: "TOEFL2",
        dropDownOptions: prePareDropDownOptions,
      },
      {
        id: "prepare16",
        name: "SAT2",
        dropDownOptions: prePareDropDownOptions,
      },
    ],
  },
  {
    id: 3,
    name: "Test",
    path: "/test",
    subHeaderData: [
      {
        id: "test1",
        name: "CAT",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test2",
        name: "XAT",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test3",
        name: "SNAP",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test5",
        name: "IIFT",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test7",
        name: "GKZone",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test8",
        name: "CET",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test9",
        name: "CMAT",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test10",
        name: "MAT",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test11",
        name: "GRE",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test12",
        name: "TOEFL",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test13",
        name: "SAT",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test14",
        name: "GRE2",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test15",
        name: "TOEFL2",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test16",
        name: "SAT2",
        dropDownOptions: testDropDownOptions,
      },
    ],
  },
  {
    id: 4,
    name: "myPage",
    path: "/mypage",
    subHeaderData: [
      {
        id: "mypage1",
        name: "MY TASKS",
        path: "/mypage/mytasks",
      },
      {
        id: "mypage2",
        name: "DOUBT SOLVING",
        path: "/mypage/doubt solving",
      },
      {
        id: "mypage3",
        name: "MY PLAN",
        path: "/mypage/myplan",
      },
      {
        id: "mypage4",
        name: "BOOKMARKS",
        path: "/mypage/bookmarks",
      },
    ],
  },
  { id: 5, name: "Performance", path: "/performance" },
  { id: 6, name: "Upgrades", path: "/upgrades" },
  {
    id: 7,
    name: "School",
    path: "/school",
    subHeaderData: [
      {
        id: "school1",
        name: "HOME",
        path: "/school/home",
      },
      {
        id: "school2",
        name: "SELECTION CRITERIA",
        path: "/school/selection criteria",
      },
      {
        id: "school3",
        name: "ELIGIBILITY CRITERIA",
        path: "/school/eligibility criteria",
      },
      {
        id: "school4",
        name: "CUTOFFS",
        path: "/school/cutoffs",
      },
      {
        id: "school5",
        name: "DEADLINES",
        path: "/school/deadlines",
      },
    ],
  },
  { id: 8, name: "Community", path: "/community" },
  { id: 9, name: "FAQ", path: "/faq" },
];
export const headerData = [
  { id: 1, name: "home", path: "/" },
  {
    id: 2,
    name: "Prepare",
    path: "/prepare",
    subHeaderData: [
      {
        id: "prepare1",
        name: "CAT",
        dropDownOptions: prePareDropDownOptions,
      },
      {
        id: "prepare2",
        name: "XAT",
        dropDownOptions: prePareDropDownOptions,
      },
      {
        id: "prepare3",
        name: "SNAP",
        dropDownOptions: prePareDropDownOptions,
      },
      {
        id: "prepare5",
        name: "IIFT",
        dropDownOptions: prePareDropDownOptions,
      },
      {
        id: "prepare7",
        name: "GKZone",
        dropDownOptions: prePareDropDownOptions,
      },
      {
        id: "prepare8",
        name: "CET",
        dropDownOptions: prePareDropDownOptions,
      },
      {
        id: "prepare9",
        name: "CMAT",
        dropDownOptions: prePareDropDownOptions,
      },
      {
        id: "prepare10",
        name: "MAT",
        dropDownOptions: prePareDropDownOptions,
      },
      {
        id: "prepare11",
        name: "GRE",
        dropDownOptions: prePareDropDownOptions,
      },
      {
        id: "prepare12",
        name: "TOEFL",
        dropDownOptions: prePareDropDownOptions,
      },
      {
        id: "prepare13",
        name: "SAT",
        dropDownOptions: prePareDropDownOptions,
      },
      {
        id: "prepare14",
        name: "GRE2",
        dropDownOptions: prePareDropDownOptions,
      },
      {
        id: "prepare15",
        name: "TOEFL2",
        dropDownOptions: prePareDropDownOptions,
      },
      {
        id: "prepare16",
        name: "SAT2",
        dropDownOptions: prePareDropDownOptions,
      },
    ],
  },
  {
    id: 3,
    name: "Test",
    path: "/test",
    subHeaderData: [
      {
        id: "test1",
        name: "CAT",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test2",
        name: "XAT",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test3",
        name: "SNAP",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test5",
        name: "IIFT",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test7",
        name: "GKZone",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test8",
        name: "CET",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test9",
        name: "CMAT",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test10",
        name: "MAT",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test11",
        name: "GRE",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test12",
        name: "TOEFL",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test13",
        name: "SAT",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test14",
        name: "GRE2",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test15",
        name: "TOEFL2",
        dropDownOptions: testDropDownOptions,
      },
      {
        id: "test16",
        name: "SAT2",
        dropDownOptions: testDropDownOptions,
      },
    ],
  },
  {
    id: 4,
    name: "myPage",
    path: "/mypage",
    subHeaderData: [
      {
        id: "mypage1",
        name: "MY TASKS",
        path: "/mypage/mytasks",
      },
      {
        id: "mypage2",
        name: "DOUBT SOLVING",
        path: "/mypage/doubt solving",
      },
      {
        id: "mypage3",
        name: "MY PLAN",
        path: "/mypage/myplan",
      },
      {
        id: "mypage4",
        name: "BOOKMARKS",
        path: "/mypage/bookmarks",
      },
    ],
  },
  { id: 5, name: "Performance", path: "/performance" },
  { id: 6, name: "Upgrades", path: "/upgrades" },
  {
    id: 7,
    name: "School",
    path: "/school",
    subHeaderData: [
      {
        id: "school1",
        name: "HOME",
        path: "/school/home",
      },
      {
        id: "school2",
        name: "SELECTION CRITERIA",
        path: "/school/selection criteria",
      },
      {
        id: "school3",
        name: "ELIGIBILITY CRITERIA",
        path: "/school/eligibility criteria",
      },
      {
        id: "school4",
        name: "CUTOFFS",
        path: "/school/cutoffs",
      },
      {
        id: "school5",
        name: "DEADLINES",
        path: "/school/deadlines",
      },
    ],
  },
  { id: 8, name: "Community", path: "/community" },
  { id: 9, name: "FAQ", path: "/faq" },
];

export const dropdownData = [
  // NOTE: Do not change IDs for below Data
  { id: 1, title: "IMS-ID", src: ScreenMirror, path: "/" },
  // { id: 2, title: "My Profile", src: ProfilePic, path: "/myprofile" },
  {
    id: 3,
    title: "Change Program", 
    src: SelectCourse,
  },
  { id: 4, title: "Logout", src: Logout },
  { id: 5, title: "CMS Login", src: "", path: process.env.REACT_APP_CMSROUTE },
];
