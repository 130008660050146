import React from "react";
import propTypes from "prop-types";

const Icon = ({ src ,className, extraClasses }) => {
  return (
    <div className={className ? className : `flex justify-center items-center w-12 h-12 rounded-full shadow-outer bg-white relative ${extraClasses} `}>
      <img src={src} alt="img" loading="lazy"/>
    </div>
  );
};
Icon.propTypes = {
  src: propTypes.string,
  className : propTypes.string,
  extraClasses : propTypes.string
};

export default Icon;
