import { configureStore } from "@reduxjs/toolkit";
// import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist/";
import sectionReducer from "../features/section/sectionSlice";
import headerReducer from "../features/header/headerSlice";
import calendarReducer from "../features/calendar/calendarSlice";
import compareReducer from "../features/compare/compareSlice";
import slotSlice from "../features/slots/SlotSlice";
import LoginSlice from "../features/login/LoginSlice";
import AssetDetailSlice from "../features/assetDetail/assetDetailSlice";
import PreloginSlice from "../features/prelogin/preloginSlice";
import SchoolSlice from "../features/schools/SchoolSlice";
import localforage from "localforage";
import AttendanceSlice from "../features/attendance/AttendanceSlice";
import AdvertisementSlice from "../features/advertisement/AdvertisementSlice";
// import {
//   FLUSH,
//   REHYDRATE,
//   PAUSE,
//   PERSIST,
//   PURGE,
//   REGISTER,
// } from "redux-persist";

const reducers = combineReducers({
  section: sectionReducer,
  header: headerReducer,
  calendar: calendarReducer,
  compare: compareReducer,
  slots: slotSlice,
  login: LoginSlice,
  prelogin: PreloginSlice,
  assetDetail: AssetDetailSlice,
  school: SchoolSlice,
  attendance: AttendanceSlice,
  advertisement: AdvertisementSlice
});

const persistConfig = {
  key: "root",
  storage: localforage,
  whitelist: ["header", "login", "assetDetail","prelogin","school","advertisement"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
