import React, { useRef } from "react";
import PropTypes from "prop-types";

import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Thumbs } from "swiper";

import { ReactComponent as LeftIcon } from "../../../../assets/icons/arrow-left-circle.svg";
import { ReactComponent as RightIcon } from "../../../../assets/icons/arrow-right-circle.svg";
import Cancel from "../../../../assets/icons/cancel.svg";
// import Thumbnail from "../../../../assets/images/thumbnail.svg";

import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "./gallery.css";

SwiperCore.use([Navigation, Pagination, Thumbs]);

// const slides = [
//   { key: "1", path: { Thumbnail } },
//   { key: "2", path: { Thumbnail } },
//   { key: "3", path: { Thumbnail } },
//   { key: "4", path: { Thumbnail } },
//   { key: "5", path: { Thumbnail } },
// ];

// const thumbs = [
//   { key: "1", path: { Thumbnail } },
//   { key: "2", path: { Thumbnail } },
//   { key: "3", path: { Thumbnail } },
//   { key: "4", path: { Thumbnail } },
//   { key: "5", path: { Thumbnail } },
// ];

const Gallery = ({ onImageClick, images, isFromSchool }) => {
  const [thumbsSwiper, setThumbsSwiper] = React.useState(null);
  const prevRef = useRef();
  const nextRef = useRef();

  return (
    <div className="bg-black px-8 pt-8 pb-8 h-full w-full ">
      <div onClick={() => onImageClick(false)} className="flex justify-end">
        <img
          className=" h-10 w-10 cursor-pointer"
          src={Cancel}
          alt="Cancel"
          loading="lazy"
        />
      </div>

      <Swiper
        // install Swiper modules
        className="relative"
        thumbs={{ swiper: thumbsSwiper }}
        modules={[Navigation, Pagination]}
        spaceBetween={50}
        navigation={false}
        pagination={{ clickable: true }}
        onInit={swiper => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
        loop={true}>
        <LeftIcon
          ref={prevRef}
          className="absolute left-4 top-1/2 z-10 transform -translate-y-1/4 cursor-pointer "
        />
        {images && images.length > 0 && images.map(slide => (
          <SwiperSlide key={slide} className="">
            <img
              className=" object-cover w-full h-[458px] flex justify-between items-center rounded-2xl mt-2 mb-4"
              src={isFromSchool ? slide.link : slide}
              alt="Thumbnail"
              loading="lazy"
            />
          </SwiperSlide>
        ))}
        <RightIcon
          ref={nextRef}
          className="absolute right-4 top-1/2 z-10 transform -translate-y-1/4 cursor-pointer"
        />
      </Swiper>
      <Swiper
        id="thumbs"
        spaceBetween={15}
        slidesPerView={5}
        watchSlidesProgress
        onSwiper={setThumbsSwiper}>
        {images && images.length > 0 && images.map(slide => (
          <SwiperSlide key={slide} className="h-36 w-56 ">
            <img
              className="object-cover w-full h-44 bg-red-200 flex justify-between items-center rounded-2xl cursor-pointer "
              src={isFromSchool ? slide.link : slide}
              alt="Thumbnail"
              loading="lazy"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
Gallery.propTypes = {
  onImageClick: PropTypes.func,
  images: PropTypes.array,
  isFromSchool :PropTypes.bool
};
export default Gallery;
