import React from "react";
import Gallery from "../global/gallery/Gallery";

import { useQuery } from "react-query";
import { endpoint } from "../.././../API/config";
import { getSchoolGallery } from "./apiFunctions";

function SchoolGallery() {
  const schoolId = "62ab25979f68ae5f188f0231";

  const { data } = useQuery(
    [endpoint.getSchoolGallery.key, schoolId],
    () => getSchoolGallery(schoolId),
    {
      select: x => x.data.data[0],
    },
  );



  return (
    <>
      <Gallery isFromSchool={true} images={data} />
    </>
  );
}

export default SchoolGallery;
