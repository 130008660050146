import { endpoint } from "../../../API/config";
import { apirequest_schoolsAPI } from "../../../API/api";
import {loginDetails } from "../../../redux/features/login/LoginSlice";
import {  useSelector } from "react-redux";

export const fetchSchoolArticles = schoolId => {
  const LoginDetails = useSelector(loginDetails);

  return apirequest_schoolsAPI({
    url: `${endpoint.getSchoolArticles.addr}`,
    method: "post",
 
    headers: {
      "access-token": LoginDetails.token
    },
    data: {
      schoolId,
    },
  });
};

// export const fetchCourseSchoolProgramData = (courseId, student_ImsPin) => {
//   const LoginDetails = useSelector(loginDetails);

//   return apirequest_schoolsAPI({
//     url: `${endpoint.getCourseSchoolProgramData.addr}`,
//     method: "post",
 
//     headers: {
//       "access-token": LoginDetails.token
//     },
//     data: {
//       courseId,
//       student_ImsPin,
//     },
//   });
// };

// export const fetchSchoolProgrammes = schoolId => {
//   const LoginDetails = useSelector(loginDetails);

//   return apirequest_schoolsAPI({
//     url: `${endpoint.getSchoolDetails.addr}`,
//     method: "post",
 
//     headers: {
//       "access-token": LoginDetails.token
//     },
//     data: {
//       schoolId,
//     },
//   });
// };


// removed student_ImsPin 
export const fetchCourseArticleEventData = (courseId, LoginDetails) => {
  // const LoginDetails = useSelector(loginDetails);

  return apirequest_schoolsAPI({
    url: `${endpoint.getCourseArticleEventData.addr}`,
    method: "post",
 
    headers: {
      "access-token": LoginDetails.token
    },
    data: {
      // schoolId,
      courseId
    },
  });
};

export const fetchCourseSchoolProgramData = (courseId, LoginDetails) => {

  return apirequest_schoolsAPI({
    url: `${endpoint.getCourseSchoolProgramData.addr}`,
    method: "post",
 
    headers: {
      "access-token": LoginDetails.token
    },
    data: {
      courseId,
    },
  });
};

export const fetchSelecttionCriteriaData = (courseId)=>{
  const LoginDetails = useSelector(loginDetails);
  
  return apirequest_schoolsAPI({
    url : endpoint.GetSelectionCriteria.addr,
    method : "post" ,

    header : {
      "access-token": LoginDetails.token
    },
    data : {
      courseId
    }
  });
};

export const fetchSchoolProgrammes = (schoolId, LoginDetails , courseId) => {
  
  return apirequest_schoolsAPI({
    url: `${endpoint.getSchoolDetails.addr}`,
    method: "post",
 
    headers: {
      "access-token": LoginDetails.token
    },
    data: {
      schoolId,
      courseId
    },
  });
};


// removed student_ImsPin 
// export const fetchCourseArticleEventData = (courseId, LoginDetails) => {
//   return apirequest_schoolsAPI({
//     url: `${endpoint.getCourseArticleEventData.addr}`,
//     method: "post",
 
//     headers: {
//       "access-token": LoginDetails.token
//     },
//     data: {
//       courseId,
//     },
//   });
// };

export const applyNow = (data ,token ) => { 


  return apirequest_schoolsAPI({
    url: `${endpoint.applyNow.addr}`,
    method: "post",
 
    headers: {
      "access-token": token
    },
    data:data,
  });
};

export const getSchoolGallery = (schoolId,LoginDetails) => {

  return apirequest_schoolsAPI({
    url: `${endpoint.getSchoolGallery.addr}`,
    method: "post",
 
    headers: {
      "access-token": LoginDetails.token
    },
    data: {
      schoolId,
    }
  });
};
