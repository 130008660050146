import React from "react";
import PropTypes from "prop-types";
import altImage from "../../../../assets/icons/altImage.svg";

function CompareItem({ data }) {
  return (
    <div className="p-4 relative shadow-md rounded-2xl font-ibm w-[256px] text-center text-xl mr-3 mb-3 z-10">
      <img className=" min-w-[224px] h-[144px] bg-center bg-no-repeat bg-contain rounded-2xl mb-[17px] " src={data.schoolImages.length ? data.schoolImages[0].link : altImage} alt="schoolImg"/>
      <p className=" mb-11 h-20 line-clamp-3">
        <span className="font-medium ">{data.schoolName}</span> -{" "}
        {data.programName}
      </p>
      <p className="mb-12">{data.Classification}</p>
      <p className="mb-12 truncate">#{data.ranking}</p>
      <p className="mb-12">{data.batchSize}</p>
      <p className="mb-12">{data.Fee}</p>
      <p className="mb-12">{data.avgSalary}</p>
      <p className="mb-14">{data.Duration}</p>
      <p>{data?.examAccepted}</p>
    </div>
  );
}

CompareItem.propTypes = {
  data: PropTypes.object,
};

export default CompareItem;
