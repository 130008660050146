import React, { useRef, useEffect, useState } from "react";
import { ReactComponent as ArrowLeft } from "../../../assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../../assets/icons/arrow-right.svg";
import { LongEvents, SingleEvents } from "./events";
import { EmptyHour, HoursArray } from "./hours";
import { getAndChangeEventsFormat } from "./utils";
import moment from "moment";
import propTypes from "prop-types";
import "./calendar.css";

function Day({
  events,
  date,
  setSelectedDate,
  setMonth,
  setYear,
}) {
  let { _longEvents, _singleEvents } = getAndChangeEventsFormat(date, events);
  
  const singleEvents = Object.entries(_singleEvents).map(([,val]) => val);

  const [emptyHourHeight, setEmptyHourHeight] = useState("82px");

  const [hourPointerTop, setHourPointerTop] = useState();

  const changeDate = num => {
    let newDate = moment(date).add(num, "d");
    if (date.month() !== newDate.month()) {
      setMonth(newDate.format("MMMM"));
      setYear(newDate.format("YYYY"));
    }
    setSelectedDate(newDate);
  };

  const addDate = () => {
    changeDate(1);
  };

  const subtractDate = () => {
    changeDate(-1);
  };

  let emptyEvents = useRef();

  let eventsContainer = useRef();

  useEffect(() => {
    let height = emptyEvents.current?.clientHeight || 82;
    setEmptyHourHeight(height + "px");
    let currentHour = moment().hour();
    let currentMinute = moment().minute();
    let hourPointerTop = currentHour * 60 + currentMinute;
    setHourPointerTop(hourPointerTop * 1.36666 + height + "px");
  }, []);

  return (
    <div>
      <div className="mb-9 flex items-center justify-between">
        <div className="flex">
          <ArrowLeft
            onClick={subtractDate}
            className="mr-[24px] cursor-pointer"
          />
          <p className="mr-[24px] text-[18px] font-medium w-20 text-center ">
            {date.format("ddd, DD")}
          </p>
          <ArrowRight onClick={addDate} className="cursor-pointer" />
        </div>
      </div>

      <div className="flex relative" ref={eventsContainer}>
        {/* events without time */}
        <div>
          <EmptyHour height={emptyHourHeight} />
          <HoursArray />
        </div>

        {/* events with time */}
        <div className="flex-1 shadow-outer ">
          <LongEvents
            events={_longEvents}
            date={date}
            day_view={true}
            refs={emptyEvents}
          />
          <SingleEvents events={singleEvents} date={date} day_view={true} />
        </div>
        <div
          className="absolute flex w-full items-center left-0"
          style={{ top: hourPointerTop }}>
          <div className="h-3 w-3 mr-1 rounded-full bg-primary-200 "></div>
          <div className="h-px w-full bg-primary-200"></div>
        </div>
      </div>
    </div>
  );
}

Day.propTypes = {
  events: propTypes.array,
  date: propTypes.object,
  setSelectedDate: propTypes.func,
  setMonth: propTypes.func,
  setYear: propTypes.func,
  setSelectedMonth: propTypes.func,
};

export default Day;
