import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import SearchIcon from "../../../../assets/icons/search-new.svg";
import { ReactComponent as ClearIcon } from "../../../../assets/icons/cancel.svg";
import PropTypes from "prop-types";

function Search({ isShadow = true, data, setFunction,classData,setClassFunction,isSearchDisable=false}) {
  const placeholder = "Search by learning assests";
  const [wordEntered, setWordEntered] = useState("");
  const [showInput, setShowInput] = useState(false); 

  const handleFilter = event => {
    const searchWord = event.target.value;
    setWordEntered(event.target.value);
    if (searchWord === "") {
      setWordEntered("");
      if(data) setFunction(data);
      if(classData) setClassFunction(classData);
    }
    else{
      //For Channels
      if(data){
        const dataAfterFilter = data?.map(item=>{
          return {
            ...item , 
            children : item.children.filter (
              val=>(val.name || val.assetName).toLowerCase()
                .includes(searchWord.toLowerCase())
            )
          } ;
        }) ;

        setFunction(()=>dataAfterFilter.filter(val=>val.children.length));
      }


      //For Liveclasses
      if(classData){
        const newObj=Object.keys(classData).map(item=>{
          if(classData[item].length==1){
            return {[item]:classData[item]};
  
          }
          else {
            let val = classData[item];
            
            return {
              [item]:val.filter(i=>i.classTitle.toLowerCase().includes(searchWord.toLowerCase()))
            };
          }
        });
  
        setClassFunction(()=>Object.assign({},...newObj));
      }
      
    }
   
  };


  const outSideClickHandler = () => {
    setShowInput(false);
    // setShowDropdown(false);
  };

  const clearTextHandler = () => {
    setWordEntered("");
    setShowInput(!showInput);
    // setShowDropdown(false);
    setFunction(data);
  };

  const handleSearchIconClick = () => {
    setShowInput(!showInput);
    clearTextHandler();
  };
  return (
    <OutsideClickHandler onOutsideClick={outSideClickHandler}>
      <div className="mr-14" >
        <div
          className={`${
            showInput ? "min-w-[26rem] " : "max-w-[56px] "
          } rounded-2xl ${
            isShadow ? "shadow-outer" : ""
          } flex p-[1rem] relative bg-white `}>
          {showInput && (
            <div
              className={`${
                showInput ? "w-full" : ""
              } flex items-center pr-5 `}>
              <input
                type="text"
                name="search"
                placeholder={placeholder}
                className="w-full text-secondary-600 font-normal font-ibm focus:outline-none"
                onChange={e => handleFilter(e)}
                value={wordEntered}
                autoComplete="off"
                autoFocus = {showInput}
              />
              <ClearIcon
                className="cursor-pointer"
                onClick={clearTextHandler}
              />
            </div>
          )}
          {!showInput &&<div className="h-6 w-6 flex items-center justify-center ">
            <button disabled={isSearchDisable?true:false} onClick={handleSearchIconClick} >
              <img
                src={SearchIcon}
                className="cursor-pointer h-5 w-5 shrink-0 "
                alt="search"
                loading="lazy"
              />
            </button>
          </div>}
        </div>
        {/* 
        {showDropdown && (
          <div className="bg-white z-50 shadow-md mt-5 rounded-2xl pl-[1rem] pt-[1.75rem] max-h-[15rem] overflow-y-scroll scrollbar-thumb-secondary-700 scrollbar-thin scrollbar-thumb-rounded absolute w-full max-w-[26rem]">
            {data?.map(val => (
              <p
                className="mb-5 hover:font-semibold cursor-pointer"
                key={val.learningPathId}
                onClick={() => handleClick(val.name)}>
                {val?.name}
              </p>
            ))}
          </div>
        )} */}
      </div>
    </OutsideClickHandler>
  );
}
Search.propTypes = {
  isShadow: PropTypes.bool,
  data: PropTypes.array,
  classData: PropTypes.object,
  setFunction: PropTypes.func,
  setClassFunction: PropTypes.func,
  isSearchDisable: PropTypes.bool,

};
export default Search;
