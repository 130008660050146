import React from "react";
import propTypes from "prop-types";

function SelectionCriteria({ data }) {

  return (
    <div className="mb-16">
      <h1 className="mb-6 font-medium text-secondary-600">Selection Criteria</h1>
      {/*Table */}
      {data?.firstCall["firstCallCsv"].length>0 ? <div className="shadow-outer rounded-2xl ">
        {/* Table Header */}
        <div className=" flex items-center text-center px-8 py-6 shadow-outer rounded-t-2xl">
          {Object.keys(data?.firstCall["firstCallCsv"][0]).map(item=>(
            <p key={item} className="flex-1 font-medium text-secondary-600 text-lg">{item}</p>
          ))} 
        </div>

        {/* Table Values */}
        {data?.firstCall["firstCallCsv"].map((item , index)=>(
          <div key={index} className="flex justify-items-start px-4 py-2">
            {Object.keys(data?.firstCall["firstCallCsv"][0]).map(val=>(
              <p className="px-4 py-2 text-center flex-1 font-medium text-secondary-600 text-lg" key={val}>{item[val]}</p>
            ))}
          </div>      
        ))}
      </div> : <p className="text-red-500 font-semibold text-base flex justify-center">No Record found</p>}
    </div>
  );
}
SelectionCriteria.propTypes = {
  data: propTypes.object,
};

export default SelectionCriteria;
