import React from "react";
import propTypes from "prop-types";
import UpcomingTag from "../../../../assets/icons/upcoming-tag.svg";
import Play from "../../../../assets/icons/play.svg";
import EventBookmark from "./EventBookmark";
import moment from "moment";
import altImage from "../../../../assets/icons/altImage.svg";

const dateFormatter = date => {
  const updateDate = moment(date).format("DD MMM YYYY");
  return updateDate;
};

{/* redirect to eventLink */}
const EventsCard = ({ tag, type, name, isPlay, isUpcoming, eventId, isBookmark, eventRefetch, thumbnailLink, eventLink}) => {

  return (
    <div className="relative flex rounded-2xl min-w-[565px] max-w-[565px] mr-4 shadow-outer mb-6">
      <a href={eventLink} target="_blank" rel="noreferrer noopener" >
        <img src= {thumbnailLink=== "" ? altImage : thumbnailLink} className=" w-56 rounded-2xl object-cover h-[142px] " alt="event image" loading="lazy" />
        {isPlay && (
          <img
            className="w-8 mt-8 rounded-xl z-60 absolute top-6 left-20"
            src={Play}
            loading="lazy"
          />
        )}
      </a>
      <div className="flex flex-col justify-center w-full px-6 ">
        <div className="flex items-center justify-between mb-4 w-full ">
          <div className="flex items-center space-x-2 text-[12px] ">
            {tag && (
              <p className="px-[12px] py-[6px] bg-secondary-200 rounded-full  font-ibm ">
                {dateFormatter(tag)}
              </p>
            )}
            {type && (
              <p className="px-[12px] py-[6px] bg-secondary-200 rounded-full font-ibm">
                {type}
              </p>
            )}
          </div>
          {!isUpcoming && (
            <EventBookmark
              isBookMark={isBookmark}
              eventId={eventId}
              eventRefetch={eventRefetch}
            />
          )}
          {isUpcoming && (
            <img
              className="absolute top-0 right-0"
              src={UpcomingTag}
              alt="upcoming"
              loading="lazy"
            />
          )}
        </div>
        {/* redirect to eventLink */}
        <a href={eventLink} target="_blank" rel="noreferrer noopener">     
          <p className=" text-sm font-medium font-ibm">{name}</p>
        </a>
      </div>
    </div>
  );
};

EventsCard.propTypes = {
  tag: propTypes.string,
  type: propTypes.string,
  thumbnailLink: propTypes.string,
  name: propTypes.string,
  url: propTypes.string,
  isPlay: propTypes.bool,
  isUpcoming: propTypes.bool,
  eventId: propTypes.string,
  isBookmark:propTypes.string,
  eventRefetch:propTypes.func,
  event: propTypes.object,
  eventLink: propTypes.string,
};

export default EventsCard;
