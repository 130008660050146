import React, { useState, useEffect } from "react";
import Filter from "../../cals/filter_item";
import MonthView from "../../cals/month_view";
import arrowLeft from "../../../../assets/icons/arrow-left.svg";
import arrowRight from "../../../../assets/icons/arrow-right.svg";
import moment from "moment";
import propTypes from "prop-types";

function AttendanceCalendar({
  events_data = {},
  allFilterState,
  setAllFilterState,
  missedClassFilterState,
  setMissedClassFilterState,
  attendedClassFilterState,
  setAttendedClassFilterState,
}) {
  const [selectedDate, setSelectedDate] = useState(moment().startOf("month"));
  const [month, setMonth] = useState(moment().format("MMMM"));
  const [year, setYear] = useState(moment().format("YYYY"));
  const [events, setEvents] = useState([]);

  useEffect(() => {
    let temp = [];
    let keys = Object.keys(events_data);
    keys.forEach(key => {
      temp.push(...events_data[key]);
    });
    console.log("temp ; ", temp);
    setEvents([...temp]);
  }, []);

  const handleAllFilter = () => {
    if (allFilterState) {
      setMissedClassFilterState(false);
      setAttendedClassFilterState(false);
      setEvents([]);
    } else {
      setMissedClassFilterState(true);
      setAttendedClassFilterState(true);
      let temp = [];
      let keys = Object.keys(events_data);
      keys.forEach(key => {
        temp = [...temp, ...events_data[key]];
      });
      setEvents([...temp]);
    }
    setAllFilterState(!allFilterState);
  };

  const handleMissedClassFilter = () => {
    if (missedClassFilterState) {
      setAllFilterState(false);
      if(attendedClassFilterState)
        setEvents([...events_data.attendedClasses]);
      else
        setEvents([]);
    } else {
      if (attendedClassFilterState) {
        setAllFilterState(true);
      }
      setEvents([...events, ...events_data.missedClasses]);
    }
    setMissedClassFilterState(!missedClassFilterState);
  };

  const handleAttendedClassFilter = () => {
    if (attendedClassFilterState) {
      setAllFilterState(false);
      if(missedClassFilterState)
        setEvents([...events_data.missedClasses]);
      else
        setEvents([]);
    } else {
      if (missedClassFilterState) {
        setAllFilterState(true);
      }
      setEvents([...events, ...events_data.attendedClasses]);
    }
    setAttendedClassFilterState(!attendedClassFilterState);
  };

  const changeMonth = num => {
    let updatedMonth = moment(selectedDate.add(num, "months"));
    setSelectedDate(updatedMonth);
    setMonth(updatedMonth.format("MMMM"));
    setYear(updatedMonth.format("YYYY"));
  };

  const addMonth = () => {
    changeMonth(1);
  };

  const subtractMonth = () => {
    changeMonth(-1);
  };

  return (
    <div className="flex mb-16 font-ibm">
      <div className="w-[309px] mr-8 ">
        <p className="text-2xl font-medium  py-[11px]  mb-9  ">Classes</p>
        <div className="shadow-outer px-4 py-6 rounded-2xl ">
          <p className=" ml-2 mb-6 text-[18px] font-medium  ">Filters</p>
          <Filter
            filterName={"All"}
            func={() => handleAllFilter()}
            condition={allFilterState}
          />
          <Filter
            filterName={"Missed Classes"}
            func={() => handleMissedClassFilter()}
            condition={missedClassFilterState}
          />
          <Filter
            filterName={"Attended Classes"}
            func={() => handleAttendedClassFilter()}
            condition={attendedClassFilterState}
          />
        </div>
      </div>
      <div className="w-[988px] text-[14px]">
        <div className="mb-9 flex items-center justify-between">
          <div className="flex justify-between w-full items-center">
            <div className="flex items-center justify-between">
              <div
                onClick={subtractMonth}
                className="cursor-pointer w-12 h-12 bg-white rounded-full shadow-outer flex items-center justify-center">
                <img src={arrowLeft} alt="left-arrow" loading="lazy" />
              </div>
              <p className="text-2xl font-medium  w-auto whitespace-nowrap text-center ml-2 mr-2 ">
                {month}&nbsp;{year}
              </p>
              <div
                onClick={addMonth}
                className="cursor-pointer w-12 h-12 bg-white rounded-full shadow-outer flex items-center justify-center ">
                <img src={arrowRight} alt="arrow-right" loading="lazy" />
              </div>
            </div>
            <p className="font-medium text-base italic">{events?.length} {events?.length < 2 ? " Class" : " Classes"}</p>
          </div>
        </div>
        <div className="shadow-outer py-[2.4294%] px-[3.4009%] rounded-2xl">
          <MonthView
            selectedDate={selectedDate}
            changeDay={setSelectedDate}
            events={events}
            setMonth={setMonth}
            setYear={setYear}
            attendance={true}
          />
        </div>
      </div>
    </div>
  );
}

AttendanceCalendar.propTypes = {
  events_data: propTypes.object,
  allFilterState: propTypes.bool,
  setAllFilterState: propTypes.func,
  missedClassFilterState: propTypes.bool,
  setMissedClassFilterState: propTypes.func,
  attendedClassFilterState: propTypes.bool,
  setAttendedClassFilterState: propTypes.func,
};

export default AttendanceCalendar;
