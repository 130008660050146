import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import pdfIcon from "../../../../../assets/icons/pdf-grey.svg";
import propTypes from "prop-types";
import { Link } from "react-router-dom";
import CardBookMark from "../masterClass/CardBookMark";
import ImsLiveModalTrigger from "../../../../web/modal/imsLive/ImsLiveModalTrigger";
import Close from "../../../../../assets/icons/cancel.svg";
import PdfViewer from "./PdfViewer";
import moment from "moment";
import { ReactComponent as VideoIcon } from "../../../../../assets/icons/video.svg";
import { ReactComponent as HtmlIcon } from "../../../../../assets/icons/pdf.svg";
import { ReactComponent as PlaylistIcon } from "../../../../../assets/icons/video-playlist.svg";
import { loginDetails } from "../../../../../redux/features/login/LoginSlice";
import {  useSelector } from "react-redux";
import watchLiveIcon from "../../../../../assets/icons/watch-live.svg";
import playVideoIcon from "../../../../../assets/icons/playIcon.svg";
import { endpoint } from "../../../../../API/config";
import { apirequest_liveAPI } from "../../../../../API/api";
import { useQuery } from "react-query";

function MasterClassCard({
  classData,
  headerTag,
  status,
  title,
  date,
  tag,
  time,
  learningPathId,
  assetId,
  bookmarked,
  refetchData,
  moduleId,
  pdfLink,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const LoginDetails = useSelector(loginDetails);

  const bgColor = tag => {
    switch (tag) {
    case "live":
      return "from-[#FFCF00] to-[#FFA500]";
    case "upcoming":
      return "from-[#FFCF00] to-[#FFA500]";
    case "video":
      return "from-[#BC94FF] to-[#9F66FF]";
    case "play":
      return "from-[#BC94FF] to-[#9F66FF]";
    case "html":
      return "from-[#00ABFB] to-[#2FD0FA]";
    case "pdf":
      return "from-[#00ABFB] to-[#2FD0FA]";
    }
  };

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  
  function returnIcon() {
    if (!assetId) return <PlaylistIcon />;
    if (status.toLowerCase() === "video") return <VideoIcon />;
    if (status.toLowerCase() === "html" || status.toLowerCase() === "pdf")
      return <HtmlIcon />;
  }

  const timeformat = time => {
    const [hourString, minute] = time.split(":");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + minute +" " +(hour < 12 ? "AM" : "PM");
  };

  const getpdfUrl = () => {
    return apirequest_liveAPI({
      url: `${endpoint.getPdfUrl.addr}/${classData.mappingId}`,
      method: "get",
      headers: {
        "access-token": LoginDetails.token,
      },
    });
  };

  const {data:handoutLink} = useQuery(
    [endpoint.getPdfUrl.key, classData.mappingId],
    () => getpdfUrl(),
    {
      enabled: !!pdfLink,
      retry: false,
      select:res=>res.data.expiryURL,
     
    },
  );

  return (
    <div className="rounded-2xl max-w-[90%] shadow-outer mb-6 overflow-hidden">
      {/* Header */}
      <span
        className={`h-14 pt-3 pb-4 px-6 flex justify-between items-center bg-gradient-to-r  ${bgColor(
          status.toLowerCase(),
        )} `}>
        {headerTag && (
          <div className="px-4 py-[6px] bg-secondary-600 text-white rounded-md font-ibm">
            {headerTag}
          </div>
        )}
        {tag && (
          <div className="px-4 py-[6px] bg-white rounded-md">
            <p>{tag}</p>
          </div>
        )}
      </span>

      {/* Body */}
      <div className="pt-6 pb-[26px] pl-6 pr-7 h-[210px]">
        <div className="flex justify-between">
          {assetId ? (
            <p className="mb-4 font-ibm text-secondary-600 font-medium text-lg h-14 cursor-pointer line-clamp-2" >
              {title}
            </p>
          ) : (
            <p className="mb-4 font-ibm text-secondary-600 font-medium text-lg h-14 cursor-pointer line-clamp-2 "  >
              {title}
            </p>
          )}
          {bookmarked && (
            <CardBookMark
              className="w-10 h-10 -mt-2"
              moduleId={moduleId}
              assetId={assetId}
              learningPathId={learningPathId}
              bookmarked={bookmarked}
              refetchData={refetchData}
            />
          )}
        </div>

        {date && time && (
          <p className="mb-6 font-ibm">
            {moment(date, "DD-MM-YYYY").format("DD MMM YYYY")} |{" "}
            {timeformat(time)}{" "}
          </p>
        )}
        {tag!=="Upcoming" && <div className="flex items-center justify-between">
          {/* If ismentor login redirect to card detail page with meeting info otherwise showing liveclass modal */}
          {LoginDetails.isMentor ?
            <Link to={`/Classes/LIVE/${classData.classTitle}/?status=${status}&mode=${classData.mode}&mappingId=${classData.mappingId}&url=${classData.hostJoinURL}&date=${moment(date, "DD-MM-YYYY").format("DD MMM YYYY")}&time=${timeformat(time)}`}>
              {/* Redirecting to Launch class page */}
              <div className="flex items-center space-x-2 cursor-pointer">
                <img  
                  src={status === "Live" ? watchLiveIcon : playVideoIcon}
                  alt="watch live"
                  loading="lazy"
                />
                <p className={`${status === "Live"  ?"text-primary-500":"text-primary-200"} font-ibm font-medium text-base`} >
                  {status === "Live"  ? "Live Class" :"View Details"}
                </p>
              </div>
            </Link>:
            <div>
              {(classData?.mode?.toLowerCase() !== "offline" && (status?.toLowerCase() == "play" || status?.toLowerCase() == "live"))  ? (
                <ImsLiveModalTrigger status={status} classData={classData} />
              ) :
                classData?.mode?.toLowerCase() !== "offline" ?<div className="flex items-center space-x-2 cursor-pointer">
                  {returnIcon()}
                  <p className="text-primary-200 font-ibm font-medium text-base">
                    {status.toLowerCase() == "video"
                      ? "Play video"
                      : status.toLowerCase() === "html" ||
                      status.toLowerCase() === "pdf"
                        ? "Read More"
                        : "Play Episode"}
                  </p>
                </div>:""
              }
            </div>}
          {pdfLink &&
            <div>

              <img
                className=" w-10 h-10 cursor-pointer right-24 bottom-16"
                src={pdfIcon}
                onClick={openModal}
                loading="lazy"
              />
            

              <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                  </Transition.Child>

                  <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95">
                        <Dialog.Panel className="w-3/4 h-[55rem] overflow-y-scroll scrollbar-thin scrollbar-thumb-secondary-700 scrollbar-thumb-rounded  transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                          <img
                            src={Close}
                            alt="close"
                            onClick={closeModal}
                            className="float-right cursor-pointer absolute right-1 top-0"
                            loading="lazy"
                          />
                          <PdfViewer pdfLink={handoutLink} />
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition>
            </div>}
        </div>}
      </div>
    </div>
  );
}

MasterClassCard.propTypes = {
  date: propTypes.string,
  headerTag: propTypes.string,
  title: propTypes.string,
  status: propTypes.string,
  tag: propTypes.string,
  video: propTypes.string,
  time: propTypes.string,
  isPlaylist: propTypes.string,
  variantId: propTypes.string,
  courseId: propTypes.string,
  learningPathId: propTypes.string,
  assetId: propTypes.string,
  channelId: propTypes.string,
  learningPathParentId: propTypes.string,
  bookmarked: propTypes.string,
  refetchData: propTypes.func,
  classData: propTypes.object,
  assetName: propTypes.string,
  moduleId: propTypes.string,
  pdfLink:propTypes.string,
  width:propTypes.string,
};

export default MasterClassCard;
