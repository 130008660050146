import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  detailPageAsset: {},
  playList: [],
  pathData: [],
  continueLearningTitle: "",
  assetProgressData: "",
  parentIdOfPlaylist: null,
  parentIdOfContinueLearning: null,
  playListName: "",
  isFromChannel: false,
  parentIdForNextLearning: null,
  learningPathIdForNextLearning: null,
  nextLearningUnit: null,
  accordianId: null,
  preparePageUrl: null,
  accordianType: null,
};

const assetDetailSlice = createSlice({
  name: "assetDetailSlice",
  initialState,
  reducers: {
    setDetailPageAsset: (state, action) => {
      state.detailPageAsset = action.payload;
    },
    setPlaylist: (state, action) => {
      state.playList = action.payload;
    },
    setPathData: (state, action) => {
      state.pathData = action.payload;
    },
    setContinueLearningTitle: (state, action) => {
      state.continueLearningTitle = action.payload;
    },
    setAssetProgressData: (state, action) => {
      state.assetProgressData = action.payload;
    },
    clearPlaylistAndPathData: state => {
      state.playList = [];
      state.pathData = [];
    },
    setParentIdOfPlaylist: (state, action) => {
      state.parentIdOfPlaylist = action.payload;
    },
    setParentIdOfContinueLearning: (state, action) => {
      state.parentIdOfContinueLearning = action.payload;
    },
    setPlaylistName: (state, action) => {
      state.playListName = action.payload;
    },
    setIsFromChannel: (state, action) => {
      state.isFromChannel = action.payload;
    },
    setParentIdForNextLearning: (state, action) => {
      state.parentIdForNextLearning = action.payload;
    },
    setLearningPathIdForNextLearning: (state, action) => {
      state.learningPathIdForNextLearning = action.payload;
    },
    setNextLearningUnit: (state, action) => {
      state.nextLearningUnit = action.payload;
    },
    setAccordianId: (state, action) => {
      state.accordianId = action.payload;
    },
    setPreparePageUrl: (state, action) => {
      state.preparePageUrl = action.payload;
    },
    setAccordianType: (state, action) => {
      state.accordianType = action.payload;
    }
  },
});

export const {
  setDetailPageAsset,
  setPlaylist,
  setPathData,
  setContinueLearningTitle,
  setAssetProgressData,
  clearPlaylistAndPathData,
  setParentIdOfPlaylist,
  setParentIdOfContinueLearning,
  setPlaylistName,
  setIsFromChannel,
  setParentIdForNextLearning,
  setLearningPathIdForNextLearning,
  setNextLearningUnit,
  setAccordianId,
  setPreparePageUrl,
  setAccordianType,
} = assetDetailSlice.actions;

export default assetDetailSlice.reducer;
