import React, { useState } from "react";
// import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import arrowUp from "../../../../assets/icons/arrow-up.svg";
import arrowDown from "../../../../assets/icons/arrow-down.svg";
import MyTask from "../../../../assets/icons/mytask-add.svg";
import Tag from "../tags/Tag";
import SubmittedIcon from "../../../../assets/icons/submitted.svg";
import InProgress from "../../../../assets/icons/mark-for-review.svg";
import Tick from "../../../../assets/icons/tickCircle-active.svg";
import Answered from "../../../../assets/icons/answered.svg";
import Attach from "../../../../assets/icons/attach.svg";
import Cancelled from "../../../../assets/icons/cancelredbackground.svg";
import Calendar from "../../../../assets/icons/calendar-inactive.svg";
// import Thumbnail from "../../../../assets/images/thumbnail.png";
import CancelSlotTrigger from "../../myPage/cancelSlot/CancelSlotTrigger";
// import LiveNow from "../../global/liveNow/LiveNow";
import Image from "../../../../assets/icons/Image.svg";
import MyTaskActive from "../../../../assets/icons/mytask-active.svg";
import moment from "moment";
import AttachIcon from "../../../../assets/icons/attach.svg";
import liveDot from "../../../../assets/icons/liveDot.svg";
import pdfIcon from "../../../../assets/icons/pdf-grey.svg";


import { apirequest_myplan,apirequest } from "../../../../API/api";
import { endpoint } from "../../../../API/config";
import { useQuery } from "react-query";
import {loginDetails } from "../../../../redux/features/login/LoginSlice";
import {  useSelector } from "react-redux";
import ToggleButton from "../Buttons/ToggleButton";
import PDFViewer from "../pdf/PdfViewer";
const MentorFeedBack = ({
  bookingId,
  title,
  mentorName,
  reasonForBooking,
  date,
  time,
  place,
  mode,
  feedBackModifiedDate,
  bookingStatus,
  mentorAction,
  feedback,
  classStatus,
  hangoutLink,
  refetchBookingData,
  studentName,
  studentImsPin,
  course,
  setAttachmentArray,
  attachments,
  isMentorDasboard,
  onImageClick,
  status,
  isCancelled,
  isAwaitingFeedBack,
  attendance,
}) => {
  const LoginDetails = useSelector(loginDetails);
  const [isViewDetail, setIsViewDetail] = useState(false);
  const [selectedFiles,setSelectedFiles] = useState( attachments? attachments:[]);
  const [edit, setEdit] = React.useState(false);
  const [message, setMessage] = React.useState(feedback?feedback:"");
  const inputRef = React.useRef(null);
  var formData = new FormData();
  // state to store the attendance toggle
  const [activeTab, setActiveTab] = useState("");
  //Request to fetch Booking data API
  const fetchMentorActiondApi = () => {
    return apirequest_myplan({
      url: `${endpoint.mentorAction.addr}`,
      method: "post",
 
      headers: {
        "access-token": LoginDetails.token
      },
      data: {
        bookingId:bookingId.toString(),
        requestType:"feedback",
        feedback:message,
        data:
          selectedFiles?.map(item=>{
            return {"attachment":item};
          })
      },
    
    });
  };

  // API for student data
  const { refetch: refetchDashboardAction } = useQuery(
    [endpoint.mentorAction.key,selectedFiles],
    fetchMentorActiondApi,
    {
      retry:false,
      enabled:  false,
      onSuccess: () => {refetchBookingData();}
    },
    
  );

  //Request to fetch Booking data API
  const s3FileUplaod = () => {
    return apirequest({
      url: `${endpoint.s3FileUplaod.addr}`,
      method: "post",
 
      headers: {
        headers: { "Content-Type": "multipart/form-data" },
        "access-token": LoginDetails.token
      },
      data: formData
    });
  };
  // API for student data
  const { refetch } = useQuery(
    [endpoint.s3FileUplaod.key,formData],
    s3FileUplaod,
    {
      enabled:  false,
      onSuccess: (res) => {
        res.data.data.filePath.map(item =>selectedFiles.push(item));
        // refetchDashboardAction();
      }
    },
  );
  //Request to fetch attendance API
  const markAttendance = () => {
    return apirequest_myplan({
      url: `${endpoint.mentorAction.addr}`,
      method: "post",
 
      headers: {
        "access-token": LoginDetails.token
      },
      data: {
        bookingId:bookingId.toString(),
        requestType:activeTab,
        feedback:"",
        data:"",
      },
    
    });
  };
  React.useEffect(() => {
    setMessage(feedback);
  }, [feedback]);
  
  // API for attendance data
  useQuery(
    [endpoint.mentorAction.key,activeTab],
    markAttendance,
    {
      retry:false,
      enabled: !!activeTab,
      onSuccess: () =>{
        refetchBookingData();
      }
    },
    
  );
  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const handleImageChange = (e) => {
    Array.from(e.target.files).map((file) =>
      formData.append("user_file", file)
    );
    // setSelectedFiles(formData);
    refetch();
  };
  //To find the asset is image or pdf
  function isThisImage(url) {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  }
  const removeImage =(asset)=>{
    setSelectedFiles(selectedFiles?.filter(itm=>itm!==asset));
  };
  const RenderPhotos = ({source}) => {
    return (
      <div className="flex mt-2 ml-8">
        {source.map((item,ind) => {
          return (
            <div className=" relative" key={ind}>
              {isThisImage(item)?<img src={item} className="w-16 h-16 mr-2" alt="" key={item} loading="lazy"/>:
                <PDFViewer pdfIcon={pdfIcon} pdfLink={item} />}
              {edit && <div className="absolute right-2 top-0 w-4 h-4 flex justify-center items-center ">
                <img src={Cancelled} alt="remove" className="float-right z-40" onClick={()=>removeImage(item)} loading="lazy"/>
              </div>}
            </div>
          );
        })}
      </div>);
  };
  RenderPhotos.propTypes = {
    source : PropTypes.any
  };

  // Date formatter to format date as per the UI
  const dateFormatter = date => {
    const updateDate = moment(date).format("DD MMM ‘YY");
    return updateDate;
  };

  const dateFormatter2 = date => {
    const updateDate = moment(date).format("DD MMM YYYY");
    return updateDate;
  };

  const timeFormatted = time => {
    const updateTime = moment(time).format("hh:mm A");
    return updateTime;
  };
  
  return (
    <div key={bookingId} className=" pt-14 pb-6 font-ibm">
      <div className="flex justify-between bg-secondary-100 ">
        <div className="flex w-2/5">
          <div className="flex flex-row items-center relative -mt-6">
           
            {/* All the Booking status icons are handle START */}
            {/* Status Icon for Mentors */}
            {isMentorDasboard && 
              <>
                <img className="" src={MyTask} alt="Quiz Icon" loading="lazy"/>
                {mentorAction == "FEEDBACK GIVEN" &&  <img className="h-5 w-5 absolute left-16 top-6 mb-14" src={Tick} alt={"Tick"} loading="lazy"/>}
                {isAwaitingFeedBack && classStatus === "Completed" && <img className="h-5 w-5 absolute left-16 top-6 mb-14" src={InProgress} alt={"InProgress"} loading="lazy"/>}
                {isCancelled &&  <img className="h-5 w-5 absolute left-16 top-6 mb-14" src={Cancelled} alt={"Cancelled"} loading="lazy"/>}
              </>
            }
            {/* Status Icon for Students */}
            {!isMentorDasboard &&
              <>
                <img className="" src={MyTask} alt="Quiz Icon" loading="lazy"/>
                {mentorAction == "FEEDBACK GIVEN" &&  <img className="h-5 w-5 absolute left-16 top-6 mb-14" src={Tick} alt={"Tick"} loading="lazy"/>}
                {status === "Completed" && mentorAction === "GIVE FEEDBACK" && <img className="h-5 w-5 absolute left-16 top-6 mb-14" src={InProgress} alt={"InProgress"} loading="lazy"/> }
                {isCancelled &&  <img className="h-5 w-5 absolute left-16 top-6 mb-14" src={Cancelled} alt={"Cancelled"} loading="lazy"/>}
              </>
            }
          </div>
          {/* Booking status icon END */}

          {/* Student description details START */}
          <div className="flex flex-col cursor-pointer ">
            <div className="flex font-normal text-xl font-ibm">
              <div className="flex flex-col">
                <p  className="font-normal text-xl"> {title}  </p>
                <p className="font-normal text-base mt-2"> {mentorName}</p>
              </div>
              <span className=" font-normal text-base font-ibm mt-3 flex">
                {
                  isMentorDasboard && classStatus == "Live" && !isCancelled && <div className=" flex ml-2 text-base text-primary-500 font-semibold font-ibm -mt-2 space-x-1"><img src={liveDot} className="w-4 mt-1 h-4" alt="live"/><p>Live Now</p></div>
                }
              </span>
            </div>
            <div className="flex flex-row mt-3 w-full ">
              <img src={Calendar} alt="calendar" className="mr-2" loading="lazy"/>
              <p>
                {dateFormatter2(date)}, {isMentorDasboard?timeFormatted(date):time}
              </p>
              <span className="mx-2 ">|</span>
              <p>{place}</p>
            </div>
          </div>
        </div>

        {/* profile details */}
        {
          isMentorDasboard  && 
          <div className="flex ">
            <div className="mt-4">
              <img className="" src={Image} alt="Quiz Icon" loading="lazy"/>
            </div>
          
            <div className="flex flex-col cursor-pointer w-auto space-x-4">
              <div className="w-full font-medium text-xl font-ibm mt-3 ml-4">
                {studentName} 
              </div>
              <div className="mt-1 w-full ">
                <p>{studentImsPin}</p>
                <p>{course}</p>
              </div>
            </div>
          </div>
        }
        
        {/* Student description details END */}
        <div className="flex flex-col mr-7 w-1/3 items-end justify-end">
          <div className="flex space-x-4">
            {/* Join now class button is activated if class status is live & is not cancelled*/}
            {
              isMentorDasboard  && classStatus == "Live" && !isCancelled && 
                <div className="flex flex-row space-x-4">
                  {/* commented this as this link is not needed now */}
                  {/* <Link to="#">
                    <p className="underline text-primary-200 font-ibm cursor-pointer font-medium  text-xl">Attendence</p>
                  </Link> */}
                  <a href={hangoutLink} target="_blank" rel="noreferrer noopener">
                    <p className="underline text-primary-200 font-ibm cursor-pointer font-medium  text-xl">Join now</p>
                  </a>
                </div>
            }
            {/* commented this as this link is not needed anymore */}
            {/* {
              isMentorDasboard  && classStatus == "Completed" && !isCancelled && (bookingStatus !== "CANCELLED BY MENTOR" && bookingStatus !=="CANCELLED" && bookingStatus !=="CANCELLED AGAINST POLICY") &&
              <div className="text-primary-200  font-ibm text-xl underline font-medium cursor-pointer">
                <a href="">Attendence</a>
              </div>
            } */}
            {
              !isMentorDasboard && status?.toLowerCase()==="live" && !isCancelled &&
              <div className="text-primary-200  font-ibm text-xl underline font-medium cursor-pointer">
                <a href={hangoutLink} target="_blank" rel="noreferrer noopener">Join now</a>
              </div>
            }
            <Tag tag={mode=="Virtual"? "Virtual mode":"In person"} isGrayBackground={true} />
          </div>

          {/* View Details Section START, the mentor comments are displayed here */}
          <div
            className="mt-8 flex flex-row items-center justify-end cursor-pointer"
            onClick={() => setIsViewDetail(!isViewDetail)}>
            <p className=" font-ibm text-base  font-medium ">View details</p>
            {isViewDetail ? (
              <img className="ml-1" src={arrowUp} alt="arrow up" loading="lazy"/>
            ) : (
              <img className="ml-1" src={arrowDown} alt="arrow down" loading="lazy"/>
            )}
          </div>
        </div>
      </div>
      {/* Student description START*/}
      {isViewDetail && (
        <>
          <div className="bg-secondary-100 items-center shadow-outer rounded-2xl w-auto h-auto ml-16 mr-8 my-6 pb-4">
            <div className="flex pt-6 items-center">
              <img
                className=" ml-8 w-5 h-5 "
                src={SubmittedIcon}
                alt="SubmittedIcon"
                loading="lazy"
              />
              <div className="mx-2">Description</div>
              <Tag tag={dateFormatter(date)} isGrayBackground={true} />
            </div>

            <div className="flex flex-col ">
              <p className="mx-8 mt-6 text-justify text-sm font-normal">{reasonForBooking}</p>
              {/*CAncel slot will be displayed if the slot is live and slot not completed*/}
              <div className="flex flex-row-reverse mt-3">
                {!isMentorDasboard && bookingStatus == "BOOKED" && status=="UpComing" && (
                  <div className=" mr-6">
                    <CancelSlotTrigger bookingId={bookingId} bookingDate={date}  refetchBookingData={refetchBookingData} />
                  </div>
                )}
                
                { isMentorDasboard && bookingStatus == "BOOKED" && classStatus == "UpComing"  && (
                  <div className=" mr-6">
                    <CancelSlotTrigger bookingId={bookingId} bookingDate={date} isMentorDashboard={isMentorDasboard} refetchBookingData={refetchBookingData} />
                  </div>
                )}
              </div>
            </div>
            {/* Cancelled status and button for Students*/}
            {!isMentorDasboard && isCancelled && (
              <p className="ml-8  mt-6 text-secondary-800 font-ibm font-medium text-base">
                {bookingStatus == "CANCELLED BY MENTOR"
                  ? "Cancelled Slot (mentor)"
                  : "Cancelled Slot (you)"}
              </p>
            )}
            {/* Cancelled status and button for Mentors*/}

            {isMentorDasboard && isCancelled && (
              <p className="ml-8  mt-6 text-secondary-800 font-ibm font-medium text-base">
                {(bookingStatus == "CANCELLED AGAINST POLICY" || bookingStatus == "CANCELLED")
                  ? "Cancelled Slot (student)"
                  : "Cancelled Slot (you)"}
              </p>
            )}


            {
              !isMentorDasboard ? (
                mentorAction != "FEEDBACK GIVEN" && bookingStatus == "ATTENDED" && (
                  <div className="  ml-8 pb-6 mt-10 w-4/6 h-auto border-2 border-[#F4F5F6] rounded-2xl ">
                    <p className="  mx-2 w-11/12 h-11/12 p-4 text-secondary-400">
                      Mentor’s feedback pending...
                    </p>
                  </div>
                )
              ) :  (
                isMentorDasboard && !isCancelled && classStatus === "Completed" &&  (bookingStatus !== "CANCELLED" && bookingStatus!=="CANCELLED BY MENTOR") && (
                  <div className=" flex flex-col">
                    <div>{
                      edit ? 
                        <div>
                          <div>
                            <textarea 
                              className=" w-4/6 h-56 scrollbar-thumb-secondary-700 cursor-auto scrollbar-thin scrollbar-thumb-rounded	  ml-8 p-5 mt-10  border-2 border-[#F4F5F6] rounded-2xl focus:outline-none" 
                              placeholder="Mentor’s feedback pending.."
                              ref={inputRef}
                              id="message"
                              name="message"
                              onChange={handleChange}
                              value={message}
                            />
                            <div>
                              <label  className="flex flex-col w-1/2">
                                <div className="flex flex-col ml-7">
                                  <div className="flex mt-2 ">
                                    {/* If asset is image onclick show image gallary, if it is pdf then show it in pdf viewer */}
                                    {selectedFiles && <RenderPhotos source={selectedFiles} />}
                                    <label htmlFor="file"><img className="w-20 h-20" src={MyTaskActive} loading="lazy" /></label>
                                    <p className="mt-5 -ml-3">Add images</p>
                                  </div>
                                </div>
                              </label>
                              {/* Image selection restricted to webp */}
                              <input type="file" accept="image/*,application/pdf" id="file" multiple  style={{ display: "none" }} className="z-10" onChange={handleImageChange} ></input>
                              <br />
                            </div>
                          </div>
                        </div>
                        :
                        (isAwaitingFeedBack) ?
                          <div className="  ml-8 pb-6 mt-10 w-4/6 h-auto border-2 border-[#F4F5F6] rounded-2xl ">
                            <p className="  mx-2 w-11/12 h-52 p-4 text-secondary-400">
                              Please provide feedback...
                            </p>
                            {!edit &&  <button className="shadow-forButton rounded-2xl text-white bg-primary-200 mb-5 -mt-10 px-10 py-3 mr-5 float-right" onClick={() => {if(attendance==="present"){setEdit(true);}}}>Edit</button>}
                          </div>:
                          <div className="flex-flex-col">
                            <div className="flex pt-6 items-center pl-5">
                              <img className="ml-3 w-5 h-5 " src={Answered} alt="Answered" loading="lazy"/>
                              <p className="font-ibm text-sm font-medium ml-2 mr-3">
                              Mentors feedback
                              </p>
                              {feedBackModifiedDate!=="None" &&<Tag
                                tag={dateFormatter(feedBackModifiedDate)}
                                isGrayBackground={true}
                              />}
                            </div>
                            <div>
                              {console.log("feedback",feedback)}
                              <textarea readOnly="readonly" className="mx-8 w-11/12	h-56 text-justify text-sm font-normal resize-none scrollbar-thumb-secondary-700 cursor-auto scrollbar-thin scrollbar-thumb-rounded">{message}</textarea>
                              {attachments?.length>0 && <div className="flex flex-col">
                                <div className="flex space-x-2 ml-8 mt-3">
                                  <img className="w-6 h-6" src={AttachIcon} loading="lazy"/> 
                                  <p>Attachments</p>
                                </div>
                                {attachments ?<RenderPhotos source={attachments} />:""}
                              </div>}
                            </div>
                          </div>
                    }</div>
                    <div className="flex space-x-1 flex-row-reverse mr-2">
                      {/* Added asset removal from mentor feedback */}
                      {!edit ? (!isAwaitingFeedBack && <button className="shadow-forButton rounded-2xl text-white bg-primary-200 mb-5 mt-3 px-10 py-3 mr-5 float-right" onClick={() => {if(attendance==="present"){setEdit(true);}}}>Edit</button>)
                        :<button
                          className="shadow-forButton rounded-2xl text-white ml-3 bg-primary-200 mb-5 px-10 py-3 -mt-12 float-right"
                          onClick={() => {
                            refetchDashboardAction();
                            setEdit(!edit);
                          }}
                        >
                          Save
                        </button>}
                    </div>
                    
                  </div>
                )
              )
            }

            {/* Student description END*/}
            {/* Mentor feedback Section START */}
            { mentorAction == "FEEDBACK GIVEN" && !isMentorDasboard &&(
              <div>
                <div className="flex pt-6 items-center pl-5">
                  <img className="ml-3 w-5 h-5 " src={Answered} alt="Answered" loading="lazy"/>
                  <p className="font-ibm text-sm font-medium ml-2 mr-3">
                    Mentors feedback
                  </p>
                  {/* Not displaying date if its none  */}
                  {feedBackModifiedDate!=="None" &&<Tag
                    tag={dateFormatter(feedBackModifiedDate)}
                    isGrayBackground={true}
                  />}
                </div>
                <div>
                  <p className="text-sm font-normal mt-5 text-justify ml-3 w-[66%] pl-5">
                    {feedback}
                  </p>
                  {/* Showing attachments heading if its there */}
                  {(attachments?.length>0 ||attachments=="None") && <div className="flex flex-col mt-6 pl-5">
                    <div className="flex mt-3">
                      <img
                        className="ml-3 w-5 h-5 "
                        src={Attach}
                        alt="Answered"
                        loading="lazy"
                      />
                      <p className="text-sm font-ibm font-normal ml-2">
                        Attachments
                      </p>
                    </div>

                    
                    <div className="flex flex-grow mt-4 ml-3 space-x-2 cursor-pointer">
                      
                      {attachments?.map((item,ind) => 
                        <>
                          {isThisImage(item)?<img
                            key={ind}
                            className="w-28 h-20 rounded-md "
                            src={item}
                            alt="item"
                            loading="lazy"
                            onClick={() => {setAttachmentArray(attachments);onImageClick(true);}}
                          />:<PDFViewer pdfIcon={pdfIcon} pdfLink={item}/>}
                        </>
                      )}
                      
                    </div>
                  </div>}
                </div>
              </div>
            )}
            {/* Mentors feedback END */}
          </div>
          {/*toggle added for attendance marking  */}
          {
            isMentorDasboard && !isCancelled && classStatus === "Completed" &&  
          <div className="bg-white shadow-outer px-10 py-5 ml-14 mr-10 rounded-2xl flex justify-between">
            <p className="mt-3 font-ibm">Attendance</p>
            <ToggleButton
              toggle1={"Present"}
              toggle2={"Absent"}
              activeTab={attendance}
              setActiveTab={setActiveTab}
              isAttendance={true}
            />
          </div>}
         
        </>
      )}
    
    </div>
  );
};

MentorFeedBack.propTypes = {
  bookingId: PropTypes.number,
  title: PropTypes.string,
  mentorName: PropTypes.string,
  date: PropTypes.string,
  time: PropTypes.string,
  place: PropTypes.string,
  mode: PropTypes.string,
  reasonForBooking: PropTypes.string,
  bookingStatus: PropTypes.string,
  feedBackModifiedDate: PropTypes.string,
  mentorAction: PropTypes.string,
  feedback: PropTypes.string,
  hangoutLink: PropTypes.string,
  studentName:PropTypes.string,
  studentImsPin:PropTypes.string,
  course:PropTypes.string,
  attachments: PropTypes.array,
  onImageClick: PropTypes.func,
  refetchBookingData: PropTypes.func,
  setAttachmentArray:PropTypes.func,
  isMentorDasboard:PropTypes.bool,
  isLiveNow:PropTypes.bool,
  classStatus:PropTypes.string,
  status:PropTypes.string,
  isCancelled:PropTypes.bool,
  isAwaitingFeedBack:PropTypes.string,
  attendance:PropTypes.string,
};
export default MentorFeedBack;
