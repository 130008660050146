import React,{useState,useEffect} from "react";
import propTypes from "prop-types";

import OutsideClickHandler from "react-outside-click-handler";
import { ReactComponent as Cancel } from "../../../assets/icons/cancel.svg";
import Counter from "./OtpCounter";
function SelectCourseModal({title,description,setIsShowModal,generateNewOtp,buttonText,setOtpValue,error,refetch }) {
  const [otp, setOtp] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
  });
  const handleChange = (value1, event) => {
    setOtp((prevState) => ({ ...prevState, [value1]: event.target.value }));
  };

  const inputfocus = (el) => {
    if (el.key === "Delete" || el.key === "Backspace") {
      const next = el.target.tabIndex - 2;
      if (next > -1) {
        el.target.form.elements[next].focus();
      }
    } else {
      const next = el.target.tabIndex;
      if (next < 6) {
        el.target.form.elements[next].focus();
      }
    }
  };
  const convertOtpArr = () => {
    let init = "";
    Object.keys(otp).map((key) => {
      init = init + otp[key];
    });
    return init;
  };
  useEffect(() => {
    setOtpValue(convertOtpArr());
  }, [otp.otp6]);
  
  return (
    <div className=" z-50 h-screen w-screen flex items-center justify-center bg-black/70 fixed left-0 top-0 font-ibm ">
      <OutsideClickHandler
        onOutsideClick={() => setIsShowModal(false)}
      >
        <div className=" z-50 flex flex-col justify-between bg-white rounded-xl h-[332px] w-[396px]  p-5">
          <div className=" flex justify-between items-center">
            <p className="font-ibm text-lg text-secondary-600 font-medium">
              {title}
            </p>
            <Cancel className="cursor-pointer" onClick={()=>setIsShowModal(false)}/>
          </div>
          <p className="font-ibm text-base font-normal text-black">
            {description}
          </p>
          <form>
            <div className="space-x-2 my-2 font-ibm">
              <input
                name="otp1"
                type="text"
                className="border border-gray-300 w-12 h-12 rounded-xl outline-none text-center text-2xl font-medium  text-secondary-600 "
                value={otp.otp1}
                onChange={(e) => handleChange("otp1", e)}
                tabIndex="1"
                maxLength={1}
                onKeyUp={(e) => inputfocus(e)}
                autoComplete={false}
              />
              <input
                name="otp2"
                type="text"
                className="border border-gray-300 w-12 h-12 rounded-xl outline-none text-center text-2xl font-medium text-secondary-600 "
                value={otp.otp2}
                onChange={(e) => handleChange("otp2", e)}
                tabIndex="2"
                maxLength={1}
                onKeyUp={(e) => inputfocus(e)}
                autoComplete={false}
              />
              <input
                name="otp3"
                type="text"
                className="border border-gray-300 w-12 h-12 rounded-xl outline-none text-center text-2xl font-medium text-secondary-600 "
                value={otp.otp3}
                onChange={(e) => handleChange("otp3", e)}
                tabIndex="3"
                maxLength={1}
                onKeyUp={(e) => inputfocus(e)}
                autoComplete={false}
              />
              <input
                name="otp4"
                type="text"
                className="border border-gray-300 w-12 h-12 rounded-xl outline-none text-center text-2xl font-medium text-secondary-600 "
                value={otp.otp4}
                onChange={(e) => handleChange("otp4", e)}
                tabIndex="4"
                maxLength={1}
                onKeyUp={(e) => inputfocus(e)}
                autoComplete={false}
              />
              <input
                name="otp5"
                type="text"
                className="border border-gray-300 w-12 h-12 rounded-xl outline-none text-center text-2xl font-medium text-secondary-600 "
                value={otp.otp5}
                onChange={(e) => handleChange("otp5", e)}
                tabIndex="5"
                maxLength={1}
                onKeyUp={(e) => inputfocus(e)}
                autoComplete={false}
              />
              <input
                name="otp6"
                type="text"
                className="border border-gray-300 w-12 h-12 rounded-xl outline-none text-center text-2xl font-medium text-secondary-600 "
                value={otp.otp6}
                onChange={(e) => handleChange("otp6", e)}
                tabIndex="6"
                maxLength={1}
                onKeyUp={(e) => inputfocus(e)}
                autoComplete={false}
              />
            </div>
          </form>
          {/* Resend OTP */}
          <Counter 
            refetchOTP={generateNewOtp} 
          />

              
          {/* verify otp button */}
          <div className="flex flex-col items-center justify-between mt-5">
            <button
              onClick={() => {
                refetch();
              }}
              disabled={Object.keys(otp).some((key) => otp[key] == "")?true:false}
              className={`px-32 py-4 justify-center text-secondary-100  text-base font-medium rounded-2xl focus:outline-none focus-visible:ring-offset-2 focus-visible:ring-blue-500
                ${Object.keys(otp).some((key) => otp[key] == "")?"bg-secondary-800":"bg-primary-200 "}`}
            >
              {buttonText}
            </button>
            {error &&<p className="text-red-500 text-base">Incorrect OTP</p>}
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
}

SelectCourseModal.propTypes = {
  title:propTypes.string,
  description:propTypes.string,
  buttonText:propTypes.string,
  setIsShowModal:propTypes.func,
  generateNewOtp:propTypes.func,
  disableOtpSubmitButton:propTypes.bool,
  setOtpValue:propTypes.func,
  error:propTypes.bool,
  refetch:propTypes.func,
  isFetched :propTypes.bool,
};

export default SelectCourseModal;
