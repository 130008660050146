import React from "react";
// import na2 from "./../masterClass/na2.pdf";
import propTypes from "prop-types";
import Download from "../../../../../assets/icons/download.svg";
import PreviousPage from "../../../../../assets/icons/scroll-left-active.svg";
import NextPage from "../../../../../assets/icons/scroll-right-active.svg";

const ControlPanel = (props) => {
  
  const  downloadFileAtURL= (file_url) => {
    // using Java Script method to get PDF file
    fetch(file_url)?.then(response => {
      response.blob()?.then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = file_url.split("/").pop();
        alink.click();
      });
    });
  };

  const {
    pageNumber, numPages, setPageNumber, scale,setScale,file
  } = props;

  const isMinZoom = scale < 0.6;
  const isMaxZoom = scale >= 2.0;

  const zoomOutClass = isMinZoom ? "disabled" : "clickable";
  const zoomInClass = isMaxZoom ? "disabled" : "clickable";

  const zoomOut = () => {
    if (!isMinZoom) setScale(scale - 0.1);
  };

  const zoomIn = () => {
    if (!isMaxZoom) setScale(scale + 0.1);
  };

  const isFirstPage = pageNumber === 1;
  const isLastPage = pageNumber === numPages;

  const firstPageClass = isFirstPage ? "disabled" : "clickable";
  const lastPageClass = isLastPage ? "disabled" : "clickable";

  
  const goToPreviousPage = () => {
    if (!isFirstPage) setPageNumber(pageNumber - 1);
  };
  const goToNextPage = () => {
    if (!isLastPage) setPageNumber(pageNumber + 1);
  };


  const onPageChange = (e) => {
    const { value } = e.target;
    setPageNumber(Number(value));
  };

  return (
    <div className=" grid grid-cols-3 shadow-outer rounded-2xl mx-10 p-8 mb-5 place-items-center relative">
      <div className="">
        <span>
          <img 
            src={PreviousPage} 
            alt="previous page" 
            className={`absolute -top-4 left-20 h-32 cursor-pointer ${firstPageClass}`} 
            onClick={goToPreviousPage}
            loading="lazy"/>
        </span>
        <span>
          <input
            name="pageNumber"
            type="number"
            min={1}
            max={numPages || 1}
            className="border border-none absolute top-8 left-10"
            value={pageNumber}
            onChange={onPageChange}
          />
          {/* of {numPages} */}
        </span>
        <span>
          <img 
            src={NextPage} 
            alt="previous page" 
            className={`absolute -top-4 h-32 cursor-pointer ${lastPageClass}`}
            onClick={goToNextPage}
            loading="lazy"
          />
        </span>
      </div>
      <div>
        <p onClick={zoomOut} className={`absolute top-5 left-[33rem] cursor-pointer text-3xl shadow-outer rounded-full px-[16px] py-[4px] ${zoomOutClass}`}>-</p>
        <span className=" text-xl -ml-10">{(scale * 100).toFixed()}%</span>
        <p onClick={zoomIn} className={`absolute top-5 left-[42rem] cursor-pointer text-3xl shadow-outer rounded-full px-3 py-[4px] ${zoomInClass}`}>+</p>
      </div>
      <div>
        <img src={Download} alt="download" onClick={() => {file?.split(".").pop()==="pdf"?downloadFileAtURL(file):"";}} className=" cursor-pointer ml-32" loading="lazy"/>
      </div>
    </div>
  );
};

ControlPanel.propTypes = {
  scale:propTypes.number,
  setScale:propTypes.func,  
  pageNumber:propTypes.number,
  numPages:propTypes.bool,
  setPageNumber:propTypes.func,
  file:propTypes.string,
};

export default ControlPanel;
