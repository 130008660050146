import React from "react";
import { returnIcon } from "./utils";
import propTypes from "prop-types";
import EventDetails from "./event_details";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentActiveSechuleViewPopup } from "../../../redux/features/calendar/calendarSlice";

function ScheduleItem({ ind, events, event, day }) {
  const currentActiveSechuleViewPopup = useSelector(
    state => state.calendar?.currentActiveSechuleViewPopup,
  );
  const dispatch = useDispatch();
  return (
    <div>
      <div
        onMouseEnter={() =>
          dispatch(
            setCurrentActiveSechuleViewPopup(
              (event?.id ?? event.bookingId ?? event.mappingId) + day,
            ),
          )
        }
        className={`${
          ind !== events.length - 1
            ? "border-b-[1px] border-solid border-primary-400"
            : ""
        } w-full h-16 inline-flex items-center cursor-pointer relative`}>
        <div className="inline-table">{returnIcon(event.moduleType)}</div>
        <p className="ml-6 inline-table ">{event.name}</p>
        {currentActiveSechuleViewPopup ===
          (event?.id ?? event.bookingId ?? event.mappingId) + day && (
          <EventDetails event={event} />
        )}
      </div>
    </div>
  );
}

ScheduleItem.propTypes = {
  ind: propTypes.number,
  events: propTypes.array,
  event: propTypes.object,
  day: propTypes.string,
};

export default ScheduleItem;
