import React, { useState } from "react";
import UnLike from "../../../../../assets/icons/unlike.svg";
import Like from "../../../../../assets/icons/like.svg";
import propTypes from "prop-types";
import { useQuery , useQueryClient } from "react-query";
import { endpoint } from "../../../../../API/config";
import {apirequest_schoolsAPI} from "../../../../../API/api";
import {loginDetails } from "../../../../../redux/features/login/LoginSlice";
import {  useSelector } from "react-redux";

const SchoolWishList = ({schoolId,isLiked,schoolListingRefetch,schoolProgramCourseMappingId}) => {
  const LoginDetails = useSelector(loginDetails);
  const queryClient = useQueryClient();
  const [liked , setLiked] = useState(isLiked ==="1" ? true : false);
  const like = (schoolId) => {
    return apirequest_schoolsAPI({
      url: `${endpoint.AddStudentSchoolLike.addr}`,
      method: "post",
 
      headers: {
        "access-token": LoginDetails.token
      },
      data: {
        schoolId:schoolId,
        schoolProgramCourseMappingId:schoolProgramCourseMappingId,
        isLiked: !liked  ? "1" : "0"
      }
    });
  };
  
  // const disLike = (schoolId) => {
  //   return apirequest_schoolsAPI({
  //     url: `${endpoint.AddStudentSchoolLike.addr}`,
  //     method: "post",
  //     headers: {
  //       "access-token": LoginDetails.token
  //     },
  //     data: {
  //       schoolId:schoolId,
  //       schoolProgramCourseMappingId:schoolProgramCourseMappingId,
  //       isLiked: "0"
  //     }
  //   });
  // };
  
  const { refetch : likeRefetch} = useQuery(
    [endpoint.AddStudentSchoolLike.key],
    () => like(schoolId,schoolProgramCourseMappingId),
    {
      enabled: false,
      onSuccess: () => {
        schoolListingRefetch();
        queryClient.invalidateQueries(endpoint.GetCourseSchoolProgramData.key);
        queryClient.invalidateQueries(endpoint.getshortlistedSchools.key);
      },
    }
  ); 
  // const { refetch : unLikeRefetch} = useQuery(
  //   [endpoint.AddStudentSchoolLike.key],
  //   () => disLike(schoolId),
  //   {
  //     enabled: false,
  //     retry:false,
  //     onSuccess: () => {
  //       schoolListingRefetch();
  //     },
  //   }
  // ); 
  return (
    <div className="cursor-pointer">
      <img src={ liked? Like : UnLike } loading="lazy" onClick={()=>{
        setLiked(!liked);
        likeRefetch() ;
      }}/>  
    </div>
  );
};
SchoolWishList.propTypes = {
  schoolId: propTypes.string,
  isLiked: propTypes.string,
  schoolListingRefetch: propTypes.func,
  schoolProgramCourseMappingId:propTypes.string,
};
export default SchoolWishList;