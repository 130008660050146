import React, { useState } from "react";
// import Image from "../../../../assets/images/Image.png";
import { Tab } from "@headlessui/react";
// import banner from "../../../../assets/images/adv.svg";
import Header from "../../header/Header";
import Footer from "../../global/footer/Footer";

import { useQuery } from "react-query";
import { apirequest_schoolsAPI } from "../../../../API/api";
import { endpoint } from "../../../../API/config";
import { useSelector } from "react-redux";
import { loginDetails } from "../../../../redux/features/login/LoginSlice";
import Loader from "../../global/loader/Loader";
import Advertisement from "../../home/cards/advertisement/Advertisement";

//Request to selection criteria classes API

export const SelectionCriteria = () => {
  const LoginDetails = useSelector(loginDetails);
  const courseId = useSelector(state=>state.login.courseId);

  const [selectedTab, setSelectedTab] = useState("firstCall");
  const [selectedCSV , setSelectedCSV] = useState("firstCallCsv");
  const advertisementData = useSelector(state=>state.advertisement.advertisementData);

  const fetchSelection = async () => {
    return apirequest_schoolsAPI({
      url: `${endpoint.GetSelectionCriteria.addr}`,
      method: "post",
      headers: {
        "access-token": LoginDetails.token,
      },
      data: {
        courseId,
      },
    });
  };
  // const images = [
  //   { id: 1, img: Image },
  //   { id: 2, img: Image },
  //   { id: 3, img: Image },
  //   { id: 4, img: Image },
  //   { id: 5, img: Image },
  // ];


  // API for selection criteria api data
  const { data: selection, isSuccess: selectionSuccess , isError :selectionCriteriaError , isLoading} = useQuery(
    [`${endpoint.GetSelectionCriteria.key}` , courseId],
    () => fetchSelection(),
    {
      select: (res) => res.data.data[0],
      enabled : !!courseId
    }
  );
  console.log(selection , "data");
  return (
    <div className="">
      <Header />
      {/* <div className="px-16">
        <div className="flex space-x-4">
          {images.map((item) => (
            <img
              src={item.img}
              alt="img"
              className="w-[19%] rounded-2xl mt-10"
              key={item.id}
              loading="lazy"
            />
          ))}
        </div>
      </div> */}
      <Advertisement width={"373px"} height={"195px"} slidesPerView={5} data={advertisementData?.selectioncriteria?.smallbanner}/>

      <div className="flex flex-col w-full my-12">
        <Tab.Group
          onChange={(e) => {
            setSelectedTab(e === 0 ? "firstCall" : "finalCall");
            setSelectedCSV(e===0 ? "firstCallCsv" : "finalCallCsv" );
          }}
        >
          {selectionSuccess &&<Tab.List className="flex flex-row space-between items-center space-x-3 text px-16">
            {selection["calls"]?.map((item) => (
              <Tab
                key={item}
                className={({ selected }) =>
                  `${
                    selected
                      ? "text-white bg-primary-300 font-medium"
                      : "text-secondary-600"
                  } font-ibm font-medium text-lg shadow-outer rounded-2xl px-6 py-4`
                }
              >
                {item}
              </Tab>
            ))}
          </Tab.List>}

          {/* <div className="px-16 py-10 text-sm w-2/3">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.{" "}
            </p>
          </div> */}
          {isLoading && <Loader />}
          {selectionCriteriaError &&<p className="text-red-600 flex justify-center font-medium text-lg">No Record Found</p>}
          {selectionSuccess && selection[selectedTab][selectedCSV].length >0  ?<div className="rounded-2xl shadow-outer m-8">
            {/* Table header */}
            <div className=" flex items-center text-center px-8 py-6 shadow-outer rounded-t-2xl">
              {Object.keys(selection[selectedTab][selectedCSV][0]).map((item , index) => (
                <p
                  key={index}
                  className="flex-1 font-medium text-secondary-600 text-lg"
                >
                  {item}
                </p>
              ))}
            </div>

            {/* Table body */}
            <div className="">
              {selection[selectedTab][selectedCSV].map((item , parentIndex) => (
                <div className="flex flex-col" key={ parentIndex} >
                  <div className="flex justify-items-start px-4 py-2">
                    {Object.keys(selection[selectedTab][selectedCSV][0]).map((val, childIndex) => (
                      <p
                        className="px-4 py-2 text-center flex-1 font-medium text-secondary-600 text-lg"
                        key={childIndex}
                      >
                        {item[val]}
                      </p>
                    ))}
                  </div>
                  {advertisementData?.selectioncriteria?.bigbanner?.length>0 && 
                    <>
                      {parentIndex ==2 && <div className="w-auto m-6 rounded-xl "> <iframe src={`Advertisement.html?id=${advertisementData?.selectioncriteria?.bigbanner[0]}`}></iframe></div>}
                      {parentIndex ==5 && <div className="w-auto m-6 rounded-xl "> <iframe src={`Advertisement.html?id=${advertisementData?.selectioncriteria?.bigbanner[1]}`}></iframe></div>}
                    </>}
                </div>
              ))}
            </div>
          </div> : <p className="text-red-500 text-base font-semibold flex justify-center">No Record Found</p>}
        </Tab.Group>
      </div>
      <Footer />
    </div>
  );
};

export default SelectionCriteria;
