import React from "react";
import propTypes from "prop-types";
import Icon from "../global/icons/Icon";
import arrowLeft from "../../../assets/icons/arrow-left-homepage-active.svg";
import { endpoint } from "../../../API/config";
import { loginAPI_apirequest } from "../../../API/api";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const ProfileHeader = ({ title, prevStep , isLoading, isSkipForNeeded}) => {
  const profileDetails = useSelector(state => state.prelogin.profileDetails);
  let history = useHistory();

  const fecthPreloginDetails = () => {
    return loginAPI_apirequest({
      url: `${endpoint.studentPrelogin.addr}`,
      method: "post",
      data: profileDetails,
    });
  };

  const { refetch } = useQuery(
    `${endpoint.studentPrelogin.key}`,
    fecthPreloginDetails,
    {
      enabled: false,
    },
  );

  const handleClick = () => {
    refetch();
    history.push("/home");
  };

  return (
    <div className="flex flex-row justify-between items-center w-full h-20 shadow-outer mb-12 px-5">
      <div onClick={prevStep} className="cursor-pointer">
        {" "}
        <Icon src={arrowLeft} />{" "}
      </div>
      <div className="text-xl font-ibm font-medium m-auto">{title}</div>
      {!isLoading && !isSkipForNeeded && // change for removal of Skip for now button in congratulations tab ==>
        <p
          onClick={handleClick}
          className="cursor-pointer underline text-primary-200 font-ibm font-medium">
          Skip for now
        </p>
      }
    </div>
  );
};
ProfileHeader.propTypes = {
  title: propTypes.string,
  prevStep: propTypes.func,
  isSkipForNeeded: propTypes.bool,
  isLoading : propTypes.bool
};

export default ProfileHeader;
