import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sectionName: "",
};

export const sectionSlice = createSlice({
  name: "section",
  initialState,
  reducers: {
    getSection: (state, action) => {
      state.sectionName = action.payload;
    },
  },
});

export const { getSection } = sectionSlice.actions;

export const selectSection = state => state.section.sectionName;

export default sectionSlice.reducer;
