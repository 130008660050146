import React from "react";
import propTypes from "prop-types";
import moment from "moment";
import EventBookmark from "./EventBookmark";
import altImage from "../../../../assets/icons/altImage.svg";

{/* redirect to eventLink */}
function MoreEventCard({ thumbnailLink, content, date, type,eventId,isBookmark,eventRefetch, eventLink }) {
  return (
    <div className=" w-full relative overflow-hidden flex justify-between ">
      <div className="flex">
        <a href={eventLink} target="_blank" rel="noreferrer noopener">     
          <img className="w-56 h-36 mt-8 rounded-xl" src={thumbnailLink === "" ?altImage : thumbnailLink} loading="lazy"/>
        </a>  
        <div className="mt-12  pl-6 pr-7">
          <div className="w-full flex-col justify-between">
            <a href={eventLink} target="_blank" rel="noreferrer noopener">     
              <p className="mb-6 font-ibm text-secondary-600 font-normal text-lg hover:underline hover:font-medium">
                {content}
              </p>
            </a>
            <div className="flex space-x-4">
              <p className="px-[12px] py-[6px] bg-secondary-200 rounded-full font-ibm mt-2 w-32">
                {moment(date).format("DD MMM YYYY")}
              </p>
              <p className="px-[12px] py-[6px] bg-secondary-200 rounded-full font-ibm mt-2">
                {type.length> 0 ? type?.join(", ") :  ""}
              </p>
            </div>
          </div>
        </div>
      </div>
      <EventBookmark 
        className="shadow-md mt-14 rounded-full flex p-3 bg-secondary-100" 
        isBookMark={isBookmark}
        eventId={eventId}
        eventRefetch={eventRefetch}
      />
    </div>
  );
}

MoreEventCard.propTypes = {
  thumbnailLink: propTypes.string,
  date: propTypes.string,
  headerTag: propTypes.string,
  content: propTypes.string,
  status: propTypes.string,
  type: propTypes.string,
  video: propTypes.string,
  activeTab: propTypes.string,
  subContent: propTypes.string,
  time: propTypes.string,
  eventId: propTypes.string,
  isBookmark: propTypes.string,
  eventRefetch:propTypes.func,
  eventLink: propTypes.string,

};

export default MoreEventCard;
