import React from "react";
import propTypes from "prop-types";
import Tick from "../../../../assets/icons/SmallTick.svg";

const Tag = ({
  tag = "",
  tagInfo = "",
  tagIcon = "",
  isGrayBackground = false,
  isSelected = false,
}) => {
  return (
    <div className="flex h-8 items-center  cursor-pointer -space-x-2">
      {tagIcon && <img className="h-5 w-5" src={tagIcon} alt="Tag Icon" loading="lazy"/>}
      <span
        className={`flex py-2 px-4 ${
          isGrayBackground && "bg-secondary-200"
        } rounded-[30px] font-ibm font-normal text-sm text-secondary-600`}>
        {tag} {tag && tagInfo ? <span>&nbsp;&#8226;&nbsp;</span>:""} {tagInfo}{" "}
        {tag === "Score" && ""} {tag === "Percentile" && ""}
        {isSelected && <img className="ml-1" alt="a" src={Tick} loading="lazy"/>}
      </span>
    </div>
  );
};

Tag.propTypes = {
  tag: propTypes.string,
  tagInfo: propTypes.oneOfType([propTypes.object, propTypes.string]),
  tagIcon: propTypes.string,
  isGrayBackground: propTypes.bool,
  isSelected: propTypes.bool,
};

export default Tag;
