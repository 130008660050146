import React from "react";
import propTypes from "prop-types";

function Tag({children}) {
  return (
    <div className="py-[6px] px-4 bg-secondary-200 rounded-[30px] font-ibm font-normal text-xs text-secondary-600">
      {children}
    </div>
  );
}

Tag.propTypes = {
  children: propTypes.string,
};
export default Tag;
