import React, { useState } from "react";
import PropTypes from "prop-types";
import tick from "../../../../assets/icons/tick.svg";
import { prepare_apirequest } from "../../../../API/api";
import { endpoint } from "../../../../API/config";
import { useQuery } from "react-query";
import { loginDetails } from "../../../../redux/features/login/LoginSlice";
import { useSelector } from "react-redux";
const TopicHeader = ({
  title,
  courseId,
  learningPathId,
  variantId,
  markAsComplete,
  prepareListRefetch,
}) => {
  const login = useSelector(loginDetails);
  const moduleId = useSelector(state => state.header.headerId);
  const [markComplete, setMarkComplete] = useState(
    markAsComplete === "1" ? true : false,
  );
  const MarkCompleteText = {
    markText: "Mark as Complete",
    unMarkText: "Completed",
  };
  const handleMarkAsComplete = () => {
    return prepare_apirequest({
      url: `${endpoint.markAsComplete.addr}`,
      method: "POST",
      headers: {
        "access-token": login.token,
      },
      data: {
        courseId: courseId,
        variantId: variantId,
        moduleId: moduleId,
        learningPathId: learningPathId,
        markAsComplete: markComplete ? "0" : "1",
      },
    });
  };

  const { refetch: markasComplete } = useQuery(
    [endpoint.markAsComplete.key],
    handleMarkAsComplete,
    {
      enabled: false,
      onSuccess: () => {
        prepareListRefetch();
      },
    },
  );
  function handleMyTaskClick() {
    setMarkComplete(!markComplete);
    markasComplete();
  }

  return (
    <div className="flex justify-between items-center mb-10 font-ibm text-secondary-600">
      <h1 className="font-semibold ">{title}</h1>
      <button
        className="flex items-center justify-between bg-secondary-100 shadow-md px-4 py-3 font-normal text-sm rounded-3xl "
        onClick={handleMyTaskClick}>
        {!markComplete ? (
          <>
            <img className="mr-2" src={tick} alt="Tick" loading="lazy"/>
            <span>{MarkCompleteText.markText}</span>
          </>
        ) : (
          <>
            {/* <img className="mr-2" src={tick} alt="Tick" /> */}
            <span>{MarkCompleteText.unMarkText}</span>
          </>
        )}
      </button>
    </div>
  );
};

export default TopicHeader;
TopicHeader.propTypes = {
  title: PropTypes.string.isRequired,
  setCompletedSections: PropTypes.func,
  completedSections: PropTypes.array,
  moduleId: PropTypes.string,
  learningPathId: PropTypes.string,
  variantId: PropTypes.string,
  courseId: PropTypes.string,
  markAsComplete: PropTypes.string,
  prepareListRefetch: PropTypes.func,
};
