import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import SearchIcon from "../../../../assets/icons/search-new.svg";
import { ReactComponent as ClearIcon } from "../../../../assets/icons/cancel.svg";
import PropTypes from "prop-types";

function Search({ isShadow = true, data, setFunction, placeholder="Search by learning assests"}) {
  const [wordEntered, setWordEntered] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [filteredData, setFilteredData] = useState(data);

  const handleClick = val => {
    setShowDropdown(false);
    setWordEntered(val);
    const newFilter = data?.filter(value => {
      //Same search Component is used in Student Attendance page so checking for student name also
      if ((value.name||value.studentName)?.toLowerCase() === val.toLowerCase()) {
        return value;
      }
    });
    setFunction(newFilter);
  };

  const handleFilter = event => {
    setShowDropdown(true);
    const searchWord = event.target.value;
    if (searchWord === "") {
      setWordEntered("");
      setFilteredData(data);
      setFunction(data);
    } else {
      setWordEntered(event.target.value);
      const newFilter = data?.filter(value => {
        return (value.name||value.studentName)?.toLowerCase().includes(searchWord.toLowerCase());
      });
      setFilteredData(newFilter);
      setFunction(newFilter);
    }
  };

  const outSideClickHandler = () => {
    setShowInput(false);
    setShowDropdown(false);
  };

  const clearTextHandler = () => {
    setWordEntered("");
    setShowDropdown(false);
    setFunction(data);
  };

  const handleSearchIconClick = () => {
    setShowInput(!showInput);
    clearTextHandler();
  };
  return (
    <OutsideClickHandler onOutsideClick={outSideClickHandler}>
      <div>
        <div
          className={`${
            showInput ? "min-w-[26rem] " : "max-w-[56px] "
          } rounded-2xl ${
            isShadow ? "shadow-outer" : ""
          } flex p-[1rem] relative bg-white `}>
          {showInput && (
            <div
              className={`${
                showInput ? "w-full" : ""
              } flex items-center pr-5 `}>
              <input
                type="text"
                name="search"
                placeholder={placeholder}
                className="w-full text-secondary-600 font-normal font-ibm focus:outline-none"
                onChange={e => handleFilter(e)}
                value={wordEntered}
                autoComplete="off"
                autoFocus = {showInput}
              />
              <ClearIcon
                className="cursor-pointer"
                onClick={clearTextHandler}
              />
            </div>
          )}
          <div className="h-6 w-6 flex items-center justify-center ">
            <img
              src={SearchIcon}
              className="cursor-pointer h-5 w-5 shrink-0 "
              alt="search"
              onClick={handleSearchIconClick}
              loading="lazy"
            />
          </div>
        </div>

        {showDropdown && (
          <div className="bg-white z-50 shadow-md mt-5 rounded-2xl pl-[1rem] pt-[1.75rem] max-h-[15rem] overflow-y-scroll scrollbar-thumb-secondary-700 scrollbar-thin scrollbar-thumb-rounded absolute w-full max-w-[26rem]">
            {filteredData?.map((val , index)=> (
              <p
                className="mb-5 hover:font-semibold cursor-pointer"
                key={val.learningPathId || index}
                onClick={() => handleClick(val.name)}>
                {val.name||val.studentName}
              </p>
            ))}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
}
Search.propTypes = {
  isShadow: PropTypes.bool,
  data: PropTypes.array,
  setFunction: PropTypes.func,
  placeholder:PropTypes.string,
  // disabled : PropTypes.bool
};
export default Search;
