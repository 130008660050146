import React from "react";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";


const Tooltip = ({ id, place, text, offset, multiline,icon }) => {
  return (
    <div>
      <ReactTooltip id={id} place={place} offset={offset} multiline={multiline}>
        <div className="flex">
          <img src={icon} loading="lazy"/>
          {text}
        </div>
      </ReactTooltip>
    </div>
  );
};

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  place: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object.isRequired,
  ]),
  offset:PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  multiline: PropTypes.bool,
  icon:PropTypes.string
};

export default Tooltip;
