import React from "react";
import bookmarkDisable from "../../../../../assets/icons/bookmark-disable.svg";
import bookmarkActive from "../../../../../assets/icons/bookmark-active-without-bg.svg";
import propTypes from "prop-types";
import { useQuery } from "react-query";
import { endpoint } from "../../../../../API/config";
import { homeAPI_apirequest } from "../../../../../API/api";
import {
  loginDetails,
} from "../../../../../redux/features/login/LoginSlice";
import { useSelector } from "react-redux";

const BookmarkClick = ({
  learningPathId,
  assetId,
  bookmarked,
  refetchData,
  moduleId,
  isPlayList
}) => {
  const LoginDetails = useSelector(loginDetails);
  const [isBookmarked, setIsBookmarked] = React.useState(
    bookmarked == "1" ? true : false,
  );
  const courseID = useSelector(state => state.login.courseId);

  const variantID = useSelector(state => state.login.variantId);

  //for Blogs Bookmark
  const bookMark = () => {
    const response = homeAPI_apirequest({
      url: `${endpoint.addBookmark.addr}`,
      method: "post",

      headers: {
        "access-token": LoginDetails.token,
      },
      data: {
        studentImsPin: LoginDetails.student_imspin,
        courseId: courseID.toString(),
        variantId: variantID.toString(),
        moduleId: moduleId,
        learningPathId: learningPathId,
        assetId: assetId?assetId:learningPathId,
        bookMark: "1",
        createdBy: LoginDetails.student_imspin,
        assetPath: isPlayList?"1":"0"
      },
    });
    return response;
  };

  //for blogs unBookMark
  const unBookMark = () => {
    const response = homeAPI_apirequest({
      url: `${endpoint.addBookmark.addr}`,
      method: "post",

      headers: {
        "access-token": LoginDetails.token,
      },
      data: {
        studentImsPin: LoginDetails.student_imspin,
        courseId: courseID.toString(),
        variantId: variantID.toString(),
        moduleId: moduleId,
        learningPathId: learningPathId,
        assetId: assetId?assetId:learningPathId,
        bookMark: "0",
        createdBy: LoginDetails.student_imspin,
        assetPath: isPlayList?"1":"0"
      },
    });
    return response;
  };

  //For Bookmark Blogs
  const { refetch } = useQuery(
    [endpoint.addBookmark.key,isBookmarked],
    () => {
      isBookmarked ? unBookMark() : "";
      !isBookmarked ? bookMark() : "";
    },
    {
      enabled: false,
      onSuccess: () => {
        refetchData ? refetchData() : "";
      },
    },
  );

  return (
    <div>
      <div className="flex justify-center items-center w-12 h-12 rounded-full shadow-outer bg-white relative ">
        <img
          src={isBookmarked ? bookmarkActive : bookmarkDisable}
          className="cursor-pointer w-6 h-6"
          onClick={() => {
            refetch();
            setIsBookmarked(!isBookmarked);
          }}
          loading="lazy"
        />
      </div>
      
    </div>
  );
};

BookmarkClick.propTypes = {
  className: propTypes.string,
  bookmarked: propTypes.string,
  blogsRefetch: propTypes.func,
  postId: propTypes.number,
  variantId: propTypes.string,
  courseId: propTypes.string,
  learningPathId: propTypes.string,
  assetId: propTypes.string,
  channelId: propTypes.string,
  learningPathParentId: propTypes.string,
  refetchData: propTypes.func,
  moduleId:propTypes.string,
  isPlayList:propTypes.bool,
};
export default BookmarkClick;
