import React from "react";
import { Link, Element } from "react-scroll";
import TopicHeader from "./TopicHeader";
import Topic from "./Topic";
import propTypes from "prop-types";
import { ReactComponent as TickIcon } from "../../../../assets/icons/tick.svg";
import { useDispatch } from "react-redux";
import { getSection } from "../../../../redux/features/section/sectionSlice";
import { setAccordianType, setLearningPathIdForNextLearning, setParentIdForNextLearning } from "../../../../redux/features/assetDetail/assetDetailSlice";

function AccordianBodyWithScroll({
  assets,
  title,
  completedSections,
  setCompletedSections,
  courseId,
  prepareListRefetch,
}) {
  const dispatch = useDispatch();
  const onHandleClick = name => {
    dispatch(getSection(name));
  };
  function handleTopicClick(asset) {
    const areSubtiersCompleted = asset.children.every(child => {
      return(("isCompleted" in child && child.isCompleted === "True") || ("status" in child && child.status === "completed"));
    });
    if (areSubtiersCompleted) {
      dispatch(setParentIdForNextLearning(asset.parentId));
      dispatch(setLearningPathIdForNextLearning(asset.learningPathId));
      dispatch(setAccordianType("subtier"));
    } else {
      dispatch(setParentIdForNextLearning(null));
      dispatch(setLearningPathIdForNextLearning(null));
      dispatch(setAccordianType(null));
    }
  }
  return (
    <div className="flex relative flex-grow  max-h-[700px] space-x-2  ">
      {/* Left section */}
      <div className=" w-[26rem] flex flex-col items-left rounded-l-2xl shadow-outer px-8 pt-16 scrollbar-thumb-secondary-700 scrollbar-thin scrollbar-thumb-rounded ">
        <div className="cursor-pointer space-y-8 mt-5">
          {assets.map(
            asset =>
              !("assetId" in asset) &&
              asset.assetPath !== 1 && (
                <div key={asset.learningPathId || asset.assetId}>
                  <Link
                    activeClass="active"
                    isDynamic={true}
                    to={asset.name + asset.learningPathId.toString()}
                    key={asset.learningPathId}
                    containerId={title}
                    spy={true}
                    smooth={true}
                    duration={500}>
                    <div className="flex items-center">
                      <button
                        onClick={() => onHandleClick(asset.name)}
                        className="text-ibm text-xl font-ibm font-normal text-left focus:border-b-2 focus:border-black focus:font-semibold transition duration-300 ease-in-out transform">
                        {asset.name}
                      </button>
                      {asset.markAsComplete === "1" && <TickIcon />}
                    </div>
                  </Link>
                </div>
              ),
          )}
        </div>
      </div>

      {/*Right section */}
      <Element
        id={title}
        className="px-8 pt-16 w-full shadow-outer rounded-r-2xl max-h-[700px]  scrollbar-thumb-secondary-700 scrollbar-thin scrollbar-thumb-rounded overflow-x-hidden">
        {assets.map(asset => (
          <Element
            name={asset.name + asset.learningPathId.toString()}
            key={asset.learningPathId || asset.assetId}>
            <div>
              <TopicHeader
                title={asset.name}
                completedSections={completedSections}
                setCompletedSections={setCompletedSections}
                courseId={courseId}
                learningPathId={asset.learningPathId}
                variantId={asset.variantId}
                markAsComplete={asset.markAsComplete}
                prepareListRefetch={prepareListRefetch}
              />
            </div>
            <div onClick={() => handleTopicClick(asset)}>
              {asset.children?.map(content => (
                <Topic
                  key={content.learningPathId || content.assetId}
                  asset={content}
                  parentId={asset.learningPathId}
                  title={asset.name}
                  isSetContinueLearningParentId={true}
                  clearPrepareSlicePlaylist={true}
                />
              ))}
            </div>
          </Element>
        ))}
      </Element>
    </div>
  );
}

AccordianBodyWithScroll.propTypes = {
  data: propTypes.object,
  assets: propTypes.array,
  title: propTypes.string,
  parentIdOfAsset: propTypes.string,
  moduleId: propTypes.string,
  completedSections: propTypes.array,
  setCompletedSections: propTypes.func,
  courseId: propTypes.string,
  prepareListRefetch: propTypes.func,
};

export default AccordianBodyWithScroll;
