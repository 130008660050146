import React from "react";
import Subheader from "./SubHeader";
import SecondSubheader from "./SecondSubheader";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
  selectShowSubheader,
  selectShowSecondSubheader,
  selectSubheaderData,
  selectSecondSubheaderData,
  selectSelectedDropdownOption,
} from "../../../redux/features/header/headerSlice";

function SubheaderContainer({isFromStudentProfile}) {

  const showSubheader = useSelector(selectShowSubheader);
  const showSecondSubheader = useSelector(selectShowSecondSubheader);
  const subHeaderData = useSelector(selectSubheaderData);
  const secondSubheaderData = useSelector(selectSecondSubheaderData);
  const selectedDropdownOption = useSelector(selectSelectedDropdownOption);


  return (
    <div>
      {subHeaderData && showSubheader ? (
        <Subheader isFromStudentProfile={isFromStudentProfile}/>
      ) : secondSubheaderData && showSecondSubheader ? (
        <SecondSubheader selectedDropdownOption={selectedDropdownOption} isFromStudentProfile={isFromStudentProfile}/>
      ) : null}
    </div>
  );
}
SubheaderContainer.propTypes = {
  isFromStudentProfile:PropTypes.bool
};
export default SubheaderContainer;
