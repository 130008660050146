import React from "react";

import propTypes from "prop-types";
import ToolTip from "./ToolTip";
import { addToCompare } from "../../../redux/features/schools/SchoolSlice";
import CompareInactive from "./../../../assets/icons/compare-inactive.svg";
import CompareActive from "./../../../assets/icons/compare-active.svg";
import { useDispatch, useSelector } from "react-redux";

const Compare = ({schoolId,programId}) => {
  const dispatch = useDispatch();
  const compareSchoolList = useSelector(state=> state.school.compareListIds);
  const handleClick = () => {
    let payload = {
      schoolId: schoolId,
      programId:programId
    };
    dispatch(addToCompare(payload));
  };
  const isSelectedToCompare = compareSchoolList.some(element => {
    if (element.programId === programId) {
      return true;
    }
    return false;
  });

  return (
    <div>
      <span
        className="cursor-pointer"
      >
        <img
          onClick={handleClick}
          src={isSelectedToCompare?CompareActive:CompareInactive}
          id="tool-tip"
          data-for="tool-tip"
          data-tip
          loading="lazy"
        />
        <ToolTip
          id="tool-tip"
          offset={{right:0,bottom:0}}
          place="bottom"
          text="View in Compare"
          multiline={false}
        />
      </span>
    </div>
  );
};

Compare.propTypes = {
  schoolId: propTypes.string,
  programId:propTypes.string,
};

export default Compare;
