import moment from "moment";
export function filterBasedOnDates(eventsTime, setFunction, eventsData,keyName , setCurrentPage) {
  setCurrentPage(1);
  switch (eventsTime) {
  case "Today":
    {
      const currentDate = moment().format("YYYY-MM-DD");
      const filterEvent = eventsData?.filter(
        (event) => event[keyName] === currentDate
      );
      setFunction(filterEvent);
    }
    break;
  case "Last Week":
    {
      const lastWeekStartDate = moment().subtract(1, "week").startOf("week");
      const lastWeekEndDate = moment().subtract(1, "week").endOf("week");
      const filterDate = eventsData?.filter((event) => {
        const dateToCheck = moment(event[keyName]);
        return dateToCheck.isBetween(
          lastWeekStartDate,
          lastWeekEndDate,
          null,
          "[]"
        );
      });
      setFunction(filterDate);
    }

    break;
  case "Last Month":
    {
      const lastMonthStartDate = moment().subtract(1, "month").startOf("month");
      const lastMonthEndDate = moment().subtract(1, "month").endOf("month");
      const filterDate = eventsData?.filter((event) => {
        const dateToCheck = moment(event[keyName]);
        return dateToCheck.isBetween(
          lastMonthStartDate,
          lastMonthEndDate,
          null,
          "[]"
        );
      });
      setFunction(filterDate);
    }
    break;
  case "Last Year":
    {
      const lastYearStartDate = moment().subtract(1, "year").startOf("year");
      const lastYearEndDate = moment().subtract(1, "year").endOf("year");
      const filterDate = eventsData?.filter((event) => {
        const dateToCheck = moment(event[keyName]);
        return dateToCheck.isBetween(
          lastYearStartDate,
          lastYearEndDate,
          null,
          "[]"
        );
      });
      setFunction(filterDate);
    }
    break;
  default:
    setFunction(eventsData);
  }
}
