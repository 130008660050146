import React, { useEffect, useState } from "react";
// import Bannerimage from "../../../../assets/images/banner.png";
// import FilterModalTrigger from "../filterModule/FilterModalTrigger";
import BeforeSort from "../../../../assets/icons/before-sort.svg";
import TablePagination from "../schools_articles/TablePagination";
import SchoolTableItem from "./schoolTableItem";
import propTypes from "prop-types";
// import {Link } from "react-router-dom";
import { useQuery } from "react-query";
import { endpoint } from "../../../../API/config";
import {apirequest_schoolsAPI} from "../../../../API/api";
import {loginDetails } from "../../../../redux/features/login/LoginSlice";
import {  useSelector,useDispatch } from "react-redux";
import {setCompareSchoolList,clearCompareSchoolList } from "../../../../redux/features/schools/SchoolSlice";
import { useHistory } from "react-router-dom";
import { Tab } from "@headlessui/react";
import FilterModalTrigger from "../filterModule/FilterModalTrigger";

const columns = [
  { id: 1, col: "IMS Classification",value:"imsClassification" },
  { id: 2, col: "Batch Size" ,value:"batchSize"},
  { id: 5, col: "Test accepted",value:"" },
  { id: 3, col: "Fee",value:"fees" },
  { id: 4, col: "Avg. Salary" ,value:"avgSalary"},
];

const SchoolsListing = ({ schoolListingsData,schoolListingRefetch }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const LoginDetails = useSelector(loginDetails);
  const history = useHistory();
  const compareSchoolList = useSelector(state=> state.school.compareListIds);
  const dispatch = useDispatch();
  /* Limit Data To Show to 10 */
  const dataPerPage = 5;
  const indexOfLastItem = currentPage * dataPerPage;
  const indexOfFirstItem = indexOfLastItem - dataPerPage;
  // const [filteredData,setFilteredData] = useState(dataSource);

  const paginate = pageNumbers => {
    setCurrentPage(pageNumbers);
  };

  //<=================== Tab headerfunctionalities for School listing  ================>
  const courseId = useSelector(state=>state.login.courseId);
  const [tabIndex , setTabIndex] = useState(0);
  const [schoolData , setSchoolData] = useState(schoolListingsData);

  const tabOptions = [
    {
      id :1 ,
      name : "All"
    },
    {
      id :2 ,
      name : "Shortlisted Schools"
    }
  ];

  // <<< ========== shortlisted schools api call =============>
  const {data :ShortlistedSchools} =useQuery(endpoint.getshortlistedSchools.key , ()=>apirequest_schoolsAPI({
    method : "Post", 
    url : endpoint.getshortlistedSchools.addr ,
    headers: {
      "access-token": LoginDetails.token
    },
    data : {
      courseId 
    }
  }),{
    retry :1,

    select : (res)=>{
      return res.data.data.map(parentArr=>parentArr.schoolProgramData.map(childArr=>{
        return {
          schoolId: parentArr.schoolId,
          schoolProgramCourseMapping :  childArr.schoolProgramCourseMapping,
          batchSize : childArr.batchSize,
          fees: childArr.fees/100000,
          avgSalary : (childArr.avgSalary)/100000, //============>.replace(/,/g, "") value changed from string to number <=======
          cutOffId: childArr.cutOffId,
          isSchoolLiked : childArr.isSchoolLiked,
          programs : childArr.programs,
          imsClassification :  childArr.imsClassification,
          examAccepted : childArr.examAccepted,
          schoolName : parentArr.schoolName,
          courses : childArr.courses
        };
      })).flat();
    },

  });


  // <========== api call ends here =================>

  const addToComp = () => {
    dispatch(clearCompareSchoolList());
    return apirequest_schoolsAPI({
      url: `${endpoint.SchoolCompare.addr}`,
      method: "post",
 
      headers: {
        "access-token": LoginDetails.token
      },
      data: {
        schoolDetails:compareSchoolList
      }
      // compareSchoolList
    });
  };
  const { refetch} = useQuery(
    [endpoint.SchoolCompare.key],
    addToComp,
    {
      enabled: false,
      retry:false,
      onSuccess: async res => {
        await dispatch(setCompareSchoolList(res.data.data));
        history.push("/school/compare");
      }
    }
  ); 

  useEffect(()=>{
    setSchoolData(tabIndex ? ShortlistedSchools ??[] : schoolListingsData );
  },[tabIndex]);

  const handleSorting = (sortValue)=>{
    switch (sortValue){
    case "imsClassification":{
      const sort = [...schoolData].sort(function (a, b) {
        if (a.imsClassification < b.imsClassification) {
          return -1;
        }
        if (a.imsClassification > b.imsClassification) {
          return 1;
        }
        return 0;
      });
      setSchoolData(sort);
    }
      break;
    case "batchSize" : {
      const sort = [...schoolData].sort((a,b)=>a.batchSize - b.batchSize);
      setSchoolData(sort);  
    }
      break;
    case "fees" : {
      const sorted  = [...schoolData].sort((a,b)=>a.fees - b.fees);
      setSchoolData(sorted);
    }
      break;
    case "avgSalary" : {
      // const newData = [...schoolData];
      const sort = [...schoolData].sort((a,b)=>a.avgSalary - b.avgSalary);
      setSchoolData(sort);
    }
    }
  };

  return (
    <div className="mt-6">
      <div className="flex items-center justify-between mb-[30px]">
        <section>
          <Tab.Group selectedIndex={tabIndex} onChange={setTabIndex}>
            {
              tabOptions?.map(({name, id} )=>(
                <Tab
                  key = {id}
                  className={({ selected }) =>
                    ` ${
                      selected
                        ? "text-secondary-100 bg-primary-300 font-medium"
                        : "text-secondary-600"
                    } font-ibm font-normal text-md shadow-outer mr-3 rounded-2xl px-6 py-4`
                  }
                >
                  {name}
                </Tab>
              ))
            }
          </Tab.Group>
        </section>
        <FilterModalTrigger 
          setMappedSchool = {setSchoolData}
        />
      </div>

      <div className="bg-white  rounded-2xl shadow-outer">
        <div className="grid  bg-white shadow-outer rounded-2xl">
          <div className="grid grid-cols-8 bg-white rounded-t-2xl shadow-outer p-5 h-16">
            <div className="col-span-2">
              <span className="font-ibm font-medium text-lg ">
                School Name
              </span>
            </div>
            {/* first col end*/}

            {columns.map(items => (
              <div
                key={items.id}
                className="flex justify-center h-8 space-x-4 cursor-pointer  "
                // onClick={() => sorting("classification")}
              >
                <div className="flex space-x-[4px] items-center ">
                  <div className="font-ibm font-medium text-lg">
                    {items.col}
                  </div>
                  <img src={BeforeSort} onClick={()=>handleSorting(items.value)} className="w-[18px] h-[18px] " loading="lazy"/>
                </div>
              </div>
            ))}
          </div>

          <div className="flex flex-col mt-12 ">
            {/* {schoolListingsData.slice(indexOfFirstItem,indexOfLastItem).map((items) => ( */}
            {/* <div className="flex flex-col"> */}
            <SchoolTableItem
              items={schoolData?.slice(indexOfFirstItem,indexOfLastItem)}
              schoolListingRefetch={schoolListingRefetch}
            />
            
            {/* { ((index+1) %5==0 ) && <div className=" w-auto h-80 rounded-3xl mx-9 mb-12">
                <img
                  className="w-full rounded-3xl h-full "
                  src={Bannerimage}
                  alt="Banner"
                />
              </div>} */}
            {/* </div> */}
            {/* ))} */}
            {/* {<Link to="/school/compare"> */}
            <button disabled={compareSchoolList?.length>5} onClick={refetch}  className={`z-10 ${compareSchoolList?.length>5? "bg-secondary-400":"bg-primary-300"} fixed right-24 bottom-8 text-secondary-100 px-8 py-3 cursor-pointer text-lg rounded-3xl flex`}>
              Compare({compareSchoolList?.length})
            </button>
            {/* </Link>} */}
          </div>
          {/* { data.length<5 && <div className=" w-auto h-80 rounded-3xl mx-9 mb-12">
            <img
              className="w-full rounded-3xl h-full "
              src={Bannerimage}
              alt="Banner"
              loading="lazy"
            />
          </div>} */}
        </div>
      </div>
      {schoolData?.length>0 && <div className="courseTable__footer mx-auto mb-7">
        <TablePagination
          data={schoolData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          paginate={paginate}
          dataPerPage={dataPerPage}
        />
      </div>}
    </div>
  );
};

SchoolsListing.propTypes = {
  schoolListingsData: propTypes.array,
  schoolListingRefetch: propTypes.func,
};

export default SchoolsListing;