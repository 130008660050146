import React, { useState, useRef, useEffect } from "react";
import arrowRight from "../../../assets/icons/arrow-right-homepage-active.svg";
import arrowRightInactive from "../../../assets/icons/arrow-right-homepage-inactive.svg";
import arrowLeft from "../../../assets/icons/arrow-left-homepage-active.svg";
import arrowLeftInactive from "../../../assets/icons/arrow-left-homepage-inactive.svg";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
 
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import SwiperCore, { Navigation } from "swiper";

import "swiper/swiper.min.css";
import "swiper/swiper-bundle.css";
import "swiper/swiper-bundle.min.css";

SwiperCore.use([Navigation]);

function HomeSliderTemplate({ children, SliderHeader, slidesPerView ,activeTab,learningPathId,name=""}) {
  const [disableRightIcon, setDisableRightIcon] = useState(false);
  const [disableLeftIcon, setDisableLeftIcon] = useState(true);
  const history = useHistory();

  useEffect(()=>{
    handleLeftIcon();
  },[activeTab]);
  const handleRightIcon = () => {
    setDisableRightIcon(true);
    setDisableLeftIcon(false);
  };
  const handleLeftIcon = () => {
    setDisableLeftIcon(true);
    setDisableRightIcon(false);
  };

  const prevRef = useRef();
  const nextRef = useRef();
  
  const handleClick =()=>{
    localStorage.setItem("breadcrumb1", "Home");
    localStorage.setItem("breadcrumb2",name);
    localStorage.setItem("breadcrumb3","");
    localStorage.setItem("breadcrumb4", "");
    history.push(`/home/seeallchannels/${name}?lpid=${learningPathId}`);
  };

  return (
    <div className="w-full">
      {/* Header */}
      <div className={`${SliderHeader ?"flex  justify-between ":" flex flex-row-reverse -mt-10"} items-center mb-5`}>
        {SliderHeader && <SliderHeader />}
        <div className="flex ml-12 items-center mr-4">
          <div
            className="bg-white cursor-pointer h-12 w-12 rounded-full flex items-center justify-center shadow-outer"
            ref={prevRef}>
            <img src={disableLeftIcon ? arrowLeftInactive : arrowLeft} alt="" />
          </div>
          <div
            className="bg-white cursor-pointer h-12 w-12 rounded-full flex items-center justify-center shadow-outer ml-6"
            ref={nextRef}>
            <img src={disableRightIcon ? arrowRightInactive : arrowRight} alt="" loading="lazy"/>
          </div>
        </div>
        {(!SliderHeader)&& <div>
          <p className="underline text-primary-200 cursor-pointer" onClick={handleClick}>See all</p>
        </div>}
      </div>

      {/*Swiper Body */}
      <div className="w-full z-0 relative">
        <Swiper
          navigation={false}
          onReachEnd={handleRightIcon}
          onReachBeginning={handleLeftIcon}
          onBeforeSlideChangeStart={() => setDisableLeftIcon(true)}
          onSlideNextTransitionStart={()=>setDisableLeftIcon(false)}
          onSlidePrevTransitionStart={() => setDisableRightIcon(false)}
          spaceBetween={-10}
          onInit={swiper => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          slidesPerView={slidesPerView}>
          {children?.length ?
            children?.map((child, ind) => (
              <SwiperSlide key={child + ind}>{child}</SwiperSlide>
            )):<p className="mt-6 font-medium text-base">No Posts Available</p>}
        </Swiper>
      </div>
    </div>
  );
}

HomeSliderTemplate.propTypes = {
  children: PropTypes.any,
  SliderHeader: PropTypes.func,
  slidesPerView: PropTypes.number,
  activeTab : PropTypes.string,
  learningPathId: PropTypes.string,
  parentId:PropTypes.string,
  isSeeAll:PropTypes.bool,
  name:PropTypes.string
};

export default HomeSliderTemplate;
