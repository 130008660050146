import moment from "moment";
import propTypes from "prop-types";
import React from "react";
import NewDropDown from "../../../global/Test&Prepare/NewDropDown";
import ModalButton from "../../ModalTemplate/ModalButton";
import ModalTitle from "../../ModalTemplate/ModalTitle";
import CalendarModule from "../Calander/CalendarModule";

function BookingSlotScreen({
  setIsCalendarOpen,
  closeModal,
  setBookingData,
  bookingData,
  bookSlot,
  venues,
  slots,
  asset,
  variantDetails,
  isBooking,
}) {
  return (
    <div className="p-6 w-96">
      <ModalTitle
        title={"Book Slot"}
        closeModal={closeModal}
        closeIcon={true}
      />
      <p>Venue:</p>
      <NewDropDown
        selected={bookingData.venue_name}
        setSelected={(id, name) => {
          if (name === "Take Home") {
            setBookingData(() => ({
              venue: null,
              venue_name: name,
              date: null,
              slot: null,
              is_take_home: true,
            }));
          } else {
            setBookingData((prev) => ({
              ...prev,
              venue: id,
              venue_name: name,
            }));
          }
        }}
        optionName={"venue_name"}
        optionId={"venue_id"}
        dropDownOptions={
          variantDetails.centreProctoredAllowed === "1"
            ? venues
              ? [{ venue_name: "Take Home", id: null }, ...venues]
              : [{ venue_name: "Take Home", id: null }]
            : [{ venue_name: "Take Home", id: null }]
        }
        placeHolder={"Select venue"}
      />
      <p className="py-1">Date:</p>
      {bookingData.venue_name !== "Take Home" && (
        <CalendarModule
          venue={bookingData.venue}
          setIsCalendarOpen={setIsCalendarOpen}
          selectedDate={bookingData.date}
        />
      )}
      {bookingData.venue_name === "Take Home" && (
        <p
          className={`px-5 py-2 rounded-full max-w-fit font-normal text-sm cursor-pointer bg-gray-300 text-black `}
        >
          {moment(asset.assetDetails[0].releaseDate)?.format("MMM DD, YYYY")} -{" "}
          {moment(asset.assetDetails[0]?.endDate)?.format("MMM DD, YYYY")}
        </p>
      )}
      <p className="py-1">Time Slot</p>
      <div className="flex flex-wrap gap-2 items-center py-2">
        {bookingData.venue &&
          bookingData.venue_name !== "Take Home" &&
          bookingData.date &&
          slots &&
          slots
            .filter(
              (slot) =>
                slot.slot_date === bookingData.date &&
                slot.venue_id === bookingData.venue &&
                moment(`${slot.slot_date} ${slot.start_time}`).isAfter(
                  moment.now()
                )
            )
            .map((slot) => (
              <div
                onClick={() => {
                  setBookingData((prev) => ({ ...prev, slot: slot.slot_id }));
                }}
                key={slot.slot_id}
                className={`px-5 py-2 rounded-full  font-normal text-sm cursor-pointer ${
                  bookingData.slot === slot.slot_id
                    ? "bg-gradient-to-b from-gradient-yellow1 to-gradient-yellow2 text-white"
                    : "bg-gray-300 text-black"
                }`}
              >
                {moment(slot.start_time, "HH:mm").format("hh:mm A") +
                  " " +
                  "-" +
                  " " +
                  moment(slot.end_time, "HH:mm").format("hh:mm A")}
              </div>
            ))}
        {bookingData.venue_name === "Take Home" && (
          <p className="text-xs text-red-500 font-medium">
            Anytime during the proctored window
          </p>
        )}
      </div>

      <div>
        <ModalButton
          modalButtonFunctions={[bookSlot]}
          modalButtonValue={isBooking ? "Booking Slot" : "Book a Slot"}
          disabled={isBooking}
        />
      </div>
    </div>
  );
}

BookingSlotScreen.propTypes = {
  setIsCalendarOpen: propTypes.func,
  setIsSlotBooked: propTypes.func,
  closeModal: propTypes.func,
  selectedDate: propTypes.string,
  setSelectedDate: propTypes.func,
  setBookingData: propTypes.any,
  bookingData: propTypes.any,
  bookSlot: propTypes.func,
  venues: propTypes.array,
  slots: propTypes.array,
  asset: propTypes.any,
  variantDetails: propTypes.any,
  isBooking: propTypes.bool,
};

export default BookingSlotScreen;
