import React from "react";
import Topic from "./Topic";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { setAccordianType, setLearningPathIdForNextLearning, setParentIdForNextLearning } from "../../../../redux/features/assetDetail/assetDetailSlice";

function AccordianBody({ assets, parentId, title, data }) {
  const dispatch = useDispatch();
  function handleClick(){
    dispatch(setParentIdForNextLearning(data.parentId));
    dispatch(setLearningPathIdForNextLearning(data.learningPathId));
    dispatch(setAccordianType("tier"));
  }
  return (
    <div className="rounded-md px-8 py-14 shadow-outer ">
      {assets.map(asset => (
        <div onClick={handleClick} key={asset.learningPathId || asset.assetId}>
          <Topic
            asset={asset}
            parentId={parentId}
            title={title}
            isSetContinueLearningParentId={true}
            clearPrepareSlicePlaylist={true}
          />
        </div>
      ))}
    </div>
  );
}

AccordianBody.propTypes = {
  assets: PropTypes.array,
  parentId: PropTypes.string,
  moduleId: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.object,
};

export default AccordianBody;
