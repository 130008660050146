import React from "react";
// import { Link } from "react-router-dom";
import propTypes from "prop-types";
// commented static Thumbail
// import Thumbnail from "../../../../assets/images/schoolArticleImage.png";
import ArticleBookmark from "./ArticleBookmark";
import moment from "moment";
import "./articlecard.css";

// changed payload as per updated API changes
function ArticleCard({
  articleId,
  articleName,
  htmlContent,
  readTime,
  activeDate,
  isBookmark,
  articleRefetch,
  thumbnailLink,
}) {
  const convertToMinutes = duration => {
    const updatedDuration = duration?.split(":");
    const minutes = + updatedDuration[0] * 60 + + updatedDuration[1];
    return minutes;
  };
 
  return (
    <div className=" shadow-outer w-[373px] mr-11 rounded-xl overflow-hidden ">
      <div className=" h-[209px] relative ">
        <img className="w-full h-full" src={thumbnailLink} alt="" loading="lazy"/>
        <div className="w-full h-full absolute top-0 left-0 bgGradient " />
        <div className="flex items-center justify-between px-6 absolute top-2 w-full ">
          <div className="flex items-center text-xs space-x-2 ">
            <p className="px-[12px] py-[6px] bg-secondary-200 rounded-full font-ibm">
              {moment(activeDate).format("DD MMM YYYY")}
            </p>
            <p className="px-[12px] py-[6px] bg-secondary-200 rounded-full font-ibm ">
              {convertToMinutes(readTime)} min read
            </p>
          </div>

          <div>
            <ArticleBookmark 
              isBookMark={isBookmark}
              articleId={articleId}
              articleRefetch={articleRefetch}
              thumbnailLink={thumbnailLink}
            />
          </div> 
         
        </div>
      </div>

      {/* Body */}
      <div className="p-6">
        <div className="flex justify-between">
          {/* link to htmlContent */}
          <a href={htmlContent} target="_blank" rel="noreferrer noopener">
            <p className="mb-4 font-ibm text-secondary-600 font-medium text-base white ">
              {articleName}
            </p>
          </a>
        </div>
      </div>
    </div>
  );
}

ArticleCard.propTypes = {
  articleId : propTypes.string,
  articleName: propTypes.string,
  htmlContent: propTypes.string,
  readTime: propTypes.string,
  activeDate: propTypes.string,
  thumbnailLink: propTypes.string,
  isBookmark: propTypes.string,
  articleRefetch:propTypes.func,
};

export default ArticleCard;
