import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  advertisementData: [],
};

export const AdvertisementSlice = createSlice({
  name: "advertisement",
  initialState,
  reducers: {
    setSchoolAdvertisementData: (state, action) => {
      state.advertisementData = action.payload;
    },
  },
});

export const { setSchoolAdvertisementData } = AdvertisementSlice.actions;


export default AdvertisementSlice.reducer;
