import React from "react";
import Calendar from "../global/calendar/Calendar";
import propTypes from "prop-types";

import WithModal from "../global/WithModal/WithModal";

function CalendarModal({ closeModal,setDate,isProfile }) {
  return (
    <div>
      <Calendar closeModal={closeModal} setSelectedDate={setDate} isProfile={isProfile}/> 
    </div>
  );
}

CalendarModal.propTypes = {
  closeModal: propTypes.func,
  setDate: propTypes.func,
  isProfile:propTypes.bool
};

export default WithModal(CalendarModal);
