import React from "react";
import FilterFunctionality from "./FilterFunctionality";
import propTypes from "prop-types";
// import WithModal from "../global/WithModal/WithModal";
import Filter from "../../../../assets/icons/filter.svg";
const Trigger = () => {
  return (
    <div className="flex w-36 h-14 items-center shadow-outer rounded-2xl">
      <img className="ml-9" src={Filter} alt="" loading="lazy"/>
      <p className="font-ibm text-lg font-normal ml-4">Filter</p>
    </div>
  );
};

const FilterModalTrigger =({onCloseFunction , setMappedSchool})=>{
  return <FilterFunctionality Trigger={Trigger} onCloseFunctions={onCloseFunction} setMappedSchoolData={ setMappedSchool } />;
};

FilterModalTrigger.propTypes ={
  onCloseFunction : propTypes.array,
  setMappedSchool :  propTypes.func
};
export default FilterModalTrigger ;