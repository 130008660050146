import React from "react";
import propTypes from "prop-types";
import CalenderIcon from "../../../assets/icons/calendar.svg";
import CalendarModal from "./CalendarModal";


const Trigger = () => {
  return (
    <img
      className="w-5 h-5 font-medium cursor-pointer"
      src={CalenderIcon}
      loading="lazy"
    />
  );
};

const CalendarModalTrigger = ({onCloseFunctions,setDate,isProfile}) => {
  return <CalendarModal Trigger={Trigger} onCloseFunctions={onCloseFunctions} setDate={setDate} isProfile={isProfile}/>;
};

CalendarModalTrigger.propTypes = {
  onCloseFunctions: propTypes.array,
  setDate: propTypes.func,
  isProfile:propTypes.bool,
};

export default CalendarModalTrigger;