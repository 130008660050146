import React,{Fragment,useState} from "react";
import PdfView from "./PdfView";
import { Dialog, Transition } from "@headlessui/react";
import Close from "../../../../assets/icons/cancel.svg";
import propTypes from "prop-types";

const PdfViewer =({pdfIcon,pdfLink})=>{
  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  return (
    <div>
      <img
        className=" w-16 h-16 cursor-pointer right-24 bottom-16"
        src={pdfIcon}
        alt="no img"
        onClick={openModal}
        loading="lazy"
      />
  
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="w-3/4 h-[55rem] overflow-y-scroll scrollbar-thin scrollbar-thumb-secondary-700 scrollbar-thumb-rounded  transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <img
                    src={Close}
                    alt="close"
                    onClick={closeModal}
                    className="float-right cursor-pointer absolute right-1 top-0"
                    loading="lazy"
                  />
                  <PdfView pdfLink={pdfLink}/>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

PdfViewer.propTypes = {
  pdfIcon:propTypes.string,
  pdfLink:propTypes.string,

};

export default PdfViewer;
