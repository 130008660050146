import React from "react";
import BookingConfirmationModal from "./BookingConfirmationModal";
import propTypes from "prop-types";

const Trigger = () => {
  return (
    <p className="cursor-pointer underline text-primary-200 font-ibm h-12">
      View Booking
    </p>
  );
};

function BookingConfimMain({ filteredBookedSlots, asset, onClick }) {
  return (
    <BookingConfirmationModal
      Trigger={Trigger}
      filteredBookedSlots={filteredBookedSlots}
      asset={asset}
      onClick={onClick}
    />
  );
}

BookingConfimMain.propTypes = {
  filteredBookedSlots: propTypes.any,
  asset: propTypes.any,
  onClick: propTypes.func,
};

export default BookingConfimMain;
