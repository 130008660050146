import React, { useState } from "react";
import Header from "../../header/Header";
import Footer from "../../global/footer/Footer";
import MoreEventsCard from "./MoreEventCard";
import TablePagination from "../schools_articles/TablePagination";
import Breadcrumb from "../../global/breadcrumb/Breadcrumb";
import DropDown from "../../global/dropDown/DropDown";
import ArticlesEventsSearch from "../../global/search/ArticlesAndEventsSearch";
import EventsCard from "./EventsCard";
import { useQuery } from "react-query";
import { endpoint } from "../../../../API/config";
import { fetchCourseArticleEventData } from "../apiFunctions";
import moment from "moment";
import { loginDetails } from "../../../../redux/features/login/LoginSlice";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { filterBasedOnDates } from "../filterEventsArticles";
import Loader from "../../global/loader/Loader";

const options = [
  { id: 1, topic: "All" },
  { id: 2, topic: "Today" },
  { id: 3, topic: "Last Week" },
  {id :4 , topic : "Last Month"},
  { id :5 ,topic : "Last Year"}
];

const Events_Landing = () => {
  const LoginDetails = useSelector(loginDetails);
  const [currentPage, setCurrentPage] = useState(1);
  /* Limit Data To Show to 10 */
  const dataPerPage = 5;
  const indexOfLastItem = currentPage * dataPerPage;
  const indexOfFirstItem = indexOfLastItem - dataPerPage;
  // const [filteredData,setFilteredData] = useState(dataSource);
  const paginate = pageNumbers => {
    setCurrentPage(pageNumbers);
  };

  const courseId = useSelector(state=>state.login.courseId);
  

  const [eventsTime , setEventsTime] = useState(options[0]["topic"]);
  const [filteredEventsData , setFilteredData ] = useState([]);
 

  const { data: eventsData, refetch  , isSuccess , isLoading ,  isError} = useQuery(
    [endpoint.getCourseArticleEventData.key, courseId],
    () => fetchCourseArticleEventData(courseId, LoginDetails),
    {
      select: x => x.data.data[0].event,
      onSuccess : (res)=>setFilteredData(res),
      enabled : !!courseId
    },
  );

  
  useEffect(()=>{
    filterBasedOnDates(eventsTime , setFilteredData , eventsData ,"date" , setCurrentPage);
  },[eventsTime]);
  
  return (
    <div className="">
      <Header />
      <div className=" my-4">
        <Breadcrumb />
      </div>
      {isLoading &&  <Loader />}
      {isError && <p className="font-medium text-base text-red-500 flex justify-center">No Record Found</p>}
      {isSuccess && <div className="px-16 w-full ">
        <div className=" flex flex-wrap w-full ">
          {/* Added eventLink */}
          {eventsData &&
            filteredEventsData
              .filter(event => event.isPinned === "1")
              .map(item => (
                <EventsCard
                  key={item.eventId}
                  event={item}
                  eventId={item?.eventId}
                  tag={item?.date}
                  type={item?.tags}
                  name={item?.eventTitle}
                  isUpcoming={moment(item?.date).isAfter(moment())}
                  isBookmark={item?.isBookMark}
                  thumbnailLink={item?.thumbnailLink}
                  eventLink={item?.registrationUrl}
                  eventRefetch={refetch}
                />
              ))}
        </div>
        <div className="">
          <p className="font-medium text-2xl">More events</p>
        </div>
        <div className="mb-4 mt-6 flex justify-between">
          <DropDown 
            options={options} 
            text="Show" 
            setDropdownValue={setEventsTime}
          />
          <div className="">
            <ArticlesEventsSearch 
              setFunction={setFilteredData}
              data ={eventsData}
              filterName = "eventTitle"
              filterId= "eventId"
              payloadKey = "Events"
              placeholder= "Search by Events"
              setCurrentPage = {setCurrentPage}
            />
          </div>
        </div>
        <div className="flex-col space-y-6 ">
          {/* Added eventLink */}
          {filteredEventsData.length >0 ?<div>
            {filteredEventsData
              .slice(indexOfFirstItem, indexOfLastItem)
              .map(item => (
                <MoreEventsCard
                  key={item.eventId}
                  eventId={item?.eventId}
                  isBookmark={item?.isBookMark}
                  content={item?.eventTitle}
                  date={item?.date}
                  type={item?.tags}
                  thumbnailLink={item?.thumbnailLink}
                  eventLink={item?.registrationUrl}
                  eventRefetch={refetch}
                />
              ))}
            <div className="courseTable__footer mx-auto mb-7">
              {/* {getBlogData && getBlogData.data.data && ( */}
              <TablePagination
                data={filteredEventsData}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                paginate={paginate}
                dataPerPage={dataPerPage}
              />
              {/* )} */}
            </div>
          </div>: <p className="font-medium text-base text-red-600 flex justify-center">No Record Found</p>}
          
        </div>
      </div>}
      <Footer />
    </div>
  );
};

export default Events_Landing;
