import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  profileDetails:{},
  educationalDetails: [],
  examAttempts: [],
  avatars:[]
};

const preloginSlice = createSlice({
  name: "prelogin",
  initialState,
  reducers: {
  // profile Details
    setProfileDetails: (state, action) => {
      state.profileDetails = action.payload;
    },
    setFirstName: (state, action) => {
      state.profileDetails.firstName = action.payload;
    },
    setProfileImage: (state, action) => {
      state.profileDetails.profileImage = action.payload;
    },
    setDateOfBirth: (state, action) => {
      state.profileDetails.dob = action.payload;
    },
    setGender: (state, action) => {
      state.profileDetails.gender = action.payload;
    },

    handleObjectChange : (state,action) => {
      // Passed stateId and cityId
      let { value, objectName, attrName, stateId, cityId} = action.payload;
      let duplicate = state.profileDetails;
      let obj = duplicate[objectName];

      obj[attrName] = value;
      // condition for if stateId is present
      if (stateId){
        obj.stateId = stateId;
        // obj.cityId= "";
        // obj.city ="";
      }
      if(!cityId){
        obj.cityId = null;
        obj.city = null;
      }
      // condition for if cityId is present
      if (cityId){
        obj.cityId = cityId;
        // obj.cityName = 
      }
      state.profileDetails = {...duplicate};
    },

    handleArrayChange: (state,action) => {
      let { value, id, arrayName, attrName } = action.payload;
      let duplicate = state.profileDetails;
      let array = duplicate[arrayName];
      let obj = array[id];
      obj[attrName] = value;
      state.profileDetails = duplicate;
    },

    // educational details
    handleObjectChangeEducation: (state, action) => {
      let { id, value, attrName } = action.payload;
      let duplicate = state.profileDetails.educationalDetails.ProfessionalCertification;
      let obj = duplicate.find((item, index) => {
        if (index == id) {
          return item;
        }
      });
      obj[attrName] = value;
      state.profileDetails.educationalDetails.ProfessionalCertification =
        duplicate;
    },

    handleChangeProfessionalCertificate: (state, action) => {
      let { id, value, attrName } = action.payload;
      let duplicate =
        state.profileDetails.educationalDetails.ProfessionalCertification;
      let obj = duplicate[id];
      obj[attrName] = value;
      state.profileDetails.educationalDetails.ProfessionalCertification =
        duplicate;
    },

    handleChangePostGraduation: (state, action) => {
      let { value, attrName } = action.payload;
      let duplicate =
        state.profileDetails.educationalDetails.PostGraduation;
      let obj = duplicate;
      obj[attrName] = value;
      state.profileDetails.educationalDetails.PostGraduation =
        duplicate;
    },

    handleChangeGraduation: (state, action) => {
      let { value, attrName, gradLevel } = action.payload;
      state.profileDetails.educationalDetails[gradLevel][attrName] = value;
    },

    handleGradNPostGradIsApplicable: (state, action) => {
      let level = action.payload; // grad or postGrad
      let prevIsApplicable = state.profileDetails.educationalDetails[level].isApplicable;
      state.profileDetails.educationalDetails[level].isApplicable = prevIsApplicable === "1" ? "0" : "1";
      if (prevIsApplicable === "0" && level === "Graduation") {
        state.profileDetails.educationalDetails.PostGraduation.isApplicable = "1";
      }
    },

    handleCopyAddressToggle: (state) => {
      const addressSameAsMailingAddress =  state.profileDetails.permanentAddress.addressSameAsMailingAddress;
      const isPermanentSameAsMailing = (addressSameAsMailingAddress === "1") ? true : false;
      if (isPermanentSameAsMailing){
        state.profileDetails.permanentAddress.addressSameAsMailingAddress = "0";
        state.profileDetails.permanentAddress.address = "";
        state.profileDetails.permanentAddress.city = "";
        state.profileDetails.permanentAddress.cityId = "";
        state.profileDetails.permanentAddress.country = "";
        state.profileDetails.permanentAddress.pincode = "";
        state.profileDetails.permanentAddress.state = "";
        state.profileDetails.permanentAddress.stateId = "";
      }
      else{
        state.profileDetails.permanentAddress.addressSameAsMailingAddress = "1";
        state.profileDetails.permanentAddress.address =
          state.profileDetails.mailingAddress.address;
        state.profileDetails.permanentAddress.city =
          state.profileDetails.mailingAddress.city;
        state.profileDetails.permanentAddress.cityId =
          state.profileDetails.mailingAddress.cityId;
        state.profileDetails.permanentAddress.country =
          state.profileDetails.mailingAddress.country;
        state.profileDetails.permanentAddress.pincode =
          state.profileDetails.mailingAddress.pincode;
        state.profileDetails.permanentAddress.state =
          state.profileDetails.mailingAddress.state;
        state.profileDetails.permanentAddress.stateId =
          state.profileDetails.mailingAddress.stateId;
      }
    },

    handleChangeGradeSystemValue: (state, action) => {
      let { value, type } = action.payload;
      let gradeSystem = state.profileDetails.educationalDetails[type].Grade_Type;
      if (gradeSystem === "CGPA") {
        state.profileDetails.educationalDetails[type].CGPA_x_y = value;
      } else {
        state.profileDetails.educationalDetails[type].Marks = value;
      }
    },

    handleChangeGraduationType : (state, action) => {
      let { system, std } = action.payload;
      state.profileDetails.educationalDetails[std].Grade_Type = system;
    },

    handleEducationalInput: (state, action) => {
      let { value, type, keyName } = action.payload;
      state.profileDetails.educationalDetails[type][keyName] = value;
    },

    addNewObject: (state, action) => {
      let { obj, arrayName } = action.payload;
      state.profileDetails[arrayName] = [
        ...state.profileDetails[arrayName],
        obj,
      ];
    },

    addNewObjectEducation: (state, action) => {
      let { obj, arrayName } = action.payload;
      state.profileDetails.educationalDetails[arrayName] = [
        ...state.profileDetails.educationalDetails[arrayName],
        obj,
      ];
    },

    removeArrayId: (state, action) => {
      let { keyName, arrayName } = action.payload;
      let duplicate = state.profileDetails[arrayName].map(item => {
        return {
          [keyName] : "",
          ...item,
        };
        
      });
      state.profileDetails[arrayName] = duplicate;
    },

    removeArrayIdProfessionalCert: (state, action) => {
      let { keyName, arrayName } = action.payload;
      let duplicate = state.profileDetails.educationalDetails[arrayName].map(item => {
        return {
          [keyName]: "",
          ...item,
        };
      });
      state.profileDetails.educationalDetails[arrayName] = duplicate;
    },

    removeObjectId: (state, action) => {
      let { objectName, keyName } = action.payload;
      let duplicate = state.profileDetails.educationalDetails[objectName];
      duplicate[keyName] = "";
      state.profileDetails.educationalDetails[objectName] = duplicate;
    },

    setExmAttempt: (state, action) => {
      state.profileDetails.examAttempts = action.payload;
    },
    //getting and storing avatars
    setAvatars: (state, action) => {
      state.avatars = action.payload;
    },
  },
});

export const {
  setProfileDetails,
  setFirstName,
  setProfileImage,
  setDateOfBirth,
  setGender,
  handleArrayChange,
  setExmAttempt,
  handleObjectChange,
  addNewObject,
  handleCopyAddressToggle,
  addNewObjectEducation,
  handleObjectChangeEducation,
  handleChangeGraduation,
  handleChangePostGraduation,
  handleGradNPostGradIsApplicable,
  handleChangeProfessionalCertificate,
  handleChangeGradeSystemDropdown, //
  handleChangeGradeSystemValue,
  // handleChangeStateDropdown,
  handleEducationalInput,
  removeArrayId,
  removeObjectId,
  removeArrayIdProfessionalCert,
  handleChangeGraduationType,
  setAvatars
} = preloginSlice.actions;
export const getProfileDetails = state => state.prelogin.profileDetails;
export default preloginSlice.reducer;