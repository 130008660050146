import React, { useState } from "react";
import ArrowLeft from "../../../../assets/icons/arrow-left.svg";
import ArrowRight from "../../../../assets/icons/arrow-right.svg";
import propTypes from "prop-types";
function TablePagination({
  data,
  paginate,
  currentPage,
  setCurrentPage,
  dataPerPage,
}) {
  // const dataPerPage = 10;
  const pageNumberLimit = 10;
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(10);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);

  const handleNextBtn = () => {
    setCurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };
  const handlePrevBtn = () => {
    setCurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit == 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };
  // for load more...
  // const handleLoadMore = () => {
  //   setItemsPerpage(dataPerPage + 5);
  // };
  let pageDotsIncrement = null;
  if (data?.length > maxPageNumberLimit) {
    pageDotsIncrement = <li onClick={handleNextBtn}> &hellip; </li>;
  }
  let pageDotsDecrement = null;
  if (minPageNumberLimit >= 1) {
    pageDotsDecrement = <li onClick={handlePrevBtn}> &hellip; </li>;
  }
  const totalPage = [];
  for (let i = 1; i <= Math.ceil(data?.length / dataPerPage); i++) {
    totalPage.push(i);
  }
  const renderPageNumbers = totalPage.map(number => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      const padZero = number < 10 ? "0" + number : number;
      return (
        <li
          className="px-4 text-sm font-ibm font-medium list-none"
          key={number}
        >
          <span
            className={
              currentPage == number
                ? "bg-gray-200 font-bold p-2 rounded-full"
                : ""
            }
            id={number}
            onClick={() => paginate(number)}>
            {padZero}
          </span>
        </li>
      );
    } else {
      return null;
    }
  });
  return (
    <div className="ml-[5%] mt-6 flex items-center">
      <div className="text-sm mr-[20%]">
        Showing{" "}
        <span className="font-semibold">
          {currentPage} of {totalPage[totalPage.length - 1]}
        </span>
      </div>
      <div className="flex items-center cursor-pointer align-top">
        <button
          type="button"
          onClick={handlePrevBtn}
          disabled={currentPage === 1 ? true : false}>
          {" "}
          <img src={ArrowLeft} className="h-5" loading="lazy"/>
        </button>
        <div className="fontFamily-ibm text-sm opacity-70 ">
          <ul className="flex">
            {currentPage > 10 && pageDotsDecrement}
            {renderPageNumbers}
            {currentPage != totalPage.length && pageDotsIncrement}
          </ul>
        </div>
        <button
          type="button"
          onClick={handleNextBtn}
          disabled={currentPage == totalPage.length ? true : false}>
          {" "}
          <img src={ArrowRight} className="h-5" loading="lazy"/>
        </button>
      </div>
    </div>
  );
}
TablePagination.propTypes = {
  data: propTypes.array,
  paginate: propTypes.func,
  currentPage: propTypes.number,
  setCurrentPage: propTypes.func,
  dataPerPage: propTypes.number,
};
export default TablePagination;
