import React, { useState } from "react";
import Header from "../../header/Header";
import Breadcrumb from "../../global/breadcrumb/NewBreadCrumb";
import MasterClassCard from "../cards/masterClass/MasterClassCard";
import { useParams } from "react-router-dom";

import { useQuery } from "react-query";
import { apirequest_liveAPI } from "../../../../API/api";
import { endpoint } from "../../../../API/config";

import {loginDetails } from "../../../../redux/features/login/LoginSlice";
import {  useSelector } from "react-redux";
import Loader from "../../global/loader/Loader";
import Footer from "../../global/footer/Footer";

function MasterClassesSeeAll() {
  const LoginDetails = useSelector(loginDetails);
  const [activeTab, setActiveTab] = useState("completed");
  const [data, setData] = useState();
  const courseID = useSelector(state => state.login.courseId);  
  const [moduleArray,setModuleArray] = useState([
    ...new Set(data?.map(item => item.moduleNumber)),
  ]);
  const [activeModule, setActiveModule] = useState("");
  const { batchId } = useParams();

  const fetchCompletedClassDataUsingBatchId = batchId => {
  
    return apirequest_liveAPI({
      url: `${endpoint.getCompletedViewAllClassData.addr}`,
      method: "post",
 
      headers: {
        "access-token": LoginDetails.token
      },
      data: {
        studentImsPin: LoginDetails.student_imspin,
        courseId:courseID?.toString(),
        batchId: batchId?.toString(),
      },
    });
  };

  const {isFetching,refetch:fetchRecentClassData}= useQuery(
    [endpoint.getCompletedClassUsingBatchId.key, batchId],
    () => fetchCompletedClassDataUsingBatchId(batchId),
    {
      enabled: !!batchId,
      onSuccess: res => {
        const data = res.data[0]?.data;
        const flattenedData = data ? Object.values(data).flat() : [];
        setData(flattenedData);
        setModuleArray([
          ...new Set(flattenedData.map(item => item.moduleNumber)),
        ]);
        setActiveModule(flattenedData[0].moduleNumber);

      },
    },
  );
  // fetch UPCOMING CLASS data
  const fetchUpcomingClassDataUsingVariantId = () => {
    return apirequest_liveAPI({
      url: `${endpoint.getUpcomingClassData.addr}`,
      method: "post",

      headers: {
        "access-token": LoginDetails.token,
      },
      data: {
        studentImsPin: LoginDetails.student_imspin,
        courseId: courseID?.toString(),
        batchId,
      },
    });
  };

  const { refetch, isFetching:isUpcomingFetching } = useQuery(
    [endpoint.getUpcomingClassData.key, batchId],
    () => fetchUpcomingClassDataUsingVariantId(batchId),
    {
      enabled: false,
      retry: false,
      onSuccess: res => {
        const data_received = res.data[0].data;
        let key = Object.keys(data_received)[0];
        let upcomingData = data_received[key];
        if (
          upcomingData.length === 1 &&
          Object.keys(upcomingData[0]).length === 1 &&
          "batchId" in upcomingData[0]
        )
          setData([]);
        else {
          setModuleArray([
            ...new Set(upcomingData.map(item => item.moduleNumber)),
          ]);
          setData(upcomingData);
          setActiveModule(upcomingData[0].moduleNumber);
        }
      },
    },
  );
  
  const handleClick = tab => {
    setActiveTab(tab);
    if (tab === "upcoming") {
      refetch();
    } else {
      fetchRecentClassData();
    }
  };
 
  return (
    <div>
      <Header />
      <div className="mt-8 mb-12 ">
        <Breadcrumb />
      </div>

     
      <div className="px-14">
        
        <div className="font-ibm flex mb-6">
          <div
            className={`px-6 py-4 rounded-2xl  shadow-outer mr-4 ${
                activeTab === "completed"
                  ? "text-white bg-primary-300 font-medium "
                  : "text-secondary-600 "
              } cursor-pointer `}
            onClick={() => handleClick("completed")}>
              Completed
          </div>
          <div
            className={`px-6 py-4 rounded-2xl shadow-outer mr-4 ${
                activeTab === "upcoming"
                  ? "text-white bg-primary-300 font-medium "
                  : "text-secondary-600"
              } cursor-pointer `}
            onClick={() => handleClick("upcoming")}>
              Upcoming
          </div>
        </div>
        {(isFetching || isUpcomingFetching) ? (
          <div className=" h-[278px] ">
            <Loader />
          </div>
        ) : 
          <div>
            <div className="flex items-center space-x-4 mb-4 -mt-2">
              {data?.length > 0 && moduleArray?.length > 0 &&
                moduleArray.map((item, ind) => (
                  <p
                    key={ind}
                    className={`px-6 py-5 font-ibm cursor-pointer ${
                      activeModule === item &&
                      "border-b-[3px] border-primary-200 font-medium text-lg"
                    }`}
                    onClick={() => setActiveModule(item)}>
                    {item}
                  </p>
                ))}
            </div>
            {data.length>0 ? <div className="grid grid-cols-4">
              {data?.filter(x => x.moduleNumber == activeModule).map((item,ind) => (
                <MasterClassCard
                  key={ind}
                  headerTag={item.classNumber}
                  tag={activeTab === "upcoming" ? "Upcoming" : ""}
                  title={item.classTitle}
                  date={item.classDate}
                  time={item.classTime}
                  status={item.status}
                  pdfLink={item.handout}
                  classData={item}
                />
              ))}
            </div>:<p className="mt-6 font-medium text-base">No Classes Available</p>}</div>}
      </div>
      <Footer />
    </div>
  );
}

export default MasterClassesSeeAll;
