import React from "react";
import plusActive from "../../../../assets/icons/plusActive.svg";
import Search from "../search/Search";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";


function AddToCompare({setSchoolId,setProgramId}) {
  const schoolList = useSelector(state=>state.school.schoolList);
  const [isClicked,setIsClicked]=React.useState(false);
  const handleClick=()=>{
    setIsClicked(true);
  };
  return (
    <div className="p-4 shadow-compare rounded-2xl font-ibm w-64 text-center text-lg font-light mr-3 mb-3 ">
      <div onClick={handleClick} className=" bg-secondary-200 w-56 h-[144px] bg-center bg-no-repeat bg-contain rounded-2xl mb-[17px] flex items-center justify-center  ">
        <div className="rounded-full bg-white flex items-center justify-center h-12 w-12 mr-4 cursor-pointer ">
          <img src={plusActive} alt="plus" loading="lazy"/>
        </div>
        <p>Add to compare</p>
      </div>
      {isClicked && 
        <div>
          <Search schoolList={schoolList} setIsClicked={setIsClicked}  setSchoolId={setSchoolId} setProgramId={setProgramId} />
        </div>
      }
    </div>
  );
}
AddToCompare.propTypes = {
  setCollegeDetails: PropTypes.func,
  setSchoolId:PropTypes.func,
  setProgramId:PropTypes.func,
};
export default AddToCompare;
 