import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loginDetails: {},
  courseIdNvariantId: "",
  courseId: "",
  course_name: "",
  variantId: "",
  channelId:"",
  studentPageTab:"Profile",
  studentVariantId: "",
  studentCourseId: "",
  studentCourseName: "",
  studentLearningId: "",
  studentHeaderTab:"Profile",
  studentData: [],
  isParent:false
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    saveLoginDetails: (state, action) => {
      state.loginDetails = action.payload;
    },
    changeCourse: (state, action) => {
      state.courseIdNvariantId = action.payload.courseIdNVariantId;
      state.courseId = action.payload.courseId;
      state.course_name = action.payload.name;
      state.variantId = action.payload.variantId;
    },
    clearLoginData: (state) => {
      state.loginDetails = [];
      state.courseIdNvariantId = "";
      state.courseId = "";
      state.course_name = "";
      state.variantId = "";
    },
    setChannelData: (state, action) => {
      state.loginDetails.channelData = action.payload;
    
    },
    setIsMentor: (state, action) => {
      state.loginDetails.isMentor = action.payload;
    
    },
    // state added for storing the channelId 
    setChannelId: (state, action) => {
      state.channelId = action.payload;
    },
    clearChannelId: (state) => {
      state.channelId="";
    },
    setStudentPageTab: (state,action) => {
      state.studentPageTab = action.payload;
    },
    setStudentPageData: (state,action) => {
      // const {payload} = action.payload;
      console.log("Store",action.payload);
      state.studentVariantId = action.payload.variantId;
      state.studentCourseId = action.payload.courseId;
      state.studentCourseName = action.payload.courseName;
      state.studentHeaderTab = action.payload.name;
    },
    saveStudentData: (state, action) => {
      state.studentData = action.payload;
    },
    setIsParent: (state, action) => {
      state.isParent = action.payload;
    },
    clearStudentPageData: (state) => {
      state.studentVariantId = "";
    }
  },
});

export const { saveLoginDetails,clearStudentPageData, changeCourse, clearLoginData ,setChannelData,setIsMentor,setChannelId,clearChannelId,setStudentPageTab,setStudentPageData,saveStudentData,setIsParent} = loginSlice.actions;

export const loginDetails = state => state.login.loginDetails;
export const courseIdNvariantId = state => state.login.courseIdNvariantId;
export const token = state => state.login.loginDetails.token;
export const getCourseId = state => state.login.courseId;
export const getVariantId = state => state.login.variantId;

export default loginSlice.reducer;
