import React from "react";
import EventCard from "../../home/cards/featuredBlog/Blogs";
import propTypes from "prop-types";
import HomeSliderTemplate from "../../home/HomeSliderTemplate";

function SchoolEvents({data}) {
  const SliderHeader = ()=>(<span className="-h-5"></span>);

  return (
    <div>
      <h1 className="mb-4 font-medium">Events</h1>
      <div className="flex space-x-3 ">
        <HomeSliderTemplate
          SliderHeader={SliderHeader}
          slidesPerView={2}
        >
          {data?.map(item => (
            <div key={item.eventId} className="m-4">
              <EventCard
                key={item.eventId}
                tag={item.date}
                type={item.type}
                name={item.eventTitle}
                isBookMark={item.isBookMark}
                url={item.thumbnailLink}
                postId={item.eventId}
                forFeatureBlogs={false}
                blogLink={item.registrationUrl}
                // status={item.status.toLowerCase()==="upcoming"}
              />
            </div>
          ))}
        </HomeSliderTemplate>
      </div>
    </div>
  );
}

SchoolEvents.propTypes = {
  data: propTypes.array,
};

export default SchoolEvents;
