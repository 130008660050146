import React from "react";
import propTypes from "prop-types";
import CardBookMark from "../masterClass/CardBookMark";
import moment from "moment";
import { ReactComponent as VideoIcon } from "../../../../../assets/icons/video.svg";
import { ReactComponent as HtmlIcon } from "../../../../../assets/icons/pdf.svg";
import { ReactComponent as PlaylistIcon } from "../../../../../assets/icons/video-playlist.svg";
import { ReactComponent as PdfIcon } from "../../../../../assets/icons/quiz-new.svg";
import { ReactComponent as AssesmentIcon } from "../../../../../assets/icons/testnew.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useDispatch } from "react-redux";
import {
  setParentIdOfPlaylist,
  setPathData,
  setPlaylist,
  setPlaylistName,
} from "../../../../../redux/features/assetDetail/assetDetailSlice";
function ChannelCard({
  isPlayList,
  status,
  content,
  date,
  tag,
  time,
  learningPathId,
  assetId,
  bookmarked,
  refetchData,
  assetName,
  moduleId,
  duration,
  thumbnailLink,
  title
}) {
  const bgColor = tag => {
    switch (tag) {
    case "live":
      return "from-[#FFCF00] to-[#FFA500]";
    case "video":
      return "from-[#BC94FF] to-[#9F66FF]";
    case "html":
      return "from-[#00ABFB] to-[#2FD0FA]";
    case "pdf":
      return "from-[#00ABFB] to-[#2FD0FA]";
    }
  };

  const history = useHistory();
  const dispatch = useDispatch();

  function returnIcon() {
    if (isPlayList) return <PlaylistIcon />;
    if (status?.toLowerCase() === "video") return <VideoIcon />;
    if (status?.toLowerCase() === "html") return <HtmlIcon />;
    if (status?.toLowerCase() === "pdf") return <PdfIcon />;
    if (status?.toLowerCase() === "assessment") return <AssesmentIcon />;
  }

  const timeformat = time => {
    let timeArr = time.split(":");
    const updateTime = (timeArr[0] % 12) + ":" + timeArr[1] + " ";
    return updateTime + (timeArr[0] > 12 ? "PM" : "AM");
  };

  const formatDuration = () => {
    let [hour, min] = duration?.split(":");
    return (
      (hour !== "0" || min !== "00") && (
        <div className="float-right absolute  z-50 w-auto h-6  bg-black rounded-bl-xl rounded-tr-lg ">
          <p className="px-4  font-ibm text-secondary-100">
            {hour !== "0"
              ? `${hour}hr : ${min}min`
              : min !== "00" && `${min}min`}
          </p>
        </div>
      )
    );
  };

  const handleClick = () => {
    localStorage.setItem("breadcrumb1", "Home");
    localStorage.setItem("breadcrumb2",title);
    localStorage.setItem("breadcrumb3", assetName);
    localStorage.setItem("breadcrumb4", "");

    dispatch(setPlaylist(null));
    dispatch(setPathData(null));
    if (isPlayList) {
      dispatch(setPlaylistName(assetName));
      dispatch(setParentIdOfPlaylist(assetId));
    }
    history.push(
      `/home/homedetail/${assetName?.replaceAll(
        "/",
        "-",
      )}/?moduleId=${moduleId}&parentId=${learningPathId}&assetId=${assetId}`,
    );
  };

  return (
    <div className="rounded-2xl max-w-[90%]  shadow-outer mb-6 overflow-hidden font-ibm">
      {/* Header */}
      <div className="w-full  bg-gradient-to-t	from-secondary-600 to-secondary-100 z-10">
        <div className="flex flex-row-reverse ">
          {status?.toLowerCase() === "live" ? (
            <div className="float-right absolute  z-50 w-20 h-6  bg-[#FF5757] rounded-bl-xl rounded-tr-lg ">
              <p className="px-4  font-ibm text-secondary-100">LIVE</p>
            </div>
          ) : (
            duration && formatDuration()
          )}
        </div>
        <img
          className="w-full h-52 rounded-tl-xl rounded-tr-lg z-1 cursor_pointer"
          src={thumbnailLink}
          alt="no thumbnail"
          loading="lazy"
        />

        {tag && (
          <div
            className={`ml-6 absolute -mt-3 z-20 w-auto h-6 rounded-md bg-gradient-to-r ${bgColor(
              status.toLowerCase(),
            )}`}>
            <p className="px-4 text-white font-ibm z-40">{tag}</p>
          </div>
        )}
      </div>

      {/* Body */}
      <div className="pt-6 pb-[26px] pl-6 pr-7 relative h-52">
        <div className="flex justify-between">
          <p
            className="mb-4 line-clamp-2 font-ibm text-secondary-600 font-medium text-lg h-14 cursor-pointer "
            onClick={handleClick}>
            {content}
          </p>
          {
            <CardBookMark
              className="w-10 h-10 -mt-2"
              isPlayList={isPlayList}
              moduleId={moduleId}
              assetId={assetId}
              learningPathId={learningPathId}
              bookmarked={bookmarked}
              refetchData={refetchData}
            />
          }
        </div>

        {date && time && (
          <p className="mb-6 font-ibm">
            {moment(date, "DD-MM-YYYY").format("DD MMM YYYY")} |{" "}
            {timeformat(time)}{" "}
          </p>
        )}

        <div className="flex items-center justify-between">
          <div
            className="flex items-center space-x-2 cursor-pointer absolute bottom-6"
            onClick={handleClick}>
            {returnIcon()}
            <p className="text-primary-200 font-ibm font-medium text-base">
              {status?.toLowerCase() == "video"
                ? "Play video"
                : status?.toLowerCase() === "html" ||
                  status?.toLowerCase() === "pdf"
                  ? "Read More"
                  : "Play Episode"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

ChannelCard.propTypes = {
  date: propTypes.string,
  headerTag: propTypes.string,
  content: propTypes.string,
  status: propTypes.string,
  tag: propTypes.array,
  video: propTypes.string,
  time: propTypes.string,
  isPlaylist: propTypes.string,
  variantId: propTypes.string,
  courseId: propTypes.string,
  learningPathId: propTypes.string,
  assetId: propTypes.string,
  channelId: propTypes.string,
  learningPathParentId: propTypes.string,
  bookmarked: propTypes.string,
  refetchData: propTypes.func,
  classData: propTypes.object,
  assetName: propTypes.string,
  moduleId: propTypes.string,
  duration: propTypes.string,
  thumbnailLink: propTypes.string,
  isPlayList: propTypes.bool,
  title: propTypes.string,
};

export default ChannelCard;
