import React from "react";
import PropTypes from "prop-types";

function ToggleButton({ toggle1, toggle2, activeTab, setActiveTab,isAttendance }) {
  return (
    <div className="flex items-center">
      <div className="flex bg-secondary-200 rounded-2xl">
        <p
          onClick={() => setActiveTab(toggle1?.toLowerCase())}
          className={`py-4 px-5 rounded-2xl cursor-pointer   ${
            activeTab?.toLowerCase() === toggle1?.toLowerCase()
            // conditions added to change the font color in attendance section of mentor side
              ?isAttendance?"bg-white shadow-outer font-ibm text-present": "bg-white shadow-outer font-ibm"
              : "font-ibm"
          } `}>
          {toggle1}
        </p>
        <p
          onClick={() => setActiveTab(toggle2?.toLowerCase())}
          className={`py-4 px-5 rounded-2xl cursor-pointer ${
            activeTab?.toLowerCase() === toggle2?.toLowerCase()
              ?isAttendance?"bg-white shadow-outer font-ibm text-primary-500": "bg-white shadow-outer font-ibm"
              : "font-ibm"
          } `}>
          {toggle2}
        </p>
      </div>
    </div>
  );
}

ToggleButton.propTypes = {
  toggle1: PropTypes.string,
  toggle2: PropTypes.string,
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
  title: PropTypes.string,
  isAttendance:PropTypes.bool,
};

export default ToggleButton;
