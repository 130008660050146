import React from "react";
import propTypes from "prop-types";
import ScheduleItem from "./schedule_item";
import moment from "moment";

function ScheduleDay({ day, events }) {
  return (
    <div className="mb-8">
      <p className="text-[18px] font-medium mb-6 ">{moment(day).format("dddd, DD")}</p>
      <div className=" px-6 border-[1px] border-solid border-primary-400 shadow-outer ">
        {events.map((event, ind) => (
          <ScheduleItem
            key={event.id}
            ind={ind}
            event={event}
            events={events}
            day={day}
          />
        ))}
      </div>
    </div>
  );
}

ScheduleDay.propTypes = {
  day: propTypes.string,
  events: propTypes.array,
};

export default ScheduleDay;
