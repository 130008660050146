import React, { useState } from "react";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { ReactComponent as ArrowLeft } from "../../../../assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../../../assets/icons/arrow-right.svg";
import { ReactComponent as Cancel } from "../../../../assets/icons/cancel.svg";
import arrowUp from "../../../../assets/icons/arrow-up-14px.svg";
import arrowDown from "../../../../assets/icons/arrow-down-14px.svg";
import OutsideClickHandler from "react-outside-click-handler";

import propTypes, { string } from "prop-types";

function Calendar({
  availableDates,
  setIsCalendarOpen,
  setSelectedDate,
  closeModal,
  isProfile,
}) {
  const today = moment().startOf("day");
  const [open, setOpen] = useState(false);
  const [showMonth, setShowMonth] = useState(false);
  const [showYear, setShowYear] = useState(false);
  const [selectedDay, setSelectedDay] = useState(today);
  const [selectedYear, setSelectedYear] = useState(2023);
  const [selectedMonth, setSelectedMonth] = useState(moment().startOf("month"));
  const [monthSelected, setMonthSelected] = useState(moment().format("MMMM"));
  const daysInWeek = ["S", "M", "T", "W", "T", "F", "S"];
  const month = moment.months();
  const [years, setYears] = useState([
    moment().year(),
    moment().year() - 1,
    moment().year() - 2,
    moment().year() - 3,
    moment().year() - 4,
    moment().year() - 5,
    moment().year() - 6,
    moment().year() - 7,
    moment().year() - 8,
    moment().year() - 9,
    moment().year() - 10,
  ]);
  const changeMonth = (num) => {
    let newDate = moment(selectedMonth).add(num, "month");
    setSelectedMonth(newDate);
    setMonthSelected(newDate.format("MMMM"));
    setSelectedYear(newDate.year());
  };

  const changeDay = (day) => {
    setSelectedDay(day);
    // format is YYYY-MM-DD from backend, else from getStuentData 0000-00-00 is reflected
    const d = day.format("YYYY-MM-DD");
    setSelectedDate(d); // setting state in parent
    // setIsCalendarOpen(false);
  };

  const handleCancel = () => {
    setIsCalendarOpen && setIsCalendarOpen(false);
    closeModal && closeModal();
  };

  const addMonth = () => {
    changeMonth(1);
  };

  const subtractMonth = () => {
    changeMonth(-1);
  };

  const getDaysInMonth = () => {
    const totalDays = new Array();
    const totalLimit = 42;
    // Add days of current month
    for (let i = 0; i < selectedMonth.daysInMonth(); i++) {
      totalDays.push(moment(selectedMonth).add(i, "days"));
    }
    // Add days of previous month if first day of current month isn't Sunday
    if (moment(selectedMonth).day() !== 0) {
      const previousMonth = moment(selectedMonth) // returns 2021-12-01T00:00:00+05:30 (comment is based on code written in December 2021)
        .subtract(1, "months") // returns 2021-11-01T00:00:00+05:30
        .endOf("month") // returns 2021-11-30T23:59:59+05:30
        .startOf("day"); // returns 2021-11-30T00:00:00+05:30
      for (let i = 0; i < moment(selectedMonth).day(); i++) {
        // moment(selectedMonth).day() returns any number between 0-6 , where 0 = sunday
        totalDays.unshift(moment(previousMonth).subtract(i, "days"));
      }
    }
    // Add days of next month if total days is not enough
    if (totalDays.length < totalLimit) {
      const nextMonth = moment(selectedMonth).add(1, "months");
      const daysToFill = totalLimit - totalDays.length;
      for (let i = 0; i < daysToFill; i++) {
        totalDays.push(moment(nextMonth).add(i, "days"));
      }
    }
    return totalDays;
  };

  const getClassName = (day) => {
    if (selectedDay.isSame(day)) {
      return "bg-primary-200 text-white";
    } else if (day.month() != selectedMonth.month()) {
      return "text-secondary-400";
    } else {
      return "";
    }
  };

  function addYears() {
    setYears((prev) => {
      let lastItem = prev[prev.length - 1];
      let nextItem = lastItem - 1;
      return [...prev, nextItem];
    });
  }

  function handleChangeMonth(month) {
    setMonthSelected(month);
    let newDate = moment().month(month).year(selectedYear).startOf("month");
    setSelectedMonth(newDate);
    setShowMonth(false);
  }

  function handleChangeYear(year) {
    setSelectedYear(year);
    let newDate = moment()
      .year(year)
      .month(selectedMonth.month())
      .startOf("month");
    setSelectedMonth(newDate);
    setShowYear(false);
  }

  return (
    <div className="w-[396px] h-auto pt-7 pl-8 pr-[30px] pb-[30px] rounded-2xl shadow-outer text-[14px] ">
      {/* header */}
      <div className="mb-4 flex justify-between">
        <div className="flex">
          <ArrowLeft
            onClick={subtractMonth}
            className="mr-[24px] cursor-pointer"
          />
          <p
            className="mr-[24px] text-[18px] font-medium cursor-pointer"
            onClick={() => setOpen(!open)}
          >
            {selectedMonth.format("MMMM YYYY")}
          </p>
          <ArrowRight onClick={addMonth} className="cursor-pointer" />
        </div>

        <Cancel className="cursor-pointer" onClick={handleCancel} />
      </div>

      {isProfile && open && (
        <div className="m-4 p-4 shadow-outer rounded-2xl space-x-10 flex ">
          {/****************************** SELECT MONTH  ******************************/}
          <div className="relative ">
            <div
              className="flex cursor-pointer items-center"
              onClick={() => setShowMonth(!showMonth)}
            >
              <p className="mr-4 ">{monthSelected}</p>
              <img
                src={showMonth ? arrowUp : arrowDown}
                alt="arrow"
                loading="lazy"
              />
            </div>
            {showMonth && (
              <OutsideClickHandler onOutsideClick={() => setShowMonth(false)}>
                <div className=" px-6 py-3 absolute top-8 max-h-60  scrollbar-thin scrollbar-thumb-secondary-700 scrollbar-thumb-rounded bg-[#5f6364] shadow-outer rounded-md ">
                  {month?.map((m) => (
                    <p
                      key={m}
                      className={`mb-2 hover:font-semibold cursor-pointer text-white ${
                        month === monthSelected && "font-semibold"
                      } `}
                      onClick={() => handleChangeMonth(m)}
                    >
                      {m}
                    </p>
                  ))}
                </div>
              </OutsideClickHandler>
            )}
          </div>
          {/****************************** SELECT YEAR  *******************************/}
          <div className="relative ">
            <div
              className="flex cursor-pointer items-center"
              onClick={() => setShowYear(!showYear)}
            >
              <p className="mr-4 ">{selectedYear}</p>
              <img
                src={showYear ? arrowUp : arrowDown}
                alt="arrow"
                loading="lazy"
              />
            </div>
            {showYear && (
              <OutsideClickHandler onOutsideClick={() => setShowYear(false)}>
                <div
                  id="scrollableDiv"
                  className=" px-6 py-3 absolute top-8 max-h-60  scrollbar-thin scrollbar-thumb-secondary-700 scrollbar-thumb-rounded bg-[#5f6364] shadow-outer rounded-md "
                >
                  <InfiniteScroll
                    dataLength={years.length}
                    next={addYears}
                    scrollableTarget="scrollableDiv"
                    hasMore={true}
                  >
                    {years?.map((year) => (
                      <p
                        key={year}
                        className={`mb-2 hover:font-semibold cursor-pointer text-white ${
                          year === selectedYear && "font-semibold"
                        } `}
                        onClick={() => handleChangeYear(year)}
                      >
                        {year}
                      </p>
                    ))}
                  </InfiniteScroll>
                </div>
              </OutsideClickHandler>
            )}
          </div>
        </div>
      )}

      {/* days in grid */}
      <div>
        {/* s m t w t f s grid */}
        <div className="grid grid-cols-7 justify-items-center">
          {daysInWeek.map((day, ind) => {
            return (
              <span
                className="h-[40px] w-[40px] bg-secondary-200 flex items-center justify-center rounded-full"
                key={day + ind}
              >
                {day}
              </span>
            );
          })}
        </div>

        {/* dates grid */}
        <div className="grid grid-cols-7 justify-items-center">
          {getDaysInMonth().map((day) => {
            return (
              <button
                key={day}
                onClick={() => {
                  if (!availableDates) {
                    changeDay(day);
                    return;
                  }
                  const haveDate =
                    availableDates &&
                    availableDates.find(
                      (date) => date === day.format("YYYY-MM-DD")
                    );
                  if (!!haveDate) {
                    changeDay(day);
                  }
                }}
                className={`disabled:bg-gray-400 h-[40px] w-[40px] cursor-pointer flex items-center justify-center rounded-xl ${getClassName(
                  day
                )} ${
                  availableDates &&
                  availableDates.find(
                    (date) => date === day.format("YYYY-MM-DD")
                  )
                    ? ""
                    : "text-secondary-400"
                }`}
              >
                {day.date()}
              </button>
            );
          })}
        </div>
      </div>
      <div className="flex justify-end mr-[2px] text-primary-200 mt-7 text-sm font-medium">
        <p className="underline cursor-pointer" onClick={handleCancel}>
          Done
        </p>
      </div>
    </div>
  );
}

Calendar.propTypes = {
  setIsCalendarOpen: propTypes.func,
  setSelectedDate: propTypes.func,
  closeModal: propTypes.func,
  isProfile: propTypes.bool,
  availableDates: propTypes.arrayOf(string),
};

export default Calendar;
