import React from "react";
import PropTypes from "prop-types";
import CancelSlot from "./CancelSlot";

import Cancelled from "../../../../assets/icons/cancelredbackground.svg";
// import SlotCancelModalTrigger from "../slotCancelled/SlotCancelModalTrigger";
const Trigger = () => {
 
 
  return (
    <div className="flex items-center mr-6">
      <img className="h-5 w-5  " src={Cancelled} alt="Cancelled" loading="lazy"/>
      <p 
        className="ml-2 font-ibm text-base font-medium text-[#ff5757] cursor-pointer"
      >
        Cancel Slot
      </p>
      {/*<SlotCancelModalTrigger />*/}

   
    </div>
  );
};
const CancelSlotTrigger = ({bookingId, refetchBookingData,isMentorDashboard,bookingDate}) => {
  return <CancelSlot Trigger={Trigger} bookingId={bookingId} isMentorDashboard={isMentorDashboard} date={bookingDate} refetchBookingData={refetchBookingData}/>;
};
CancelSlotTrigger.propTypes = {
  bookingId: PropTypes.number,
  refetchBookingData: PropTypes.func,
  isMentorDashboard:PropTypes.bool,
  bookingDate:PropTypes.string
};
export default CancelSlotTrigger;
