import React from "react";
import { ReactComponent as Cancel } from "../../../../assets/icons/cancel.svg";
import WithModal from "../../global/WithModal/WithModal";
import propTypes from "prop-types";
import { useMutation, useQueryClient } from "react-query";
import { endpoint } from "../../../../API/config";
import { prepare_apirequest } from "../../../../API/api";
import { useSelector } from "react-redux";
import { loginDetails } from "../../../../redux/features/login/LoginSlice";
import moment from "moment";
import Loader from "../../global/loader/Loader";

function BookingConfirmationModal({
  closeModal,
  filteredBookedSlots,
  asset,
  onClick,
}) {
  const login = useSelector(loginDetails);
  const queryClient = useQueryClient();

  const bookingInfo = filteredBookedSlots?.find(
    (slot) => slot.is_active === "1"
  );

  const cancelBooking = async () => {
    return prepare_apirequest({
      url: endpoint.cancelBookedSlot.addr,
      method: "post",
      headers: {
        "access-token": login.token,
      },
      data: {
        asset_id: Number(bookingInfo.asset_id),
        slot_booking_id: bookingInfo.slot_booking_id,
      },
    });
  };

  const { mutate, isLoading } = useMutation({
    mutationKey: [endpoint.cancelBookedSlot.key],
    mutationFn: cancelBooking,
    onSuccess: () => {
      alert("Cancelled Successfully.");
      queryClient.invalidateQueries({
        queryKey: ["Booked Slots"],
      });
    },
    onError: () => {
      alert("Cancel Booking Failed.");
    },
  });

  return (
    <div className="flex flex-col w-[806px] py-6 px-8 ">
      <div className="flex justify-between mb-8">
        <p className="font-ibm text-lg text-secondary-600 font-medium">
          Booking Confirmed
        </p>
        <Cancel className="cursor-pointer" onClick={closeModal} />
      </div>

      <div className="flex">
        {/* left section */}
        <div className="w-[348px] max-h-[300px] mr-8">
          <div className="flex flex-col gap-4 h-full">
            {/* grid */}
            <div className="grid grid-cols-2 gap-6 ">
              <div>
                <p className="font-ibm text-sm text-secondary-800 font-normal">
                  Date
                </p>
                {!bookingInfo.is_take_home && (
                  <p className="mt-3 font-ibm text-sm text-secondary-600 font-normal">
                    {moment(bookingInfo.slot_start_time).format("MMM DD, YYYY")}
                  </p>
                )}
                {bookingInfo.is_take_home && (
                  <p className="mt-3 font-ibm text-sm text-secondary-600 font-normal">
                    {moment(asset.assetDetails[0].releaseDate)?.format(
                      "MMM DD, YYYY"
                    )}{" "}
                    -{" "}
                    {moment(asset.assetDetails[0].endDate)?.format(
                      "MMM DD, YYYY"
                    )}
                  </p>
                )}
              </div>
              <div>
                <p className="font-ibm text-sm text-secondary-800 font-normal">
                  Time
                </p>
                {!bookingInfo.is_take_home && (
                  <p className="mt-3 font-ibm text-sm text-secondary-600 font-normal">
                    {moment(
                      bookingInfo.slot_start_time.split(" ")[1],
                      "HH:mm"
                    )?.format("hh:mm A")}{" "}
                    -{" "}
                    {moment(
                      bookingInfo.slot_end_time.split(" ")[1],
                      "HH:mm"
                    )?.format("hh:mm A")}
                  </p>
                )}
                {bookingInfo.is_take_home && (
                  <p className="mt-3 font-ibm text-sm text-secondary-600 font-normal">
                    Anytime during the proctored window.
                  </p>
                )}
              </div>
            </div>

            {/* description */}
            <div>
              <p className="font-ibm text-sm text-secondary-800 font-normal">
                Venue
              </p>
              {!bookingInfo.is_take_home && (
                <p className="mt-3 font-ibm text-sm text-secondary-600 font-normal">
                  {bookingInfo.venue_name} - {bookingInfo.venue_address}
                </p>
              )}
              {bookingInfo.is_take_home && (
                <p className="mt-3 font-ibm text-sm text-secondary-600 font-normal">
                  Take Home
                </p>
              )}
            </div>

            {/* buttons */}
            <div className="flex justify-between mt-auto">
              {filteredBookedSlots?.length < 2 && (
                <button
                  onClick={mutate}
                  className="underline flex-shrink-0 disabled:cursor-default"
                >
                  {isLoading ? <Loader /> : "Cancel Booking"}
                </button>
              )}
              {!bookingInfo.is_take_home &&
                moment(bookingInfo.slot_start_time).isBefore(moment.now()) && (
                  <button
                    onClick={onClick}
                    className="inline-flex justify-center bg-primary-200 py-4 px-8 text-base font-medium text-secondary-100  border border-transparent rounded-2xl focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                  >
                    Launch Test
                  </button>
                )}
              {bookingInfo.is_take_home &&
                moment(asset.assetDetails[0].releaseDate).isBefore(
                  moment.now()
                ) && (
                  <button
                    onClick={onClick}
                    className="inline-flex justify-center bg-primary-200 py-4 px-8 text-base font-medium text-secondary-100  border border-transparent rounded-2xl focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                  >
                    Launch Test
                  </button>
                )}
            </div>
          </div>
        </div>

        {/* right section */}
        <div className="font-ibm text-sm text-secondary-600 font-normal max-w-[362px] px-8 pt-[27px] bg-secondary-200 rounded-2xl ">
          <p className="required">
            Important Instructions
            <sup className="text-red-700 text-lg top-0">*</sup>
          </p>
          {(!bookingInfo.is_take_home &&
            moment(bookingInfo.slot_end_time).isBefore(moment.now())) ||
          (bookingInfo.is_take_home &&
            moment(asset.assetDetails[0].releaseDate).isBefore(
              moment.now()
            )) ? (
            <p className="mt-4 mr-9">
              You are advised to finish the test in a single sitting. In case of any difficulties restart the test.
            </p>
          ) : (
            <div className="py-4 flex flex-col gap-2">
              <p className=" mr-9">
                You will be able to launch test from here during the booked time
                slot.
              </p>
              <p className=" mr-9">
                Cancellation of booking is allowed only once per test.
              </p>
            </div>
          )}

          {/* <p className="text-primary-200 mt-9 font-ibm text-xl underline font-medium">
            {bookingData.map((ele) => (
              <div key={ele.tokenId}>
                <a href={ele.link}>Click here to take {ele.linkText}</a>
              </div>
            ))}
          </p> */}
        </div>
      </div>
    </div>
  );
}

BookingConfirmationModal.propTypes = {
  closeModal: propTypes.func,
  filteredBookedSlots: propTypes.any,
  asset: propTypes.any,
  onClick: propTypes.func,
};

export default WithModal(BookingConfirmationModal);
