import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  studentsData: [],
};

export const AttendanceSlice = createSlice({
  name: "section",
  initialState,
  reducers: {
    setAttendanceData: (state, action) => {
      state.studentsData = action.payload;
    },
    setAttendance: (state, action) => {
      let imspin = action.payload.imspin;
      let attend_status = action.payload.attend_status;
      state.studentsData = state.studentsData.map(item =>{
        if(item.imspin == imspin){
          return {...item,attend_status:attend_status};
        }
        return item;
      });
    },
  },
});

export const { setAttendance, setAttendanceData } = AttendanceSlice.actions;

export default AttendanceSlice.reducer;
