import React, { useState, useEffect } from "react";
import { Tab } from "@headlessui/react";
import SectionalTestHeader from "../../Test/SectionalTest/SectionalTestHeader";
import Topic from "../../global/accordian/Topic";
import moment from "moment";
import propTypes from "prop-types";
import { apirequest, prepare_apirequest } from "../../../../API/api";
import { useQuery } from "react-query";
import { endpoint } from "../../../../API/config";
import { useSelector } from "react-redux";
import {
  getVariantId,
  loginDetails,
} from "../../../../redux/features/login/LoginSlice";
import Loader from "../../global/loader/Loader";

const ProtectedTest = ({ data, parentId, testModule }) => {
  const login = useSelector(loginDetails);
  const variantId = useSelector(getVariantId);
  const tabOptions = [
    { id: 0, name: "All" },
    { id: 1, name: "Upcoming" },
    { id: 2, name: "Completed" },
  ];
  const [selectedIndex, setselectedIndex] = useState(0);
  const [filteredData, setFilteredData] = useState(data);
  const tests = data?.filter(
    (test) =>
      (test?.status?.toLowerCase() === "completed" &&
        moment(test.assetDetails[0].endDate).isBefore(moment.now())) ||
      (test.assetDetails[0].proctored === "0" &&
        test?.status?.toLowerCase() === "completed")
  );
  // ?.map((test) => `${test?.assetDetails[0].assessmentId}`)
  // .join(",");

  const getFilterData = (tests, index) => {
    let filteredOptions = tests.filter((assessment) => {
      if (index !== 0) {
        if (index === 1) {
          if (
            moment()
              .startOf("day")
              .isBefore(
                moment(assessment.assetDetails[0].releaseDate).startOf("day")
              )
          ) {
            return assessment;
          }
        } else {
          if (assessment?.status?.toLowerCase() == "completed") {
            return assessment;
          }
        }
      } else {
        return assessment;
      }
    });
    console.log(filteredOptions, index);
    return filteredOptions;
  };

  useEffect(() => {
    const filteredOptions = getFilterData(data, selectedIndex);
    setFilteredData([...filteredOptions]);
  }, [selectedIndex, data]);

  const getVariantDetails = () => {
    return apirequest({
      url: `${endpoint.getVariantDetails.addr}`,
      method: "post",
      headers: {
        "access-token": login.token,
      },
      data: {
        adminimspin: login.student_imspin,
        variantId: variantId,
      },
    });
  };

  const proctoredTestId = [];

  filteredData &&
    filteredData.length !== 0 &&
    data.map((asset) => {
      if (
        !!asset.assetDetails[0].releaseDate &&
        !!asset.assetDetails[0].endDate &&
        asset.assetDetails[0].proctored === "1"
      ) {
        if (
          moment(asset.assetDetails[0].slotStartDate).isBefore(moment.now()) &&
          moment(asset.assetDetails[0].endDate + " " + "23:59:59").isAfter(
            moment.now()
          )
        ) {
          proctoredTestId.push(asset.assetId);
        }
      }
    });

  const getBookedSlots = (proctoredTestId) => {
    return prepare_apirequest({
      url: `${endpoint.getBookedSlotsUrl.addr}?asset_ids=${proctoredTestId.join(
        ","
      )}`,
      method: "get",
      headers: {
        "access-token": login.token,
      },
    });
  };

  const { data: bookedSlots, isLoading } = useQuery({
    queryKey: ["Booked Slots", proctoredTestId],
    queryFn: () => getBookedSlots(proctoredTestId),
    select: (data) => data.data.data,
    enabled: !!proctoredTestId.length > 0,
  });

  const {
    data: variantDetails,
    isLoading: isDetailsLoading,
    isError: isDetailsError,
  } = useQuery({
    queryKey: [endpoint.getVariantDetails.key],
    queryFn: getVariantDetails,
  });
  if (isDetailsLoading || isLoading) {
    return <Loader />;
  }
  if (isDetailsError) {
    return (
      <h3 className="text-red-500 font-semibold text-lg">
        Error getting variant details
      </h3>
    );
  }

  return (
    <div className=" font-ibm">
      <div className="flex flex-col w-full my-12">
        <Tab.Group selectedIndex={selectedIndex}>
          <Tab.List className="flex flex-row space-between items-center space-x-3 text">
            {tabOptions.map(
              (item) =>
                getFilterData(data, item.id).length !== 0 && (
                  <div key={item.id} onClick={() => setselectedIndex(item.id)}>
                    <Tab
                      className={() =>
                        ` ${
                          selectedIndex === item.id
                            ? "text-secondary-100 bg-primary-300 font-medium"
                            : "text-secondary-600"
                        } font-ibm font-normal text-md shadow-outer rounded-2xl px-6 py-4`
                      }
                    >
                      {item.name}
                    </Tab>
                  </div>
                )
            )}
            <SectionalTestHeader information={false} tests={tests} />
          </Tab.List>
          <div className="mt-8 shadow-lg px-8 pt-9 pb-14 rounded-2xl">
            <Tab.Panels>
              {tabOptions.map((tabOption) => (
                <Tab.Panel key={tabOption.id}>
                  {filteredData.length < 1 ? (
                    <p className="flex items-center justify-center">
                      No records found!
                    </p>
                  ) : (
                    filteredData.map((item) => (
                      <Topic
                        testModule={testModule}
                        bookedSlots={bookedSlots}
                        variantDetails={variantDetails}
                        key={item.assetId}
                        asset={item}
                        parentId={parentId}
                        clearPrepareSlicePlaylist={true}
                      />
                    ))
                  )}
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </div>
        </Tab.Group>
      </div>
    </div>
  );
};

ProtectedTest.propTypes = {
  data: propTypes.array,
  parentId: propTypes.string,
  testModule: propTypes.bool,
};
export default ProtectedTest;
