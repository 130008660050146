import React from "react";
import ProfileHeader from "../ProfileHeader";
// import PreloginDropDown from "../PreloginDropDown";
import propTypes from "prop-types";
import AddIcon from "../../../../assets/icons/add.svg";

import { useSelector, useDispatch } from "react-redux";
import {
  addNewObject,
  handleArrayChange,
  setProfileDetails
} from "../../../../redux/features/prelogin/preloginSlice";

import { useQuery } from "react-query";
import { loginAPI_apirequest } from "../../../../API/api";
import { endpoint } from "../../../../API/config.jsx";
import SimpleDropDown from "../SimpleDropDown";
import DeleteIcon from "../../../../assets/icons/delete.svg";

import { useFormik } from "formik";
import * as Yup from "yup";


const ExamAttempt = ({ nextStep, prevStep }) => {
  const fetchExamNameDropDown = () => {
    return loginAPI_apirequest({
      url: `${endpoint.getExamName.addr}`,
      method: "get",
    });
  };

  const { data: examNameDropDown } = useQuery(
    `${endpoint.getExamName.key}`,
    fetchExamNameDropDown
  );

  const attemptDetails = useSelector((state) => state.prelogin.profileDetails);

  const dispatch = useDispatch();

  const handleInputData = (e, id, arrayName, attrName) => {
    let payload = {
      value: e.target.value,
      id,
      arrayName,
      attrName,
    };
    dispatch(handleArrayChange(payload));
  };

  const initialValues = {
    Expected_Actual_Yr_of_completion:
      attemptDetails?.examAttempts?.Expected_Actual_Yr_of_completion || "",
    percentile: attemptDetails?.examAttempts?.percentile || "",  
  };

  //yup validation
  const validationSchema = Yup.object({
    Expected_Actual_Yr_of_completion: Yup.string().matches(
      /^[0-9]{4}$/,
      "Year should be of 4 digits."
    ),
    percentile: Yup.number()
      .typeError("Enter a number")
      .min(0.01,  "Enter min. percentile should at least 0.01")
      .max(100, "Percentile should be at most 100%")
  });

  //implement formik
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      nextStep();
    },
  });

  const handleDropdownchange = (dropdownName, id, arrayName, attrName) => {
    let payload = {
      value: dropdownName,
      id,
      arrayName,
      attrName,
    };
    dispatch(handleArrayChange(payload));
  };

  const addFields = () => {
    let newObject = {
      examId: "",
      examName: "",
      percentile: "",
      Expected_Actual_Yr_of_completion: "",
      // Added missing isDelete key with empty string value 
      isDelete: ""
    };
    let payload = {
      obj: newObject,
      arrayName: "examAttempts",
    };
    dispatch(addNewObject(payload));
  };

  const handleDeleteExamAttempts = (id,attrName,attrValue,objInd) => {
    let formObj = JSON.parse(JSON.stringify(attemptDetails));
    let {examAttempts} = formObj ;

    if(!id){
      let updatedObj = formObj.examAttempts.filter((item,ind)=>ind != objInd);

      dispatch(setProfileDetails({ ...formObj,examAttempts:updatedObj }));
    }
    else{
      let newObjInd = examAttempts.findIndex((obj) => {
        if (obj.examId == id) {
          return obj;
        }
      });
      formObj.examAttempts[newObjInd].isDelete = attrValue;
      dispatch(setProfileDetails({ ...formObj }));
    }
    
  };
  const inputStyles =
    "border-2 text-sm border-gray-200 rounded-2xl py-3 px-3 w-full focus:outline-none";
  return (
    <div className="font-ibm">
      <ProfileHeader
        title="How many times have you attempted the CAT examination ?"
        backIcon={true}
        prevStep={prevStep}
      />

      {/* work Experience */}
      <section className="grid grid-cols-1 place-content-center justify-items-center">
        <div className="w-[60%]">
          <form onSubmit={formik.handleSubmit}>
            <div className=" shadow-outer rounded-2xl grid grid-cols-1 justify-items-center py-12 px-20">
              <div className="grid grid-cols-1 space-y-3 mb-10 w-full">
                <h2 className=" font-medium text-lg mb-5 ">Enter Details</h2>
                {attemptDetails.examAttempts?.slice(0,1)?.map((form, index) => (
                  <div key={index} className="space-y-3">
                    <div className="my-5">
                      {/* Fetching "examName" value, if user has selected from the dropdown options */}
                      <div className="flex space-x-2 w-full relative">
                        <div className="w-full">
                          <SimpleDropDown value={form?.examName || "Exam"}>
                            <SimpleDropDown.Options>
                              {examNameDropDown?.data?.data.map((option) => (
                                <SimpleDropDown.Option
                                  key={option.examId}
                                  option={option.examName}
                                  setValue={() =>
                                    handleDropdownchange(
                                      option.examName,
                                      index,
                                      "examAttempts",
                                      "examName"
                                    )
                                  }
                                />
                              ))}
                            </SimpleDropDown.Options>
                          </SimpleDropDown>
                        </div>
                      </div>
                    </div>

                    <input
                      className={inputStyles}
                      autoComplete="off"
                      placeholder="Year of latest attempt"
                      name="Expected_Actual_Yr_of_completion"
                      value={form.Expected_Actual_Yr_of_completion}
                      onChange={(event) => {
                        formik.handleChange(event);
                        formik.values.Expected_Actual_Yr_of_completion =
                          event.target.value;
                        handleInputData(
                          event,
                          index,
                          "examAttempts",
                          "Expected_Actual_Yr_of_completion"
                        );
                      }}
                      maxLength={4}
                    />
                    {Boolean(
                      formik.errors.Expected_Actual_Yr_of_completion
                    ) && (
                      <span className="text-red-400">
                        {formik.errors.Expected_Actual_Yr_of_completion}
                      </span>
                    )}
                    <input
                      className={inputStyles}
                      autoComplete="off"
                      placeholder="Percentile"
                      name="percentile"
                      value={form.percentile ? form.percentile : ""}
                      onChange={(event) => {
                        formik.handleChange(event);
                        formik.values.percentile =
                          event.target.value;
                        handleInputData(
                          event,
                          index,
                          "examAttempts",
                          "percentile"
                        );
                      }}
                    />
                    {Boolean(formik.errors.percentile) && (
                      <span className="text-red-400">
                        {formik.errors.percentile}
                      </span>
                    )}
                  </div>
                ))}
                {attemptDetails.examAttempts?.slice(1,).map((form, index) => (
                  form.isDelete!=="1" && <div key={index+1} className="space-y-3">
                    <div className="my-5">
                      {/* Fetching "examName" value, if user has selected from the dropdown options */}
                      <div className="flex space-x-2 w-full relative">
                        <div className="w-full">
                          <SimpleDropDown value={form?.examName || "Exam"}>
                            <SimpleDropDown.Options>
                              {examNameDropDown?.data?.data.map((option) => (
                                <SimpleDropDown.Option
                                  key={option.examId}
                                  option={option.examName}
                                  setValue={() =>
                                    handleDropdownchange(
                                      option.examName,
                                      index+1,
                                      "examAttempts",
                                      "examName"
                                    )
                                  }
                                />
                              ))}
                            </SimpleDropDown.Options>
                          </SimpleDropDown>
                        </div>
                        <div 
                          onClick={()=>{handleDeleteExamAttempts(form?.examId,"isDelete","1",index+1);}}
                          className="w-12 h-12 cursor-pointer rounded-full shadow-outer flex justify-center items-center absolute -right-14">
                          <img className="w-5 h-5" src={DeleteIcon} alt="del"/>
                        </div>
                      </div>
                    </div>

                    <input
                      className={inputStyles}
                      autoComplete="off"
                      placeholder="Year of latest attempt"
                      name="Expected_Actual_Yr_of_completion"
                      value={form.Expected_Actual_Yr_of_completion}
                      onChange={(event) => {
                        formik.handleChange(event);
                        formik.values.Expected_Actual_Yr_of_completion =
                          event.target.value;
                        handleInputData(
                          event,
                          index+1,
                          "examAttempts",
                          "Expected_Actual_Yr_of_completion"
                        );
                      }}
                      maxLength={4}
                    />
                    {Boolean(
                      formik.errors.Expected_Actual_Yr_of_completion
                    ) && (
                      <span className="text-red-400">
                        {formik.errors.Expected_Actual_Yr_of_completion}
                      </span>
                    )}
                    <input
                      className={inputStyles}
                      autoComplete="off"
                      placeholder="Percentile"
                      name="percentile"
                      value={form.percentile ? form.percentile : ""}
                      onChange={(event) => {
                        formik.handleChange(event);
                        formik.values.percentile =
                          event.target.value;
                        handleInputData(
                          event,
                          index+1,
                          "examAttempts",
                          "percentile"
                        );
                      }}
                    />
                    {Boolean(formik.errors.percentile) && (
                      <span className="text-red-400">
                        {formik.errors.percentile}
                      </span>
                    )}
                  </div>
                ))}
                <div className="flex">
                  <img
                    src={AddIcon}
                    className="cursor-pointer mt-5"
                    alt="add"
                    onClick={addFields}
                    loading="lazy"
                  />
                  <p className="font-medium text-lg mt-8 px-2">Add attempt</p>
                </div>
              </div>
            </div>

            <button
            //  initial submit as button type to remove the warning
              type="submit"
              className="bg-primary-200 text-lg font-medium px-10 py-3 rounded-xl text-white  float-right my-10"
              // onClick={() => {
              //   nextStep();
              // }}
            >
              Next
            </button>
          </form>
        </div>
      </section>
    </div>
  );
};


ExamAttempt.propTypes = {
  nextStep:propTypes.func,
  prevStep:propTypes.func,
};

export default ExamAttempt;
