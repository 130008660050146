import React from "react";
import Image from "../../../../assets/images/Image.png";
// import EligibleCriteriaPage from "./EligibileCriteriaPage";
// import banner from "../../../../assets/images/adv.svg";
import Header from "../../header/Header";
import Footer from "../../global/footer/Footer";
import { useQuery } from "react-query";
import { apirequest_schoolsAPI } from "../../../../API/api";
import { endpoint } from "../../../../API/config";
import { useSelector } from "react-redux";
import { loginDetails } from "../../../../redux/features/login/LoginSlice";
import Loader from "../../global/loader/Loader";
import Advertisement from "../../home/cards/advertisement/Advertisement";

export const EligibleCriteria = () => {
  const LoginDetails = useSelector(loginDetails);

  const courseID = useSelector(state => state.login.courseId);

  const advertisementData = useSelector(state=>state.advertisement.advertisementData);

  const images = [
    { id: 1, img: Image },
    { id: 2, img: Image },
    { id: 3, img: Image },
    { id: 4, img: Image },
    { id: 5, img: Image },
  ];

  //Request to fetch live classes API
  const fetchEligibility =  () => {
    return apirequest_schoolsAPI({
      url: `${endpoint.getIMSEligibilityCriteria.addr}`,
      method: "post",

      headers: {
        "access-token": LoginDetails.token,
      },
      data: {
        courseId: courseID.toString(),
      },
    });
  };

  // API for live class api data
  const { data: eligibilityData, isSuccess: eligibilitySuccess , isLoading , isError} = useQuery(
    `${endpoint.getIMSEligibilityCriteria.key}`,
    fetchEligibility,{
      select : (res)=>res.data.data[0]
    }
  );
  
  function convertKeysToSmallerCase(objectFromMap){
    const lowercaseKeysObject = Object.fromEntries(
      Object.entries(objectFromMap).map(([key, value]) => [key.toLowerCase(), value])
    );
    console.log(lowercaseKeysObject , "Object");
    return lowercaseKeysObject ;
  }
  return (
    <div className="">
      <Header />
      <Advertisement width={"373px"} height={"195px"} slidesPerView={5} data={advertisementData?.eligibilitycriteria?.smallbanner}/>

      {isLoading && <Loader />}
      {isError && <p className="text-red-600 text-base font-medium flex justify-between"> No Record found</p>}
      <>
        <div className="px-16">
          <div className="flex space-x-4">
            {images.map(item => (
              <img
                src={item.img}
                alt="img"
                className="w-[19%] rounded-2xl mt-10"
                key={item.id}
              />
            ))}
          </div>
        </div>
        {/* Table body completed */}
        {eligibilitySuccess &&<div className="m-8 shadow-outer rounded-2xl">

          <div className=" flex items-center text-center px-8 py-6 shadow-outer rounded-t-2xl">
            {eligibilityData?.parametersToBeDisplayed?.map(item=>(
              <p key={item} className="flex-1 font-medium text-secondary-600 text-lg">{item}</p>
            ))} 
          </div>
          {/* Table body */}
          <div className="shadow-outer rounded-b-2xl">  
            {eligibilityData?.csvFile?.map((item ,index)=>(
              <div className="flex flex-col" key={index} >
                <div key={index} className="flex justify-items-start px-4 py-2">  
                  {eligibilityData?.parametersToBeDisplayed.map(val=>(
                    <p className="px-4 py-2 text-center flex-1 font-medium text-secondary-600 text-lg" key={val}>
                      {convertKeysToSmallerCase(item)[val.toLowerCase()]}
                    </p>
                  ))}
                </div>
                {advertisementData?.eligibilitycriteria?.bigbanner?.length>0 && 
                  <>
                    {index ==2 && <div className="w-auto m-6 rounded-xl "> <iframe src={`Advertisement.html?id=${advertisementData?.eligibilitycriteria?.bigbanner[0]}`}></iframe></div>}
                    {index ==5 && <div className="w-auto m-6 rounded-xl "> <iframe src={`Advertisement.html?id=${advertisementData?.eligibilitycriteria?.bigbanner[1]}`}></iframe></div>}
                  </>
                }
              </div>
            ))}
          </div>
        </div>}
      </>
      <Footer />
    </div>
  );
};

export default EligibleCriteria;
