import React from "react";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";

const Tooltip = ({ id, place, text, offset, multiline }) => {
  return (
    <div>
      <ReactTooltip id={id} place={place} offset={offset} multiline={multiline}>
        {text}
      </ReactTooltip>
    </div>
  );
};

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  place: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  offset: PropTypes.oneOfType([PropTypes.object,PropTypes.string]),
  multiline: PropTypes.bool.isRequired,
};

export default Tooltip;
