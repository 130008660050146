import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import SearchIcon from "../../../../assets/icons/search-new.svg";
import { ReactComponent as ClearIcon } from "../../../../assets/icons/cancel.svg";
import PropTypes from "prop-types";

function Search({ schoolList, isShadow = true,setIsClicked, setSchoolId,setProgramId}) {
  
  const placeholder = "Search by College";
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const [showInput, setShowInput] = useState(true);
  const [showDropdown, setShowDropdown] = useState(true);
  
  const handleFilter = event => {
    setShowDropdown(true);
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    const newFilter = schoolList?.filter(value => {
      return value.schoolName.toLowerCase().includes(searchWord.toLowerCase());
    });
    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
    
  };
  const outSideClickHandler = () => {
    setShowInput(false);
    setShowDropdown(false);
  };
  const clearTextHandler = () => {
    setWordEntered("");
    setShowDropdown(false);
  };

  const handleClick= (schoolId,programId)=>{
    setSchoolId(schoolId);
    setProgramId(programId);
    setIsClicked(false);
  };
  
  return (
    <OutsideClickHandler onOutsideClick={outSideClickHandler}>
      <div>
        <div
          className={`${showInput ? "min-w-[18rem] " : ""} rounded-2xl ${
            isShadow ? "shadow-outer" : ""
          } flex p-[1rem] relative bg-white `}>
          {showInput && (
            <div className={`${showInput ? "w-full" : ""} flex items-center pr-5 `}>
              <input
                type="text"
                name="search"
                placeholder={placeholder}
                className="w-full text-secondary-600 font-normal font-ibm focus:outline-none"
                onChange={handleFilter}
                value={wordEntered}
                autoComplete="off"
              />

              <ClearIcon
                className="cursor-pointer"
                onClick={clearTextHandler}
              />
            </div>
          )}
          <div className="h-6 w-6 flex items-center justify-center ">
            <img
              src={SearchIcon}
              className="cursor-pointer h-5 w-5 shrink-0 "
              alt="search"
              onClick={() => setShowInput(!showInput)}
              loading="lazy"
            />
          </div>
        </div>
        {filteredData?.length != 0 && showDropdown &&<div className="bg-white z-50 shadow-md mt-5 rounded-2xl pl-[1rem] pt-[1.75rem] max-h-[15rem] overflow-y-scroll scrollbar-thumb-secondary-700 scrollbar-thin scrollbar-thumb-rounded absolute w-full max-w-[18rem]">
          <div className="my-2 flex space-x-1 text-sm font-normal  ">
            <span className="text-red-600">*</span>
            <p>Choose upto 5 colleges to compare</p>
          </div>
          {filteredData?.length != 0 && showDropdown && filteredData?.map((school,ind) => (
            <div key={ind} className="">
              {school.schoolProgramData?.map((program) => (
                <div className="flex -mt-3" key={program.schoolProgramCourseMapping} onClick={()=>handleClick(school.schoolId,program.schoolProgramCourseMapping)}>
                  <p className="mb-5 mt-5 pl-4 hover:font-semibold" >
                    {school.schoolName}-{program.programs}
                  </p>
                </div>
              ))}
            </div>)
          )}
        </div>}
      </div>
    </OutsideClickHandler>
  );
}
Search.propTypes = {
  isShadow: PropTypes.bool,
  setCollegeDetails: PropTypes.func,
  setIsClicked: PropTypes.func,
  schoolList: PropTypes.array,
  setSchoolId:PropTypes.func,
  setProgramId:PropTypes.func,
};
export default Search;
