import React from "react";
import Class from "./Class";
import propTypes from "prop-types";

function Classes({ classData, type, marginBottom }) {
  return (
    <div className={`${marginBottom && "mb-16"}`}>
      <div className="flex justify-between items-center mb-6 ">
        <h1>{type}</h1>
        <p className="italic font-medium text-base">
          {classData.length}
          {classData.length < 2 ? " Class" : " Classes"}
        </p>
      </div>
      {classData.map((mc, i) => (
        <Class
          key={mc.id}
          className={mc.classTitle}
          date={mc.classDate}
          tag={mc.classNumber}
          index={(i + 1) % 3}
        />
      ))}
    </div>
  );
}

Classes.propTypes = {
  classData: propTypes.array,
  type: propTypes.string,
  marginBottom: propTypes.bool,
};
export default Classes;
