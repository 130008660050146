import React from "react";
import propTypes from "prop-types";
import ProfileHeader from "../ProfileHeader";
import { handleArrayChange } from "../../../../redux/features/prelogin/preloginSlice";
import { useDispatch, useSelector } from "react-redux";

import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";

const ProfileSlideforFamily = ({ nextStep, prevStep }) => {
  const dispatch = useDispatch();
  const ParentDetails = useSelector((state) => state.prelogin.profileDetails);
  const handleInputData = (e, id, arrayName, attrName) => {
    let payload = {
      value: e.target.value,
      id,
      arrayName,
      attrName,
    };
    dispatch(handleArrayChange(payload));
  };

  const validationSchema = Yup.object({
    parentDetails: Yup.array().of(
      Yup.object().shape({
        Parent_Guardian_FirstName: Yup.string()
          .required("Guardian name is required")
          .matches(
            /^[a-zA-Z0-9\s]*$/,
            "* This field cannot contain special character"
          ),
        Parent_Guardian_Relation: Yup.string()
          .required("Relation is required")
          .max(50),
        Parent_Guardian_Mobile_No: Yup.string()
          .required("Phone Number is required")
          .matches(
            /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
            "Phone number is not valid"
          ),
        Parent_Guardian_Email_ID: Yup.string()
          .matches(/\S+@\S+\.\S+/, "Enter valid mail id")
          .required("Email Id is required")
          .max(50),
      })
    ),
  });

  return (
    <>
      <div className="font-ibm">
        <ProfileHeader
          title="Tell us a little about your family"
          backIcon={true}
          prevStep={prevStep}
        />
        <section className="grid grid-cols-1 place-content-center justify-items-center">
          <div className="w-[60%]">
            <Formik
              initialValues={{
                parentDetails: ParentDetails.familyDetails,
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                console.log(values);
                // setSubmitting(false);
                nextStep();
              }}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => {
                return (
                  <>
                    <form onSubmit={handleSubmit}>
                      <div className=" shadow-outer rounded-2xl grid grid-cols-1 justify-items-center p-16 w-full h-[454px] pb-16">
                        <div className="w-[50%] ">
                          <div className="grid grid-cols-1 ">
                            <h2 className=" font-medium text-lg mb-5 ">
                              Enter Details
                            </h2>

                            {values?.parentDetails.map((item, index) => (
                              <div
                                className=" space-y-2 flex flex-col"
                                key={item.Parent_Guardian_Id}
                              >
                                <input
                                  autoComplete="off"
                                  name={`parentDetails[${index}].Parent_Guardian_FirstName`}
                                  className="border-2 text-sm border-gray-200 rounded-2xl py-3 px-3  focus:outline-none"
                                  value={item.Parent_Guardian_FirstName}
                                  type="text"
                                  placeholder="Enter guardian name"
                                  onBlur={handleBlur}
                                  onChange={(event) => {
                                    handleInputData(
                                      event,
                                      index,
                                      "familyDetails",
                                      "Parent_Guardian_FirstName"
                                    );
                                    handleChange(event);
                                  }}
                                />
                                <p className="text-red-500">
                                  <ErrorMessage
                                    name={`parentDetails.${index}.Parent_Guardian_FirstName`}
                                  />
                                </p>

                                <input
                                  autoComplete="off"
                                  className="border-2 text-sm border-gray-200 rounded-2xl py-3 px-3  focus:outline-none"
                                  type="text"
                                  placeholder="Mobile number"
                                  name={`parentDetails[${index}].Parent_Guardian_Mobile_No`}
                                  value={item.Parent_Guardian_Mobile_No}
                                  onChange={(event) => {
                                    handleInputData(
                                      event,
                                      index,
                                      "familyDetails",
                                      "Parent_Guardian_Mobile_No"
                                    );
                                    handleChange(event);
                                  }}
                                  maxLength={10}
                                />
                                <p className="text-red-500">
                                  <ErrorMessage
                                    name={`parentDetails.${index}.Parent_Guardian_Mobile_No`}
                                  />
                                </p>
                                <input
                                  autoComplete="off"
                                  className="border-2 text-sm border-gray-200 rounded-2xl py-3 px-3  focus:outline-none"
                                  type="text"
                                  placeholder="Enter Relationship"
                                  value={item.Parent_Guardian_Relation}
                                  name={`parentDetails[${index}].Parent_Guardian_Relation`}
                                  onChange={(event) => {
                                    handleInputData(
                                      event,
                                      index,
                                      "familyDetails",
                                      "Parent_Guardian_Relation"
                                    );
                                    handleChange(event);
                                  }}
                                />
                                <p className="text-red-500">
                                  <ErrorMessage
                                    name={`parentDetails.${index}.Parent_Guardian_Relation`}
                                  />
                                </p>
                                <input
                                  autoComplete="off"
                                  className="border-2 text-sm border-gray-200 rounded-2xl py-3 px-3  focus:outline-none"
                                  type="text"
                                  placeholder="Email Id"
                                  name={`parentDetails[${index}].Parent_Guardian_Email_ID`}
                                  value={item.Parent_Guardian_Email_ID}
                                  onChange={(event) => {
                                    handleInputData(
                                      event,
                                      index,
                                      "familyDetails",
                                      "Parent_Guardian_Email_ID"
                                    );
                                    handleChange(event);
                                  }}
                                />
                                <p className="text-red-500">
                                  <ErrorMessage
                                    name={`parentDetails.${index}.Parent_Guardian_Email_ID`}
                                  />
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="px-10 py-3 text-lg font-medium shadow-outer rounded-2xl bg-primary-200 float-right mt-8 mr-4 text-white cursor-pointer"
                      >
                        Next
                      </button>
                    </form>
                  </>
                );
              }}
            </Formik>
          </div>
        </section>
      </div>
    </>
  );
};

ProfileSlideforFamily.propTypes = {
  nextStep: propTypes.func,
  prevStep: propTypes.func,
};
export default ProfileSlideforFamily;
