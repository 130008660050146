import React from "react";
import { hours } from "./utils";
import propTypes from "prop-types";

export function EmptyHour({ height }) {
  return (
    <div
      style={{ height: height }}
      className="l mr-4 text-white select-none min-h-[82px] ">
      00:00
    </div>
  );
}

export function HoursArray() {
  return (
    <div className=" mr-4  min-h-[82px]">
      {hours.map(hr => (
        <div key={hr} className="min-h-[82px]">
          {hr}
        </div>
      ))}
    </div>
  );
}

EmptyHour.propTypes = {
  week_view: propTypes.bool,
  height: propTypes.string,
};

HoursArray.propTypes = {
  week_view: propTypes.bool,
};
