import React, { useState, Fragment } from "react";
import Counter from "../../web/myPage/enterOtp/Counter";
import { ReactComponent as Cancel } from "../../../assets/icons/cancel.svg";
// import WithModal from "../../global/WithModal/WithModal";
import propTypes from "prop-types";
import { loginAPI_apirequest } from "../../../API/api";
import { useQuery } from "react-query";
import { endpoint } from "../../../API/config.jsx";
import { loginDetails } from "../../../redux/features/login/LoginSlice";
import { useSelector } from "react-redux";
import { Dialog,Transition } from "@headlessui/react";
import { useEffect } from "react";

const OtpModal = ({
  setIsgetOTPClicked,
  mobileNumber,
  verificationDetails,
  setShowModal,
  showModal,
  generateNewOtp
}) => {
  const LoginDetails = useSelector(loginDetails);

  const [isButtonClicked, setIsButtonClicked] = React.useState(false);

  const [otp, setOtp] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
  });

  const [disableVerifyButton , setDisableVerifyButton] = useState( true) ;

  // To disable / enable the verify Otp button ====>
  useEffect(()=>{
    Object.values(otp).includes("") ? setDisableVerifyButton(true) : setDisableVerifyButton(false);
  },[JSON.stringify(otp)]);

  const handleChange = (value1, event) => {
    setOtp((prevState) => ({ ...prevState, [value1]: event.target.value }));
  };

  const inputfocus = (el) => {
    if (el.key === "Delete" || el.key === "Backspace") {
      const next = el.target.tabIndex - 2;
      if (next > -1) {
        el.target.form.elements[next].focus();
      }
    } else {
      const next = el.target.tabIndex;
      if (next < 6) {
        el.target.form.elements[next].focus();
      }
    }
  };
  const convertOtpArr = () => {
    let init = "";
    Object.keys(otp).map((key) => {
      init = init + otp[key];
    });
    return init;
  };
  const getOtpVerification = () => {
    return loginAPI_apirequest({
      url: `${endpoint.newloginOtpVerification.addr}`,
      method: "post",

      headers: {
        "access-token": LoginDetails.token,
      },
      data: {
        studentEmail: verificationDetails.emailId,
        studentImsPin: verificationDetails.studentImsPin,
        otp: convertOtpArr(),
      },
    });
  };
  const {isError } = useQuery([endpoint.newloginOtpVerification.key], getOtpVerification, {
    enabled: !!isButtonClicked,
    retry: false,
    onSuccess: () => {
      setIsgetOTPClicked(true);
    },
    onError : ()=>setDisableVerifyButton(false)
  });

  const last4Digits = mobileNumber?.toString().slice(-4);
  const maskedNumber = last4Digits?.padStart(mobileNumber?.length, "*");

  return (
    <div className="min-h-screen px-4 text-center">
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Dialog.Overlay className={`fixed inset-0 bg-black ${showModal ? "opacity-80" : "opacity-0"}`} />
      </Transition.Child>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
          <Dialog.Panel>
            <div className="flex flex-col w-[396px] py-6 px-8 ">
              <Dialog.Title className="flex justify-between mb-5">
                <p className="font-ibm text-lg text-secondary-600 font-medium">
                  Login to myIMS
                </p>
                <Cancel className="cursor-pointer" onClick={()=>setShowModal(false)}/>
              </Dialog.Title>

              {/* description */}
              <Dialog.Description className="w-[348px] max-h-[300px] mr-8 flex flex-col justify-between h-full">
                <p className="font-ibm text-base font-normal text-black">
                  A text message containing a 6 digit OTP has been sent to your
                  registered mobile number {maskedNumber} and email Id
                </p>
              </Dialog.Description>

              {/* otp input */}
              <form>
                <div className="space-x-2 my-2 font-ibm">
                  <input
                    name="otp1"
                    type="text"
                    className="border border-gray-300 w-12 h-12 rounded-xl outline-none text-center text-2xl font-medium  text-secondary-600 "
                    value={otp.otp1}
                    onChange={(e) => handleChange("otp1", e)}
                    tabIndex="1"
                    maxLength={1}
                    onKeyUp={(e) => inputfocus(e)}
                    autoComplete={false}
                  />
                  <input
                    name="otp2"
                    type="text"
                    className="border border-gray-300 w-12 h-12 rounded-xl outline-none text-center text-2xl font-medium text-secondary-600 "
                    value={otp.otp2}
                    onChange={(e) => handleChange("otp2", e)}
                    tabIndex="2"
                    maxLength={1}
                    onKeyUp={(e) => inputfocus(e)}
                    autoComplete={false}
                  />
                  <input
                    name="otp3"
                    type="text"
                    className="border border-gray-300 w-12 h-12 rounded-xl outline-none text-center text-2xl font-medium text-secondary-600 "
                    value={otp.otp3}
                    onChange={(e) => handleChange("otp3", e)}
                    tabIndex="3"
                    maxLength={1}
                    onKeyUp={(e) => inputfocus(e)}
                    autoComplete={false}
                  />
                  <input
                    name="otp4"
                    type="text"
                    className="border border-gray-300 w-12 h-12 rounded-xl outline-none text-center text-2xl font-medium text-secondary-600 "
                    value={otp.otp4}
                    onChange={(e) => handleChange("otp4", e)}
                    tabIndex="4"
                    maxLength={1}
                    onKeyUp={(e) => inputfocus(e)}
                    autoComplete={false}
                  />
                  <input
                    name="otp5"
                    type="text"
                    className="border border-gray-300 w-12 h-12 rounded-xl outline-none text-center text-2xl font-medium text-secondary-600 "
                    value={otp.otp5}
                    onChange={(e) => handleChange("otp5", e)}
                    tabIndex="5"
                    maxLength={1}
                    onKeyUp={(e) => inputfocus(e)}
                    autoComplete={false}
                  />
                  <input
                    name="otp6"
                    type="text"
                    className="border border-gray-300 w-12 h-12 rounded-xl outline-none text-center text-2xl font-medium text-secondary-600 "
                    value={otp.otp6}
                    onChange={(e) => handleChange("otp6", e)}
                    tabIndex="6"
                    maxLength={1}
                    onKeyUp={(e) => inputfocus(e)}
                    autoComplete={false}
                  />
                </div>
              </form>

              {/* Error message when OTP incorrect enters */}
              {isError && <p className="text-red-500 text-sm  font-medium ">Incorrect Otp</p>}

              {/* Resend OTP */}
              <Counter 
                disableVerifyOtpButton={setDisableVerifyButton} 
                refetchOTP={generateNewOtp} 
              />

              
              {/* verify otp button */}
              <div className="flex justify-between mt-5">
                <button
                  onClick={() => {
                    setIsButtonClicked(true);
                  }}
                  disabled={disableVerifyButton}
                  className={`px-32 py-4 justify-center bg-primary-200 text-base font-medium text-secondary-100 rounded-2xl focus:outline-none focus-visible:ring-offset-2 focus-visible:ring-blue-500 ${
                    !disableVerifyButton
                      ? "bg-primary-200"
                      : "bg-[#8E9EAB]"
                  } `}
                >
                  Verify OTP
                </button>
              </div>
            </div>
            
          </Dialog.Panel>
        </div>
      </Transition.Child>
    </div>
    
  );
};

OtpModal.propTypes = {
  closeModal: propTypes.func,
  setProfile: propTypes.func,
  loginPage: propTypes.bool,
  setIsgetOTPClicked: propTypes.func,
  mobileNumberOrEmail: propTypes.string,
  verificationDetails: propTypes.object,
  mobileNumber: propTypes.string,
  setShowModal : propTypes.func,
  showModal : propTypes.bool,
  generateNewOtp : propTypes.func
};
export default OtpModal;
