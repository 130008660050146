import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import SearchIcon from "../../../../assets/icons/search-new.svg";
import { ReactComponent as ClearIcon } from "../../../../assets/icons/cancel.svg";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import { apirequest_schoolsAPI } from "../../../../API/api";
import { endpoint } from "../../../../API/config";
import { useSelector } from "react-redux";
import { loginDetails } from "../../../../redux/features/login/LoginSlice";

function ArticlesEventsSearch({
  isShadow = true,
  data,
  setFunction,
  placeholder,
  filterName,
  filterId,
  payloadKey,
  setCurrentPage,
}) {
  const [wordEntered, setWordEntered] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [filteredData, setFilteredData] = useState(data);
  const courseId = useSelector((state) => state.login.courseId);
  const LoginDetails = useSelector(loginDetails);
 
  const handleClick = (val) => {

    setShowDropdown(false);

    const promise = new Promise((resolve)=>{
      setTimeout(()=>resolve(()=>setWordEntered(val)),100);

    });
    promise.then(()=>{
      // console.log(wordEntered , "then");
      refetch(); //api call
    }
    );

  };

  const handleFilter = (event) => {
    setShowDropdown(true);
    const searchWord = event.target.value;
    if (searchWord === "") {
      setWordEntered("");
      setFilteredData(data);
      setFunction(data);
    } else {
      setWordEntered(event.target.value);
      const newFilter = data?.filter((value) => {
        return value[filterName]
          ?.toLowerCase()
          .includes(searchWord.toLowerCase());
      });
      setFilteredData(newFilter);
      //   setFunction(newFilter);
    }
  };

  const { refetch } = useQuery(
    endpoint.fullTextSearchForArticlesandEvents.key,
    () =>
      apirequest_schoolsAPI({
        method: "post",
        url: endpoint.fullTextSearchForArticlesandEvents.addr,
        headers: {
          "access-token": LoginDetails.token,
        },
        data: {
          moduleName: payloadKey,
          searchKey: wordEntered,
          courseId: courseId,
        },
      }),
    {
      enabled: false,
      onSuccess: (res) => {
        setFunction(res.data.data);
        setCurrentPage(1);
      },
      onError : ()=>setFunction([]),
      retry: 1,
    }
  );

  const outSideClickHandler = () => {
    setShowInput(false);
    setShowDropdown(false);
  };

  const clearTextHandler = () => {
    setWordEntered("");
    setShowDropdown(false);
    setFunction(data);
  };

  const handleSearchIconClick = () => {
    showInput && refetch();
  };

  const handleEnter = (e) => {
    // console.log(e.keyCode);
    if (e.keyCode === 13) {
      refetch();
    }
  };
  return (
    <OutsideClickHandler onOutsideClick={outSideClickHandler}>
      <div>
        <div
          className={`${
            showInput ? "min-w-[26rem] " : "max-w-[56px] "
          } rounded-2xl ${
            isShadow ? "shadow-outer" : ""
          } flex p-[1rem] relative bg-white `}
        >
          {showInput && (
            <div
              className={`${showInput ? "w-full" : ""} flex items-center pr-5 `}
            >
              <input
                type="text"
                name="search"
                placeholder={placeholder}
                className="w-full text-secondary-600 font-normal font-ibm focus:outline-none"
                onChange={(e) => handleFilter(e)}
                value={wordEntered}
                autoComplete="off"
                autoFocus={showInput}
                onKeyUp={handleEnter}
              />
              <ClearIcon
                className="cursor-pointer"
                onClick={clearTextHandler}
              />
            </div>
          )}
          <div className="h-6 w-6 flex items-center justify-center ">
            <img
              src={SearchIcon}
              className="cursor-pointer h-5 w-5 shrink-0 "
              alt="search"
              onClick={() => {
                setShowInput(true);
                handleSearchIconClick();
              }}
              loading="lazy"
            />
          </div>
        </div>

        {showDropdown && (
          <div className="bg-white z-50 shadow-md mt-5 rounded-2xl pl-[1rem] pt-[1.75rem] max-h-[15rem] overflow-y-scroll scrollbar-thumb-secondary-700 scrollbar-thin scrollbar-thumb-rounded absolute w-full max-w-[26rem]">
            {filteredData.length ? (
              filteredData?.map((val) => (
                <p
                  className="mb-5 hover:font-semibold cursor-pointer"
                  key={val[filterId]}
                  onClick={() => {
                    setWordEntered(val[filterName]);
                    handleClick(val[filterName]);
                  }}
                >
                  {val[filterName]}
                </p>
              ))
            ) : (
              <p className="text-sm font-ibm text-red-600 mb-2 flex justify-center">
                No Record found
              </p>
            )}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
}
ArticlesEventsSearch.propTypes = {
  isShadow: PropTypes.bool,
  data: PropTypes.array,
  setFunction: PropTypes.func,
  placeholder: PropTypes.string,
  filterName: PropTypes.string,
  filterId: PropTypes.string,
  payloadKey: PropTypes.string,
  setCurrentPage: PropTypes.func,
};
export default ArticlesEventsSearch;
