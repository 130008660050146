import React from "react";
import BookingSlotModal from "./BookingSlotModal";
import propTypes from "prop-types";

export const Trigger = () => {
  return (
    <p className="cursor-pointer underline text-primary-200 font-ibm h-12">
      Book Slot
    </p>
  );
};

const BookingSlotModalTrigger = ({
  onCloseFunctions,
  variantDetails,
  asset,
}) => {
  return (
    <BookingSlotModal
      Trigger={Trigger}
      onCloseFunctions={onCloseFunctions}
      variantDetails={variantDetails}
      asset={asset}
    />
  );
};

BookingSlotModalTrigger.propTypes = {
  onCloseFunctions: propTypes.array,
  variantDetails: propTypes.any,
  asset: propTypes.any,
};

export default BookingSlotModalTrigger;
