import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  collegeList: [
    {
      id: 12,
      college: "IIM Ahmedabad",
      abbreviation: "Indian Institute of Management",
      classification: "A++",
      ranking: 2,
      batch_size: 250,
      fee: "25.30 LPA",
      avg_salary: "250 L",
      duration: "2 yrs",
      exams_accepted: "CAT, NMAT, SNAP",
    },
    {
      id: 13,
      college: "IIM Bangalore",
      abbreviation: "Indian Institute of Management",
      classification: "A++",
      ranking: 2,
      batch_size: 250,
      fee: "25.30 LPA",
      avg_salary: "250 L",
      duration: "2 yrs",
      exams_accepted: "CAT, NMAT, SNAP",
    },
    // {
    //   id: 14,
    //   college: "IIM Indore",
    //   abbreviation: "Indian Institute of Management",
    //   classification: "A++",
    //   ranking: 2,
    //   batch_size: 250,
    //   fee: "25.30 LPA",
    //   avg_salary: "250 L",
    //   duration: "2 yrs",
    //   exams_accepted: "CAT, NMAT, SNAP",
    // },
    //   {
    //     mapping_id: 15,
    //     college: "IIM Roorkee",
    //     abbreviation: "Indian Institute of Management",
    //     classification: "A++",
    //     ranking: 2,
    //     batch_size: 250,
    //     fee: "25.30 LPA",
    //     avg_salary: "250 L",
    //     duration: "2 yrs",
    //     exams_accepted: "CAT, NMAT, SNAP",
    //   },
  ],
};




export const compareSlice = createSlice({
  name: "compare",
  initialState,
  reducers: {
    addCollege: (state, action) => {
      state.collegeList.push(action.payload);
    },
  },

  
});

export const { addCollege } = compareSlice.actions;


export const collegeList = (state) => state.compare.collegeList;



export default compareSlice.reducer;
